import React, { useEffect, useState } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import BreadCrumb from "../../global/breadCrumb";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import {
  sortByField,
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  IsTokenChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";

const CranberryManagementDetails = (props) => {
  const [cranberryManagement, setCranberryManagement] = useState([]);
  const [loading, setLoading] = useState(true);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const getCranberryManagement = async () => {
    const url =
      "/call_to_actions?select=*,page_listings!inner(slug)&page_listings.slug=eq.cranberry-research&order=order_by.asc";
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempCranberryManagement = safeArrayCheck(
          sortByField(_.get(resp, "data.data", []))
        );
        setCranberryManagement(tempCranberryManagement);
      })
      .catch((error) => {
        setCranberryManagement([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getCranberryManagement();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="ag-sciences" />
            <QuickLinks {...props} slug="ag-sciences" />
          </div>
          <div className="col-12 col-lg-10 region_list">
            <div className="row">
              {IsArrayNotEmpty(cranberryManagement) &&
                cranberryManagement.map((list, index) => {
                  const { id, feature_image_url, url = "/" } = list;
                  const title = _.get(list, `title_${resolvedLanguage}`, "");
                  return (
                    <div
                      key={id || `cran-mgmt-${index}`}
                      className={`col-12 col-lg-4 ${
                        index === 0 ? "mt-5" : ""
                      } mt-lg-0`}
                    >
                      <Link
                        className="position-relative d-block"
                        to={IsStringChecker(url) ? url : "/"}
                      >
                        {IsStringChecker(feature_image_url) && (
                          <img
                            src={feature_image_url}
                            className="img-fluid border-radius-6"
                          />
                        )}
                        <div className="position-absolute outer-grey-bg"></div>
                        {IsStringChecker(title) && (
                          <h4 className="position-absolute white_color fw-bold">
                            {title}
                          </h4>
                        )}
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CranberryManagementDetails;
