import React from "react";
import {
  IsStringChecker,
  regExpPreTagGlobal,
  regExpPreTag,
  regExpPreTagEnd,
  decodeHtml,
  lowStr,
  getproper_Atag_URLfromRichContent,
  GetTextFromDecodedHTML,
  getResolvedLanguage,
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import parse, { domToReact } from "html-react-parser";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import TruncateContent, { WidthBasedCharCount } from "./truncateContent";
import RichTextWrap from "./richTextWrap";
/**
 * @function: parseATagToLink
 * @description: Converts a tag to Link from react-router-dom. Suitable for internal routing.
 * @author: Ashish Ranjan & Nivethithan Kannan
 */
export function parseATagToLink(text = null) {
  try {
    const safeStringRenderer = (str) => {
      try {
        if (IsStringChecker(str)) {
          return str;
        } else {
          return "";
        }
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return "";
      }
    };
    const options = {
      replace: ({ name, attribs, children }) => {
        if (name === "a" && attribs.href) {
          const link = lowStr(attribs.href);
          const internalRoute =
            link.indexOf(".pdf") === -1 &&
            !link.includes("http:") &&
            !link.includes("https:") &&
            _.startsWith(link, "/");

          const className = `${safeStringRenderer(
            _.get(attribs, "class", "")
          )} ${safeStringRenderer(
            _.get(attribs, "className", "")
          )} ${safeStringRenderer(_.get(attribs, "classname", ""))}`;

          const newAttribs = { ...attribs };
          delete newAttribs.href;
          delete newAttribs.target;
          delete newAttribs.class;
          delete newAttribs.classname;
          delete newAttribs.className;
          delete newAttribs.style;

          if (internalRoute) {
            return (
              <Link
                to={attribs.href}
                className={className.trim()}
                {...newAttribs}
              >
                {domToReact(children)}
              </Link>
            );
          }
        }
      },
    };
    return parse(text, options);
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return "";
  }
}

/**
 * @function: ContentModifierLogic
 * @description: Extracts the content from <pre></pre> tag. Suitable for content block responses.
 * @author: Nivethithan Kannan
 */
export const ContentModifierLogic = (str = null) => {
  function replacer(match) {
    try {
      // console.log("match => ",match);
      let decode = decodeHtml(match);
      let final = IsStringChecker(decode)
        ? decode.replace(regExpPreTag, "").replace(regExpPreTagEnd, "")
        : decode;
      // console.log("Final => ",final);
      return final;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return match;
    }
  }

  try {
    let content = str.replace(regExpPreTagGlobal, replacer);
    // console.log("content => ", content);
    return content;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return str;
  }
};

export const RemoveNBSPfromString = (str = null) => {
  try {
    if (IsStringChecker(str)) {
      // console.log({str, rep:str.replace(/&nbsp;/g,"")});
      return str.replace(/&nbsp;/g, " ");
    }
    return str;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return str;
  }
};

export const AddIMGClassToString = (str = null) => {
  try {
    if (IsStringChecker(str)) {
      // console.log({str, rep:str.replace(/&nbsp;/g,"")});
      return str.replace(
        /<img src="data/g,
        `<img class="cms-content-block-img-fix" src="data`
      );
    }
    return str;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return str;
  }
};

const ContentModifier = ({
  str = null,
  format = true,
  // nbsp = false,
  imgClass = true,
  fontSize = "14px",
  key = null,
}) => {
  if (str.indexOf("ql-video") > -1) {
    let element = document.createElement("div");
    element.style.display = "none";
    element.innerHTML = str;
    Array.prototype.forEach.call(
      element.getElementsByClassName("ql-video"),
      (element) => {
        element.outerHTML = `<div id="iframe-video" class='video-iframe'>${element.outerHTML}</div>`;
      }
    );
    str = element.getElementsByClassName("ql-editor")[0].outerHTML;
    element.remove();
  }
  // const modifiedContent = nbsp ? str : RemoveNBSPfromString(str);
  const imgClassAddedContent = imgClass ? AddIMGClassToString(str) : str;
  const content = IsStringChecker(imgClassAddedContent)
    ? ContentModifierLogic(imgClassAddedContent)
    : "";
  if (IsStringChecker(content)) {
    if (format) {
      return <RichTextWrap key={key} fontSize={fontSize}>{parseATagToLink(content)}</RichTextWrap>;
    } else {
      return <RichTextWrap key={key} fontSize={fontSize}>{parse(content)}</RichTextWrap>;
    }
  } else {
    return <></>;
  }
};
export default ContentModifier;

export const NewsContentToModify = ({
  str = null,
  allVideos = true,
  videoCount = 1,
  newsLink = null,
  readMore = null,
  post = null,
}) => {
  const FilterVideoCount = (final = "", videoCount = 1) => {
    const groupHeadTag = `<div class="video-iframe-group">`;
    const videoHeadTag = `<div class="video-iframe">`;
    const videoEndTag = `</div>`;
    const iframe = `<iframe`;
    try {
      const IframeVideoArray = final.includes(groupHeadTag)
        ? final.replace(groupHeadTag, "").split(videoEndTag)
        : final.split(videoEndTag);
      const VideoArray = IframeVideoArray.filter((list) =>
        list.includes(iframe)
      ).map((list) =>
        list.includes(videoHeadTag)
          ? `${list}\n${videoEndTag}`
          : `${videoHeadTag}\n${list}\n${videoEndTag}`
      );
      // console.log("VideoArray", VideoArray);
      const returnVideo = VideoArray.slice(
        0,
        videoCount <= VideoArray.length ? videoCount : VideoArray.length
      );
      // console.log("ReturnVideo", returnVideo);
      const videoFinal = `${groupHeadTag}\n${returnVideo.join(
        ""
      )}\n${videoEndTag}`;
      return videoFinal;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return final;
    }
  };

  const ContentModifierLogicWithVideoCount = (
    str = null,
    allVideos = true,
    videoCount = 1
  ) => {
    function replacer(match) {
      try {
        let decode = decodeHtml(match);
        let final = IsStringChecker(decode)
          ? decode.replace(regExpPreTag, "").replace(regExpPreTagEnd, "")
          : decode;
        // console.log("Final => ", final);
        if (allVideos) {
          return final;
        } else {
          return FilterVideoCount(final, videoCount);
        }
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return match;
      }
    }

    try {
      if (str.indexOf("ql-video") > -1) {
        let element = document.createElement("div");
        element.style.display = "none";
        element.innerHTML = str;
        Array.prototype.forEach.call(
          element.getElementsByClassName("ql-video"),
          (element) => {
            element.outerHTML = `<div id="iframe-video" class='video-iframe'>${element.outerHTML}</div>`;
          }
        );
        str = element.getElementsByClassName("ql-editor")[0].outerHTML;
        element.remove();
      }
      let content = str.replace(regExpPreTagGlobal, replacer);
      return content;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return str;
    }
  };

  if (IsStringChecker(str)) {
    const descLength = GetTextFromDecodedHTML(str).length;
    const charactersCount = WidthBasedCharCount();
    const PerFactor = 10; //Assumption total lines per content;
    const truncSymbol = "...";
    const truncated =
      descLength > charactersCount
        ? TruncateContent(str, truncSymbol, charactersCount, PerFactor)
        : str;
    const content = ContentModifierLogicWithVideoCount(
      // RemoveNBSPfromString(getproper_Atag_URLfromRichContent(truncated)),
      getproper_Atag_URLfromRichContent(truncated),
      allVideos,
      videoCount
    );
    return IsStringChecker(content) ? (
      <RichTextWrap>
        {parseATagToLink(content)}
        {descLength > charactersCount && (
          <div className="mt-3">
            {IsStringChecker(newsLink) && (
              <Link
                onClick={() =>
                  sessionStorage.setItem(
                    "selecteddetailpage",
                    JSON.stringify(post)
                  )
                }
                to={newsLink}
              >
                {readMore}
              </Link>
            )}
          </div>
        )}
      </RichTextWrap>
    ) : (
      <></>
    );
  } else {
    return <></>;
  }
};

export const ForumContentToModify = ({ str = null, forumLink = null }) => {
  const charactersCount = WidthBasedCharCount("forum-post");
  const truncSymbol = "...";
  const PerFactor = 3; //Assumption total lines per content;

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "home_page"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return defaultValue;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultValue;
    }
  };

  const ContentModifierLogicForForum = (str = null) => {
    function replacer(match) {
      try {
        let decode = decodeHtml(match);
        let final = IsStringChecker(decode)
          ? decode.replace(regExpPreTag, "").replace(regExpPreTagEnd, "")
          : decode;
        // console.log("Final => ", final);
        return final;
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return match;
      }
    }

    try {
      let content = str.replace(regExpPreTagGlobal, replacer);
      return content;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return str;
    }
  };

  // const readMore = LocalTranslator("read_more", "Read More");
  const view_post = LocalTranslator("view_post", "View Post");

  if (IsStringChecker(str)) {
    // const descLength = GetTextFromDecodedHTML(str).length;
    const truncated = TruncateContent(
      str,
      truncSymbol,
      charactersCount,
      PerFactor
    );
    const content = ContentModifierLogicForForum(
      // RemoveNBSPfromString(getproper_Atag_URLfromRichContent(truncated))
      getproper_Atag_URLfromRichContent(truncated)
    );
    return (
      <>
        {IsStringChecker(content) ? <RichTextWrap>{parseATagToLink(content)}</RichTextWrap> : <></>}
        {IsStringChecker(forumLink) && (
          <p className="mt-2">
            <Link to={forumLink}>
              {view_post}
              {/* {descLength > 100 ? readMore : view_post} */}
            </Link>
          </p>
        )}
      </>
    );
  } else {
    return (
      <>
        {IsStringChecker(forumLink) && (
          <p className="mt-2">
            <Link to={forumLink}>
              {view_post}
              {/* {descLength > 100 ? readMore : view_post} */}
            </Link>
          </p>
        )}
      </>
    );
  }
};
