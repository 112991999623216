import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  IsTokenChecker,
  getCurrentYear,
  toDecimal,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
// import parse from "html-react-parser";
import ContentModifier from "../../global/contentModifier";
import Loading from "../../global/loading";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import moment from "moment";
import { dateFormate } from "../../../utils/constantsVariable";
// import DatePicker from "react-datepicker";
import MultiDropDown from "../../selectBox/multiDropDown";
import DataGridPagination from "../../global/dataGridPagination";
// import Moment from "react-moment";
import CurrentLocation from "../../global/currentLocation";
import { Accordion, Modal } from "react-bootstrap";
import ExportFile from "../../exportFile/exportFile";
import DatePickerComp from "../../global/datepicker";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import {EPURSubmitPDFFile as PdfFile} from "../../exportFile/pdfFile";
import { DetermineImpersonation } from "../../../permissionWrapper";

const SubmitEndOfSeasonReport = (props) => {
  const myRef = useRef(null);
  const asyncTypeaheadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const impersonated = DetermineImpersonation();

  const contractData = useRef({});
  const cropYear = getCurrentYear();
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  // const personContracts = accounts[0].idTokenClaims.gbsContractIds;
  const [, moreLangs] = CurrentLocation();
  const [contentBlock, setcontentBlock] = useState(null);
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    isChecked: 0,
    contact_name: accounts[0].idTokenClaims?.name,
    phone: "",
    email: accounts[0].idTokenClaims?.email,
    anticipated_harvest_date: "",
    ids: [],
  });

  const dispatch = useDispatch();
  const [contractNumbers, setContractNumbers] = useState([]);
  const [defaultContract, setDefaultContract] = useState("");
  const [defContracts, setdefContracts] = useState([""]);
  const [CN_MultiValue, setCN_MultiValue] = useState([]);

  const [filteredGridItem, setFilteredGridItem] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [tableHasNewEntry, setTableHasNewEntry] = useState([]);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    message: "",
    onsubmit: null,
  });

  const dateFormat = (date) => {
    date = date?.split("T")[0]?.split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  }

  const [allSubmitted, setAllSubmitted] = useState(true);
  const [selectedObject, setSelectedObject] = useState({});

  const columns = [
    {
      key: "ContractName",
      name: "Contract #",
      cellClass: "enablesTextWrapping",
      width: 100,
    },
    {
      key: "AppliedDate",
      name: "Application Date",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 100,
      dateFormate: dateFormate,
      formatter({ row }) {
        return (
          <>{dateFormat(row.AppliedDate)}</>
        );
      },
    },
    {
      key: "PesticideCodeName",
      name: "EPA Or PCP Reg.# / Chemical",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "Rate",
      name: "Rate (Formulated Product Per Acre)",
      minWidth: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "Description",
      name: "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "MethodName",
      name: "Method Of Application",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "TypeName",
      name: "Fruit Type",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "HarvestBedList",
      childkey: "HarvestBed",
      name: "Bed(S)",
      width: 130,
      cellClass: "enablesTextWrapping text-lg-center",
      formatter({ row }) {
        return (
          <><span className="d-block expandedValue">{row.HarvestBedList} {(row.HarvestBedList.indexOf('...') > -1 || row.HarvestBedListIsExpanded) && <button title={!row.HarvestBedListIsExpanded ? 'Show more' : 'Hide'} className="show-more" onClick={() => showMore(row)}>{!row.HarvestBedListIsExpanded ? '+' : '-'}</button>} </span></>
        )
      }
    },
    {
      key: "ExhibitAcres",
      name: "Total Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">
              {toDecimal(row.ExhibitAcres, 2)}
            </span>
          </>
        );
      },
    },
    {
      key: "AcresTreated",
      name: "Treated Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">
              {toDecimal(row.AcresTreated, 2)}
            </span>
          </>
        );
      },
    },
    {
      key: "IsFinalise",
      name: "Status",
      width: 85,
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">
              {row.IsFinalise ? (
                <span className="light_green_color">Submitted</span>
              ) : (
                <span className="red_dark_color">Not Submitted</span>
              )}
            </span>
          </>
        );
      },
    },
    {
      key: "PURContactEmail",
      name: "PUR Contact Email",
      width: 95,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping emailBreak",
    },
  ];

  const showMore = (row) => {
    const gridData = contractData.current;
    let index = gridData.findIndex((item) => row.ItemId === item.ItemId);
    if (index > -1) {
      if (!row.HarvestBedListIsExpanded) {
        gridData[index].HarvestBedList = gridData[index].HarvestBedListView;
        gridData[index].HarvestBedListIsExpanded = true;
      }
      else {
        gridData[index].HarvestBedList = gridData[index].HarvestBedListHiddenView;
        gridData[index].HarvestBedListIsExpanded = false;
      }
    }
    myRef?.current?.scrollIntoView();
    setFilteredGridItem([...gridData]);
    setTimeout(() => {
      let height = "";
      document.querySelectorAll('[role="grid"] [role="row"]').forEach((i) => {
        let height1 = [], expandValue = '';
        i.childNodes.forEach((k) => {
          if (k.querySelectorAll('.expandedValue').length == 1 && k.querySelectorAll('[title="Hide"').length == 1) {
            expandValue = k.querySelectorAll('.expandedValue')[0].offsetHeight;
            height1.push(k.querySelectorAll('.expandedValue')[0].offsetHeight);
          }
          else {
            height1.push(k.offsetHeight);
          }
        });
        if (expandValue != '') {
          height += expandValue + 30 + 'px ';
        }
        else if (height1.sort()[0] > 0) {
          height += height1.sort()[0] + 'px ';
        }
      });
      document.querySelector('[role="grid"]').style.gridTemplateRows = height;
    }, 500);
  }
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlock();
      if (props.roleAgScientist) {
        getEPURDataForAGScientist();
      }
    }
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  useEffect(() => {
    setdefContracts([defaultContract]);
    setCN_MultiValue({ contractid: defaultContract });
  }, [defaultContract]);

  useEffect(() => {
    if (props.GBSContracts.length > 0) {
      fetchContract();
    }
  }, [props.GBSContracts]);

  useEffect(() => {
    if (props.isDataAdded && ((CN_MultiValue.hasOwnProperty("contractid") && CN_MultiValue?.contractid !== "") || props.roleAgScientist)) {
      props.setISDataAdded(false);
      if (props.roleAgScientist) {
        contractTypeheadChangeEvent(CN_MultiValue.contractid);
      }
      else {
        handlecontractFilterChange({ id: CN_MultiValue.contractid });
      }
    }
  }, [props]);

  //AG Scientist role start
  const limit = 10;
  const pageNo = 1;
  const [totalPageCount, setTotalPageCount] = useState(null);
  // const [isContractSelected, setIsContractSelected] = useState(props.roleAgScientist ? (props.roleAgScientist && CN_MultiValue.contractid ==="") : true);
  const [isgridValuChanges, setIsgridValuChanges] = useState(true);
  const [allUnSubmitted, setAllUnSubmitted] = useState(true);
  //AG Scientist role End
  useEffect(() => {

  }, [totalPageCount])
  const getEPURDataForAGScientist = (page = null, isMobile = false) => {
    setLoader(true);
    setAllSubmitted(true);
    gbsDataCommonMethod(idToken, accounts, `pesticides/e-pur?cropyear=${cropYear}&limit=${limit}&page=${page || pageNo}&showtotal=1`, "get", {})
      .then((resp) => {
        setLoader(false);
        // console.log(resp);
        setIsgridValuChanges(true);
        if (Array.isArray(resp.data)) {
          let data = resp.data;
          setTotalPageCount(data[0].total);
          data = data.splice(1);
          if (!isMobile)
            formatEPURData(data);
          else {
            data.length === 0 && setMobilePageOff(false);
            formatEPURData(data, isMobile);
          }

        }
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  }

  const getNextpagecount = (e) => {
    getEPURDataForAGScientist(e + 1);
  }

  const handleClickExcelData = async () => {
    setLoader(true);
    let data = [];
    try {
      data = await gbsDataCommonMethod(idToken, accounts, `pesticides/e-pur-export?cropyear=${cropYear}`, "get", {})
        .then((resp) => {
          setLoader(false);
          setIsgridValuChanges(true);
          return resp.data?.map((li) => {
            return {
              "Contract Number": li.RelationshipNumber,
              "Contract Name": li.Name,
              "Application Date": dateFormat(li.AppliedDate),
              "EPA Or PCP Reg.# / Chemical": li.PesticideCodeName,
              "Rate (Formulated Product Per Acre)": li.Rate,
              "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)": li.Description,
              "Method Of Application": li.MethodName,
              "Fruit Type": li.TypeName,
              "Bed(S)": li.AllBeds,
              "Total Acres": li.TotalExhibitAcres,
              "Treated Acres": li.TotalAcresTreated,
              Status: li.IsFinalise ? "Submitted" : "Not Submitted",
              "PUR Contact Email": li.ContactEmail,
            };
          });
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          return data;
        });
    }
    catch (e) {
      data = [];
    }

    return data;
  }
  const [mobilePageNo, setMobilePageNo] = useState(1);
  const [mobilePageOff, setMobilePageOff] = useState(true);
  const loadMoreData = () => {
    getEPURDataForAGScientist(mobilePageNo + 1, true);
    setMobilePageNo(mobilePageNo + 1);
  }
  // Fetching Contracts
  const fetchContract = async () => {
    if (props.GBSContracts.length === 1) {
      const getcurrentcrop =
        props.GBSContracts.length === 1
          ? props.GBSContracts[0].RelationshipId
          : 0;
      setDefaultContract(getcurrentcrop.toString());
      setdefContracts([defaultContract]);
      setCN_MultiValue({ contractid: defaultContract });
      filterPesticideEpur(props.GBSContracts[0].RelationshipId);
    }
    setContractNumbers(
      setOptions(
        props.GBSContracts,
        "RelationshipId",
        false,
        "RelationshipNumber",
        "Name"
      )
    );
  };

  const filterPesticideEpur = (id) => {
    handlecontractFilterChange({ id });
  };

  const getContentBlock = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      "content_blocks?select=id,content_en,content_fr,content_es,order_by,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_es,accordion_name_fr,accordion_name_en)&component_listings.component_id=eq.276a5ffe-4b1e-47fc-a173-829088532901"
    )
      .then((resp) => {
        if (resp?.data?.data[0]) {
          setcontentBlock(resp?.data?.data[0]);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // creating option for mulit select
  const setOptions = (
    data,
    keyPrram,
    unique,
    concatValue1 = "",
    concatValue2 = ""
  ) => {
    if (unique && keyPrram !== "RelationshipId" && concatValue1 === "") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      let contracts = [];
      if (concatValue1 !== "" && concatValue2 !== "") {
        contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
      } else if (concatValue1 !== "") {
        contracts = data.map((li) => {
          return { name: li[concatValue1], id: li[keyPrram] };
        });
      }
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  const handlecontractFilterChange = async (selectedItem, upload = false) => {
    setSelectedObject(selectedItem.id)
    setLoader(true);
    if(upload){
      await PdfFile(ArrangedData(filteredGridItem,'PDF'),uploadPDF);
    }
    let url = `pesticides/e-pur?relationshipid=${selectedItem.id}&cropyear=${cropYear}`;
    if (selectedItem.hasOwnProperty("id")) {
      await gbsDataCommonMethod(idToken, accounts, url, "get", {})
        .then((resp) => {
          formatEPURData(resp.data);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    } else {
      setFilteredGridItem([]);
      setNoResultsFound(false);
      setTableHasNewEntry([]);
      setLoader(false);
    }
  };

  const formatEPURData = (resp, isMobile) => {
    if (Array.isArray(resp) && resp.length > 0) {
      let PesticideArray = formatEPURResponseData(resp);
      PesticideArray = _.orderBy(
        PesticideArray,
        ["IsFinalise"],
        ["asc"]
      );
      if (!props.roleAgScientist) {
        let allSubmitted = PesticideArray.filter(
          (i) => i.IsFinalise === true
        );
        setAllSubmitted(allSubmitted.length === PesticideArray.length);
      }
      let FilteredPesticideArray = PesticideArray;
      setNoResultsFound(false);
      if (FilteredPesticideArray.length === 0)
        setNoResultsFound(true);
      setTableHasNewEntry(FilteredPesticideArray);
      isMobile ? setFilteredGridItem([...filteredGridItem, ...FilteredPesticideArray]) : setFilteredGridItem([...FilteredPesticideArray]);
      contractData.current = FilteredPesticideArray;
      return FilteredPesticideArray;
    } else {
      setFilteredGridItem([]);
      setNoResultsFound(true);
    }
  }
  const bedSort = (data = []) =>{
    return data?.sort((a, b) =>
      a.HarvestBed?.toString()?.localeCompare(b?.HarvestBed?.toString())
    );
  }
  const formatEPURResponseData = (resp) => {
    let PesticideArray = [];
    resp?.map((item, index) => {
      // let hiddenData = item.Beds.length > 5 ? item.Beds?.map((i) => i.HarvestBed).splice(0, 5)?.toString()?.replace(/,/g, ", ") + '...' : item.Beds?.map((i) => i.HarvestBed)
      //   ?.toString()
      //   ?.replace(/,/g, ", ")
      //   ?.trim();

      // let beds = item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null);
      // let hiddenData = beds.length > 5 ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ") !==""  ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ")+ '...' : "" : item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null)
      // ?.toString()
      // ?.replace(/,/g, ", ")
      // ?.trim();
      item.Beds = bedSort(item.Beds);
      let beds = item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null);
      let BedList = beds.length > 5 ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ")?.trim()+ '...' : beds?.toString()?.replace(/,/g, ", ")?.trim();
      let hiddenData = BedList; //beds.length > 5 ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ") !==""  ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ")+ '...' : "" : item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null)?.toString()?.replace(/,/g, ", ")?.trim();

      let ExhibitAcresDecimalLength=0, AcresTreatedDecimalLength=0;
      item.Beds.map(i=>{
        let a = i.ExhibitAcres?.toString()?.split(".") || "";
        if(a.length === 2){
          ExhibitAcresDecimalLength < a[1].length ? ExhibitAcresDecimalLength=a[1].length : ExhibitAcresDecimalLength = ExhibitAcresDecimalLength; 
        }    
      });
      item.Beds.map(i=>{
        let a = i.AcresTreated?.toString()?.split(".") || "";
        if(a.length === 2){
          AcresTreatedDecimalLength < a[1].length ? AcresTreatedDecimalLength=a[1].length : AcresTreatedDecimalLength = AcresTreatedDecimalLength; 
        }    
      });
            
      let obj = {
        ItemId: `${item.Id}-${index}`,
        Id: item.Id,
        RelationshipId: item.RelationshipId,
        RelationshipNumber: item.RelationshipNumber,
        Name: item.Name,
        CropYear: item.CropYear,
        AppliedDate: item.AppliedDate,
        PesticideId: item.PesticideId,
        PesticideCode: item.PesticideCode,
        PesticideName: item.PesticideName,
        PesticideCodeName: `${item.PesticideCode}-${item.PesticideName}`,
        Rate: item.Rate,
        RateUomId: item.RateUomId,
        Description: item.Description,
        MethodId: item.MethodId,
        MethodName: item.MethodName,
        TypeId: item.TypeId,
        TypeName: item.TypeName,
        IsFinalise: item.IsFinalise,
        HarvestBedList: hiddenData,
        HarvestBedListView: item.Beds?.filter((i) =>{if(i.HarvestBed !== null) return i })?.map(i=>i.HarvestBed)?.toString()?.replace(/,/g, ", ")?.trim(),
        HarvestBedListHiddenView: hiddenData,
        HarvestBedListIsExpanded: false,
        ContractName: `${item.RelationshipNumber} - ${item.Name}`,
        ExhibitAcres: ExhibitAcresDecimalLength > 0 ? parseFloat(_.sumBy(item.Beds, "ExhibitAcres")?.toFixed(ExhibitAcresDecimalLength)) : _.sumBy(item.Beds, "ExhibitAcres"),
        AcresTreated: AcresTreatedDecimalLength > 0 ? parseFloat(_.sumBy(item.Beds, "AcresTreated")?.toFixed(AcresTreatedDecimalLength)) : _.sumBy(item.Beds, "AcresTreated"),
        PURContactEmail: item.ContactEmail || "-",
      };
      PesticideArray.push(obj);
    });
    return PesticideArray;
  }
  const submitESR = async () => {
    let url = `pesticides/e-pur/submit-end-of-season`;
    let request = {
      contact_name: formData.contact_name,
      phone: formData.phone,
      email: formData.email,
      anticipated_harvest_date: moment(
        formData.anticipated_harvest_date
      ).format(dateFormate),
      ids: tableHasNewEntry.map((i) => i.Id),
    };
    await gbsDataCommonMethod(idToken, accounts, url, "put", request)
      .then((resp) => {
        if (resp?.data?.status === "success") {
          setShowModal({
            isOpen: true,
            message: "Your report has been submitted successfully",
          });
          // props.getEPURsPesticideData();
          !props.roleAgScientist ? handlecontractFilterChange({ id: CN_MultiValue.contractid }, true): contractTypeheadChangeEvent(CN_MultiValue.contractid, true);
          setFormData({ ...formData, phone: "", anticipated_harvest_date:"", isChecked: 0 });
          document.getElementById("other_option").checked = false;
          props.setISDataAdded(true);
          if(props.roleAgScientist){
            setAllUnSubmitted(false);
            setAllSubmitted(true);
          }
        }
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const unSubmitESR = async () =>{
    let url = 'pesticides/e-pur/undo-submit-end-of-season';
    await gbsDataCommonMethod(idToken, accounts, url, "put", {
      "ids": filteredGridItem.map((i)=>i.Id)})
      .then((resp) => {
        if (resp?.data?.status === "success") {
          setShowModal({
            isOpen: true,
            message: "Your report has been unsubmitted successfully",
          });
          !props.roleAgScientist ? handlecontractFilterChange({ id: CN_MultiValue.contractid }) : contractTypeheadChangeEvent(CN_MultiValue.contractid);
          setFormData({ ...formData, phone: "", anticipated_harvest_date: "" });
          props.setISDataAdded(true);
          if (props.roleAgScientist) {
            setAllUnSubmitted(false);
            setAllSubmitted(false);
          }
        }
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  }
  const onModalHide = () => {
    setShowModal({ isOpen: false, message: "" });
  };

  // Converting raw data to Arranged Data
  const ArrangedData = (resultData, type) => {
    let arrangedData = resultData.map((li) => {
      if(type === "PDF"){
        return {
          "Contract Number": li.RelationshipNumber,
          "Contract Name": li.Name,
          "Application Date": dateFormat(li.AppliedDate),
          "EPA Or PCP Reg.# / Chemical": li.PesticideCodeName,
          "Rate": li.Rate,
          "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)": li.Description,
          "Method Of Application": li.MethodName,
          "Fruit Type": li.TypeName,
          "Bed(S)": li.HarvestBedListView,
          "Total Acres": li.ExhibitAcres,
          "Treated Acres": li.AcresTreated,
          "Status": "Submitted",
          "PUR Contact Email": formData.email,
          "Submitted Date": moment().format(dateFormate),
        };
      }
      else{
        return {
          "Contract Number": li.RelationshipNumber,
          "Contract Name": li.Name,
          "Application Date": dateFormat(li.AppliedDate),
          "EPA Or PCP Reg.# / Chemical": li.PesticideCodeName,
          "Rate (Formulated Product Per Acre)": li.Rate,
          "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)": li.Description,
          "Method Of Application": li.MethodName,
          "Fruit Type": li.TypeName,
          "Bed(S)": li.HarvestBedListView,
          "Total Acres": li.ExhibitAcres,
          "Treated Acres": li.AcresTreated,
          Status: li.IsFinalise ? "Submitted" : "Not Submitted",
          "PUR Contact Email": li.PURContactEmail,
        };
      }      
    });
    if(type === "PDF"){
      arrangedData = _.orderBy(arrangedData, ["Application Date"], ["asc"]);
    }
    return arrangedData;
  };

  const handleSearch = (query) => {
    if (/^\d+$/.test(query)) {
      setIsLoading(true);
      gbsDataCommonMethod(idToken, accounts, `/contracts?r.relationshipnumber=${query}`, "get", {})
        .then((res) => {
          let data = res.data.filter((a) => {
            return [1000, 1001].includes(a.ContractStatus);
          });
          data = res.data.map((i) => {
            i.ContractRelationshipNumber = `${i.RelationshipNumber} ${i.Name || ''} ${i.Name2 || ''} ${i.Name3 || ''}`;
            return i;
          })
          setContractOptions(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    }
  };

  const contractTypeheadChangeEvent = async (RelationshipId , upload = false) => {
    setSelectedObject(RelationshipId);
    setIsgridValuChanges(false);
    setLoader(true);
    if(upload){
      await PdfFile(ArrangedData(filteredGridItem,'PDF'),uploadPDF)
    }
    if (RelationshipId !== "") {
      // setIsContractSelected(true);
      setCN_MultiValue({ contractid: RelationshipId })
      gbsDataCommonMethod(idToken, accounts, `pesticides/e-pur?relationshipid=${RelationshipId}&cropyear=${cropYear}`, "get", {})
        .then((resp) => {
          setLoader(false);
          // console.log(resp);
          setIsgridValuChanges(true);
          if (Array.isArray(resp.data)) {
            let data = resp.data;
            setTotalPageCount(null);
            formatEPURData(data);
            let allSubmitted = data.filter(
              (i) => i.IsFinalise === true
            );
            setAllSubmitted(allSubmitted.length === data.length);
            let allUnsubmitted = data.filter(
              (i) => i.IsFinalise === false
            );
            setAllUnSubmitted(allUnsubmitted.length === data.length);
          }
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          setIsgridValuChanges(true);
        });
    }
  }

  const uploadPDF = async (doc) =>{
    let file = doc.output('blob');
    let fd = new FormData();
    let filter = props?.GBSContracts?.filter(i=>i.RelationshipId?.toString() === selectedObject.toString());
    if(contractOptions.length > 0){
      filter = contractOptions?.filter(i=>i.RelationshipId?.toString() === selectedObject.toString());
    }
    fd.append('uploaded_file',file);
    setLoader(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `azure-blob-storage/purpdf/pur-${cropYear}-${filter[0]?.RelationshipNumber}.pdf`,"POST",fd
    )
      .then(() => {
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  }

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        {!props.roleAgScientist &&
          <div className="col-12 col-lg-3">
            <div className="contact_number_Label custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
              <MultiDropDown
                optionList={contractNumbers}
                prompt="Select"
                value={CN_MultiValue}
                onChange={(selectedList, selectedItem) => {
                  handlecontractFilterChange(selectedItem);
                }}
                label="contractid"
                usestate={setCN_MultiValue}
                onDelete={(selectedList, removedItem) =>
                  handlecontractFilterChange(selectedList, removedItem)
                }
                disabled={false}
                singleSelect={true}
                preSelectedOpt={defContracts}
                optionNotFound={{ contractid: noContractsFound }}
              />
            </div>
          </div>
        }
        {props.roleAgScientist && <div className="col-12 col-lg-4 personal_info">
          <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
            <span className="text-14 z-index-1 mandatoryField ps-3">
              Contract Number & Name
            </span>
            {/* {fieldReset && */}
            <AsyncTypeahead
              // clearButton              
              ref={asyncTypeaheadRef}
              id="async-example"
              isLoading={isLoading}
              labelKey="ContractRelationshipNumber"
              minLength={3}
              onSearch={handleSearch}
              options={contractOptions}
              placeholder="Search contract number"
              // filterBy={["RelationshipNumber", "Name","Name2","Name3"]}
              className="AG_contracts"
              onChange={(data) => contractTypeheadChangeEvent(data[0].RelationshipId)}
            >
              {({ onClear, selected }) =>
                !!selected.length && (
                  <div className="rbt-aux">
                    <ClearButton
                      onClick={() => {
                        onClear();
                        setCN_MultiValue({ contractid: "" });
                        getEPURDataForAGScientist(1);
                        setIsgridValuChanges(true);
                      }}
                    />
                  </div>
                )
              }
            </AsyncTypeahead>
            {/* } */}
            {/* <input type="text" aria-label="epa-pcp" className="form-control mb-3" /> */}
          </div>
        </div>
        }
      </div>
      {/* show grid with Export buttons*/}
      <div className="row mt-2 hidden_print">
        <div
          className={`col-12 me-4 d-none ${filteredGridItem.length == 0 ? "" : "d-lg-block"
            } `}
        >
          <div className="float-end mb-2">
            {/* {
              props.roleAgScientist ? <>
                  <NavLink
                to="#"
                className="d-inline-block "
                onClick={handleClickExcelData}
                data-tip="Export as Excel"
                data-for="main"
                data-iscapture="true"
              >
                {" "}
                <i
                  className="fa fa-file-excel-o text-16 border-1 border-radius-6 excelIconPadding position-relative"
                  alt="ExcelIcon"
                ></i>
              </NavLink>
              <ReactTooltip
                id="main"
                place="bottom"
                effect="float"
                type="dark"
                multiline={true}
              />
                  </> :
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={ArrangedData(filteredGridItem)}
                    fileName="EndOfSeasonReport"
                  />
                </span>
              } */}
            {
              props.roleAgScientist && CN_MultiValue.contractid === "" ? <span>
                <ExportFile
                  downloadFormate="xlsx"
                  exportData={ArrangedData(filteredGridItem)}
                  fileName="EndOfSeasonReport"
                  getData={handleClickExcelData}
                />
              </span> :
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={ArrangedData(filteredGridItem)}
                    fileName="EndOfSeasonReport"
                  />
                </span>
            }
          </div>
        </div>
      </div>
      {/*<!-- Desktop table starts -->*/}
      {filteredGridItem.length > 0 && isgridValuChanges && !displayAccordian && (
        <div ref={myRef} className="expanded-row hor-space-20 top-space-20 light_bg  mb-lg-4 bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
          <DataGridPagination
            uniqueIdentifier="PaymentId"
            gridData={filteredGridItem}
            columns={columns}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
            autoRowHeight={true}
            dynamicHeaderHeight={80}
            totalRecordCount={totalPageCount}
            getNextpage={getNextpagecount}
          // summaryRows = {summaryRows}
          />
        </div>
      )}

      {filteredGridItem.length > 0 && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="e-purEdit delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion>
              {filteredGridItem.length > 0 &&
                Object.values(filteredGridItem).map((data) => {
                  return (
                    <Fragment key={data.Id}>
                      <Accordion.Item eventKey={data.Id}>
                        <Accordion.Header>
                          <div>
                            Contract #: {data.RelationshipNumber} <br />
                            Application Date:{" "}
                            {
                              dateFormat(data.AppliedDate)
                            }
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div className="td" data-header="expand_icon">
                                <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>
                              </div>
                              <div className="td" data-header="Contract #:">
                                <span>
                                  {data.RelationshipNumber} - {data.Name}
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="Application Date:"
                              >
                                <span>
                                  {
                                    dateFormat(data.AppliedDate)
                                  }
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="EPA or PCP Reg.#/Chemical:"
                              >
                                <span>
                                  {data.PesticideCode}-{data.PesticideName}
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="Rate(formulated product per acre):"
                              >
                                <span>{data.Rate}</span>
                              </div>
                              <div
                                className="td"
                                data-header="Rate unit of measure per acres(e.g. oz/acre):"
                              >
                                <span>{data.Description}</span>
                              </div>
                              <div
                                className="td"
                                data-header="Method of Application:"
                              >
                                <span>{data.MethodName}</span>
                              </div>
                              <div className="td" data-header="Fruit Type:">
                                <span>{data.TypeName}</span>
                              </div>
                              <div className="td" data-header="Bed(s):">
                                <span>{data.HarvestBedList}<span>{(data.HarvestBedList.indexOf('...') > -1 || data.HarvestBedListIsExpanded) && <button title={!data.HarvestBedListIsExpanded ? 'Show more' : 'Hide'} className="show-more" onClick={() => showMore(data)}>{!data.HarvestBedListIsExpanded ? '+' : '-'}</button>} </span></span>
                              </div>
                              <div className="td" data-header="Total Acres:">
                                <span>{toDecimal(data.ExhibitAcres, 2)}</span>
                              </div>
                              <div className="td" data-header="Treated Acres:">
                                <span>{toDecimal(data.AcresTreated, 2)}</span>
                              </div>
                              <div className="td" data-header="Status:">
                                {data.IsFinalise ? (
                                  <span className="light_green_color">
                                    Submitted
                                  </span>
                                ) : (
                                  <span className="red_dark_color">
                                    Not Submitted
                                  </span>
                                )}
                              </div>
                              <div
                                className="td "
                                data-header="PUR Contact Email:"
                              >
                                <span className="emailBreak">
                                  {data.PURContactEmail}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
            {/* Load More button for AG Scientist */}
            {props.roleAgScientist && totalPageCount > 10 && mobilePageOff && <div> <button className="btn btn-primary width-100 mt-4 mb-4" onClick={loadMoreData}>Load More</button></div>}
          </div>
        </div>
      )}
      {/* no record found section start */}
      {filteredGridItem.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4">
          <i className="fa fa-info-circle pe-2"></i>
          {moreLangs[i18n.resolvedLanguage].ePUR.noRecordFound}
        </div>
      )}
      {/* no record found section End */}
      <div className={allSubmitted && 'allDisabled'}>
        <div className="row">
          <div className="col-12 col-lg-6 personal_info">
            <div className="position-relative mb-2 mb-lg-0">
              <span className="lable-text text-14 mandatoryField  ps-3">
                PUR Contact Name
              </span>
              <input
                type="text"
                aria-label="PUR Contact Name"
                onChange={(e) =>
                  setFormData({ ...formData, contact_name: e.target.value })
                }
                value={formData.contact_name}
                className="form-control mb-3"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 personal_info">
            <div className="position-relative mb-2 mb-lg-0">
              <span className="lable-text text-14 mandatoryField  ps-3">
                PUR Contact Email
              </span>
              <input
                type="text"
                aria-label="PUR Contact Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
                className="form-control mb-3"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 personal_info">
            <div className="position-relative mb-2 mb-lg-0">
              <span className="lable-text text-14 mandatoryField  ps-3">
                PUR Contact Phone
              </span>
              <input
                type="text"
                aria-label="PUR Contact Phone"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phone: formatPhoneNumber(e.target.value),
                  });
                }}
                value={formData.phone}
                className="form-control mb-3"
              />
              {formData.phone !== "" && formData.phone.length < 14 && (
                <p className="error-text">Please enter valid phone number</p>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="position-relative mb-4 mb-lg-0 start_harvest_on datepicker-field">
              <span className="lable-text text-14 mandatoryField  ps-3">
                Planning to Start Harvest On
              </span>
              <DatePickerComp
                id="applicationDatePicker"
                selected={formData.anticipated_harvest_date}
                onChange={(date) =>
                  setFormData({ ...formData, anticipated_harvest_date: date })
                }
                placeholderText={dateFormate}
                className="datepicker_input form-control z-index-1"
                minDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={allSubmitted && 'allDisabled'}>
          <fieldset className="input_block mb-4 d-flex">
            <input
              id="other_option"
              onChange={() =>
                formData.isChecked === 0
                  ? setFormData({ ...formData, isChecked: 1 })
                  : setFormData({ ...formData, isChecked: 0 })
              }
              value={formData.isChecked}
              className="ms-2 me-1 cursor_pointer top_checkbox"
              type="checkbox"
              name="other_option"
            />
            <span className="pos-relative checkbox mandatoryField"></span>
            <label htmlFor="other_option" className="cursor_pointer fw-bold ps-0">
              This is my complete Grower Pesticide Use Report:{" "}
            </label>
          </fieldset>
          {contentBlock != null ? (<>
            {/* <div className="rich-editor-content-block"> */}
              <ContentModifier
                key={0}
                str={contentBlock["content_" + i18n.resolvedLanguage]}
              />
              {/* {parse(contentBlock["content_" + i18n.resolvedLanguage])} */}
            {/* </div> */}
          </>
          ) : null}
        </div>
        <div className="row">
          <div className={`col-12 col-sm-6 ${allSubmitted && 'allDisabled'}`}>
            <p>
              <span className="fw-bold">Grower Signature: </span>{" "}
              {accounts[0].idTokenClaims?.name}
            </p>
          </div>
          <div className={`col-12 col-sm-6 ${allSubmitted && 'allDisabled'}`}>
            <p className="float-sm-end">
              <span className="fw-bold">Signature date: </span>{" "}
              {moment().format(dateFormate)}
            </p>
          </div>
          <div>
            <button
              className="btn btn-primary text-16 float-end mb-4 ms-sm-3"
              disabled={
                impersonated ||
                formData.contact_name === "" ||
                formData.email === "" ||
                formData.phone === "" ||
                formData.anticipated_harvest_date === "" ||
                formData.isChecked === 0 ||
                formData.phone.length !== 14 ||
                tableHasNewEntry.length === 0
              }
              onClick={() => submitESR()}
            >
              Submit
            </button>
            {props.roleAgScientist &&
              <button
                className="btn btn-danger text-16 float-end mb-4 me-sm-3"
                disabled={CN_MultiValue.contractid === "" || allUnSubmitted}
                onClick={() => unSubmitESR()}
              >
                Unsubmit
              </button>
            }
          </div>
        </div>
      </div>
      {showModal.isOpen && (
        <Modal
          show={showModal.isOpen}
          onHide={onModalHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h4 className="fw-bold mb-0" id="contained-modal-title-vcenter">
              {showModal.message}
            </h4>
          </Modal.Header>
          <Modal.Footer>
            <button
              className="btn btn-default card-shadow ms-2"
              onClick={() => onModalHide()}
              id="ok_button"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SubmitEndOfSeasonReport;
