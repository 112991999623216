/**
 * @component_Name : ContractedAcreageMap
 * @description : Contracted Acreage Map
 * @company : Photon
 * @author : Nivethithan Kannan/14-11-2024
 **/
import React from "react";
import PageLayout from "../../pageLayout";
import ContractedAcreageMapDetail from "./contractedAcreageMapDetail";

const ContractedAcreageMap = (props) => {
  return (
    <PageLayout {...props} body={<ContractedAcreageMapDetail {...props} />} />
  );
};

export default ContractedAcreageMap;
