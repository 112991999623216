/**
 * @component_Name : MultiDropDown
 * @description : library for multiselect
 * @company : Photon
 * @author : Ashish Ranjan/31-05-2022
 *
 */

import React, { useEffect, useState, useRef} from "react";
import Multiselect from "multiselect-react-dropdown";

const MultiDropDown = ({
  prompt = "Select",
  value,
  onChange,
  optionList,
  label,
  usestate,
  disabled = true,
  onDelete = '',
  preSelectedOpt = '',
  disablePreSelected='',
  singleSelect = false,
  optionNotFound ='',
  showTitle= false,
  showImage = false
}) => {
  const [selectedVal, setSelectedVal] = useState([]);
  const [classNameAll, setClassNameALL] = useState("");
  const [optionObj, setoptionObj] = useState([]);
  const multiselectRef = useRef([]);
  const [closeEvent, setCloseEvent] = useState(false);
  let preSelectString = '';
  useEffect(() => {
    let allObj = [];
    if(singleSelect==false){
      allObj = [{ name: "All", id: "all" }];
    }
    
    /*add ALL element in option array*/
    if(optionList.length>0){setoptionObj([...allObj, ...optionList])}    
    if(preSelectedOpt.length>0){
      let presele = [];
      let ids = [];
      /*preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));*/
      
      if(preSelectedOpt.length==1 && preSelectedOpt[0] === 'all'){
        setSelectedVal([...allObj, ...optionList]);
        setClassNameALL("all_dropdown_display");
        const selectedIds = optionList.map((x) => x.id);
        const addCommaVal = selectedIds.toString();
        onChange(optionList, allObj);
        usestate({ ...value, [label]: addCommaVal });
      }
      else if(preSelectedOpt.length==1){
        preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));
        setSelectedVal([...presele]);  
        preSelectString = ids.toString();
      
      }else if(optionList.length!=preSelectedOpt.length){
        preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));
        setSelectedVal([...presele]);
        preSelectString = ids.toString();
      
      }else if(optionList.length==preSelectedOpt.length && preSelectedOpt.length>1){
        setSelectedVal([...optionObj]);
        preSelectString = preSelectedOpt.toString();
        setClassNameALL("all_dropdown_display");
      }      
    }
  }, [optionList]);

  useEffect(() => {
    if(preSelectString!=''){
      usestate({[label]: preSelectString });
    }    
  },[preSelectString])

  if (value == null) {
    multiselectRef.current.resetSelectedValues();
  }
  
  /*check key and value is exist or not*/
  function checkValExist(selectedList, key, value) {
    let flag = false;
    var found = Object.values(selectedList).filter((i) => i[key] === value);
    found.length > 0 ? (flag = true) : "";
    return flag;
  }

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.id == "all") {
      setSelectedVal([...optionObj]);
      setClassNameALL("all_dropdown_display");
    }
    if (checkValExist(selectedList, "id", "all")) {
      selectedList = optionList; //.filter((x) => x.id != 'all' && x)
    }
    const selectedIds = selectedList.map((x) => x.id);
    const addCommaVal = selectedIds.toString();
    onChange(selectedList, selectedItem);
    usestate({ ...value, [label]: addCommaVal });
  };

  /*onRemove function for remove selected element*/
  const onRemove = (selectedList, removedItem) => {
    if (checkValExist(selectedList, "id", "all")) {
      setClassNameALL("");
      selectedList = selectedList.filter((x) => x.id != "all" && x);
      setSelectedVal([...selectedList]);
    }
    if (removedItem.id == "all") {
      setSelectedVal([]);
      setClassNameALL("");
      const remainItem = {};
      Object.keys(value).filter((x)=>{(x!=label)? remainItem[x]= value[x]:''} )
      usestate({ ...remainItem});
    }else{
      if(selectedList.length==0){
        const remainItem = {};
        Object.keys(value).filter((x)=>{(x!=label)? remainItem[x]= value[x]:''} )
        usestate({ ...remainItem});
      }else{
        const removedIds = selectedList.map((x) => x.id);
        const reCommaVal = removedIds.toString();      
        usestate({ ...value, [label]: reCommaVal });
      }
    }
    onDelete != "" ? onDelete(selectedList, removedItem) : "";
  };

  // const openmultiselect = () =>{
  //   setCloseEvent(false);
  // }
  multiselectRef?.current?.searchBox?.current?.addEventListener("focus", ()=>{    
    setCloseEvent(true);
  });
  multiselectRef?.current?.searchBox?.current?.addEventListener("focusout", ()=>{    
    setCloseEvent(false);
  });
  const optionValueDecorator = (a,e)=>{
    if(showImage){
      return <><span className="option-value">{e.name}</span> <span className="float-end text-center image">{e.imageValue}</span></>
    }
    else if(showTitle){
      return <span title={e.tooltiptext}>{a}</span>
    }
    else 
     return a; 
  }
  return (
    <>
      {closeEvent && <div className="dropdownArrow" ></div>}
      <Multiselect
        options={optionObj}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
        placeholder={prompt}
        disable={disabled}
        id={label}
        name={label}
        ref={multiselectRef}
        selectedValues={selectedVal}
        singleSelect={(singleSelect==true) ? true : false}
        loading={(optionList.length > 0) ? false : true}
        loadingMessage = {(optionList.length == 0 && optionNotFound[label]) ? optionNotFound[label] : 'Loading....'}
        showArrow={false}
        showCheckbox={true}
        closeOnSelect={true}
        className={`${classNameAll} ${closeEvent && 'hideArrow'}`}
        disablePreSelectedValues={(disablePreSelected==true) ? true : false}
        optionValueDecorator={optionValueDecorator}
      />
    </>
  );
};


export default MultiDropDown;


export const MultiDropDownForReducer = ({
  prompt = "Select",
  value,
  onChange,
  optionList,
  label,
  dispatchState,
  disabled = true,
  onDelete = '',
  preSelectedOpt = '',
  disablePreSelected='',
  singleSelect = false,
  optionNotFound ='',
  showTitle= false,
  showImage = false
}) => {
  const [selectedVal, setSelectedVal] = useState([]);
  const [classNameAll, setClassNameALL] = useState("");
  const [optionObj, setoptionObj] = useState([]);
  const multiselectRef = useRef([]);
  const [closeEvent, setCloseEvent] = useState(false);
  let preSelectString = '';
  useEffect(() => {
    let allObj = [];
    if(singleSelect==false){
      allObj = [{ name: "All", id: "all" }];
    }
    
    /*add ALL element in option array*/
    if(optionList.length>0){setoptionObj([...allObj, ...optionList])}    
    if(preSelectedOpt.length>0){
      let presele = [];
      let ids = [];
      /*preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));*/
      
      if(preSelectedOpt.length==1 && preSelectedOpt[0] === 'all'){
        setSelectedVal([...allObj, ...optionList]);
        setClassNameALL("all_dropdown_display");
        const selectedIds = optionList.map((x) => x.id);
        const addCommaVal = selectedIds.toString();
        if(onChange){
          onChange(optionList, allObj);
        } 
        dispatchState(label, { ...value, [label]: addCommaVal });
      }
      else if(preSelectedOpt.length==1){
        preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));
        setSelectedVal([...presele]);  
        preSelectString = ids.toString();
      
      }else if(optionList.length!=preSelectedOpt.length){
        preSelectedOpt.map(e => optionList.filter(i => {if(i['id']==e){presele.push(i);ids.push(i['id'])}}));
        setSelectedVal([...presele]);
        preSelectString = ids.toString();
      
      }else if(optionList.length==preSelectedOpt.length && preSelectedOpt.length>1){
        setSelectedVal([...optionObj]);
        preSelectString = preSelectedOpt.toString();
        setClassNameALL("all_dropdown_display");
      }      
    }
  }, [optionList]);

  useEffect(() => {
    if(preSelectString!=''){
      dispatchState(label, {[label]: preSelectString });
    }    
  },[preSelectString])

  if (value == null) {
    multiselectRef.current.resetSelectedValues();
  }
  
  /*check key and value is exist or not*/
  function checkValExist(selectedList, key, value) {
    let flag = false;
    var found = Object.values(selectedList).filter((i) => i[key] === value);
    found.length > 0 ? (flag = true) : "";
    return flag;
  }

  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.id == "all") {
      setSelectedVal([...optionObj]);
      setClassNameALL("all_dropdown_display");
    }
    if (checkValExist(selectedList, "id", "all")) {
      selectedList = optionList; //.filter((x) => x.id != 'all' && x)
    }
    const selectedIds = selectedList.map((x) => x.id);
    const addCommaVal = selectedIds.toString();
    if(onChange){
      onChange(selectedList, selectedItem);
    } 
    dispatchState(label, { ...value, [label]: addCommaVal });
  };

  /*onRemove function for remove selected element*/
  const onRemove = (selectedList, removedItem) => {
    if (checkValExist(selectedList, "id", "all")) {
      setClassNameALL("");
      selectedList = selectedList.filter((x) => x.id != "all" && x);
      setSelectedVal([...selectedList]);
    }
    if (removedItem.id == "all") {
      setSelectedVal([]);
      setClassNameALL("");
      const remainItem = {};
      Object.keys(value).filter((x)=>{(x!=label)? remainItem[x]= value[x]:''} )
      dispatchState(label, { ...remainItem});
    }else{
      if(selectedList.length==0){
        const remainItem = {};
        Object.keys(value).filter((x)=>{(x!=label)? remainItem[x]= value[x]:''} )
        dispatchState(label, { ...remainItem});
      }else{
        const removedIds = selectedList.map((x) => x.id);
        const reCommaVal = removedIds.toString();      
        dispatchState(label, { ...value, [label]: reCommaVal });
      }
    }
    onDelete != "" ? onDelete(selectedList, removedItem) : "";
  };

  // const openmultiselect = () =>{
  //   setCloseEvent(false);
  // }
  multiselectRef?.current?.searchBox?.current?.addEventListener("focus", ()=>{    
    setCloseEvent(true);
  });
  multiselectRef?.current?.searchBox?.current?.addEventListener("focusout", ()=>{    
    setCloseEvent(false);
  });
  const optionValueDecorator = (a,e)=>{
    if(showImage){
      return <><span className="option-value">{e.name}</span> <span className="float-end text-center image">{e.imageValue}</span></>
    }
    else if(showTitle){
      return <span title={e.tooltiptext}>{a}</span>
    }
    else 
     return a; 
  }
  return (
    <>
      {closeEvent && <div className="dropdownArrow" ></div>}
      <Multiselect
        options={optionObj}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
        placeholder={prompt}
        disable={disabled}
        id={label}
        name={label}
        ref={multiselectRef}
        selectedValues={selectedVal}
        singleSelect={(singleSelect==true) ? true : false}
        loading={(optionList.length > 0) ? false : true}
        loadingMessage = {(optionList.length == 0 && optionNotFound[label]) ? optionNotFound[label] : 'Loading....'}
        showArrow={false}
        showCheckbox={true}
        closeOnSelect={true}
        className={`${classNameAll} ${closeEvent && 'hideArrow'}`}
        disablePreSelectedValues={(disablePreSelected==true) ? true : false}
        optionValueDecorator={optionValueDecorator}
      />
    </>
  );
};