/* eslint-disable */
/**
 * @component_Name : PaymentEstimatorDetail
 * @description : Payment Estimator
 * @company : Photon
 * @author : Nivethithan Kannan/02-01-2024
 **/

import React, { useEffect, useState, Fragment, useReducer } from "react";
import BreadCrumb from "../../../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../../global/loading";
import moment from "moment";
import CurrentLocation from "../../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
  compareString,
  getCurrentYear,
  NumConverter,
  IsNumberChecker,
  IsObjectChecker,
  ReplaceToastStack,
} from "../../../../utils/helper";
import {
  Form,
  Accordion,
  Alert,
  InputGroup,
  Button,
  FormGroup,
  CloseButton,
  Table,
} from "react-bootstrap";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import MultiDropDown from "../../../selectBox/multiDropDown";
import QuickLinks from "../../quickLinks";
import LeftMenu from "../../../global/leftMenu";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
  LocalAccountIDSwapImpersonate,
  DetermineImpersonation,
} from "../../../../permissionWrapper";
import {
  IsArrayElementsTrue,
  CheckFetchesLoaded,
  EventMGMT,
  IsNumber,
  WholeRateValidation,
  NumberFormaterWithValidation,
  BarrelsNumberFormater,
  KeyDownHandler,
  OnChangeDecimalWrapper,
  NineMillionLimit,
  OneMillionLimit,
  HundredLimit,
  TwentyLimit,
  // RateValidation,
  NumberToNormal,
  ToConcatenatedString,
  ZeroLimit,
  InpuctComponentFieldsFormatter,
  ReversePaymentsArrayFieldsFormatter,
  ReverseInputComponentFieldsFormatter,
  OneLimit,
  FiveMillionLimit,
  pastYearCompare,
} from "./paymentEstimatorFunctionsCommon";
import PaymentEstimatorCalculationDetail from "./paymentEstimatorCalculationDetail";
import constantsVariable from "../../../../utils/constantsVariable";
import PaymentEstimatorAREStockRetain from "./paymentEstimatorAREStockRetain";
const PaymentEstimatorDetail = (props) => {
  const impersonated = DetermineImpersonation();
  const { DropDownCBRoles = [] } = props;
  const today = moment().utc().format("MM/DD/YYYY");

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const { defaultToastMsgProperties } = constantsVariable;

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const localAccountId = LocalAccountIDSwapImpersonate(accounts);

  const [loading, setLoading] = useState(false);
  const [miniLoader, setMiniLoader] = useState(false);
  const [miniLoad, setMiniLoad] = useState(false);
  const defaultAlert = { trigger: false, variant: "primary", message: "" };
  const [saveAlert, setSaveAlert] = useState(defaultAlert);

  const cropyear_info_box = `When creating crop year entries, year 1 will be your current crop (most recent complete) year with barrels for the crop year.  The estimator tool will use the barrels displayed for payment estimates.  You do have the option to override the barrels for the current year if you would like to do ‘what-if’ estimates using your current crop.`;

  const quarterly_months = ["March", "June", "September", "December"];
  const deferrelPeriodInMonths = [0, 3, 6, 9, 12];
  const deferrelPercentages = [0, 25, 50, 75, 90];
  const SA_Regions = ["Chile"];
  const payments_data = "payments";
  const rateName = "rate",
    incentiveRateOrDollarsName = "incentiveRateOrDollars",
    incentiveRateName = "incentiveRate",
    incentiveDollarsName = "incentiveDollars",
    cropyearLabel = "Crop Year",
    cropyearName = "cropyear",
    barrelsLabel = "Barrels",
    barrelsName = "barrels",
    deferrelPercentName = "deferrelPercent",
    deferrelPeriodName = "deferrelPeriod",
    interestRateName = "interestRate";
  // const payment_rate_err_msg = "A valid number [0.01 - 20] is required";
  const payment_rate_err_msg = "A valid number [0 - 20] is required";
  const rate_err_msg = "A valid number [0 - 100] is required";
  const amount_err_msg = "A valid number [0 - 1,000,000] is required";
  const barrels_err_msg = `A valid number [0 - 9,000,000] is required`;
  const estimate_name_err_msg =
    "A vaild estimate name is required. Maximum characters 1-50";
  const default_cpd_rate_api = "CONTINUOUS POOL DISTRIBUTION";
  const PAR_Value_Description = "Common Qualified";

  const default_cp_cropyear = {
    name: cropyearName,
    label: "Current Crop Year",
    value: getCurrentYear(),
    prev_label: "Previous Crop Year",
    // prev_value: moment()?.subtract(1, "year")?.format("YYYY") || "",
    prev_value: NumberToNormal(getCurrentYear(), false, 1),
  };
  const default_cp_barrels = {
    name: barrelsName,
    label: "Current Barrels",
    value: "0",
    prev_label: "Previous Barrels",
    prev_value: "0",
  };
  const default_are_stock_preload = { from_preload: null, merged_data: [] };
  const default_are_issuance_stock_data = {
    year: "",
    are_rate: "0",
    quota_rate: "0",
    retain_rate: "0",
  };

  const [enable_quarterly_payments_section, setEnableQuarterlyPaymentsSection] =
    useState(null);
  const [enable_incentives_section, setEnableIncentivesSection] =
    useState(null);
  const [enable_deferred_payments_section, setEnableDeferredPaymentsSection] =
    useState(null);
  const [enable_are_stock, setEnableAREStock] = useState(null);
  const [enable_calculate_section, setEnableCalculateSection] = useState(null);

  function AlertBar(content, timeout = false) {
    if (IsObjectChecker(content)) {
      setSaveAlert(content);
      if (timeout) {
        setTimeout(() => {
          setSaveAlert(defaultAlert);
        }, 10000);
      }
    }
  }

  function defaultDeferredPaymentsArrange(data = []) {
    try {
      if (IsArrayNotEmpty(data)) {
        const formatted_data = data.map((li) => {
          const {
            DefermentPercentage,
            DefermentPeriod: deferment_period,
            DefferedDate: deferred_date,
          } = li;
          const deferred_year = deferred_date
            ? parseInt(moment(deferred_date).format("YYYY"))
            : null;
          const month = deferred_date
            ? moment(deferred_date).format("MMMM")
            : null;
          const deferment_percent = IsNumber(DefermentPercentage)
            ? DefermentPercentage * 100
            : 0;
          return {
            deferred_year,
            month,
            deferment_percent,
            deferment_period,
          };
        });
        const final_data = IsArrayNotEmpty(formatted_data)
          ? formatted_data.filter((li) => {
              const {
                deferment_percent,
                deferment_period,
                deferred_year,
                month,
              } = li;

              const dateAccess =
                !isNaN(deferred_year) && !compareString("Invalid date", month);
              if (dateAccess) {
                const is_deferment_period_valid = IsNumber(
                  deferment_period,
                  true,
                  true,
                  true,
                  1
                );
                const is_deferment_percent_valid = IsNumber(
                  deferment_percent,
                  true,
                  true,
                  true,
                  0.01
                );
                return is_deferment_period_valid && is_deferment_percent_valid;
              }
            })
          : [];
        return final_data.map((li) => {
          const cropyear = NumberToNormal(li?.deferred_year, false, 1);
          return {
            ...li,
            cropyear,
            deferred: true,
            deferment_percent_error: false,
            deferment_percent_disabled: false,
          };
        });
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function defaultPaymentRatesArrange(data = [], current_crop_year) {
    try {
      if (IsArrayNotEmpty(data) && current_crop_year) {
        const cpd_data = data.filter((li) => {
          const { Description } = li;
          return (
            IsStringChecker(Description) &&
            Description.includes(default_cpd_rate_api)
          );
        });
        if (IsArrayNotEmpty(cpd_data)) {
          const formatted_data = cpd_data.map((li) => {
            const { Rate, SettlementDate, Projected } = li;
            const rate = NumberFormaterWithValidation(
              `${Rate}`,
              WholeRateValidation,
              ZeroLimit,
              TwentyLimit
            );
            const rate_error = IsNumber(
              rate,
              true,
              true,
              true,
              ZeroLimit,
              TwentyLimit
            )
              ? false
              : true;
            const settlement_year = SettlementDate
              ? parseInt(moment(SettlementDate).format("YYYY"))
              : null;
            const month = SettlementDate
              ? moment(SettlementDate).format("MMMM")
              : null;
            const cropyear = !isNaN(settlement_year)
              ? settlement_year - 1
              : NaN;
            return {
              cropyear,
              rate,
              month,
              rate_error,
              rate_enabled: Projected,
            };
          });
          const final_data = IsArrayNotEmpty(formatted_data)
            ? formatted_data.filter((li) => {
                const { rate, cropyear, month } = li;

                const dateAccess =
                  !isNaN(cropyear) && !compareString("Invalid date", month);
                if (dateAccess) {
                  const is_rate_valid = IsNumber(rate, true, false, false, 0);
                  const is_current_year = current_crop_year == cropyear;
                  return is_current_year && is_rate_valid;
                }
              })
            : [];
          return final_data;
        }
        return [];
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function getIncetivesSectionDefaultArr(data = []) {
    function getNumber(num) {
      if (num) {
        if (IsNumberChecker(num)) {
          return num;
        } else if (IsStringChecker(num)) {
          return parseFloat(num);
        } else {
          return 0;
        }
      }
      return 0;
    }
    try {
      if (IsArrayNotEmpty(data)) {
        const incentive = data.reduce((acc, list) => {
          const {
            WhiteIncentive,
            EarlySequencedIncentive,
            LateSequencedIncentive,
            ExportIncentive,
            ColorTacyIncentive,
            FirmnessAverageIncentive,
            OrganicIncentive,
            GlobalGapFreshIncentive,
            SizePremiumIncentive,
            PreliminaryIncentive,
          } = list;
          const temp =
            getNumber(WhiteIncentive) +
            getNumber(EarlySequencedIncentive) +
            getNumber(LateSequencedIncentive) +
            getNumber(ExportIncentive) +
            getNumber(ColorTacyIncentive) +
            getNumber(FirmnessAverageIncentive) +
            getNumber(OrganicIncentive) +
            getNumber(GlobalGapFreshIncentive) +
            getNumber(SizePremiumIncentive) +
            getNumber(PreliminaryIncentive);
          acc += temp;
          return acc;
        }, 0);
        return getNumber(getNumber(incentive) / 4);
      }
      return 0;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return 0;
    }
  }

  function getContractInfo(id, options) {
    try {
      return IsArrayNotEmpty(options)
        ? options.find((li) => li.id == id)
        : false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  //Estimate - Save, Load & Calculate
  const [estimate, setEstimate] = useState({});
  const [estimateOptions, setEstimateOptions] = useState([]);
  const [estimateData, setEstimateData] = useState([]);
  const [estimateRefresh, setEstimateRefresh] = useState(true);

  const [loadEstimate, setLoadEstimate] = useState({});
  // const [calculateEstimate, setCalculateEstimate] = useState({initial: true, calculate: false});
  const [calculateEstimate, setCalculateEstimate] = useState(false);
  const [EstimateRawInput, setEstimateRawInput] = useState({});
  const [loadBtnClicked, setLoadBtnClicked] = useState(false);

  function EstimateRefresh() {
    setEstimateRefresh(false);
    setTimeout(() => {
      setEstimateRefresh(true);
    }, 0);
  }

  function EstimateOptionsCreator(data) {
    try {
      const options = IsArrayNotEmpty(data)
        ? data.map((li) => {
            const { estimate_name, created_at, id, estimate_label } = li;
            function getName(a, b) {
              if (IsStringChecker(a)) {
                if (IsStringChecker(b)) {
                  return `${b} : ${a}`;
                }
                return a;
              }
              return null;
            }
            return {
              name: getName(estimate_name, estimate_label),
              estimate_name,
              id,
              created_at,
              estimate_label,
            };
          })
        : [];
      return options;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function EstimateChange(e, selectedItem) {
    const { id } = selectedItem;
    if (IsArrayNotEmpty(e)) {
      setEstimate({ estimate: id });
      const selectedList = estimateData.find((li) => li?.id == id);
      if (selectedList) {
        setLoadEstimate(selectedList);
      } else {
        setLoadEstimate({});
      }
    } else {
      setEstimate({});
      setLoadEstimate({});
    }
  }

  function LoadEstimateContractBased(Region, Data) {
    try {
      if (IsStringChecker(Region) && IsArrayNotEmpty(Data)) {
        const found = SA_Regions.find((li) => compareString(li, Region));
        if (found) {
          const SAOrderedMonthRateMapArr = _.orderBy(
            [...Data],
            ["SA_Order"],
            ["asc"]
          );
          return SAOrderedMonthRateMapArr;
        } else {
          const NAOrderedMonthRateMapArr = _.orderBy(
            [...Data],
            ["NA_Order"],
            ["asc"]
          );
          return NAOrderedMonthRateMapArr;
        }
      } else {
        return [];
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      return [];
    }
  }

  function onLoadEstimate() {
    const {
      contract_id,
      payment_mappings,
      estimate_name,
      id,
      estimate_label,
      additional_year,
    } = loadEstimate;

    setLoadBtnClicked(true);
    const contract_found = getContractInfo(contract_id, contractOptions);
    if (
      id &&
      contract_found &&
      IsStringChecker(contract_id) &&
      IsArrayNotEmpty(payment_mappings) &&
      IsObjectChecker(additional_year)
    ) {
      const { region, name } = contract_found;
      setContract({ contract: contract_id });
      setContractDefault([`${contract_id}`]);
      ContractRefresh();
      fetchCropYear(`${contract_id}`, false);
      setCalculateEstimate((prev) => !prev);
      setEstimateName(IsStringChecker(estimate_name) ? estimate_name : "");
      if (IsStringChecker(estimate_label)) {
        setEstimateLoadLabel(estimate_label);
      } else {
        setEstimateLoadLabel(``);
      }
      setEstimateLabel(`${name} - ${today}`);
      setEstimateNameError("");
      PreloadDataFetchLoader(true, false);
      ContractBasedMonthOrdering(
        region,
        initialPaymentsArr,
        dispatchPaymentsMapping
      );

      const load_additional_year =
        ReverseInputComponentFieldsFormatter(additional_year);
      load_additional_year.payments = LoadEstimateContractBased(
        region,
        ReversePaymentsArrayFieldsFormatter(additional_year?.payments)
      );

      const load_payment_mappings = payment_mappings.map((obj) => {
        const { payments, ...rest } = obj || {};

        const new_obj = ReverseInputComponentFieldsFormatter({ ...rest });
        new_obj.payments = LoadEstimateContractBased(
          region,
          ReversePaymentsArrayFieldsFormatter(payments)
        );
        return new_obj;
      });
      // console.log({load_additional_year});

      DispatchAdditionalYear({
        type: "replace",
        payload: load_additional_year,
      });
      dispatchInputComponentMapping({
        type: "replace",
        payload: _.orderBy(load_payment_mappings, ["order"], ["asc"]),
      });
      SectionHandler(true);
    } else {
      SectionHandler(false);
      if (id) {
        if (contract_found) {
          ReplaceToastStack(dispatch, {
            ...defaultToastMsgProperties,
            message: "Error in Saved Estimate. Unable to Load.",
          });
        } else {
          if (
            !IsStringChecker(contract_id) ||
            !IsArrayNotEmpty(payment_mappings)
          ) {
            ReplaceToastStack(dispatch, {
              ...defaultToastMsgProperties,
              message: "Error in Saved Estimate. Unable to Load.",
            });
          } else {
            const c_found = getContractInfo(
              contract_id,
              originalContractOptions
            );
            if (c_found) {
              if (c_found.status != 1000 || c_found.status != 1001) {
                ReplaceToastStack(dispatch, {
                  ...defaultToastMsgProperties,
                  message: `Unable to Load. Contract is ${
                    c_found?.status_desc || "Inactive"
                  }`,
                });
              } else {
                ReplaceToastStack(dispatch, {
                  ...defaultToastMsgProperties,
                  message: "Unable to Load. Contract is Unauthorized",
                });
              }
            } else {
              ReplaceToastStack(dispatch, {
                ...defaultToastMsgProperties,
                message: "Unable to Load. Contract not Found",
              });
            }
          }
        }
      } else {
        ReplaceToastStack(dispatch, {
          ...defaultToastMsgProperties,
          message: "Select an Estimate to Proceed",
        });
      }
    }
  }

  function onDeleteEstimate() {
    const { id } = loadEstimate;
    if (id && localAccountId) {
      const payload = {
        user_id: localAccountId,
        id,
      };
      DeleteEstimate(payload);
      // ReplaceToastStack(dispatch, {
      //   ...defaultToastMsgProperties,
      //   bg: "light",
      //   color: "black",
      //   message: "Estimate deleted successfully",
      // });
    } else {
      ReplaceToastStack(dispatch, {
        ...defaultToastMsgProperties,
        message: "Select an Estimate to Proceed",
      });
    }
  }

  // Contract Field
  const [contract, setContract] = useState({});
  const [contractDefault, setContractDefault] = useState([]);
  const [contractDisabled, setContractDisabled] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const [originalContractOptions, setOriginalContractOptions] = useState([]);
  const [contractRefresh, setContractRefresh] = useState(true);

  function ContractRefresh() {
    setContractRefresh(false);
    setTimeout(() => {
      setContractRefresh(true);
    }, 0);
  }

  function ContractOptionCreator({
    RelationshipNumber = "",
    RelationshipId = "",
    Name = "",
    Name2 = "",
    RegionDesc = "",
    ContractStatus = "",
    ContractStatusDesc = "",
  }) {
    try {
      if (RelationshipId) {
        const temp = [RelationshipNumber, Name, Name2].filter((li) =>
          IsStringChecker(li)
        );
        const name = temp.join(" - ");
        return {
          name,
          id: RelationshipId,
          region: RegionDesc,
          status: ContractStatus,
          status_desc: ContractStatusDesc,
        };
      }
      return null;
    } catch (error) {
      HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      return null;
    }
  }

  function ContractOptionChange(selectedItem) {
    const { region, name } = selectedItem || {};
    setEstimateLabel(`${name} - ${today}`);
    ContractBasedMonthOrdering(
      region,
      initialPaymentsArr,
      dispatchPaymentsMapping
    );
    fetchCropYear(selectedItem?.id);
    dispatchInputComponentMapping({ type: "replace", payload: [] });
    PreloadDataFetchLoader();
    SectionHandler(false);
  }

  function ContractOptionDelete() {
    dispatchInputComponentMapping({ type: "replace", payload: [] });
    setEstimateName("");
    setEstimateNameError("");
    setCPCropyear(default_cp_cropyear);
    setCPBarrels(default_cp_barrels);
    PreloadDataFetchLoader();
    SectionHandler(false);
  }

  function PreloadDataFetchLoader(val = true, notFromLoad = true) {
    setBarrelsByYearLoad(val);
    if (notFromLoad) {
      setRatesPayloadLoad(val);
      setAREPreloadLoad(val);
      setCommonStockSharesHeldPreload(val);
      setPARValuePreload(val);
      setDefermentsPreloadLoad(val);
      setDefermentsRatePreloadLoad(val);
      setIncentivePreloadLoad(val);
    } else {
      setRatesPayloadLoad(notFromLoad);
      setAREPreloadLoad(notFromLoad);
      setCommonStockSharesHeldPreload(notFromLoad);
      setPARValuePreload(notFromLoad);
      setDefermentsRatePreloadLoad(notFromLoad);
      setDefermentsPreloadLoad(notFromLoad);
      setIncentivePreloadLoad(notFromLoad);
    }
  }

  function RegionBasedContract(Region) {
    return SA_Regions.find((li) => compareString(li, Region));
  }

  function PaymentArrBasedContract(payments_arr) {
    try {
      if (IsArrayNotEmpty(payments_arr)) {
        const SA_Region = payments_arr.every((li) => li.contractOrder == true);
        if (SA_Region) {
          return _.orderBy(payments_arr, ["SA_Order"], ["asc"]);
        } else {
          return _.orderBy(payments_arr, ["NA_Order"], ["asc"]);
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return payments_arr;
    }
  }

  function ContractBasedMonthOrdering(
    Region,
    ContractData = [],
    dispatchMethod
  ) {
    try {
      if (IsStringChecker(Region) && IsArrayNotEmpty(ContractData)) {
        const found = RegionBasedContract(Region);
        if (found) {
          const SAOrderedMonthRateMapArr = _.orderBy(
            ContractData,
            ["SA_Order"],
            ["asc"]
          ).map((li) => ({ ...li, contractOrder: true }));
          dispatchMethod({
            type: "replace",
            payload: SAOrderedMonthRateMapArr,
          });
        } else {
          const NAOrderedMonthRateMapArr = _.orderBy(
            ContractData,
            ["NA_Order"],
            ["asc"]
          ).map((li) => ({ ...li, contractOrder: false }));
          dispatchMethod({
            type: "replace",
            payload: NAOrderedMonthRateMapArr,
          });
        }
      } else {
        dispatchMethod({ type: "replace", payload: [] });
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      dispatchMethod({ type: "replace", payload: [] });
    }
  }

  function HandleAREStockRetainSectionData() {
    const sequential_are_stock_data = AREStockBarrelsCascading(
      barrelsByYear,
      inputComponentArr
    );

    const from_preload = !!(
      IsArrayNotEmpty(are_redemption_payload) ||
      are_issuance_stock_payload?.year ||
      IsArrayNotEmpty(par_value_payload) ||
      IsArrayNotEmpty(common_stock_shares_held_payload)
    );
    if (from_preload) {
      const master_arr = [
        ...sequential_are_stock_data,
        ...are_redemption_payload,
        ...par_value_payload,
        ...common_stock_shares_held_payload,
      ];
      master_arr.push({ ...are_issuance_stock_payload });
      const merged_data = master_arr.reduce((acc, list) => {
        const index = acc.findIndex((li) => li?.year == list?.year);
        if (index >= 0) {
          const item = acc[index];
          acc.splice(index, 1, { ...item, ...list });
        } else {
          acc.push(list);
        }
        return acc;
      }, []);
      const splitted_data = SplitCurrentAdditionalYearData(
        merged_data,
        cpCropyear?.value,
        "year"
      );
      setAREStockPreload({ from_preload, splitted_data });
    } else {
      const splitted_data = SplitCurrentAdditionalYearData(
        sequential_are_stock_data,
        cpCropyear?.value,
        "year"
      );
      setAREStockPreload({
        from_preload,
        splitted_data,
      });
    }
  }

  function EmptyAREStockPreloadData() {
    setAREStockPreload(default_are_stock_preload);
    setAREIssuanceStockPayload(default_are_issuance_stock_data);
    setARERedemptionPayload([]);
    setParValuePayload([]);
    setCommonStockSharesHeldPayload([]);
  }

  function AREStockBarrelsCascading(barrelsByYear = [], arr = []) {
    try {
      if (IsArrayNotEmpty(barrelsByYear) && IsArrayNotEmpty(arr)) {
        const t_sequential_cascading_data = arr.map((list, index) => {
          const { cropyear } = list;
          const { average_deliveries, common_barrels_delivered } =
            GetPrefixAREStockDataFromArr(index, barrelsByYear, arr);
          return {
            year: cropyear,
            average_deliveries,
            common_barrels_delivered,
          };
        });
        return t_sequential_cascading_data;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function GetPrefixAREStockDataFromArr(index, barrelsByYear = [], arr = []) {
    const returnObj = {
      average_deliveries: 0,
      barrels_delivered_crop_year: 0,
      common_barrels_delivered: 0,
      cropyear: 0,
    };
    try {
      if (index >= 2) {
        const new_arr = arr.slice(index - 2, index + 1);

        const average_deliveries = new_arr.reduce((acc, list) => {
          const barrels_value = IsStringChecker(list.barrels)
            ? list.barrels.replace(/,/g, "")
            : "0";
          const barrels = parseFloat(barrels_value) || 0;
          acc = acc + barrels;
          return acc;
        }, 0);

        const cropyear = parseInt(_.get(arr, `${index}.cropyear`, "0")) || 0;

        const barrel = _.get(arr, `${index - 1}.barrels`, "") || "";
        const barrels_delivered_crop_year =
          parseInt(_.get(arr, `${index - 1}.cropyear`, "") || "") || 0;

        const common_barrels_delivered =
          parseFloat(
            IsStringChecker(barrel) ? barrel.replace(/,/g, "") : "0"
          ) || 0;

        return {
          cropyear,
          common_barrels_delivered,
          barrels_delivered_crop_year,
          average_deliveries: average_deliveries ? average_deliveries / 3 : 0,
        };
      } else {
        const first_arr = arr.slice(0, index + 1);
        const second_arr = barrelsByYear
          .slice(1, barrelsByYear.length - index)
          .map((li) => ({
            ...li,
            cropyear: li.cropyear_value,
            barrels: li.barrels_value,
          }));
        const new_arr = [...first_arr, ...second_arr];

        const average_deliveries = new_arr.reduce((acc, list) => {
          const barrels_value = IsStringChecker(list.barrels)
            ? list.barrels.replace(/,/g, "")
            : "0";
          const barrels = parseFloat(barrels_value) || 0;
          acc = acc + barrels;
          return acc;
        }, 0);

        const cropyear = parseInt(_.get(arr, `${index}.cropyear`, "0")) || 0;

        const barrel =
          _.get(
            index === 0 ? barrelsByYear : arr,
            index === 0 ? "1.barrels_value" : `${index - 1}.barrels`,
            ""
          ) || "";
        const barrels_delivered_crop_year =
          parseInt(
            _.get(
              index === 0 ? barrelsByYear : arr,
              index === 0 ? "1.cropyear_value" : `${index - 1}.cropyear`,
              ""
            ) || ""
          ) || 0;

        const common_barrels_delivered =
          parseFloat(
            IsStringChecker(barrel) ? barrel.replace(/,/g, "") : "0"
          ) || 0;

        return {
          cropyear,
          common_barrels_delivered,
          barrels_delivered_crop_year,
          average_deliveries: average_deliveries ? average_deliveries / 3 : 0,
        };
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return returnObj;
    }
  }

  function ContractDetailsForCommonData(contract = {}, contractOptions = []) {
    try {
      const contract_id = contract?.contract || "";
      if (contract_id && IsArrayNotEmpty(contractOptions)) {
        const contract_obj = contractOptions.find((li) => li.id == contract_id);
        const region_str = contract_obj ? contract_obj?.region || "" : "";
        const region = !!RegionBasedContract(region_str);
        return { region, contract_obj };
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  }

  function CommonAreStockDataBuilder(
    item = {},
    pre_data = {},
    common_data_contract = {}
  ) {
    try {
      if (
        IsObjectChecker(common_data_contract) &&
        !_.isEmpty(common_data_contract)
      ) {
        const {
          average_deliveries = 0,
          barrels_delivered_crop_year = 0,
          common_barrels_delivered:cbd = 0,
          cropyear = 0,
        } = pre_data;
        const { contract_obj = {}, region = null } = common_data_contract || {};

        const new_item = { ...item };
        new_item.common_data.contract = contract_obj;
        new_item.common_data.region = region;
        new_item.common_data.common_barrels_delivered =
          NumberFormaterWithValidation(
            `${cbd}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          );
        new_item.common_data.barrels_delivered_crop_year =
          barrels_delivered_crop_year;

        new_item.are_data.are_region = region;
        new_item.are_data.are_issuance_month = region ? "July" : "January";
        new_item.are_data.are_crop_year = cropyear;
        new_item.are_data.are_issuance_year = region
          ? cropyear + 1
          : cropyear + 2;
        new_item.are_data.are_redemption_year = cropyear + 1;
        new_item.are_data.are_redemption_crop_year = cropyear - 9;

        new_item.stock_retain_data.average_deliveries =
          NumberFormaterWithValidation(
            `${average_deliveries}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          );
        new_item.stock_retain_data.stock_retain_region = region;
        new_item.stock_retain_data.stock_retain_month = region
          ? "September"
          : "March";
        new_item.stock_retain_data.stock_retain_crop_year = cropyear;
        new_item.stock_retain_data.stock_retain_year = region
          ? cropyear + 1
          : cropyear + 2;
        
        return new_item;
      }
      return {};
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  }

  function CommonStockRetainAREPreData(
    arr = [],
    barrelsByYear = [],
    common_data_contract = {}
  ) {
    try {
      if (
        IsArrayNotEmpty(arr) &&
        IsObjectChecker(common_data_contract) &&
        !_.isEmpty(common_data_contract)
      ) {
        const new_arr = arr
          .map((item, index) => {
            const pre_data = GetPrefixAREStockDataFromArr(
              index,
              barrelsByYear,
              arr
            );
            return CommonAreStockDataBuilder(
              item,
              pre_data,
              common_data_contract
            );
          })
          .filter((li) => !_.isEmpty(li));
        return IsArrayNotEmpty(new_arr) ? new_arr : arr;
      }
      return arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return arr;
    }
  }

  // Payments Section - Deliveries, Quarterly Rates, Incentive, Deferred Payments & Estimate Name
  const [barrelsByYear, setBarrelsByYear] = useState([]);
  const [deferments_preload, setDefermentsPreload] = useState([]);
  const [deferments_rate_preload, setDefermentsRatePreload] = useState("0");
  const [incentives_preload, setIncentivesPreload] = useState([]);
  const [rates_preload, setRatesPayload] = useState([]);
  const [are_redemption_payload, setARERedemptionPayload] = useState([]);
  const [common_stock_shares_held_payload, setCommonStockSharesHeldPayload] =
    useState([]);
  const [par_value_payload, setParValuePayload] = useState([]);
  const [are_issuance_stock_payload, setAREIssuanceStockPayload] = useState(
    default_are_issuance_stock_data
  );
  const [are_stock_preload, setAREStockPreload] = useState(
    default_are_stock_preload
  );
  const [incentive_preload_load, setIncentivePreloadLoad] = useState(true);
  const [deferments_preload_load, setDefermentsPreloadLoad] = useState(true);
  const [deferments_rate_preload_load, setDefermentsRatePreloadLoad] =
    useState(true);
  const [barrelsByYearLoad, setBarrelsByYearLoad] = useState(true);
  const [rates_preload_load, setRatesPayloadLoad] = useState(true);
  const [are_preload_load, setAREPreloadLoad] = useState(true);
  const [par_value_preload, setPARValuePreload] = useState(true);
  const [common_stock_shares_held_preload, setCommonStockSharesHeldPreload] =
    useState(true);
  const [cpCropyear, setCPCropyear] = useState(default_cp_cropyear);
  const [cpBarrels, setCPBarrels] = useState(default_cp_barrels);
  const minEntry = 1,
    maxEntry = 5;
  const [estimateLabel, setEstimateLabel] = useState("");
  const [estimateLoadLabel, setEstimateLoadLabel] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [estimateNameError, setEstimateNameError] = useState("");

  const initial_common_data = {
    contract: {},
    region: false,
    barrels_delivered_crop_year: 0,
    common_barrels_delivered: 0,
    common_divider: "25",
  };

  const initial_are_data = {
    are_region: null,
    are_crop_year: 0,
    are_issuance_month: "January",
    are_issuance_year: 0,
    are_redemption_month: "August",
    are_redemption_year: 0,
    are_redemption_crop_year: 0,
    are_issuance: "0",
    are_issuance_error: false,
    are_redemption: "0",
    are_redemption_error: false,
    are_rate: "0",
    are_rate_error: false,
  };

  const initial_stock_retain_data = {
    stock_retain_region: null,
    stock_retain_crop_year: 0,
    stock_retain_month: "March",
    stock_retain_year: 0,
    average_deliveries: 0,
    average_deliveries_error: false,
    quota_rate: "0",
    quota_rate_error: false,
    par_value: "25",
    par_value_error: false,
    quota_shares: "0",
    quota_shares_error: false,
    common_stock_shares_held: "0",
    common_stock_shares_held_error: false,
    percent_to_quota: "0",
    percent_to_quota_error: false,
    retain_rate: "0",
    retain_rate_error: false,
    retain_dollars: "0",
    retain_dollars_error: false,
    potential_retain_shares: "0",
    potential_retain_shares_error: false,
    shares_under_quota: "0",
    shares_under_quota_error: false,
    shares_under_quota_value: "0",
    shares_under_quota_value_error: false,
    retain_shares_to_be_issued: "0",
    retain_shares_to_be_issued_error: false,
    retain_shares_to_be_issued_value: "0",
    retain_shares_to_be_issued_value_error: false,
    cash_in_lieu_of_stock: "0",
    cash_in_lieu_of_stock_error: false,
  };

  // const [payments_pending, set_payments_pending] = useState(true);
  const [additional_year, DispatchAdditionalYear] = useReducer(
    AdditionalYearReducer,
    {}
  );

  function AyPaymentsArrBasedOnYear(arr, cropyear_value) {
    try {
      return IsArrayNotEmpty(arr)
        ? arr.filter((li) =>
            compareString(`${li?.cropyear}`, `${cropyear_value}`)
          )
        : [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function AyPaymentsProcessed(rate_arr) {
    try {
      return IsArrayNotEmpty(rate_arr)
        ? rate_arr.some((li) => IsStringChecker(li?.rate) && !li.rateDisabled)
        : false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function AyPaymentsDeferred(rate_arr) {
    try {
      return IsArrayNotEmpty(rate_arr)
        ? rate_arr.some((li) => li?.isDeferredPeriod)
        : false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function PaymentsArrPopulate(payment_arr, rate_arr) {
    try {
      const arr = payment_arr.reduce((acc, list) => {
        const { month } = list;
        const found = rate_arr.find((li) => compareString(li?.month, month));
        if (found) {
          const temp = {};
          if (found.hasOwnProperty("rate")) {
            const { rate, rate_error, rate_enabled } = found;
            temp["rate"] = rate;
            temp["rateError"] = rate_error;
            temp["rateDisabled"] = !rate_enabled;
          }
          if (
            found.hasOwnProperty("deferment_period") &&
            found.hasOwnProperty("deferment_percent")
          ) {
            const {
              deferment_percent,
              deferment_percent_error,
              deferment_percent_disabled,
              deferment_period,
              deferred,
            } = found;
            temp["deferrelPeriod"] = deferment_period;
            temp["deferrelPercent"] = deferment_percent;
            temp["isDeferredPeriod"] = deferred;
            temp["deferrelPercentError"] = deferment_percent_error;
            temp["deferrelPercentDisabled"] = deferment_percent_disabled;
          }
          if (found.hasOwnProperty("incentive")) {
            const { incentive, incentive_error } = found;
            temp["incentiveRate"] = "0";
            temp["incentiveRateError"] = false;
            temp["incentiveDollars"] = incentive;
            temp["incentiveDollarsError"] = incentive_error;
            temp["incentiveDollarsDisabled"] = false;
            temp["incentiveRateDisabled"] = false;
          }
          acc.push({
            ...list,
            ...temp,
          });
        }
        return acc;
      }, []);
      return arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function AyInitialization(
    initial_whole_data,
    cropYearData,
    barrelsData,
    payments,
    preload,
    interest_rate,
    barrelsByYear,
    common_data_contract
  ) {
    try {
      const {
        interestRate,
        interestRateError,
        populate = false,
      } = interest_rate;
      const { prev_value: cropyear_value } = cropYearData;
      const { prev_value: barrels_value } = barrelsData;
      const arr = AyPaymentsArrBasedOnYear(preload, cropyear_value);
      const payments_arr = PaymentsArrPopulate(payments, arr);

      const show = AyPaymentsProcessed(payments_arr, cropyear_value);

      const new_payments_arr = PaymentArrBasedContract(payments_arr);

      const isDeferred = AyPaymentsDeferred(new_payments_arr);

      const initial_additional_year = { ...initial_whole_data };
      initial_additional_year["payments_pending"] = show;
      initial_additional_year["key"] = 5;
      initial_additional_year["order"] = 5;
      initial_additional_year["cropyear"] = cropyear_value;
      initial_additional_year["barrels"] = barrels_value;
      initial_additional_year["payments"] = [...new_payments_arr];
      initial_additional_year["are_data"] = {...initial_are_data};
      initial_additional_year["stock_retain_data"] = {...initial_stock_retain_data};
      initial_additional_year["common_data"] = {...initial_common_data};
      initial_additional_year["isDeferred"] = isDeferred;
      if (populate) {
        initial_additional_year["interestRate"] = interestRate;
        initial_additional_year["interestRateError"] = interestRateError;
      }
      const final_additional_year = AyCommonStockRetainAREPreData(
        initial_additional_year,
        barrelsByYear,
        common_data_contract
      );

      DispatchAdditionalYear({
        type: "replace",
        payload: final_additional_year,
      });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      DispatchAdditionalYear({ type: "replace", payload: {} });
    }
  }

  function AyCommonStockRetainAREPreData(
    item = {},
    barrelsByYear = [],
    common_data_contract = null
  ) {
    try {
      if (IsArrayNotEmpty(barrelsByYear)) {
        const pre_data = {
          average_deliveries: 0,
        };
        const current_obj = barrelsByYear[barrelsByYear.length - 2] || {},
          prev_obj = barrelsByYear[barrelsByYear.length - 1] || {};
        const cropyear = parseInt(_.get(current_obj, `cropyear_value`, "0")) || 0;
        const barrels_delivered_crop_year =
          parseInt(_.get(prev_obj, `cropyear_value`, "") || "") || 0;
        const barrel = _.get(prev_obj, `barrels_value`, "") || "";
        const common_barrels_delivered =
          parseFloat(
            IsStringChecker(barrel) ? barrel.replace(/,/g, "") : "0"
          ) || 0;

        pre_data.cropyear = cropyear;
        pre_data.common_barrels_delivered = common_barrels_delivered;
        pre_data.barrels_delivered_crop_year = barrels_delivered_crop_year;
        
        const Ay_data = CommonAreStockDataBuilder(item, pre_data, common_data_contract);
        
        return {...Ay_data};
      }
      return item;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return item;
    }
  }

  function AdditionalYearReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
      case "replace":
        return { ...payload };
      case "validate":
        return AdditionalYearValidate(state);
      case "rateChange":
        return AyPaymentsChangeLogic(state, payload);
      case "rateValidate":
        return AyPaymentsValidate(state);
      case "incentiveChange":
        return AyIncentiveChangeLogic(state, payload);
      case "incentivesValidate":
        return AyIncentivesValidate(state);
      case "incentiveTypeChange":
        return AyIncentiveTypeChangeLogic(state, payload);
      case "deferralChange":
        return AyDeferredPaymentsChangeLogic(state, payload);
      case "deferrelsValidate":
        return AyDeferralValidation(state);
      case "interestRateChange":
        return AyDeferredInterestRateChangeLogic(state, payload);
      case "AREStockChange":
        return AyAREStockPopulate(state, payload);
      case "AREStockValidate":
        return AyAREStockValidate(state);
      default:
        return state;
    }
  }

  function AyPaymentsChangeTrigger(e, child) {
    const { name, value } = OnChangeDecimalWrapper(e);
    DispatchAdditionalYear({
      type: "rateChange",
      payload: { name, value, index: child },
    });
  }

  function AyIncentiveChangeTrigger(e, child, sname, invname) {
    const { name, value } = OnChangeDecimalWrapper(e);
    DispatchAdditionalYear({
      type: "incentiveChange",
      payload: { name, value, index: child, sname, invname },
    });
  }

  function AyDeferredPaymentsChangeTrigger(e, child) {
    const { name, value } = e?.target || {};
    DispatchAdditionalYear({
      type: "deferralChange",
      payload: { name, value, child },
    });
  }

  function AyPaymentsChangeLogic(state, payload) {
    try {
      const { index, name, value } = payload;
      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      const parentIndex = masterarr.findIndex((li) => li.key == index);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const num = NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          TwentyLimit
        );
        master[name] = num;
        master[`${name}Error`] = IsNumber(
          num,
          true,
          true,
          true,
          ZeroLimit,
          TwentyLimit
        )
          ? false
          : true;

        masterarr.splice(parentIndex, 1, master);
        master_state["payments"] = [...masterarr];
        return master_state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyPaymentsValidate(state) {
    try {
      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      const ValidatedArr = masterarr.map((list) => {
        const subTemp = { ...list };
        const { rate, rateDisabled } = subTemp || {};
        if (rateDisabled) {
          return subTemp;
        }
        subTemp["rateError"] = IsNumber(
          rate,
          true,
          true,
          true,
          ZeroLimit,
          TwentyLimit
        )
          ? false
          : true;
        return subTemp;
      });
      master_state["payments"] = ValidatedArr;
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyIncentiveTypeChangeLogic(state, payload) {
    try {
      const { name, value } = payload;
      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      if (IsArrayNotEmpty(masterarr)) {
        const new_master_arr = masterarr.map((it) => {
          const tem = { ...it };
          const r_disabled = tem[`${incentiveRateName}Disabled`],
            i_disabled = tem[`${incentiveDollarsName}Disabled`];
          if (r_disabled || i_disabled) {
            tem[`${incentiveRateName}Error`] = false;
            tem[`${incentiveDollarsName}Error`] = false;
          } else {
            tem[incentiveRateName] = "";
            tem[`${incentiveRateName}Error`] = false;
            tem[incentiveDollarsName] = "";
            tem[`${incentiveDollarsName}Error`] = false;
          }
          return tem;
        });
        master_state[name] = value;
        master_state["payments"] = new_master_arr;
        return master_state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyIncentiveChangeLogic(state, payload) {
    try {
      const { index, name, value, sname, invname } = payload;
      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      const parentIndex = masterarr.findIndex((li) => li.key == index);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const rate_or_dollar = master_state[sname];

        const num = NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          rate_or_dollar ? HundredLimit : OneMillionLimit
        );
        master[name] = num;
        master[`${name}Error`] = IsNumber(
          num,
          true,
          true,
          true,
          ZeroLimit,
          rate_or_dollar ? HundredLimit : OneMillionLimit
        )
          ? false
          : true;
        master[invname] = 0;
        master[`${invname}Error`] = false;

        masterarr.splice(parentIndex, 1, master);
        master_state["payments"] = [...masterarr];

        master_state["payments"] = [...masterarr];
        return master_state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyIncentivesValidate(state) {
    try {
      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      const ValidatedArr = masterarr.map((list) => {
        const subTemp = { ...list };
        const {
          incentiveDollars,
          incentiveRate,
          incentiveDollarsDisabled,
          incentiveRateDisabled,
        } = subTemp || {};
        if (master_state?.incentiveRateOrDollars) {
          if (incentiveRateDisabled) {
            return subTemp;
          }
          subTemp["incentiveRateError"] = IsNumber(
            incentiveRate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          )
            ? false
            : true;
          return subTemp;
        } else {
          if (incentiveDollarsDisabled) {
            return subTemp;
          }
          subTemp["incentiveDollarsError"] = IsNumber(
            incentiveDollars,
            true,
            true,
            true,
            ZeroLimit,
            OneMillionLimit
          )
            ? false
            : true;
          return subTemp;
        }
      });
      master_state["payments"] = ValidatedArr;
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyDeferredPaymentsChangeLogic(state, payload) {
    try {
      const { child: index, name, value } = payload;

      const master_state = { ...state };
      const masterarr = master_state["payments"] || [];
      const parentIndex = masterarr.findIndex((li) => li.key == index);
      if (parentIndex >= 0) {
        const temp = { ...masterarr[parentIndex] };
        if (compareString(name, deferrelPercentName)) {
          const num = NumberFormaterWithValidation(value, WholeRateValidation);
          temp[name] = num;
          temp[`${name}Error`] = IsNumber(num, true, true, true, 0)
            ? false
            : true;
          temp[`${name}Disabled`] = false;
        } else {
          const num = NumberFormaterWithValidation(value, WholeRateValidation);
          temp[name] = num;
          temp[`${name}Error`] = IsNumber(num, true, true, true, 0)
            ? false
            : true;
          if (num == 0) {
            temp[`${deferrelPercentName}`] = 0;
            temp[`${deferrelPercentName}Disabled`] = true;
            temp["isDeferredPeriod"] = false;
          } else {
            temp[`${deferrelPercentName}Disabled`] = false;
            temp["isDeferredPeriod"] = true;
          }
        }
        masterarr.splice(parentIndex, 1, temp);

        master_state["isDeferred"] = masterarr.some(
          (li) => li?.isDeferredPeriod || false
        );
        master_state["payments"] = masterarr;

        return master_state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyDeferredInterestRateChangeLogic(state, payload) {
    try {
      const { name, value } = payload;
      const master_state = { ...state };
      const num = NumberFormaterWithValidation(
        value,
        WholeRateValidation,
        ZeroLimit,
        HundredLimit
      );
      const error = IsNumber(num, true, true, true, ZeroLimit, HundredLimit)
        ? false
        : true;
      master_state[name] = num;
      master_state[`${name}Error`] = error;
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyDeferralValidation(state) {
    try {
      const master_state = { ...state };
      const { payments, interestRate, isDeferred } = master_state || {};
      master_state["interestRateError"] = IsNumber(
        interestRate,
        true,
        true,
        true,
        ZeroLimit,
        HundredLimit
      )
        ? false
        : true;
      const masterarr = payments || [];
      const ValidatedArr = masterarr.map((list) => {
        const subTemp = { ...list };
        const { isDeferredPeriod, deferrelPercent, deferrelPeriod } =
          subTemp || {};
        if (isDeferred) {
          if (isDeferredPeriod) {
            subTemp["deferrelPercentError"] = IsNumber(
              deferrelPercent,
              true,
              true,
              true,
              0
            )
              ? false
              : true;
            subTemp["deferrelPeriodError"] = IsNumber(
              deferrelPeriod,
              true,
              true,
              true,
              0
            )
              ? false
              : true;
          }
        }
        return subTemp;
      });
      master_state["payments"] = ValidatedArr;
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyAREStockPopulate(state, payload) {
    try {
      const { data_list } = payload;
      if (IsArrayNotEmpty(data_list)) {
        const master_state = { ...state };
        for (const element of data_list) {
          const { field, name, value, error } = element;
          master_state[field][name] = value;
          master_state[field][`${name}_error`] = error;
        }
        return master_state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AyAREStockValidate(state) {
    try {
      const master_state = { ...state };
      const { are_data = {}, stock_retain_data = {} } = master_state;
      if (IsObjectChecker(are_data) && !_.isEmpty(are_data)) {
        const temp = {...are_data} || {};
        temp.are_rate_error = IsNumber(
          temp.are_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.are_issuance_error = IsNumber(
          temp.are_issuance,
          true,
          true,
          true,
          OneLimit,
          FiveMillionLimit
        )
          ? false
          : true;
        temp.are_redemption_error = IsNumber(
          temp.are_redemption,
          true,
          true,
          true,
          OneLimit,
          FiveMillionLimit
        )
          ? false
          : true;
        master_state["are_data"] = temp;
      }
      if(IsObjectChecker(stock_retain_data) && !_.isEmpty(stock_retain_data)){
        const temp = {...stock_retain_data} || {};
        
        temp.average_deliveries_error = IsNumber(
          temp.average_deliveries,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.quota_rate_error = IsNumber(
          temp.quota_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.par_value_error = IsNumber(
          temp.par_value,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.quota_shares_error = IsNumber(
          temp.quota_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.common_stock_shares_held_error = IsNumber(
          temp.common_stock_shares_held,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.percent_to_quota_error = IsNumber(
          temp.percent_to_quota,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.retain_rate_error = IsNumber(
          temp.retain_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.retain_dollars_error = IsNumber(
          temp.retain_dollars,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.potential_retain_shares_error = IsNumber(
          temp.potential_retain_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.shares_under_quota_error = IsNumber(
          temp.shares_under_quota,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        temp.shares_under_quota_value_error = IsNumber(
          temp.shares_under_quota_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.retain_shares_to_be_issued_error = IsNumber(
          temp.retain_shares_to_be_issued,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        temp.retain_shares_to_be_issued_value_error = IsNumber(
          temp.retain_shares_to_be_issued_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.cash_in_lieu_of_stock_error = IsNumber(
          temp.cash_in_lieu_of_stock,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        master_state["stock_retain_data"] = temp;
      }
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }


  function AdditionalYearValidate(state) {
    try {
      const master_state = { ...state };
      const {
        barrels,
        payments = [],
        interestRate,
        incentiveRateOrDollars,
        isDeferred,
      } = master_state || {};
      master_state["barrelsError"] = IsStringChecker(barrels) ? false : true;
      master_state["interestRateError"] = IsNumber(
        interestRate,
        true,
        true,
        true,
        ZeroLimit,
        HundredLimit
      )
        ? false
        : true;
      const masterarr = [...payments];
      const validatedPayments = masterarr.map((list) => {
        const subTemp = { ...list };
        const {
          rate,
          rateDisabled,
          incentiveDollars,
          incentiveRate,
          incentiveDollarsDisabled,
          incentiveRateDisabled,
          isDeferredPeriod,
          deferrelPercent,
          deferrelPeriod,
        } = subTemp || {};
        if (rateDisabled) {
          subTemp["rateError"] = false;
        } else {
          subTemp["rateError"] = IsNumber(
            rate,
            true,
            true,
            true,
            ZeroLimit,
            TwentyLimit
          )
            ? false
            : true;
        }
        if (incentiveRateOrDollars) {
          if (incentiveRateDisabled) {
            subTemp["incentiveRateError"] = false;
          } else {
            subTemp["incentiveRateError"] = IsNumber(
              incentiveRate,
              true,
              true,
              true,
              ZeroLimit,
              HundredLimit
            )
              ? false
              : true;
          }
        } else {
          if (incentiveDollarsDisabled) {
            subTemp["incentiveDollarsError"] = false;
          } else {
            subTemp["incentiveDollarsError"] = IsNumber(
              incentiveDollars,
              true,
              true,
              true,
              ZeroLimit,
              OneMillionLimit
            )
              ? false
              : true;
          }
        }
        if (isDeferred) {
          if (isDeferredPeriod) {
            subTemp["deferrelPercentError"] = IsNumber(
              deferrelPercent,
              true,
              true,
              true,
              0
            )
              ? false
              : true;
            subTemp["deferrelPeriodError"] = IsNumber(
              deferrelPeriod,
              true,
              true,
              true,
              0
            )
              ? false
              : true;
          }
        }
        return subTemp;
      });
      master_state["payments"] = validatedPayments;
      return master_state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  const defaultInputComponentData = {
    key: minEntry - 1,
    order: minEntry - 1,
    cropyearReadonly: true,
    barrelsError: false,
    isDeferred: false,
    interestRateError: false,
    cropyear: cpCropyear?.value,
    barrels: cpBarrels?.value,
    interestRate: "",
    incentiveRateOrDollars: false,
    common_data: initial_common_data,
    are_data: initial_are_data,
    stock_retain_data: initial_stock_retain_data,
  };

  const initialPaymentsArr = [
    {
      key: 0,
      NA_Order: 1,
      SA_Order: 3,
      contractOrder: null,
      month: "March",
      rateError: false,
      incentiveRateError: false,
      incentiveDollarsError: false,
      isDeferredPeriod: false,
      deferrelPercentError: false,
      deferrelPercentDisabled: true,
      rate: "",
      incentiveRate: "",
      incentiveDollars: "",
      rateDisabled: false,
      incentiveRateDisabled: false,
      incentiveDollarsDisabled: false,
      deferrelPeriod: 0,
      deferrelPercent: 0,
    },
    {
      key: 1,
      NA_Order: 2,
      SA_Order: 4,
      contractOrder: null,
      month: "June",
      rateError: false,
      incentiveRateError: false,
      incentiveDollarsError: false,
      isDeferredPeriod: false,
      deferrelPercentError: false,
      deferrelPercentDisabled: true,
      rate: "",
      incentiveRate: "",
      incentiveDollars: "",
      rateDisabled: false,
      incentiveRateDisabled: false,
      incentiveDollarsDisabled: false,
      deferrelPeriod: 0,
      deferrelPercent: 0,
    },
    {
      key: 2,
      NA_Order: 3,
      SA_Order: 1,
      contractOrder: null,
      month: "September",
      rateError: false,
      incentiveRateError: false,
      incentiveDollarsError: false,
      isDeferredPeriod: false,
      deferrelPercentError: false,
      deferrelPercentDisabled: true,
      rate: "",
      incentiveRate: "",
      incentiveDollars: "",
      rateDisabled: false,
      incentiveRateDisabled: false,
      incentiveDollarsDisabled: false,
      deferrelPeriod: 0,
      deferrelPercent: 0,
    },
    {
      key: 3,
      NA_Order: 4,
      SA_Order: 2,
      contractOrder: null,
      month: "December",
      rateError: false,
      incentiveRateError: false,
      incentiveDollarsError: false,
      isDeferredPeriod: false,
      deferrelPercentError: false,
      deferrelPercentDisabled: true,
      rate: "",
      incentiveRate: "",
      incentiveDollars: "",
      rateDisabled: false,
      incentiveRateDisabled: false,
      incentiveDollarsDisabled: false,
      deferrelPeriod: 0,
      deferrelPercent: 0,
    },
  ];

  const [paymentsArr, dispatchPaymentsMapping] = useReducer(
    PaymentsMappingReducer,
    []
  );

  const [inputComponentArr, dispatchInputComponentMapping] = useReducer(
    InputComponentMappingReducer,
    []
  );

  function PaymentsMappingReducer(state, action) {
    const { type, payload } = action || {};
    switch (type) {
      case "replace":
        return [...payload];
      default:
        return state;
    }
  }

  function InputComponentMappingReducer(state, action) {
    const { type, payload } = action || {};
    switch (type) {
      case "replace":
        return [...payload];
      case "barrelsValidate":
        return BarrelsStateValidate(state);
      case "paymentsValidate":
        return PaymentsStateValidate(state);
      case "incentivesValidate":
        return IncentivesStateValidate(state);
      case "deferrelsValidate":
        return DeferrelsStateValidate(state);
      case "validate":
        return InputComponentValidate(state);
      case "barrelsChange":
        return BarrelsFormatter(state, payload);
      case "barrelsClear":
        const { name: bname, index: bindex } = payload;
        const bTempIndex = state.findIndex((li) => li.key == bindex);
        if (bTempIndex >= 0) {
          const temp = state[bTempIndex];
          temp[bname] = "";
          temp[`${bname}Error`] = true;
          const tarr = [...state];
          tarr.splice(bTempIndex, 1, temp);
          return tarr;
        }
        return state;
      case "rateChange":
        return PaymentsChangeLogic([...state], payload);
      case "rateChangeToAll":
        return PaymentsChangeToAllLogic([...state], payload);
      case "incentiveTypeChange":
        return IncentiveTypeChangeLogic([...state], payload);
      case "incentiveChange":
        return IncentiveChangeLogic([...state], payload);
      case "incentiveChangeQuarter":
        return IncentiveChangeToAllQuarterLogic([...state], payload);
      case "incentiveChangeToAll":
        return IncentiveChangeToAllLogic([...state], payload);
      case "interestRateChange":
        return InterestRateChangeLogic([...state], payload);
      case "deferralChange":
        return DeferredPaymentsChangeLogic([...state], payload);
      case "deferralNextYearChange":
        return DeferredPaymentNextYearUpdateLogic([...state], payload);
      case "deferralSkip":
        return DeferredPaymentSkipUpdateLogic([...state]);
      case "AREChange":
        return MasterAREPopulate([...state], payload);
      case "StockRetainChange":
        return MasterStockRetainPopulate([...state], payload);
      case "AREStockRetainChange":
        return MasterAREStockRetainPopulate([...state], payload);
      case "AREValidate":
        return AREDataValidate([...state]);
      case "StockRetainValidate":
        return StockRetainValidate([...state]);
      default:
        return state;
    }
  }

  // Deliveries Section
  function BarrelsStateValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = { ...item };
        const { barrels } = temp || {};
        temp["barrelsError"] = IsStringChecker(barrels) ? false : true;
        return temp;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function PaymentsStateValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = { ...item };
        const { payments } = temp || {};
        const validatedPayments = payments.map((list) => {
          const subTemp = { ...list };
          const { rate } = subTemp || {};
          subTemp["rateError"] = IsNumber(
            rate,
            true,
            true,
            true,
            ZeroLimit,
            TwentyLimit
          )
            ? false
            : true;
          return subTemp;
        });
        temp[payments_data] = validatedPayments;
        return temp;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function IncentivesStateValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = { ...item };
        const { payments, incentiveRateOrDollars } = temp || {};
        const validatedPayments = payments.map((list) => {
          const subTemp = { ...list };
          const { incentiveDollars, incentiveRate } = subTemp || {};
          if (incentiveRateOrDollars) {
            subTemp["incentiveRateError"] = IsNumber(
              incentiveRate,
              true,
              true,
              true,
              ZeroLimit,
              HundredLimit
            )
              ? false
              : true;
          } else {
            subTemp["incentiveDollarsError"] = IsNumber(
              incentiveDollars,
              true,
              true,
              true,
              ZeroLimit,
              OneMillionLimit
            )
              ? false
              : true;
          }
          return subTemp;
        });
        temp[payments_data] = validatedPayments;
        return temp;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function DeferrelsStateValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = { ...item };
        const { payments, interestRate, isDeferred } = temp || {};
        temp["interestRateError"] = IsNumber(
          interestRate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        const validatedPayments = payments.map((list) => {
          const subTemp = { ...list };
          const { isDeferredPeriod, deferrelPercent, deferrelPeriod } =
            subTemp || {};
          if (isDeferred) {
            if (isDeferredPeriod) {
              subTemp["deferrelPercentError"] = IsNumber(
                deferrelPercent,
                true,
                true,
                true,
                0
              )
                ? false
                : true;
              subTemp["deferrelPeriodError"] = IsNumber(
                deferrelPeriod,
                true,
                true,
                true,
                0
              )
                ? false
                : true;
            }
          }
          return subTemp;
        });
        temp[payments_data] = validatedPayments;
        return temp;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function InputComponentValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = { ...item };
        const {
          barrels,
          payments,
          interestRate,
          incentiveRateOrDollars,
          isDeferred,
        } = temp || {};
        temp["barrelsError"] = IsStringChecker(barrels) ? false : true;
        temp["interestRateError"] = IsNumber(
          interestRate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        const validatedPayments = payments.map((list) => {
          const subTemp = { ...list };
          const {
            rate,
            incentiveDollars,
            incentiveRate,
            isDeferredPeriod,
            deferrelPercent,
            deferrelPeriod,
          } = subTemp || {};
          subTemp["rateError"] = IsNumber(
            rate,
            true,
            true,
            true,
            ZeroLimit,
            TwentyLimit
          )
            ? false
            : true;
          if (incentiveRateOrDollars) {
            subTemp["incentiveRateError"] = IsNumber(
              incentiveRate,
              true,
              true,
              true,
              ZeroLimit,
              HundredLimit
            )
              ? false
              : true;
          } else {
            subTemp["incentiveDollarsError"] = IsNumber(
              incentiveDollars,
              true,
              true,
              true,
              ZeroLimit,
              OneMillionLimit
            )
              ? false
              : true;
          }
          if (isDeferred) {
            if (isDeferredPeriod) {
              subTemp["deferrelPercentError"] = IsNumber(
                deferrelPercent,
                true,
                true,
                true,
                0
              )
                ? false
                : true;
              subTemp["deferrelPeriodError"] = IsNumber(
                deferrelPeriod,
                true,
                true,
                true,
                0
              )
                ? false
                : true;
            }
          }
          return subTemp;
        });
        temp[payments_data] = validatedPayments;
        return temp;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function StockRetainValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = item?.stock_retain_data || {};
        temp.average_deliveries_error = IsNumber(
          temp.average_deliveries,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.quota_rate_error = IsNumber(
          temp.quota_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.par_value_error = IsNumber(
          temp.par_value,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.quota_shares_error = IsNumber(
          temp.quota_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.common_stock_shares_held_error = IsNumber(
          temp.common_stock_shares_held,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.percent_to_quota_error = IsNumber(
          temp.percent_to_quota,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.retain_rate_error = IsNumber(
          temp.retain_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.retain_dollars_error = IsNumber(
          temp.retain_dollars,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.potential_retain_shares_error = IsNumber(
          temp.potential_retain_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.shares_under_quota_error = IsNumber(
          temp.shares_under_quota,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        temp.shares_under_quota_value_error = IsNumber(
          temp.shares_under_quota_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.retain_shares_to_be_issued_error = IsNumber(
          temp.retain_shares_to_be_issued,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        temp.retain_shares_to_be_issued_value_error = IsNumber(
          temp.retain_shares_to_be_issued_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        temp.cash_in_lieu_of_stock_error = IsNumber(
          temp.cash_in_lieu_of_stock,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        item["stock_retain_data"] = temp;
        return item;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AREDataValidate(state) {
    try {
      const masterarr = [...state];
      const ValidatedArr = masterarr.map((item) => {
        const temp = item?.are_data || {};
        temp.are_rate_error = IsNumber(
          temp.are_rate,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;
        temp.are_issuance_error = IsNumber(
          temp.are_issuance,
          true,
          true,
          true,
          OneLimit,
          FiveMillionLimit
        )
          ? false
          : true;
        temp.are_redemption_error = IsNumber(
          temp.are_redemption,
          true,
          true,
          true,
          OneLimit,
          FiveMillionLimit
        )
          ? false
          : true;
        item["are_data"] = temp;
        return item;
      });
      return ValidatedArr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function AddCropyearBarrelsEntry() {
    const last = inputComponentArr.length;
    if (last < maxEntry && last >= minEntry - 1) {
      const merged_arr = mergePreloadDatas(
        rates_preload,
        incentives_preload,
        deferments_preload
      );
      const split_arrays = SplitCurrentAdditionalYearData(
        merged_arr,
        cpCropyear?.value
      );
      const common_data_contract = ContractDetailsForCommonData(
        contract,
        contractOptions
      );
      if (last === minEntry - 1) {
        const interest_rate = {
          interestRate: deferments_rate_preload,
          interestRateError: IsNumber(
            deferments_rate_preload,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          )
            ? false
            : true,
        };
        AyInitialization(
          defaultInputComponentData,
          cpCropyear,
          cpBarrels,
          paymentsArr,
          split_arrays?.additional,
          { ...interest_rate, populate: true },
          barrelsByYear,
          common_data_contract
        );
        const tempArr = [
          {
            ...defaultInputComponentData,
            cropyear: cpCropyear?.value,
            barrels: cpBarrels?.value,
            incentiveRateOrDollars: false,
            payments: [...paymentsArr],
            ...interest_rate,
          },
        ];
        const preloaded_arr = populateDefaultDeferredValues(
          tempArr,
          split_arrays?.current
        );
        const final_arr = CommonStockRetainAREPreData(
          preloaded_arr,
          barrelsByYear,
          common_data_contract
        );

        const ordered_arr = _.orderBy(final_arr, "order", "asc");
        dispatchInputComponentMapping({
          type: "replace",
          payload: ordered_arr,
        });
      } else {
        const tempArr = [...inputComponentArr];
        const data = inputComponentArr[last - 1];
        const cropyear = parseInt(data?.cropyear);
        const is_rate = data[incentiveRateOrDollarsName];
        const interest_rate = data[interestRateName];
        const interest_rate_error = data[`${interestRateName}Error`];
        const element = {
          ...defaultInputComponentData,
          key: last,
          order: last,
          cropyear: cropyear + 1,
          barrels: data?.barrels,
          payments: [...paymentsArr],
          [incentiveRateOrDollarsName]: is_rate,
          [interestRateName]: interest_rate,
          [`${interestRateName}Error`]: interest_rate_error,
        };
        tempArr.push(element);
        const preloaded_arr = populateDefaultDeferredValues(
          tempArr,
          split_arrays?.current
        );
        const final_arr = CommonStockRetainAREPreData(
          preloaded_arr,
          barrelsByYear,
          common_data_contract
        );
        const ordered_arr = _.orderBy(final_arr, "order", "asc");
        dispatchInputComponentMapping({
          type: "replace",
          payload: ordered_arr,
        });
      }
    }
  }

  function incentiveArrGenerator(months = [], incentives = {}) {
    try {
      const { cropyear, incentive, incentive_error } = incentives;
      if (IsArrayNotEmpty(months)) {
        return months.map((li) => ({
          month: li,
          incentive,
          incentive_error,
          cropyear,
        }));
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function mergePreloadDatas(rates = [], incentives = [], deferments = []) {
    try {
      const has_rate = IsArrayNotEmpty(rates),
        has_incentive = IsArrayNotEmpty(incentives),
        has_deferment = IsArrayNotEmpty(deferments);
      var initial_arr = [];
      if (has_rate) {
        initial_arr = [...initial_arr, ...rates];
      }
      if (has_incentive) {
        initial_arr = [...initial_arr, ...incentives];
      }
      if (has_deferment) {
        initial_arr = [...initial_arr, ...deferments];
      }
      if (IsArrayNotEmpty(initial_arr)) {
        const merged_arr = initial_arr.reduce((acc, current) => {
          const existing = acc.find((item) => {
            if (item.cropyear == current.cropyear) {
              return item.month == current.month;
            }
          });
          if (existing) {
            // Merge the current object into the existing one
            Object.assign(existing, current);
          } else {
            // If no existing object, add the current one
            acc.push(current);
          }
          return acc;
        }, []);
        return merged_arr;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function SplitCurrentAdditionalYearData(
    preload = [],
    year = null,
    field = "cropyear"
  ) {
    try {
      if (IsArrayNotEmpty(preload)) {
        const additional = preload.filter((li) =>
          pastYearCompare(year, li[field])
        );
        const current = preload.filter(
          (li) => !pastYearCompare(year, li[field])
        );
        return { current, additional };
      }
      return { current: [], additional: [] };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { current: [], additional: [] };
    }
  }

  function populateDefaultDeferredValues(arr = [], preload_arr = []) {
    try {
      if (IsArrayNotEmpty(preload_arr)) {
        const new_arr = arr.map((list) => {
          const { cropyear, payments } = list;
          const filtered_arr = preload_arr.filter(
            (li) => cropyear == li?.cropyear
          );
          if (IsArrayNotEmpty(filtered_arr)) {
            const master_obj = { ...list };
            master_obj.payments = payments.map((li) => {
              const found = filtered_arr.find((it) =>
                compareString(li?.month, it?.month)
              );
              if (found) {
                const temp = { ...li };
                if (found.hasOwnProperty("rate")) {
                  const { rate, rate_error, rate_enabled = true } = found;
                  temp["rate"] = rate;
                  temp["rateError"] = rate_error;
                  temp["rateDisabled"] = !rate_enabled;
                }
                if (
                  found.hasOwnProperty("deferment_period") &&
                  found.hasOwnProperty("deferment_percent")
                ) {
                  const {
                    deferment_percent,
                    deferment_percent_error,
                    deferment_percent_disabled,
                    deferment_period,
                    deferred,
                  } = found;
                  temp["deferrelPeriod"] = deferment_period;
                  temp["deferrelPercent"] = deferment_percent;
                  temp["isDeferredPeriod"] = deferred;
                  temp["deferrelPercentError"] = deferment_percent_error;
                  temp["deferrelPercentDisabled"] = deferment_percent_disabled;
                }
                if (found.hasOwnProperty("incentive")) {
                  const { incentive, incentive_error } = found;
                  temp["incentiveRate"] = "0";
                  temp["incentiveRateError"] = false;
                  temp["incentiveDollars"] = incentive;
                  temp["incentiveDollarsError"] = incentive_error;
                  temp["incentiveDollarsDisabled"] = false;
                  temp["incentiveRateDisabled"] = false;
                }
                return temp;
              }
              return { ...li };
            });
            return master_obj;
          } else {
            return list;
          }
        });
        if (IsArrayNotEmpty(new_arr)) {
          const final_arr = new_arr.map((li) => {
            const { payments } = li;
            if (IsArrayNotEmpty(payments)) {
              const isDeferred = payments.some((it) => it?.isDeferredPeriod);
              return { ...li, isDeferred };
            } else {
              return li;
            }
          });
          return final_arr;
        } else {
          return arr;
        }
      }
      return arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return arr;
    }
  }

  function BarrelsChange(e, index) {
    const { name, value } = OnChangeDecimalWrapper(e);
    dispatchInputComponentMapping({
      type: "barrelsChange",
      payload: { name, value, index },
    });
  }

  function BarrelsFormatter(ostate, payload) {
    const state = [...ostate];
    const { value, name, index } = payload;
    const tempIndex = state.findIndex((li) => li.key == index);
    if (tempIndex >= 0) {
      const temp = state[tempIndex];
      const num = BarrelsNumberFormater(value, true);
      temp[name] = IsStringChecker(num) ? num : "";
      temp[`${name}Error`] = IsStringChecker(num) ? false : true;
      const tarr = [...state];
      tarr.splice(tempIndex, 1, temp);
      return tarr;
    }
    return state;
  }

  function BarrelsClear(e, name, index) {
    dispatchInputComponentMapping({
      type: "barrelsClear",
      payload: { name, index },
    });
  }

  function DeleteOneCropyearBarrelsEntry() {
    const last = inputComponentArr.length;
    if (last <= maxEntry && last >= minEntry) {
      const tempArr = [...inputComponentArr];
      tempArr.pop();
      dispatchInputComponentMapping({ type: "replace", payload: tempArr });
      if (tempArr.length == 0) {
        SectionHandler(false);
      }
    }
  }

  function DeleteAllCropyearBarrelsEntries() {
    const last = inputComponentArr.length;
    if (last >= minEntry) {
      dispatchInputComponentMapping({ type: "replace", payload: [] });
      SectionHandler(false);
    }
  }

  // Payments Section
  function PaymentsChangeTrigger(e, parent, child) {
    const { name, value } = OnChangeDecimalWrapper(e);
    dispatchInputComponentMapping({
      type: "rateChange",
      payload: { name, value, parent, index: child },
    });
  }

  function PaymentsChangeLogic(state, payload) {
    try {
      const { parent, index, name, value } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const child = master[payments_data];
        if (IsArrayNotEmpty(child)) {
          const tarr = [...child];
          const childIndex = tarr.findIndex((li) => li.key == index);
          if (childIndex >= 0) {
            const temp = { ...tarr[childIndex] };

            const num = NumberFormaterWithValidation(
              value,
              WholeRateValidation,
              ZeroLimit,
              TwentyLimit
            );
            temp[name] = num;
            temp[`${name}Error`] = IsNumber(
              num,
              true,
              true,
              true,
              ZeroLimit,
              TwentyLimit
            )
              ? false
              : true;

            tarr.splice(childIndex, 1, temp);
            master[payments_data] = [...tarr];

            masterarr.splice(parentIndex, 1, { ...master });
            return masterarr;
          }
          return state;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function PaymentsChangeToAllTrigger(parent, name) {
    dispatchInputComponentMapping({
      type: "rateChangeToAll",
      payload: { name, parent },
    });
  }

  function PaymentsChangeToAllLogic(state, payload) {
    try {
      const { parent, name } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const child = master[payments_data];
        if (IsArrayNotEmpty(child)) {
          const new_master_arr = masterarr.map((item) => {
            const payments = item[payments_data].reduce((acc, it) => {
              const tChild = child.find((li) => li.key == it.key);
              if (tChild) {
                const tem = { ...it };
                tem[name] = tChild[name];
                tem[`${name}Error`] = tChild[`${name}Error`];
                acc.push(tem);
              } else {
                acc.push(it);
              }
              return acc;
            }, []);
            return { ...item, [payments_data]: payments };
          });
          return new_master_arr;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // Incentives Section
  function IncentiveTypeChangeTrigger(e) {
    const { name, value } = e?.target || {};
    dispatchInputComponentMapping({
      type: "incentiveTypeChange",
      payload: {
        name,
        value: value === "true" || value === true,
      },
    });
    DispatchAdditionalYear({
      type: "incentiveTypeChange",
      payload: {
        name,
        value: value === "true" || value === true,
      },
    });
  }

  function IncentiveTypeChangeLogic(state, payload) {
    try {
      const { name, value } = payload;
      const masterarr = [...state];
      if (IsArrayNotEmpty(masterarr)) {
        const new_master_arr = masterarr.map((item) => {
          const payments = item[payments_data].map((it) => {
            const tem = { ...it };
            tem[incentiveRateName] = "";
            tem[`${incentiveRateName}Error`] = false;
            tem[incentiveDollarsName] = "";
            tem[`${incentiveDollarsName}Error`] = false;
            return tem;
          }, []);
          return { ...item, [name]: value, [payments_data]: payments };
        });
        return new_master_arr;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function IncentiveChangeTrigger(e, parent, child, sname, invname) {
    const { name, value } = OnChangeDecimalWrapper(e);
    dispatchInputComponentMapping({
      type: "incentiveChange",
      payload: { name, value, parent, index: child, sname, invname },
    });
  }

  function IncentiveChangeQuarterTrigger(e, parent, child, sname, invname){
    const { name, value } = OnChangeDecimalWrapper(e);
    dispatchInputComponentMapping({
      type: "incentiveChangeQuarter",
      payload: { name, value, parent, index: child, sname, invname },
    });
  }

  function IncentiveChangeToAllTrigger(parent, name, invname) {    
    dispatchInputComponentMapping({
      type: "incentiveChangeToAll",
      payload: { name, parent, invname },
    });
  }

  function IncentiveChangeLogic(state, payload) {
    try {
      const { parent, index, name, value, sname, invname } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const child = master[payments_data];
        const rate_or_dollar = master[sname];
        if (IsArrayNotEmpty(child)) {
          const tarr = [...child];
          const childIndex = tarr.findIndex((li) => li.key == index);
          if (childIndex >= 0) {
            const temp = { ...tarr[childIndex] };

            const num = NumberFormaterWithValidation(
              value,
              WholeRateValidation,
              ZeroLimit,
              rate_or_dollar ? HundredLimit : OneMillionLimit
            );
            temp[name] = num;
            temp[`${name}Error`] = IsNumber(
              num,
              true,
              true,
              true,
              ZeroLimit,
              rate_or_dollar ? HundredLimit : OneMillionLimit
            )
              ? false
              : true;
            temp[invname] = 0;
            temp[`${invname}Error`] = false;

            tarr.splice(childIndex, 1, temp);
            master[payments_data] = [...tarr];

            masterarr.splice(parentIndex, 1, { ...master });
            return masterarr;
          }
          return state;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function IncentiveChangeToAllLogic(state, payload) {
    try {
      const { parent, name, invname } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const child = master[payments_data];
        if (IsArrayNotEmpty(child)) {
          const new_master_arr = masterarr.map((item) => {
            const payments = item[payments_data].reduce((acc, it) => {
              const tChild = child.find((li) => li.key == it.key);
              if (tChild) {
                const tem = { ...it };
                tem[name] = tChild[name];
                tem[`${name}Error`] = tChild[`${name}Error`];
                tem[invname] = 0;
                tem[`${invname}Error`] = false;
                acc.push(tem);
              } else {
                acc.push(it);
              }
              return acc;
            }, []);
            return { ...item, [payments_data]: payments };
          });
          return new_master_arr;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function IncentiveChangeToAllQuarterLogic(state, payload) {
    try {
      const { parent, index: child, name, value, sname, invname } = payload;
      const masterarr = [...state];
      return masterarr.map((li) => {
        const parent_found = parent == li?.key;
        const master = { ...li };
        const childarr = master[payments_data];
        const rate_or_dollar = master[sname];
        const num = NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          rate_or_dollar ? HundredLimit : OneMillionLimit
        );
        const error = IsNumber(
          num,
          true,
          true,
          true,
          ZeroLimit,
          rate_or_dollar ? HundredLimit : OneMillionLimit
        )
          ? false
          : true;
        if (IsArrayNotEmpty(childarr)) {
          master[payments_data] = childarr.map((item) => {
            const child_found = child == item?.key;
            const temp = { ...item };

            if (parent_found && child_found) {
              temp[name] = num;
              temp[`${name}Error`] = error;
              temp[invname] = 0;
              temp[`${invname}Error`] = false;
            } else {
              const numb = temp[name];
              const number_found = IsNumber(
                numb,
                true,
                true,
                true,
                ZeroLimit,
                rate_or_dollar ? HundredLimit : OneMillionLimit
              )
                ? false
                : true;
              if (number_found) {
                temp[name] = num;
                temp[`${name}Error`] = error;
                temp[invname] = 0;
                temp[`${invname}Error`] = false;
              }
            }
            return temp;
          });
          return master;
        } else {
          return master;
        }
      });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // Deferrel Section
  function InterestRateChangeTrigger(e) {
    const { name, value } = OnChangeDecimalWrapper(e);
    dispatchInputComponentMapping({
      type: "interestRateChange",
      payload: {
        name,
        value,
      },
    });
    DispatchAdditionalYear({
      type: "interestRateChange",
      payload: {
        name,
        value,
      },
    });
  }

  function InterestRateChangeLogic(state, payload) {
    try {
      const { name, value } = payload;
      const masterarr = [...state];
      if (IsArrayNotEmpty(masterarr)) {
        const new_master_arr = masterarr.map((item) => {
          const num = NumberFormaterWithValidation(
            value,
            WholeRateValidation,
            ZeroLimit,
            HundredLimit
          );
          const error = IsNumber(num, true, true, true, ZeroLimit, HundredLimit)
            ? false
            : true;
          return { ...item, [name]: num, [`${name}Error`]: error };
        });
        return new_master_arr;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function DeferredPaymentsChangeTrigger(e, parent, child) {
    const { name, value } = e?.target || {};
    dispatchInputComponentMapping({
      type: "deferralChange",
      payload: { name, value, parent, child },
    });
  }

  function DeferredPaymentsChangeLogic(state, payload) {
    try {
      const { parent, child: index, name, value } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      if (parentIndex >= 0) {
        const master = { ...masterarr[parentIndex] };
        const child = master[payments_data];
        if (IsArrayNotEmpty(child)) {
          const tarr = [...child];
          const childIndex = tarr.findIndex((li) => li.key == index);
          if (childIndex >= 0) {
            const temp = { ...tarr[childIndex] };

            if (compareString(name, deferrelPercentName)) {
              const num = NumberFormaterWithValidation(
                value,
                WholeRateValidation
              );
              temp[name] = num;
              temp[`${name}Error`] = IsNumber(num, true, true, true, 0)
                ? false
                : true;
              temp[`${name}Disabled`] = false;
            } else {
              const num = NumberFormaterWithValidation(
                value,
                WholeRateValidation
              );
              temp[name] = num;
              temp[`${name}Error`] = IsNumber(num, true, true, true, 0)
                ? false
                : true;
              if (num == 0) {
                temp[`${deferrelPercentName}`] = 0;
                temp[`${deferrelPercentName}Disabled`] = true;
                temp["isDeferredPeriod"] = false;
              } else {
                temp[`${deferrelPercentName}Disabled`] = false;
                temp["isDeferredPeriod"] = true;
              }
            }

            tarr.splice(childIndex, 1, temp);
            const payments = [...tarr];
            master["isDeferred"] = payments.some(
              (li) => li?.isDeferredPeriod || false
            );
            master[payments_data] = payments;

            masterarr.splice(parentIndex, 1, { ...master });
            return masterarr;
          }
          return state;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function DeferredPaymentNextYearUpdateTrigger(parent, nextParent) {
    dispatchInputComponentMapping({
      type: "deferralNextYearChange",
      payload: { parent, nextParent },
    });
  }

  function DeferredPaymentNextYearUpdateLogic(state, payload) {
    try {
      const { parent, nextParent } = payload;
      const masterarr = [...state];
      const parentIndex = masterarr.findIndex((li) => li.key == parent);
      const nextParentIndex = masterarr.findIndex((li) => li.key == nextParent);
      if (parentIndex >= 0 && nextParentIndex >= 0) {
        const parentMaster = { ...masterarr[parentIndex] };
        const master = { ...masterarr[nextParentIndex] };

        const elderChild = parentMaster[payments_data];
        const child = master[payments_data];
        if (IsArrayNotEmpty(elderChild) && IsArrayNotEmpty(child)) {
          const tarr = child.reduce((acc, li) => {
            const temp = { ...li };
            const dataFound = elderChild.find((it) => it.key == li.key);
            if (dataFound) {
              temp["isDeferredPeriod"] = dataFound["isDeferredPeriod"];
              temp[deferrelPercentName] = dataFound[deferrelPercentName];
              temp[deferrelPeriodName] = dataFound[deferrelPeriodName];
              temp[`${deferrelPercentName}Disabled`] =
                dataFound[`${deferrelPercentName}Disabled`];
              temp[`${deferrelPercentName}Error`] =
                dataFound[`${deferrelPercentName}Error`];
              temp[`${deferrelPeriodName}Error`] =
                dataFound[`${deferrelPeriodName}Error`];
              acc.push(temp);
            } else {
              acc.push(li);
            }
            return acc;
          }, []);
          if (IsArrayNotEmpty(tarr)) {
            master["isDeferred"] = parentMaster["isDeferred"];
            master[payments_data] = tarr;

            masterarr.splice(nextParentIndex, 1, { ...master });
            return masterarr;
          }
          return state;
        }
        return state;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function DeferredPaymentSkipUpdateLogic(state) {
    try {
      if (IsArrayNotEmpty(state)) {
        const master_arr = state.map((item) => {
          const { payments = [] } = item;
          const payments_arr = payments.map((list) => {
            const { deferrelPercent, deferrelPeriod } = list;
            if (deferrelPeriod == 0) {
              return {
                ...list,
                deferrelPeriod: 0,
                deferrelPercent: 0,
                isDeferredPeriod: false,
                deferrelPercentError: false,
                deferrelPercentDisabled: true,
                deferrelPeriodError: false,
              };
            } else {
              const access =
                IsNumber(
                  deferrelPeriod,
                  true,
                  true,
                  true,
                  ZeroLimit,
                  HundredLimit
                ) &&
                IsNumber(
                  deferrelPercent,
                  true,
                  true,
                  true,
                  ZeroLimit,
                  HundredLimit
                );
              if (access) {
                return {
                  ...list,
                  isDeferredPeriod: true,
                  deferrelPercentError: false,
                  deferrelPercentDisabled: false,
                  deferrelPeriodError: false,
                };
              } else {
                return {
                  ...list,
                  isDeferredPeriod: false,
                  deferrelPercent: 0,
                  deferrelPeriod: 0,
                  deferrelPercentError: false,
                  deferrelPercentDisabled: true,
                  deferrelPeriodError: false,
                };
              }
            }
          });
          return {
            ...item,
            payments: payments_arr,
          };
        });
        const value = master_arr[0]?.interestRate;
        const new_master_arr = master_arr.map((list) => {
          const { payments = [] } = list;
          const isDeferred = payments.some((li) => li?.isDeferredPeriod);
          return {
            ...list,
            isDeferred,
            interestRate: IsNumber(
              value,
              true,
              true,
              true,
              ZeroLimit,
              HundredLimit
            )
              ? value
              : "0",
            interestRateError: false,
          };
        });
        return new_master_arr;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // ARE Section
  function MasterAREPopulate(state, payload) {
    try {
      if (IsArrayNotEmpty(state)) {
        const { parent, data_list } = payload;
        const master_arr = state.map((item) => {
          const { key, are_data } = item;
          if (key === parent) {
            const new_item = { ...item };
            const new_data = { ...are_data };
            if (IsArrayNotEmpty(data_list)) {
              for (const element of data_list) {
                const { name, value, error } = element;
                new_data[name] = value;
                new_data[`${name}_error`] = error;
              }
            }
            new_item["are_data"] = new_data;
            return new_item;
          } else {
            return item;
          }
        });
        return master_arr;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // Stock Retain Section
  function MasterStockRetainPopulate(state, payload) {
    try {
      if (IsArrayNotEmpty(state)) {
        const { parent, data_list } = payload;
        const master_arr = state.map((item) => {
          const { key, stock_retain_data } = item;
          if (key === parent) {
            const new_item = { ...item };
            const new_data = { ...stock_retain_data };
            if (IsArrayNotEmpty(data_list)) {
              for (const element of data_list) {
                const { name, value, error } = element;
                new_data[name] = value;
                new_data[`${name}_error`] = error;
              }
            }
            new_item["stock_retain_data"] = new_data;
            return new_item;
          } else {
            return item;
          }
        });
        return master_arr;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // Multi Edit ARE & Stock Retain Section
  function MasterAREStockRetainPopulate(state, payload) {
    try {
      const { master_data_list = [] } = payload;
      const master_arr = [...state];
      if (IsArrayNotEmpty(master_arr) && IsArrayNotEmpty(master_data_list)) {
        for (const list of master_data_list) {
          const { parent, data_list } = list;
          if (IsArrayNotEmpty(data_list)) {
            const index = master_arr.findIndex((li) => li.key == parent);
            if (index >= 0) {
              const item = master_arr[index];
              const { stock_retain_data, are_data, common_data } = item;
              const new_item = { ...item };
              const new_stock_data = { ...stock_retain_data };
              const new_are_data = { ...are_data };
              const new_common_data = { ...common_data };
              for (const element of data_list) {
                const { name, value, error, field } = element;
                if (field === "are_data") {
                  new_are_data[name] = value;
                  new_are_data[`${name}_error`] = error;
                } else if (field === "stock_retain_data") {
                  new_stock_data[name] = value;
                  new_stock_data[`${name}_error`] = error;
                } else {
                  new_common_data[name] = value;
                  // new_common_data[`${name}_error`] = error;
                }
              }
              new_item["stock_retain_data"] = new_stock_data;
              new_item["are_data"] = new_are_data;
              new_item["common_data"] = new_common_data;

              master_arr.splice(index, 1, new_item);
            }
          }
        }
        return master_arr;
      }
      return master_arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  // Estimate Name
  function ChangeEstimateName(e) {
    EventMGMT(e);
    const { value = "" } = e?.target || {};
    // const contract_found = getContractInfo(contract?.contract, contractOptions);
    // const { name } = contract_found;
    setEstimateName(IsStringChecker(value) ? value : "");
    setEstimateNameError(IsStringChecker(value) ? "" : estimate_name_err_msg);
  }

  // Calculate Estimate
  function ErrorDisplayOnCalculate(save) {
    setEstimateNameError(
      save ? (IsStringChecker(estimateName) ? "" : estimate_name_err_msg) : ""
    );
    if (IsArrayNotEmpty(inputComponentArr)) {
      DispatchAdditionalYear({
        type: "validate",
      });
      dispatchInputComponentMapping({
        type: "validate",
      });
    }
  }

  function barrelsValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        return inputComponentArr.every((item) => {
          const { barrels } = item || {};
          const num = barrels.replace(/,/g, "");
          return IsNumber(num, true, true, true, ZeroLimit, NineMillionLimit);
        });
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function paymentsValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        let additional_access = false;
        const { payments_pending, payments = [] } = additional_year || {};
        if (payments_pending) {
          const check_list = (payments || []).filter((li) => {
            const { rateDisabled = true } = li || {};
            return !rateDisabled;
          });
          if (IsArrayNotEmpty(check_list)) {
            additional_access = check_list.every((list) => {
              const { rate } = list || {};
              return IsNumber(rate, true, true, true, ZeroLimit, TwentyLimit)
                ? true
                : false;
            });
          } else {
            additional_access = true;
          }
        } else {
          additional_access = true;
        }
        const main_access = inputComponentArr.every((item) => {
          const { payments } = item || {};
          return payments.every((list) => {
            const { rate } = list || {};
            const payment_access = IsNumber(
              rate,
              true,
              true,
              true,
              ZeroLimit,
              TwentyLimit
            )
              ? true
              : false;
            return payment_access;
          });
        });
        return additional_access * main_access;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function incentivesValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        let additional_access = false;
        const {
          payments_pending,
          payments = [],
          incentiveRateOrDollars,
        } = additional_year || {};
        if (payments_pending) {
          const check_list = payments.filter((li) => {
            const {
              incentiveDollarsDisabled = true,
              incentiveRateDisabled = true,
            } = li || {};
            if (incentiveRateOrDollars) {
              return !incentiveRateDisabled;
            } else {
              return !incentiveDollarsDisabled;
            }
          });
          if (IsArrayNotEmpty(check_list)) {
            additional_access = check_list.every((list) => {
              const { incentiveDollars, incentiveRate } = list || {};
              return incentiveRateOrDollars
                ? IsNumber(
                    incentiveRate,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  )
                  ? true
                  : false
                : IsNumber(
                    incentiveDollars,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    OneMillionLimit
                  )
                ? true
                : false;
            });
          } else {
            additional_access = true;
          }
        } else {
          additional_access = true;
        }
        const main_access = inputComponentArr.every((item) => {
          const { payments, incentiveRateOrDollars } = item || {};
          return payments.every((list) => {
            const { incentiveDollars, incentiveRate } = list || {};
            const incentive_access = incentiveRateOrDollars
              ? IsNumber(
                  incentiveRate,
                  true,
                  true,
                  true,
                  ZeroLimit,
                  HundredLimit
                )
                ? true
                : false
              : IsNumber(
                  incentiveDollars,
                  true,
                  true,
                  true,
                  ZeroLimit,
                  OneMillionLimit
                )
              ? true
              : false;
            return incentive_access;
          });
        });
        return additional_access * main_access;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function deferralValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        let additional_access = false;
        let main_access = false;
        const {
          payments_pending,
          payments = [],
          interestRate,
          isDeferred,
        } = additional_year || {};
        if (payments_pending) {
          const interest_rate_access = IsNumber(
            interestRate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          if (interest_rate_access) {
            if (isDeferred) {
              const check_list = payments.filter((li) => {
                const { isDeferredPeriod } = li || {};
                return isDeferredPeriod;
              });
              if (IsArrayNotEmpty(check_list)) {
                additional_access = check_list.every((list) => {
                  const { deferrelPercent, deferrelPeriod } = list || {};
                  const num1 = IsNumber(
                    deferrelPercent,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  const num2 = IsNumber(
                    deferrelPeriod,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  return num1 && num2;
                });
              } else {
                additional_access = true;
              }
            } else {
              additional_access = true;
            }
          } else {
            additional_access = false;
          }
        } else {
          additional_access = true;
        }
        const insterest_rate_access = inputComponentArr.every((item) => {
          const { interestRate } = item || {};
          return IsNumber(
            interestRate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
        });
        if (insterest_rate_access) {
          const masterarr = [];
          inputComponentArr.forEach((item) => {
            const { isDeferred, payments } = item || {};
            payments.forEach((list) => {
              const { isDeferredPeriod, deferrelPercent, deferrelPeriod } =
                list || {};
              if (isDeferred) {
                if (isDeferredPeriod) {
                  masterarr.push({ deferrelPercent, deferrelPeriod });
                }
              }
            });
          });
          if (IsArrayNotEmpty(masterarr)) {
            main_access = masterarr.every((item) => {
              const { deferrelPercent, deferrelPeriod } = item || {};
              const num1 = IsNumber(
                deferrelPercent,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              );
              const num2 = IsNumber(
                deferrelPeriod,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              );
              return num1 && num2;
            });
          }
          main_access = true;
        } else {
          main_access = false;
        }
        return additional_access * main_access;
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function areValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        var final_arr = [];
        if(additional_year?.payments_pending){
          final_arr = [additional_year, ...inputComponentArr];
        } else {
          final_arr = [...inputComponentArr];
        }
        return final_arr.every((item) => {
          const { are_data } = item || {};
          const { are_rate, are_issuance, are_redemption } = are_data || {};
          const are_rate_access = IsNumber(
            are_rate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          const are_issuance_access = IsNumber(
            are_issuance,
            true,
            true,
            true,
            OneLimit,
            FiveMillionLimit
          );
          const are_redemption_access = IsNumber(
            are_redemption,
            true,
            true,
            true,
            OneLimit,
            FiveMillionLimit
          );
          return IsArrayElementsTrue(
            are_rate_access, are_issuance_access, are_redemption_access
          );
        });
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function stockRetainValidation() {
    try {
      if (IsArrayNotEmpty(inputComponentArr) && inputComponentArr.length <= 5) {
        var final_arr = [];
        if(additional_year?.payments_pending){
          final_arr = [additional_year, ...inputComponentArr];
        } else {
          final_arr = [...inputComponentArr];
        }
        return final_arr.every((item) => {
          const { stock_retain_data } = item || {};
          const {
            average_deliveries,
            quota_rate,
            par_value,
            quota_shares,
            common_stock_shares_held,
            percent_to_quota,
            retain_rate,
            retain_dollars,
            potential_retain_shares,
            shares_under_quota,
            shares_under_quota_value,
            retain_shares_to_be_issued,
            retain_shares_to_be_issued_value,
            cash_in_lieu_of_stock,
          } = stock_retain_data;
          const average_deliveries_access = IsNumber(
            average_deliveries,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const quota_rate_access = IsNumber(
            quota_rate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          const par_value_access = IsNumber(
            par_value,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          const quota_shares_access = IsNumber(
            quota_shares,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const common_stock_shares_held_access = IsNumber(
            common_stock_shares_held,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const percent_to_quota_access = IsNumber(
            percent_to_quota,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          const retain_rate_access = IsNumber(
            retain_rate,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          );
          const retain_dollars_access = IsNumber(
            retain_dollars,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const potential_retain_shares_access = IsNumber(
            potential_retain_shares,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const shares_under_quota_access = IsNumber(
            shares_under_quota,
            true,
            true,
            true,
            ZeroLimit,
            OneMillionLimit
          );
          const shares_under_quota_value_access = IsNumber(
            shares_under_quota_value,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const retain_shares_to_be_issued_access = IsNumber(
            retain_shares_to_be_issued,
            true,
            true,
            true,
            ZeroLimit,
            OneMillionLimit
          );
          const retain_shares_to_be_issued_value_access = IsNumber(
            retain_shares_to_be_issued_value,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          const cash_in_lieu_of_stock_access = IsNumber(
            cash_in_lieu_of_stock,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          );
          return IsArrayElementsTrue(
            average_deliveries_access,
            quota_rate_access,
            par_value_access,
            quota_shares_access,
            common_stock_shares_held_access,
            percent_to_quota_access,
            retain_rate_access,
            retain_dollars_access,
            potential_retain_shares_access,
            shares_under_quota_access,
            shares_under_quota_value_access,
            retain_shares_to_be_issued_access,
            retain_shares_to_be_issued_value_access,
            cash_in_lieu_of_stock_access
          );
        });
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function CalculateEstimate(e, save = false, calculate = false) {
    EventMGMT(e);
    if (calculate || save) {
      ErrorDisplayOnCalculate(save);
      const contract_id = contract?.contract;
      if (IsStringChecker(contract_id)) {
        const barrels_access = barrelsValidation();
        if (barrels_access) {
          const payments_access = paymentsValidation();
          if (payments_access) {
            const incentive_access = incentivesValidation();
            if (incentive_access) {
              const deferred_payment_access = deferralValidation();
              if (deferred_payment_access) {
                const are_access = areValidation();
                if (are_access) {
                  const stock_retain_access = stockRetainValidation();
                  if (stock_retain_access) {
                    if (
                      save &&
                      IsStringChecker(estimateName) &&
                      IsStringChecker(estimateLabel)
                    ) {
                      const payment_mappings = inputComponentArr.map((li) =>
                        InpuctComponentFieldsFormatter(li)
                      );
                      const payload = {
                        user_id: localAccountId,
                        contract_id,
                        estimate_name: estimateName,
                        estimate_label: estimateLabel,
                        payment_mappings,
                        additional_year:
                          InpuctComponentFieldsFormatter(additional_year),
                      };
                      PopulateEstimate(payload);
                    }
                    if (calculate) {
                      setCalculateEstimate((prev) => !prev);
                      setLoadBtnClicked(false);
                      setEstimateRawInput({
                        contract,
                        estimateName,
                        estimateLabel,
                        payment_mappings: inputComponentArr,
                        additional_year,
                      });
                    } else {
                      console.error("Unknown Error");
                    }
                  } else {
                    if (save) {
                      AlertBar(
                        {
                          trigger: true,
                          variant: "danger",
                          message:
                            "Unable to save Estimate. Error with Stock Retain details Section.",
                        },
                        true
                      );
                    }
                    console.error("Error found: Stock Retain section");
                  }
                } else {
                  if (save) {
                    AlertBar(
                      {
                        trigger: true,
                        variant: "danger",
                        message:
                          "Unable to save Estimate. Error with ARE Redemption & ARE Issuance details Section.",
                      },
                      true
                    );
                  }
                  console.error("Error found: ARE section");
                }
              } else {
                if (save) {
                  AlertBar(
                    {
                      trigger: true,
                      variant: "danger",
                      message:
                        "Unable to save Estimate. Error with Deferred Section.",
                    },
                    true
                  );
                }
                console.error("Error found: Deferred Payments section");
              }
            } else {
              if (save) {
                AlertBar(
                  {
                    trigger: true,
                    variant: "danger",
                    message:
                      "Unable to save Estimate. Error with Incentive Section.",
                  },
                  true
                );
              }
              console.error("Error found: Incentives section");
            }
          } else {
            if (save) {
              AlertBar(
                {
                  trigger: true,
                  variant: "danger",
                  message:
                    "Unable to save Estimate. Error with Payment Section.",
                },
                true
              );
            }
            console.error("Error found: Quarterly Payments section");
          }
        } else {
          if (save) {
            AlertBar(
              {
                trigger: true,
                variant: "danger",
                message:
                  "Unable to save Estimate. Error with Deliveries Section.",
              },
              true
            );
          }
          console.error("Error found: Deliveries section");
        }
      } else {
        if (save) {
          AlertBar(
            {
              trigger: true,
              variant: "danger",
              message: "Unable to save Estimate. Error with Contract Section.",
            },
            true
          );
        }
        console.error("Error found: Contracts section");
      }
    }
  }

  function GlobalClearAllButton(e) {
    try {
      EventMGMT(e);
      SectionHandler(false);
      dispatchInputComponentMapping({ type: "replace", payload: [] });
      DispatchAdditionalYear({ type: "replace", payload: {} });
      setEstimateName("");
      setEstimateNameError("");
      setCPCropyear(default_cp_cropyear);
      setCPBarrels(default_cp_barrels);
      setEstimate({});
      setLoadEstimate({});
      EstimateRefresh();
      setContract({});
      setContractDefault([]);
      ContractRefresh();
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  // Section Based Approach
  function SectionHandler(action, section) {
    switch (section) {
      case "quarterly_payments":
        setEnableQuarterlyPaymentsSection(action);
        break;
      case "incentives":
        setEnableIncentivesSection(action);
        break;
      case "deferrel":
        setEnableDeferredPaymentsSection(action);
        break;
      case "are_stock":
        setEnableAREStock(action);
        break;
      case "calculate":
        setEnableCalculateSection(action);
        break;
      case "calculate_reverse":
        setEnableCalculateSection(action);
        break;
      case "are_stock_reverse":
        setEnableAREStock(action);
        setEnableCalculateSection(action);
        break;
      case "deferrel_reverse":
        setEnableDeferredPaymentsSection(action);
        setEnableAREStock(action);
        setEnableCalculateSection(action);
        break;
      case "incentives_reverse":
        setEnableIncentivesSection(action);
        setEnableDeferredPaymentsSection(action);
        setEnableAREStock(action);
        setEnableCalculateSection(action);
        break;
      default:
        setEnableQuarterlyPaymentsSection(action);
        setEnableIncentivesSection(action);
        setEnableDeferredPaymentsSection(action);
        setEnableAREStock(action);
        setEnableCalculateSection(action);
        break;
    }
  }

  function EnableQuarterlyPaymentsSection(action) {
    if (compareString(action, "edit")) {
      SectionHandler(false);
    } else {
      if (IsArrayNotEmpty(inputComponentArr)) {
        const barrels_access = barrelsValidation();
        if (barrels_access) {
          HandleAREStockRetainSectionData();
          SectionHandler(true, "quarterly_payments");
        } else {
          SectionHandler(false);
          dispatchInputComponentMapping({
            type: "barrelsValidate",
          });
        }
      } else {
        SectionHandler(false);
      }
    }
  }

  function EnableIncentivesSection(action) {
    if (compareString(action, "edit")) {
      SectionHandler(false, "incentives_reverse");
    } else {
      if (IsArrayNotEmpty(inputComponentArr)) {
        const payments_access = paymentsValidation();
        if (payments_access) {
          SectionHandler(true, "incentives");
        } else {
          SectionHandler(false, "incentives_reverse");
          dispatchInputComponentMapping({
            type: "paymentsValidate",
          });
          DispatchAdditionalYear({
            type: "rateValidate",
          });
        }
      } else {
        SectionHandler(false, "incentives_reverse");
      }
    }
  }

  function EnableDeferredPaymentsSection(action) {
    if (compareString(action, "edit")) {
      SectionHandler(false, "deferrel_reverse");
    } else {
      if (IsArrayNotEmpty(inputComponentArr)) {
        const incentives_access = incentivesValidation();
        if (incentives_access) {
          SectionHandler(true, "deferrel");
        } else {
          SectionHandler(false, "deferrel_reverse");
          dispatchInputComponentMapping({
            type: "incentivesValidate",
          });
          DispatchAdditionalYear({
            type: "incentivesValidate",
          });
        }
      } else {
        SectionHandler(false, "deferrel_reverse");
      }
    }
  }

  function EnableCalculateSection(action) {
    if (compareString(action, "edit")) {
      SectionHandler(false, "are_stock_reverse");
    } else {
      if (IsArrayNotEmpty(inputComponentArr)) {
        const deferrel_access = deferralValidation();
        if (deferrel_access) {
          SectionHandler(true, "are_stock");
        } else {
          SectionHandler(false, "are_stock_reverse");
          dispatchInputComponentMapping({
            type: "deferrelsValidate",
          });
          DispatchAdditionalYear({
            type: "deferrelsValidate",
          });
        }
      } else {
        SectionHandler(false, "are_stock_reverse");
      }
    }
  }

  function SkipDefermentsSection() {
    if (IsArrayNotEmpty(inputComponentArr)) {
      SectionHandler(true, "are_stock");
      dispatchInputComponentMapping({
        type: "deferralSkip",
      });
    } else {
      SectionHandler(false, "are_stock_reverse");
    }
  }

  //Accordion and API Request
  async function fetchEstimate() {
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `payment_estimates?select=*&is_active=eq.true&user_id=eq.${localAccountId}`
    )
      .then((resp) => {
        const data = safeArrayCheck(_.get(resp, `data.data`, []));
        if (IsArrayNotEmpty(data)) {
          setEstimateData(data);
          const ordered = _.orderBy(data, ["created_at"], ["desc"]);
          const options = EstimateOptionsCreator(ordered);
          setEstimateOptions(IsArrayNotEmpty(options) ? options : []);
        } else {
          setEstimateData([]);
          setEstimateOptions([]);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setEstimateData([]);
        setEstimateOptions([]);
      });
  }

  async function fetchContract() {
    setLoading(true);
    setContractDisabled(true);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/${personId}/contracts`
      // `persons/${personId}/contracts?r.ContractStatus=1000,1001`
    )
      .then((resp) => {
        const data = safeArrayCheck(_.get(resp, `data`, []));
        const filteredData = FilterContractByRole(
          data,
          accounts,
          DropDownCBRoles
        );
        const original_options = IsArrayNotEmpty(filteredData)
          ? filteredData
              .map((li) => ContractOptionCreator(li))
              .filter((li) => li)
          : [];
        const coptions = original_options.filter(
          (li) => li.status == 1000 || li.status == 1001
        );
        setContractOptions(coptions);
        setOriginalContractOptions(original_options);
        if (coptions.length === 1) {
          const { id, region, name } = coptions[0];
          setContract({ contract: id });
          setContractDefault([id]);
          ContractRefresh();
          ContractBasedMonthOrdering(
            region,
            initialPaymentsArr,
            dispatchPaymentsMapping
          );
          setEstimateLabel(`${name} - ${today}`);
          fetchCropYear(id);
        } else {
          setContract({});
          setContractDefault([]);
          ContractRefresh();
        }
      })
      .catch((error) => {
        setContract({});
        setContractDefault([]);
        ContractRefresh();
        setContractOptions([]);
        setOriginalContractOptions([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setContractDisabled(false);
        setLoading(false);
      });
  }

  async function fetchCropYear(contract = "", notFromLoad = true) {
    setLoading(true);
    await gbsDataCommonMethod(idToken, accounts, `crop-years`)
      .then(async (resp) => {
        const data = safeArrayCheck(_.get(resp, `data`, []));
        if (IsArrayNotEmpty(data)) {
          const years = [];
          const currentCrop = data.find((x) => x.CurrentCrop === true);
          const getcurrentcrop = _.get(currentCrop, `CropYear`, null);
          const current_crop_year = getcurrentcrop || getCurrentYear();
          const prev_crop_year = NumberToNormal(current_crop_year, false, 1);
          const final_prev_crop_year = NumberToNormal(
            current_crop_year,
            false,
            2
          );
          const cropyears = ToConcatenatedString(
            final_prev_crop_year,
            prev_crop_year,
            current_crop_year
          );
          years.push(final_prev_crop_year);
          years.push(prev_crop_year);
          for (let i = 0; i < 5; i++) {
            years.push(current_crop_year + i);
          }
          const allyears = years
            .map((temp) => {
              return temp ? temp - 9 : 0;
            })
            .filter((li) => li);
          setCPCropyear({
            ...default_cp_cropyear,
            value: current_crop_year,
            prev_value: prev_crop_year,
          });
          fetchBarrels(contract || null, current_crop_year);
          if (notFromLoad) {
            fetchPaymentRates(contract || null, prev_crop_year);
            fetchPaymentIncentives(contract || null, prev_crop_year);
            fetchDeferredPayments(contract || null, cropyears);
            fetchDefermentInterestRate();
            fetchARERedemption(contract || null, allyears);
            fetchCommonStockSharesHeld(
              contract || null,
              ToConcatenatedString(prev_crop_year, current_crop_year)
            );
            fetchPARValue(years);
          }
        } else {
          const current_crop_year = getCurrentYear();
          const prev_crop_year = NumberToNormal(current_crop_year, false, 1);
          setCPCropyear({
            ...default_cp_cropyear,
            value: current_crop_year,
            prev_value: prev_crop_year,
          });
          fetchBarrels(null, null);
          if (notFromLoad) {
            fetchPaymentRates(null, null);
            fetchPaymentIncentives(null, null);
            fetchDeferredPayments(null, null);
            fetchDefermentInterestRate();
            fetchARERedemption(null, null);
            fetchCommonStockSharesHeld(null, null);
            fetchPARValue(null);
          }
        }
      })
      .catch((error) => {
        const current_crop_year = getCurrentYear();
        const prev_crop_year = NumberToNormal(current_crop_year, false, 1);
        setCPCropyear({
          ...default_cp_cropyear,
          value: current_crop_year,
          prev_value: prev_crop_year,
        });
        fetchBarrels(null, null);
        if (notFromLoad) {
          fetchPaymentRates(null, null);
          fetchPaymentIncentives(null, null);
          fetchDeferredPayments(null, null);
          fetchDefermentInterestRate();
          fetchARERedemption(null, null);
          fetchCommonStockSharesHeld(null, null);
          fetchPARValue(null);
        }
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function fetchBarrels(contract = "", cropyear = getCurrentYear()) {
    if (contract && cropyear) {
      setBarrelsByYearLoad(true);
      const currentYearNum = parseInt(cropyear);
      const default_data = {
        cropyear_label: cropyearLabel,
        cropyear_name: cropyearName,
        barrels_label: barrelsLabel,
        barrels_name: barrelsName,
      };
      const cropyearArr = [];

      cropyearArr.push({
        key: 0,
        ...default_data,
        cropyear_value: currentYearNum,
        is_current: true,
        barrels_value: "0",
      });
      cropyearArr.push({
        key: 1,
        ...default_data,
        cropyear_value: currentYearNum - 1,
        is_current: false,
        barrels_value: "0",
      });
      cropyearArr.push({
        key: 2,
        ...default_data,
        cropyear_value: currentYearNum - 2,
        is_current: false,
        barrels_value: "0",
      });

      const cropyears = cropyearArr.map((li) => li.cropyear_value);
      const url = `deliveries/calculations/ytd-avg-weightings?cropyear=${cropyears}&contractid=${contract}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then(async (resp) => {
          const data = safeArrayCheck(_.get(resp, `data`, []));
          if (IsArrayNotEmpty(data)) {
            const barrelsData = cropyearArr.reduce((acc, list) => {
              const { cropyear_value } = list;
              const deldata = data.filter(
                (li) => li?.CropYear == cropyear_value
              );
              if (IsArrayNotEmpty(deldata)) {
                const cpBarrels = deldata.pop();
                const TotalBarrels = BarrelsNumberFormater(
                  `${cpBarrels?.TotalBarrels || 0}`
                );
                acc.push({ ...list, barrels_value: TotalBarrels || "0" });
              } else {
                acc.push({ ...list, barrels_value: "0" });
              }
              return acc;
            }, []);
            if (IsArrayNotEmpty(barrelsData)) {
              setBarrelsByYear(
                _.orderBy(barrelsData, "cropyear_value", "desc")
              );
              const barrel = barrelsData.find((li) => li?.is_current);
              const prev_barrel = barrelsData.find((li) => li?.key == 1);
              const barrel_value = barrel
                ? BarrelsNumberFormater(`${barrel?.barrels_value || 0}`)
                : "0";
              const prev_barrel_value = prev_barrel
                ? BarrelsNumberFormater(`${prev_barrel?.barrels_value || 0}`)
                : "0";
              setCPBarrels({
                ...default_cp_barrels,
                value: barrel_value,
                prev_value: prev_barrel_value,
              });
            } else {
              setBarrelsByYear([...cropyearArr]);
              setCPBarrels({
                ...default_cp_barrels,
                value: "0",
                prev_value: "0",
              });
            }
          } else {
            setBarrelsByYear([...cropyearArr]);
            setCPBarrels({
              ...default_cp_barrels,
              value: "0",
              prev_value: "0",
            });
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setBarrelsByYear([...cropyearArr]);
          setCPBarrels({
            ...default_cp_barrels,
            value: "0",
            prev_value: "0",
          });
        })
        .finally(() => {
          setBarrelsByYearLoad(false);
        });
    } else {
      setBarrelsByYear([]);
      setCPBarrels({
        ...default_cp_barrels,
        value: "0",
        prev_value: "0",
      });
      setBarrelsByYearLoad(false);
    }
  }

  async function fetchDeferredPayments(contract = "", cropyears = "") {
    if (contract && cropyears) {
      setDefermentsPreloadLoad(true);

      const url = `payments/deferments?relationshipid=${contract}&p.cropyear=${cropyears}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const formatted_deferments_arr =
              defaultDeferredPaymentsArrange(data);
            setDefermentsPreload(formatted_deferments_arr);
          } else {
            setDefermentsPreload([]);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setDefermentsPreload([]);
        })
        .finally(() => {
          setDefermentsPreloadLoad(false);
        });
    } else {
      setDefermentsPreload([]);
      setDefermentsPreloadLoad(false);
    }
  }

  async function fetchDefermentInterestRate() {
    setDefermentsRatePreloadLoad(true);
    const url = `payments/deferments/interest-rate`;
    await gbsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const data = resp?.data;
        if (IsArrayNotEmpty(data)) {
          const InterestRate =
            parseFloat(_.get(data, "0.InterestRate", "0")) * 100;
          const interest_rate = NumberFormaterWithValidation(
            `${InterestRate}`,
            WholeRateValidation,
            ZeroLimit,
            HundredLimit
          );
          setDefermentsRatePreload(`${interest_rate}`);
        } else {
          setDefermentsRatePreload("0");
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setDefermentsRatePreload("0");
      })
      .finally(() => {
        setDefermentsRatePreloadLoad(false);
      });
  }

  async function fetchPaymentRates(contract = "", cropyear = getCurrentYear()) {
    if (contract && cropyear) {
      setRatesPayloadLoad(true);
      const YearNum = parseInt(cropyear);
      const url = `pool-Rate-Detail?relationshipId=${contract}&cropYear=${YearNum}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const formatted_rates_arr = defaultPaymentRatesArrange(
              data,
              YearNum
            );

            setRatesPayload(formatted_rates_arr);
            PreloadAREAndStock(data, YearNum);
          } else {
            setRatesPayload([]);
            setAREIssuanceStockPayload(default_are_issuance_stock_data);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setRatesPayload([]);
          setAREIssuanceStockPayload(default_are_issuance_stock_data);
        })
        .finally(() => {
          setRatesPayloadLoad(false);
        });
    } else {
      setRatesPayload([]);
      setAREIssuanceStockPayload(default_are_issuance_stock_data);
      setRatesPayloadLoad(false);
    }
  }

  async function fetchPaymentIncentives(
    contract = "",
    cropyear = getCurrentYear()
  ) {
    if (contract && cropyear) {
      setIncentivePreloadLoad(true);
      const YearNum = parseInt(cropyear);
      const url = `deliveries/by-receipt?contractid=${contract}&cropyear=${YearNum}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const num = getIncetivesSectionDefaultArr(data);
            const incentive = NumberFormaterWithValidation(
              `${num}`,
              WholeRateValidation,
              ZeroLimit,
              OneMillionLimit
            );
            const incentive_error = IsNumber(
              incentive,
              true,
              true,
              true,
              ZeroLimit,
              OneMillionLimit
            )
              ? false
              : true;
            const incentives = incentiveArrGenerator(quarterly_months, {
              cropyear: YearNum,
              incentive,
              incentive_error,
            });
            setIncentivesPreload(incentives);
          } else {
            setIncentivesPreload([]);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setIncentivesPreload([]);
        })
        .finally(() => {
          setIncentivePreloadLoad(false);
        });
    } else {
      setIncentivesPreload([]);
      setIncentivePreloadLoad(false);
    }
  }

  async function fetchARERedemption(contract = "", cropyears = []) {
    if (contract && IsArrayNotEmpty(cropyears)) {
      setAREPreloadLoad(true);
      const url = `equity-balances/areRedemption?relationshipid=${contract}&cropyear=${cropyears}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const formatted_arr = data.map((li) => {
              const { CropYear, AreRedemption } = li;
              return {
                redemption_year: CropYear,
                year: CropYear + 9,
                are_redemption: `${AreRedemption}`,
              };
            });
            setARERedemptionPayload(formatted_arr);
          } else {
            setARERedemptionPayload([]);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setARERedemptionPayload([]);
        })
        .finally(() => {
          setAREPreloadLoad(false);
        });
    } else {
      setARERedemptionPayload([]);
      setAREPreloadLoad(false);
    }
  }

  async function fetchCommonStockSharesHeld(contract = "", cropyears = "") {
    if (IsStringChecker(cropyears) && contract) {
      setCommonStockSharesHeldPreload(true);
      const url = `equity-quotas?relationshipid=${contract}&cropyear=${cropyears}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const formatted_arr = data.map((li) => {
              const {
                CropYear,
                CommonStockShares,
                PercentToQuota,
                AverageDeliveries,
                EquityQuotaRate,
              } = li;
              return {
                year: CropYear,
                common_stock_shares_held: `${CommonStockShares}`,
                percent_to_quota: `${PercentToQuota}`,
                average_deliveries: `${AverageDeliveries}`,
                quota_rate: `${EquityQuotaRate}`,
              };
            });
            setCommonStockSharesHeldPayload(formatted_arr);
          } else {
            setCommonStockSharesHeldPayload([]);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setCommonStockSharesHeldPayload([]);
        })
        .finally(() => {
          setCommonStockSharesHeldPreload(false);
        });
    } else {
      setCommonStockSharesHeldPayload([]);
      setCommonStockSharesHeldPreload(false);
    }
  }

  async function fetchPARValue(years) {
    setPARValuePreload(true);
    const url = `equity-stockType`;
    await gbsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const data = resp?.data;
        if (IsArrayNotEmpty(data)) {
          const par_value = data
            .find((li) => {
              const { Description } = li;
              return compareString(Description, PAR_Value_Description);
            })
            
            const formatted_arr = years.map((it) => {
              return {
                year:it,
                par_value: par_value?.ParValue ? `${par_value.ParValue}` : "0",
              };
            });
          setParValuePayload(formatted_arr);
        } else {
          setParValuePayload([]);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setParValuePayload([]);
      })
      .finally(() => {
        setPARValuePreload(false);
      });
  }

  function PreloadAREAndStock(arr = [], year) {
    try {
      const are_stock_data = {
        ...default_are_issuance_stock_data,
      };
      are_stock_data["year"] = year;
      arr.forEach((list) => {
        const { Rate, Description } = list;
        if (compareString(Description, "ARE Issuance")) {
          are_stock_data["are_rate"] = Rate ? `${Rate}` : "0";
        } else if (compareString(Description, "Equity Quota Rate")) {
          are_stock_data["quota_rate"] = Rate ? `${Rate}` : "0";
        } else if (compareString(Description, "Stock Retain Rate")) {
          are_stock_data["retain_rate"] = Rate ? `${Rate}` : "0";
        }
      });
      setAREIssuanceStockPayload(are_stock_data);
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      setAREIssuanceStockPayload({ default_are_issuance_stock_data });
    }
  }

  async function PopulateEstimate(payload) {
    setMiniLoader(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `payment-estimator`,
      "POST",
      payload
    )
      .then((resp) => {
        if (resp?.data?.status == "success") {
          AlertBar(
            {
              trigger: true,
              variant: "success",
              message: "Estimate saved successfully.",
            },
            true
          );
          fetchEstimate();
          setEstimate({});
          EstimateRefresh();
        } else {
          AlertBar(
            {
              trigger: true,
              variant: "danger",
              message: "Unable to save Estimate. Please, try after sometime",
            },
            true
          );
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        AlertBar(
          {
            trigger: true,
            variant: "danger",
            message: `Unable to save Estimate. ${error?.message}`,
          },
          true
        );
      })
      .finally(() => {
        setMiniLoader(false);
      });
  }

  async function DeleteEstimate(payload) {
    setMiniLoad(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `payment-estimator`,
      "PUT",
      payload
    )
      .then((resp) => {
        if (resp?.data?.status == "success") {
          ReplaceToastStack(dispatch, {
            ...defaultToastMsgProperties,
            bg: "light",
            color: "black",
            message: "Estimate deleted successfully",
          });
          fetchEstimate();
          setLoadEstimate({});
          setEstimate({});
          EstimateRefresh();
        } else {
          ReplaceToastStack(dispatch, {
            ...defaultToastMsgProperties,
            message: "Error - Unable to Delete Estimate",
          });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setMiniLoad(false);
      });
  }

  useEffect(() => {
    if (IsTokenChecker(idToken) && personId && localAccountId) {
      fetchContract();
      fetchEstimate();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, personId, localAccountId]);

  const { trigger, variant, message } = saveAlert;
  const IncentiveTypeValue = IsArrayNotEmpty(inputComponentArr)
    ? _.get(inputComponentArr, "0.incentiveRateOrDollars", false)
    : false;
  const InterestRateValue = IsArrayNotEmpty(inputComponentArr)
    ? _.get(inputComponentArr, "0.interestRate", "")
    : "";
  const InterestRateError = IsArrayNotEmpty(inputComponentArr)
    ? _.get(inputComponentArr, "0.interestRateError", false)
    : false;
  const crop_barrel_len = inputComponentArr?.length || 0;
  const deny_delivery_access = CheckFetchesLoaded(
    rates_preload_load,
    incentive_preload_load,
    deferments_preload_load,
    barrelsByYearLoad,
    deferments_rate_preload_load
  );
  const are_stock_loading = CheckFetchesLoaded(
    are_preload_load,
    par_value_preload,
    common_stock_shares_held_preload
  );
  const MainSectionDisplay =
    contract?.contract && IsArrayNotEmpty(contractOptions);
  const enableEstimate = loadEstimate?.id;

  const {
    payments_pending = false,
    key: ay_key,
    cropyear: ay_cropyear,
    incentiveRateOrDollars: ay_incentiveRateOrDollars,
    payments: ay_payments = [],
  } = additional_year;
  const enable_additional_year_payments =
    payments_pending && IsArrayNotEmpty(ay_payments);
  // console.log({ additional_year, paymentsArr, inputComponentArr });
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="my-payments-and-equity" />
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug="payment-estimator" />
            </div>
          </div>
          <div className="payment-estimator-form col-12 col-lg-10">
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
              <h3 className="mob-fw-bold py-3">
                Payment Estimator
                <span className="drop-offinfo">(All fields are required)</span>
              </h3>
              <div className="row mb-2 px-0">
                <div className="estimate_multisetect custom_dropdown col-12 col-lg-8 mb-2 mb-lg-0">
                  {estimateRefresh && (
                    <MultiDropDown
                      optionList={estimateOptions}
                      prompt="Select Previous Estimate"
                      value={estimate}
                      label="estimate"
                      usestate={setEstimate}
                      onChange={EstimateChange}
                      onDelete={EstimateChange}
                      disabled={false}
                      optionNotFound={{ estimate: "No estimates saved" }}
                      preSelectedOpt={[]}
                      singleSelect={true}
                    />
                  )}
                </div>
                <div className="col-12 col-lg-4 d-flex justify-content-lg-between align-items-start">
                  <Button
                    variant="primary"
                    className="text-16 cursor-for-btn"
                    onClick={onLoadEstimate}
                    style={{
                      boxShadow: `0 0 5px #ccc`,
                    }}
                    disabled={!enableEstimate}
                  >
                    Load Estimate
                  </Button>
                  <Button
                    variant="light"
                    className="text-16 mx-2 cursor-for-btn"
                    onClick={onDeleteEstimate}
                    style={{
                      boxShadow: `0 0 5px #ccc`,
                    }}
                    disabled={impersonated ? impersonated : !enableEstimate}
                  >
                    Delete Estimate
                    {miniLoad ? <WhiteCircleLoader invert={true} /> : ""}
                  </Button>
                </div>
              </div>
              <div className="row mt-3 mb-2">
                <div className="col-12 col-lg-6">
                  <h4 className="fw-bold">Contract</h4>
                  <div className="estimate_multisetect custom_dropdown mb-0">
                    {contractRefresh && (
                      <MultiDropDown
                        optionList={contractOptions}
                        prompt="Select a Contract"
                        value={contract}
                        label="contract"
                        usestate={setContract}
                        onChange={(e, item) => ContractOptionChange(item)}
                        onDelete={ContractOptionDelete}
                        disabled={contractDisabled}
                        optionNotFound={{
                          contract: _.get(
                            moreLangs,
                            `${resolvedLanguage}.no_contracts_found`,
                            "No contracts found"
                          ),
                        }}
                        preSelectedOpt={contractDefault}
                        singleSelect={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              {MainSectionDisplay && (
                <Fragment>
                  <hr className="Payment-Estimator-Segment" />
                  <div className="row mb-2">
                    <div className="col">
                      <h4 className={`fw-bold`}>
                        Deliveries
                        <span className="drop-offinfo">
                          (Create delivery entries sequential from current
                          cropyear. Entry limit: 1 - 5)
                        </span>
                      </h4>
                    </div>
                  </div>
                  {deny_delivery_access ? (
                    <>
                      <div className="zindex10000">
                        <div className="nav-mobile-overlay-display"></div>
                        <Loading />
                      </div>
                      <div className="row">
                        <div className="col">
                          <h5 className={`text-center`}>
                            Deliveries section is Loading. Please wait...
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Fragment>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          {IsArrayNotEmpty(barrelsByYear) ? (
                            <Table
                              responsive
                              className="payment-estimator-grid"
                            >
                              <thead className="payest-grid-head">
                                <tr className="payest-grid-row">
                                  <th className="payest-grid-bold right-align">
                                    Crop Year
                                  </th>
                                  <th className="payest-grid-bold right-align">
                                    Barrels
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="payest-grid-body">
                                {barrelsByYear.map((list) => {
                                  const {
                                    cropyear_value,
                                    is_current,
                                    barrels_value: barrels,
                                    key,
                                  } = list;
                                  // const barrels = BarrelsNumberFormater(
                                  //   `${barrels_value}`,
                                  //   false,
                                  //   true
                                  // );
                                  return (
                                    <Fragment key={key}>
                                      <tr className="payest-grid-row">
                                        <td
                                          className={`payest-grid-data right-align col-6 ${
                                            is_current ? "fw-bold" : ""
                                          }`}
                                        >
                                          {is_current ? "(Current) " : ""}
                                          {cropyear_value}
                                        </td>
                                        <td
                                          className={`payest-grid-data right-align ${
                                            is_current ? "fw-bold" : ""
                                          }`}
                                        >
                                          {barrels}
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Alert
                            variant="primary"
                            style={{
                              textAlign: "justify",
                              fontStyle: "italic",
                            }}
                          >
                            <Alert.Heading>
                              <i className="fa fa-info"></i> Note:
                            </Alert.Heading>
                            <p>{cropyear_info_box}</p>
                          </Alert>
                        </div>
                      </div>
                      {IsArrayNotEmpty(inputComponentArr) ? (
                        <Fragment>
                          <h5>Created entries:</h5>
                          {inputComponentArr.map((item, index) => {
                            const unique = item?.key;
                            const qcvalue = item?.cropyear,
                              qcname = cropyearName,
                              qclabel = cropyearLabel,
                              qcreadonly = item?.cropyearReadonly;
                            const qblabel = barrelsLabel,
                              qbname = barrelsName,
                              qbvalue = item?.barrels,
                              qberror = item?.barrelsError;
                            const qbclose = IsStringChecker(qbvalue)
                              ? true
                              : false;
                            return (
                              <div key={unique} className="row mb-lg-2">
                                <Form.Text>Entry {unique + 1}</Form.Text>
                                <div className="col-12 mb-2 mb-lg-0 col-lg-6">
                                  <InputGroup>
                                    <InputGroup.Text>{qclabel}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      readOnly={qcreadonly}
                                      name={qcname}
                                      value={qcvalue}
                                      placeholder={"Enter a Crop Year"}
                                      required
                                      maxLength={4}
                                      minLength={4}
                                      className="cursor-not-allowed white_bg"
                                    />
                                  </InputGroup>
                                </div>
                                <div className="col-12 mb-2 mb-lg-0 col-lg-6 pe-barrelsInput payment-select-input">
                                  <InputGroup>
                                    <InputGroup.Text>{qblabel}</InputGroup.Text>
                                    <Form.Control
                                      style={{ background: "transparent" }}
                                      type="text"
                                      name={qbname}
                                      value={qbvalue}
                                      onChange={(e) => BarrelsChange(e, unique)}
                                      onKeyDown={KeyDownHandler}
                                      placeholder={"Enter no of Barrels"}
                                      isInvalid={qberror}
                                      required
                                      readOnly={
                                        enable_quarterly_payments_section
                                      }
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                  {qbclose && (
                                    <CloseButton
                                      className="pe-closeBtn"
                                      onClick={(e) =>
                                        BarrelsClear(e, qbname, unique)
                                      }
                                      disabled={
                                        enable_quarterly_payments_section
                                      }
                                    />
                                  )}
                                  {qberror && (
                                    <div className="row">
                                      <Form.Text
                                        className="col"
                                        style={{ color: "red" }}
                                      >
                                        {barrels_err_msg}
                                      </Form.Text>
                                    </div>
                                  )}
                                </div>
                                <hr
                                  className={`Payment-Estimator-Segment mt-2 d-lg-none ${
                                    index + 1 == crop_barrel_len ? "d-none" : ""
                                  }`}
                                />
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <div className="row mb-3">
                          <div className="col">
                            <h5 className={`mb-0 text-center red_color`}>
                              * Deliveries section inputs is required to
                              calculate.
                            </h5>
                          </div>
                        </div>
                      )}
                      {enable_quarterly_payments_section !== true && (
                        <div className="row mb-2">
                          <div className="col">
                            <Button
                              variant="primary"
                              className="text-16"
                              onClick={AddCropyearBarrelsEntry}
                              style={{
                                boxShadow: `0 0 5px #ccc`,
                              }}
                              disabled={crop_barrel_len >= maxEntry}
                            >
                              Add Entry
                            </Button>
                            <Button
                              variant="dark"
                              className="text-16 mx-2"
                              onClick={DeleteOneCropyearBarrelsEntry}
                              style={{
                                boxShadow: `0 0 5px #ccc`,
                              }}
                              disabled={crop_barrel_len < minEntry}
                            >
                              Delete Last Entry
                            </Button>
                            <Button
                              variant="light"
                              className="text-16 mt-2 mt-lg-0 mx-lg-2"
                              onClick={DeleteAllCropyearBarrelsEntries}
                              style={{
                                boxShadow: `0 0 5px #ccc`,
                              }}
                              disabled={crop_barrel_len <= minEntry - 1}
                            >
                              Clear Entries
                            </Button>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}
                  <div className="row mt-3 mb-2">
                    <div className="col">
                      <Button
                        variant="primary"
                        className="text-16"
                        onClick={() =>
                          EnableQuarterlyPaymentsSection(
                            enable_quarterly_payments_section ? "edit" : "enter"
                          )
                        }
                        style={{
                          boxShadow: `0 0 5px #ccc`,
                        }}
                      >
                        {`${
                          enable_quarterly_payments_section
                            ? "Edit This"
                            : "Proceed To Next"
                        } Section`}
                      </Button>
                    </div>
                  </div>
                  {enable_quarterly_payments_section ? (
                    <Fragment>
                      <hr className="Payment-Estimator-Segment" />
                      <div className="row mb-2">
                        <div className="col">
                          <h4 className={`fw-bold`}>Quarterly Payment Rates</h4>
                        </div>
                      </div>
                      <div className="row mb-2 payment-select-input">
                        {enable_additional_year_payments ? (
                          ay_payments.map((item, childIndex) => {
                            const unique = item?.key;
                            const qmvalue = item?.month;
                            const qrname = rateName,
                              qrvalue = item?.rate,
                              qrerror = item?.rateError,
                              qrdisable = item?.rateDisabled;
                            const readOnly =
                              qrdisable || enable_incentives_section;
                            return (
                              <Fragment key={unique}>
                                {childIndex === 0 && (
                                  <div
                                    className={`col-md-2 my-0 mx-0 px-0 px-md-1`}
                                  >
                                    <div className="d-md-none d-block">
                                      <div className="row my-1">
                                        <div className="col-6 px-1">
                                          <InputGroup.Text>
                                            {cropyearLabel}
                                          </InputGroup.Text>
                                        </div>
                                        <div className="col-6 px-1">
                                          <InputGroup.Text>
                                            {ay_cropyear}
                                          </InputGroup.Text>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-md-block d-none">
                                      <InputGroup.Text className="my-1">
                                        {cropyearLabel}
                                      </InputGroup.Text>
                                      <InputGroup.Text className="my-1">
                                        {ay_cropyear}
                                      </InputGroup.Text>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={`col-md-2 my-0 mx-0 px-0 px-md-1`}
                                >
                                  <div className="d-md-none d-block">
                                    <div className="row my-1">
                                      <div className="col-6 px-1">
                                        <InputGroup.Text>
                                          {qmvalue}
                                        </InputGroup.Text>
                                      </div>
                                      <div className="col-6 px-1">
                                        <Form.Control
                                          className=""
                                          style={
                                            qrdisable
                                              ? {}
                                              : {
                                                  background: "transparent",
                                                }
                                          }
                                          type="text"
                                          name={qrname}
                                          value={qrvalue}
                                          onChange={(e) =>
                                            AyPaymentsChangeTrigger(e, unique)
                                          }
                                          autoComplete="off"
                                          placeholder={"Enter a Rate"}
                                          onKeyDown={KeyDownHandler}
                                          isInvalid={!IsStringChecker(qrvalue)}
                                          required
                                          readOnly={readOnly}
                                        />
                                        {qrerror && (
                                          <Form.Text style={{ color: "red" }}>
                                            {payment_rate_err_msg}
                                          </Form.Text>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-md-block d-none">
                                    <InputGroup.Text className="my-1">
                                      {qmvalue}
                                    </InputGroup.Text>
                                    <Form.Control
                                      className="my-1"
                                      style={
                                        qrdisable
                                          ? {}
                                          : {
                                              background: "transparent",
                                            }
                                      }
                                      type="text"
                                      name={qrname}
                                      value={qrvalue}
                                      onChange={(e) =>
                                        AyPaymentsChangeTrigger(e, unique)
                                      }
                                      autoComplete="off"
                                      placeholder={"Enter a Rate"}
                                      onKeyDown={KeyDownHandler}
                                      isInvalid={!IsStringChecker(qrvalue)}
                                      required
                                      readOnly={readOnly}
                                    />
                                    {qrerror && (
                                      <Form.Text style={{ color: "red" }}>
                                        {payment_rate_err_msg}
                                      </Form.Text>
                                    )}
                                  </div>
                                </div>
                              </Fragment>
                            );
                          })
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                      <div className="row mb-2">
                        {inputComponentArr.map((list, parentIndex) => {
                          const parent = list?.key;
                          const pcvalue = list?.cropyear;
                          const pclabel = cropyearLabel;
                          const payments = list?.payments;
                          if (IsArrayNotEmpty(payments)) {
                            return (
                              <Fragment key={parent}>
                                {parentIndex === 0 && (
                                  <div
                                    className={`col-6 col-md-2 my-3 mx-0 px-1`}
                                  >
                                    {payments.map((item, childIndex) => {
                                      const unique = item?.key;
                                      const qmvalue = item?.month;
                                      return (
                                        <Fragment key={unique}>
                                          {childIndex === 0 && (
                                            <InputGroup.Text className="my-1">
                                              {pclabel}
                                            </InputGroup.Text>
                                          )}
                                          <InputGroup.Text className="my-1">
                                            {qmvalue}
                                          </InputGroup.Text>
                                        </Fragment>
                                      );
                                    })}
                                  </div>
                                )}
                                <div
                                  className={`col-6 col-md-2 my-3 mx-0 px-1 payment-select-input`}
                                >
                                  {payments.map((item, childIndex) => {
                                    const unique = item?.key;
                                    const qrname = rateName,
                                      qrvalue = item?.rate,
                                      qrerror = item?.rateError;
                                    const copyBtn =
                                      inputComponentArr.length > 1 &&
                                      childIndex === payments.length - 1 &&
                                      parentIndex === 0;
                                    return (
                                      <Fragment key={unique}>
                                        {childIndex === 0 && (
                                          <InputGroup.Text className="my-1">
                                            {pcvalue}
                                          </InputGroup.Text>
                                        )}
                                        <Form.Control
                                          className="my-1"
                                          style={{
                                            background: "transparent",
                                          }}
                                          type="text"
                                          name={qrname}
                                          value={qrvalue}
                                          onChange={(e) =>
                                            PaymentsChangeTrigger(
                                              e,
                                              parent,
                                              unique
                                            )
                                          }
                                          autoComplete="off"
                                          placeholder={"Enter a Rate"}
                                          onKeyDown={KeyDownHandler}
                                          isInvalid={!IsStringChecker(qrvalue)}
                                          required
                                          readOnly={enable_incentives_section}
                                        />
                                        {qrerror && (
                                          <Form.Text style={{ color: "red" }}>
                                            {payment_rate_err_msg}
                                          </Form.Text>
                                        )}
                                        {copyBtn && (
                                          <Button
                                            variant="primary"
                                            className={`text-16 my-1 w-100`}
                                            onClick={() =>
                                              PaymentsChangeToAllTrigger(
                                                parent,
                                                qrname
                                              )
                                            }
                                            style={{
                                              boxShadow: `0 0 5px #ccc`,
                                            }}
                                            disabled={enable_incentives_section}
                                          >
                                            Copy To All Year
                                          </Button>
                                        )}
                                      </Fragment>
                                    );
                                  })}
                                </div>
                              </Fragment>
                            );
                          } else {
                            return <Fragment key={parent}></Fragment>;
                          }
                        })}
                      </div>
                      <div className="row mb-2">
                        <div className="col">
                          <Button
                            variant="primary"
                            className="text-16"
                            onClick={() =>
                              EnableIncentivesSection(
                                enable_incentives_section ? "edit" : "enter"
                              )
                            }
                            style={{
                              boxShadow: `0 0 5px #ccc`,
                            }}
                          >
                            {`${
                              enable_incentives_section
                                ? "Edit This"
                                : "Proceed To Next"
                            } Section`}
                          </Button>
                        </div>
                      </div>
                      {enable_incentives_section ? (
                        <Fragment>
                          <hr className="Payment-Estimator-Segment" />
                          <div className="row mb-2">
                            <div className="col">
                              <h4 className={`fw-bold`}>
                                Incentives
                                <span className="drop-offinfo">
                                  (For incentive payment type you can choose
                                  either rate or dollars but not both)
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <InputGroup>
                                <InputGroup.Text className="PE-incentive-type">
                                  Select an Incentive Payment Type
                                </InputGroup.Text>
                                <Form.Select
                                  name={incentiveRateOrDollarsName}
                                  value={IncentiveTypeValue}
                                  onChange={IncentiveTypeChangeTrigger}
                                  placeholder={"Select Incentive Payment Type"}
                                  required
                                  className={
                                    enable_deferred_payments_section
                                      ? "cursor-not-allowed white_bg"
                                      : ""
                                  }
                                  disabled={enable_deferred_payments_section}
                                >
                                  <option value={true}>Rate</option>
                                  <option value={false}>Dollars</option>
                                </Form.Select>
                              </InputGroup>
                            </div>
                          </div>
                          <div className="row mt-3 payment-select-input">
                            {enable_additional_year_payments ? (
                              ay_payments.map((item, childIndex) => {
                                const unique = item?.key;
                                const qmvalue = item?.month;
                                const invname = ay_incentiveRateOrDollars
                                  ? incentiveDollarsName
                                  : incentiveRateName;
                                const qrname = ay_incentiveRateOrDollars
                                    ? incentiveRateName
                                    : incentiveDollarsName,
                                  qrvalue = ay_incentiveRateOrDollars
                                    ? item?.incentiveRate
                                    : item?.incentiveDollars,
                                  qrerror = ay_incentiveRateOrDollars
                                    ? item?.incentiveRateError
                                    : item?.incentiveDollarsError,
                                  qrdisable = ay_incentiveRateOrDollars
                                    ? item?.incentiveRateDisabled
                                    : item?.incentiveDollarsDisabled;
                                const readOnly =
                                  qrdisable || enable_deferred_payments_section;
                                return (
                                  <Fragment key={unique}>
                                    {childIndex === 0 && (
                                      <Fragment>
                                        <div
                                          className={`col-md-2 my-0 mx-0 px-0 px-md-1`}
                                        >
                                          <div className="d-md-none d-block">
                                            <div className="row my-1">
                                              <div className="col-6 px-1">
                                                <InputGroup.Text>
                                                  {cropyearLabel}
                                                </InputGroup.Text>
                                              </div>
                                              <div className="col-6 px-1">
                                                <InputGroup.Text>
                                                  {ay_cropyear}
                                                </InputGroup.Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-md-block d-none">
                                            <InputGroup.Text className="my-1 PE-incentive-type-grid-value">
                                              {cropyearLabel}
                                            </InputGroup.Text>
                                            <InputGroup.Text className="my-1">
                                              {ay_cropyear}
                                            </InputGroup.Text>
                                          </div>
                                        </div>
                                        <div
                                          className={`col-md-2 my-0 mx-0 px-0 px-md-1`}
                                        >
                                          <div className="d-md-none d-block">
                                            <div className="row my-1">
                                              <div className="col-6 px-1">
                                                <InputGroup.Text className="PE-incentive-type-grid">
                                                  Incentive Payment Type
                                                </InputGroup.Text>
                                              </div>
                                              <div className="col-6 px-1">
                                                <InputGroup.Text className="PE-incentive-type-grid-value">
                                                  {ay_incentiveRateOrDollars
                                                    ? "Rate"
                                                    : "Dollars"}
                                                </InputGroup.Text>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-md-block d-none">
                                            <InputGroup.Text className="my-1 PE-incentive-type-grid">
                                              Incentive Payment Type
                                            </InputGroup.Text>
                                            <InputGroup.Text className="my-1">
                                              {ay_incentiveRateOrDollars
                                                ? "Rate"
                                                : "Dollars"}
                                            </InputGroup.Text>
                                          </div>
                                        </div>
                                      </Fragment>
                                    )}
                                    <div
                                      className={`col-md-2 my-0 mx-0 px-0 px-md-1`}
                                    >
                                      <div className="d-md-none d-block">
                                        <div className="row my-1">
                                          <div className="col-6 px-1">
                                            <InputGroup.Text>
                                              {qmvalue}
                                            </InputGroup.Text>
                                          </div>
                                          <div className="col-6 px-1">
                                            <Form.Control
                                              style={
                                                qrdisable
                                                  ? {}
                                                  : {
                                                      background: "transparent",
                                                    }
                                              }
                                              type="text"
                                              name={qrname}
                                              value={qrvalue}
                                              onChange={(e) =>
                                                AyIncentiveChangeTrigger(
                                                  e,
                                                  unique,
                                                  incentiveRateOrDollarsName,
                                                  invname
                                                )
                                              }
                                              placeholder={`Enter ${
                                                ay_incentiveRateOrDollars
                                                  ? "a Rate"
                                                  : "Dollars amount"
                                              }`}
                                              onKeyDown={KeyDownHandler}
                                              isInvalid={
                                                !IsStringChecker(qrvalue)
                                              }
                                              required
                                              readOnly={readOnly}
                                              autoComplete="off"
                                            />
                                            {qrerror && (
                                              <Form.Text
                                                style={{ color: "red" }}
                                              >
                                                {ay_incentiveRateOrDollars
                                                  ? rate_err_msg
                                                  : amount_err_msg}
                                              </Form.Text>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-md-block d-none">
                                        <InputGroup.Text className="my-1 PE-incentive-type-grid-value">
                                          {qmvalue}
                                        </InputGroup.Text>
                                        <Form.Control
                                          style={
                                            qrdisable
                                              ? {}
                                              : {
                                                  background: "transparent",
                                                }
                                          }
                                          type="text"
                                          name={qrname}
                                          value={qrvalue}
                                          onChange={(e) =>
                                            AyIncentiveChangeTrigger(
                                              e,
                                              unique,
                                              incentiveRateOrDollarsName,
                                              invname
                                            )
                                          }
                                          placeholder={`Enter ${
                                            ay_incentiveRateOrDollars
                                              ? "a Rate"
                                              : "Dollars amount"
                                          }`}
                                          onKeyDown={KeyDownHandler}
                                          isInvalid={!IsStringChecker(qrvalue)}
                                          required
                                          readOnly={readOnly}
                                          autoComplete="off"
                                        />
                                        {qrerror && (
                                          <Form.Text style={{ color: "red" }}>
                                            {ay_incentiveRateOrDollars
                                              ? rate_err_msg
                                              : amount_err_msg}
                                          </Form.Text>
                                        )}
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </div>
                          <div className="row mb-2 payment-select-input">
                            {inputComponentArr.map((list, parentIndex) => {
                              const parent = list?.key;
                              const pcvalue = list?.cropyear;
                              const pclabel = cropyearLabel;
                              const payments = list?.payments;
                              const sname = incentiveRateOrDollarsName,
                                svalue = !!list?.incentiveRateOrDollars;
                              if (IsArrayNotEmpty(payments)) {
                                return (
                                  <Fragment key={parent}>
                                    {parentIndex === 0 && (
                                      <div
                                        className={`col-6 col-md-2 my-3 mx-0 px-1`}
                                      >
                                        {payments.map((item, childIndex) => {
                                          const unique = item?.key;
                                          const qmvalue = item?.month;
                                          return (
                                            <Fragment key={unique}>
                                              {childIndex === 0 && (
                                                <InputGroup.Text className="my-1">
                                                  {pclabel}
                                                </InputGroup.Text>
                                              )}
                                              {childIndex === 0 && (
                                                <InputGroup.Text className="my-1 PE-incentive-type-grid">
                                                  Incentive Payment Type
                                                </InputGroup.Text>
                                              )}
                                              <InputGroup.Text className="my-1">
                                                {qmvalue}
                                              </InputGroup.Text>
                                            </Fragment>
                                          );
                                        })}
                                      </div>
                                    )}
                                    <div
                                      className={`col-6 col-md-2 my-3 mx-0 px-1`}
                                    >
                                      {payments.map((item, childIndex) => {
                                        const unique = item?.key;
                                        const invname = svalue
                                          ? incentiveDollarsName
                                          : incentiveRateName;
                                        const qrname = svalue
                                            ? incentiveRateName
                                            : incentiveDollarsName,
                                          qrvalue = svalue
                                            ? item?.incentiveRate
                                            : item?.incentiveDollars,
                                          qrerror = svalue
                                            ? item?.incentiveRateError
                                            : item?.incentiveDollarsError;
                                        // const qrorder = item?.contractOrder;
                                        const copyBtn =
                                          inputComponentArr.length > 1 &&
                                          childIndex === payments.length - 1 &&
                                          parentIndex === 0;
                                        const first_field = parentIndex === 0 && childIndex === 0;
                                        const ChangeFunction = first_field ? IncentiveChangeQuarterTrigger : IncentiveChangeTrigger;
                                        return (
                                          <Fragment key={unique}>
                                            {childIndex === 0 && (
                                              <InputGroup.Text className="my-1">
                                                {pcvalue}
                                              </InputGroup.Text>
                                            )}
                                            {childIndex === 0 && (
                                              <InputGroup.Text className="my-1 PE-incentive-type-grid-value">
                                                {svalue ? "Rate" : "Dollars"}
                                              </InputGroup.Text>
                                            )}
                                            <InputGroup className="my-1">
                                              <Form.Control
                                                style={{
                                                  background: "transparent",
                                                }}
                                                type="text"
                                                name={qrname}
                                                value={qrvalue}
                                                onChange={(e) =>  ChangeFunction(
                                                    e,
                                                    parent,
                                                    unique,
                                                    sname,
                                                    invname
                                                  ) 
                                                }
                                                placeholder={`Enter ${
                                                  svalue
                                                    ? "a Rate"
                                                    : "Dollars amount"
                                                }`}
                                                onKeyDown={KeyDownHandler}
                                                isInvalid={
                                                  !IsStringChecker(qrvalue)
                                                }
                                                required
                                                readOnly={
                                                  enable_deferred_payments_section
                                                }
                                                autoComplete="off"
                                              />
                                            </InputGroup>
                                            {qrerror && (
                                              <Form.Text
                                                style={{ color: "red" }}
                                              >
                                                {svalue
                                                  ? rate_err_msg
                                                  : amount_err_msg}
                                              </Form.Text>
                                            )}
                                            {copyBtn && (
                                              <Button
                                                variant="primary"
                                                className={`text-16 my-1 w-100`}
                                                onClick={() =>
                                                  IncentiveChangeToAllTrigger(
                                                    parent,
                                                    qrname,
                                                    invname
                                                  )
                                                }
                                                style={{
                                                  boxShadow: `0 0 5px #ccc`,
                                                }}
                                                disabled={
                                                  enable_deferred_payments_section
                                                }
                                              >
                                                Copy To All Year
                                              </Button>
                                            )}
                                          </Fragment>
                                        );
                                      })}
                                    </div>
                                  </Fragment>
                                );
                              } else {
                                return <Fragment key={parent}></Fragment>;
                              }
                            })}
                          </div>
                          <div className="row mb-2">
                            <div className="col">
                              <Button
                                variant="primary"
                                className="text-16"
                                onClick={() =>
                                  EnableDeferredPaymentsSection(
                                    enable_deferred_payments_section
                                      ? "edit"
                                      : "enter"
                                  )
                                }
                                style={{
                                  boxShadow: `0 0 5px #ccc`,
                                }}
                              >
                                {`${
                                  enable_deferred_payments_section
                                    ? "Edit This"
                                    : "Proceed To Next"
                                } Section`}
                              </Button>
                            </div>
                          </div>
                          {enable_deferred_payments_section ? (
                            <Fragment>
                              <hr className="Payment-Estimator-Segment" />
                              <div className="row mb-2">
                                <div className="col">
                                  <h4 className={`fw-bold`}>
                                    Deferred Payments
                                    <span className="drop-offinfo">
                                      (Enter crop year deferred payment
                                      selections)
                                    </span>
                                  </h4>
                                </div>
                              </div>
                              <Fragment>
                                <div className="row mb-3">
                                  <div className="col-12 col-lg-6 payment-select-input">
                                    <h4 className="fw-bold">Interest Rate</h4>
                                    <Form.Control
                                      style={{ background: "transparent" }}
                                      type="text"
                                      name={interestRateName}
                                      value={InterestRateValue}
                                      onChange={InterestRateChangeTrigger}
                                      onKeyDown={KeyDownHandler}
                                      placeholder={"Enter an Interest Rate"}
                                      isInvalid={
                                        !IsStringChecker(InterestRateValue)
                                      }
                                      required
                                      readOnly={enable_are_stock}
                                      autoComplete="off"
                                    />
                                    {InterestRateError && (
                                      <Form.Text style={{ color: "red" }}>
                                        {rate_err_msg}
                                      </Form.Text>
                                    )}
                                  </div>
                                </div>
                                {enable_additional_year_payments ? (
                                  <div
                                    className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 desktop-accordion-table`}
                                  >
                                    <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                                      <Accordion defaultActiveKey="5">
                                        <Accordion.Item eventKey={ay_key}>
                                          <Accordion.Header>
                                            {`Enter deferred payments data for ${cropyearLabel}: ${ay_cropyear}`}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div className="row m-2">
                                              <div className="col">
                                                {ay_payments.map(
                                                  (list, index) => {
                                                    const child = list?.key;
                                                    const qmvalue = list?.month;
                                                    const qdpname =
                                                        deferrelPercentName,
                                                      qdpvalue =
                                                        list?.deferrelPercent,
                                                      qdpderror =
                                                        list?.deferrelPeriodError,
                                                      qdperror =
                                                        list?.deferrelPercentError,
                                                      qdpdisabled =
                                                        list?.deferrelPercentDisabled;
                                                    const qdmname =
                                                        deferrelPeriodName,
                                                      qdmvalue =
                                                        list?.deferrelPeriod ||
                                                        0;
                                                    return (
                                                      <div
                                                        key={child}
                                                        className="row mb-2"
                                                      >
                                                        <div className="col">
                                                          <InputGroup>
                                                            <FormGroup className="col-4 col-lg-4">
                                                              <Form.Text>
                                                                Payment Month
                                                              </Form.Text>
                                                              <Form.Control
                                                                type="text"
                                                                name={qmvalue}
                                                                value={qmvalue}
                                                                placeholder={
                                                                  "Enter a Month"
                                                                }
                                                                required
                                                                readOnly
                                                              />
                                                            </FormGroup>
                                                            <FormGroup className="col-8 col-lg-4 px-2">
                                                              <Form.Text>
                                                                Deferrel Period
                                                                (in months)
                                                              </Form.Text>
                                                              <Form.Select
                                                                name={qdmname}
                                                                value={qdmvalue}
                                                                placeholder={
                                                                  "Select a Deferrel Period"
                                                                }
                                                                onChange={(e) =>
                                                                  AyDeferredPaymentsChangeTrigger(
                                                                    e,
                                                                    child
                                                                  )
                                                                }
                                                                required
                                                                disabled={
                                                                  enable_are_stock
                                                                }
                                                                className={
                                                                  enable_are_stock
                                                                    ? "cursor-not-allowed white_bg"
                                                                    : ""
                                                                }
                                                              >
                                                                {IsArrayNotEmpty(
                                                                  deferrelPeriodInMonths
                                                                ) &&
                                                                  deferrelPeriodInMonths.map(
                                                                    (li) => (
                                                                      <option
                                                                        key={li}
                                                                        value={
                                                                          li
                                                                        }
                                                                      >
                                                                        {li == 0
                                                                          ? "None"
                                                                          : `${li} Months`}
                                                                      </option>
                                                                    )
                                                                  )}
                                                              </Form.Select>
                                                              {qdpderror && (
                                                                <Form.Text
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  {rate_err_msg}
                                                                </Form.Text>
                                                              )}
                                                            </FormGroup>
                                                            <FormGroup className="mt-2 mt-lg-0 col-8 col-lg-4">
                                                              <Form.Text>
                                                                Deferrel %
                                                              </Form.Text>
                                                              <Form.Select
                                                                name={qdpname}
                                                                value={qdpvalue}
                                                                placeholder={
                                                                  "Enter a Deferrel %"
                                                                }
                                                                onChange={(e) =>
                                                                  AyDeferredPaymentsChangeTrigger(
                                                                    e,
                                                                    child
                                                                  )
                                                                }
                                                                required
                                                                disabled={
                                                                  enable_are_stock ||
                                                                  qdpdisabled
                                                                }
                                                                isInvalid={
                                                                  qdperror
                                                                }
                                                                className={
                                                                  enable_are_stock ||
                                                                  qdpdisabled
                                                                    ? "cursor-not-allowed"
                                                                    : ""
                                                                }
                                                              >
                                                                {IsArrayNotEmpty(
                                                                  deferrelPercentages
                                                                ) &&
                                                                  deferrelPercentages.map(
                                                                    (li) => (
                                                                      <option
                                                                        key={li}
                                                                        value={
                                                                          li
                                                                        }
                                                                      >
                                                                        {`${li}%`}
                                                                      </option>
                                                                    )
                                                                  )}
                                                              </Form.Select>
                                                              {qdperror && (
                                                                <Form.Text
                                                                  style={{
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                >
                                                                  {rate_err_msg}
                                                                </Form.Text>
                                                              )}
                                                            </FormGroup>
                                                          </InputGroup>
                                                          <hr
                                                            className={`Payment-Estimator-Segment mt-3 mb-0 d-lg-none ${
                                                              index + 1 ==
                                                              ay_payments.length
                                                                ? "d-none"
                                                                : ""
                                                            }`}
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div
                                  className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 desktop-accordion-table`}
                                >
                                  <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                                    <Accordion defaultActiveKey="1">
                                      {inputComponentArr.map(
                                        (item, parentIndex) => {
                                          const parent = item?.key;
                                          const qcvalue = item?.cropyear,
                                            qclabel = cropyearLabel;
                                          const deferredPayment =
                                            item?.payments;
                                          const nextIndex =
                                            inputComponentArr.length > 1
                                              ? parentIndex + 1
                                              : false;
                                          const {
                                            cropyear: nextCropYear = false,
                                            key: nextParent = undefined,
                                          } = nextIndex
                                            ? inputComponentArr[nextIndex] || {}
                                            : {};
                                          const copyBtn =
                                            nextIndex &&
                                            nextCropYear &&
                                            (nextParent || nextParent == 0);
                                          return (
                                            <Fragment key={parent}>
                                              <Accordion.Item eventKey={parent}>
                                                <Accordion.Header>
                                                  {`Enter deferred payments data for ${qclabel}: ${qcvalue}`}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="row m-2">
                                                    <div className="col">
                                                      {IsArrayNotEmpty(
                                                        deferredPayment
                                                      ) &&
                                                        deferredPayment.map(
                                                          (list, index) => {
                                                            const child =
                                                              list?.key;
                                                            const qmvalue =
                                                              list?.month;
                                                            const qdpname =
                                                                deferrelPercentName,
                                                              qdpvalue =
                                                                list?.deferrelPercent,
                                                              qdpderror =
                                                                list?.deferrelPeriodError,
                                                              qdperror =
                                                                list?.deferrelPercentError,
                                                              qdpdisabled =
                                                                list?.deferrelPercentDisabled;
                                                            const qdmname =
                                                                deferrelPeriodName,
                                                              qdmvalue =
                                                                list?.deferrelPeriod ||
                                                                0;
                                                            return (
                                                              <div
                                                                key={child}
                                                                className="row mb-2"
                                                              >
                                                                <div className="col">
                                                                  <InputGroup>
                                                                    <FormGroup className="col-4 col-lg-4">
                                                                      <Form.Text>
                                                                        Payment
                                                                        Month
                                                                      </Form.Text>
                                                                      <Form.Control
                                                                        type="text"
                                                                        name={
                                                                          qmvalue
                                                                        }
                                                                        value={
                                                                          qmvalue
                                                                        }
                                                                        placeholder={
                                                                          "Enter a Month"
                                                                        }
                                                                        required
                                                                        readOnly
                                                                      />
                                                                    </FormGroup>
                                                                    <FormGroup className="col-8 col-lg-4 px-2">
                                                                      <Form.Text>
                                                                        Deferrel
                                                                        Period
                                                                        (in
                                                                        months)
                                                                      </Form.Text>
                                                                      <Form.Select
                                                                        name={
                                                                          qdmname
                                                                        }
                                                                        value={
                                                                          qdmvalue
                                                                        }
                                                                        placeholder={
                                                                          "Select a Deferrel Period"
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          DeferredPaymentsChangeTrigger(
                                                                            e,
                                                                            parent,
                                                                            child
                                                                          )
                                                                        }
                                                                        required
                                                                        disabled={
                                                                          enable_are_stock
                                                                        }
                                                                        className={
                                                                          enable_are_stock
                                                                            ? "cursor-not-allowed white_bg"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        {IsArrayNotEmpty(
                                                                          deferrelPeriodInMonths
                                                                        ) &&
                                                                          deferrelPeriodInMonths.map(
                                                                            (
                                                                              li
                                                                            ) => (
                                                                              <option
                                                                                key={
                                                                                  li
                                                                                }
                                                                                value={
                                                                                  li
                                                                                }
                                                                              >
                                                                                {li ==
                                                                                0
                                                                                  ? "None"
                                                                                  : `${li} Months`}
                                                                              </option>
                                                                            )
                                                                          )}
                                                                      </Form.Select>
                                                                      {qdpderror && (
                                                                        <Form.Text
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                        >
                                                                          {
                                                                            rate_err_msg
                                                                          }
                                                                        </Form.Text>
                                                                      )}
                                                                    </FormGroup>
                                                                    <FormGroup className="mt-2 mt-lg-0 col-8 col-lg-4">
                                                                      <Form.Text>
                                                                        Deferrel
                                                                        %
                                                                      </Form.Text>
                                                                      <Form.Select
                                                                        name={
                                                                          qdpname
                                                                        }
                                                                        value={
                                                                          qdpvalue
                                                                        }
                                                                        placeholder={
                                                                          "Enter a Deferrel %"
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          DeferredPaymentsChangeTrigger(
                                                                            e,
                                                                            parent,
                                                                            child
                                                                          )
                                                                        }
                                                                        required
                                                                        disabled={
                                                                          enable_are_stock ||
                                                                          qdpdisabled
                                                                        }
                                                                        isInvalid={
                                                                          qdperror
                                                                        }
                                                                        className={
                                                                          enable_are_stock ||
                                                                          qdpdisabled
                                                                            ? "cursor-not-allowed"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        {IsArrayNotEmpty(
                                                                          deferrelPercentages
                                                                        ) &&
                                                                          deferrelPercentages.map(
                                                                            (
                                                                              li
                                                                            ) => (
                                                                              <option
                                                                                key={
                                                                                  li
                                                                                }
                                                                                value={
                                                                                  li
                                                                                }
                                                                              >
                                                                                {`${li}%`}
                                                                              </option>
                                                                            )
                                                                          )}
                                                                      </Form.Select>
                                                                      {qdperror && (
                                                                        <Form.Text
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                        >
                                                                          {
                                                                            rate_err_msg
                                                                          }
                                                                        </Form.Text>
                                                                      )}
                                                                    </FormGroup>
                                                                  </InputGroup>
                                                                  <hr
                                                                    className={`Payment-Estimator-Segment mt-3 mb-0 d-lg-none ${
                                                                      index +
                                                                        1 ==
                                                                      deferredPayment.length
                                                                        ? "d-none"
                                                                        : ""
                                                                    }`}
                                                                  />
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                    </div>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                              {copyBtn && (
                                                <Button
                                                  variant="primary"
                                                  className={`text-16 mb-2 ${
                                                    enable_are_stock
                                                      ? "cursor-not-allowed"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    DeferredPaymentNextYearUpdateTrigger(
                                                      parent,
                                                      nextParent
                                                    )
                                                  }
                                                  style={{
                                                    boxShadow: `0 0 5px #ccc`,
                                                  }}
                                                  disabled={enable_are_stock}
                                                >
                                                  {`Copy To ${cropyearLabel}: ${nextCropYear}`}
                                                </Button>
                                              )}
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </Accordion>
                                  </div>
                                </div>
                              </Fragment>
                              <div className="row mb-2">
                                <div className="col">
                                  <Button
                                    variant="primary"
                                    className="text-16 my-2 my-lg-0"
                                    onClick={() =>
                                      EnableCalculateSection(
                                        enable_are_stock ? "edit" : "enter"
                                      )
                                    }
                                    style={{
                                      boxShadow: `0 0 5px #ccc`,
                                    }}
                                  >
                                    {`${
                                      enable_are_stock
                                        ? "Edit This"
                                        : "Proceed To Next"
                                    } Section`}
                                  </Button>
                                  <Button
                                    variant="light"
                                    className={`text-16 mx-lg-2 text-start ${
                                      enable_are_stock
                                        ? "cursor-not-allowed"
                                        : ""
                                    }`}
                                    onClick={() => SkipDefermentsSection()}
                                    style={{
                                      boxShadow: `0 0 5px #ccc`,
                                    }}
                                    disabled={enable_are_stock}
                                  >
                                    {`Skip This & Proceed To Next Section`}
                                  </Button>
                                </div>
                              </div>
                              {enable_are_stock ? (
                                <Fragment>
                                  <hr className="Payment-Estimator-Segment" />
                                  <PaymentEstimatorAREStockRetain
                                    dispatchInputComponentMapping={
                                      dispatchInputComponentMapping
                                    }
                                    SectionHandler={SectionHandler}
                                    enable_calculate_section={
                                      enable_calculate_section
                                    }
                                    inputComponentArr={inputComponentArr}
                                    are_stock_preload={are_stock_preload}
                                    are_stock_loading={are_stock_loading}
                                    EmptyAREStockPreloadData={
                                      EmptyAREStockPreloadData
                                    }
                                    additional_year={additional_year}
                                    DispatchAdditionalYear={
                                      DispatchAdditionalYear
                                    }
                                    payments_pending={payments_pending}
                                  />
                                  {enable_calculate_section ? (
                                    <Fragment>
                                      <hr className="Payment-Estimator-Segment" />
                                      <div className="row mb-2">
                                        <div className="col">
                                          <h4 className="fw-bold">
                                            Save & Calculate Estimate
                                            <span className="drop-offinfo">
                                              (Enter an estimate name to save
                                              this estimate that can be loaded
                                              in future.)
                                            </span>
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col">
                                          <InputGroup>
                                            <InputGroup.Text>
                                              Estimate Label
                                            </InputGroup.Text>
                                            <Form.Control
                                              className="cursor-not-allowed white_bg"
                                              type="text"
                                              name={"estimateLabel"}
                                              value={estimateLabel}
                                              placeholder={
                                                "Enter an Estimate Label"
                                              }
                                              readOnly
                                              autoComplete="off"
                                            />
                                          </InputGroup>
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col">
                                          <InputGroup>
                                            <InputGroup.Text>
                                              Estimate Name
                                            </InputGroup.Text>
                                            <Form.Control
                                              type="text"
                                              name={"estimateName"}
                                              value={estimateName}
                                              onChange={ChangeEstimateName}
                                              minLength={1}
                                              maxLength={50}
                                              isInvalid={IsStringChecker(
                                                estimateNameError
                                              )}
                                              placeholder={
                                                "Enter an Estimate Name"
                                              }
                                              autoComplete="off"
                                            />
                                          </InputGroup>
                                          {IsStringChecker(
                                            estimateNameError
                                          ) && (
                                            <div className="row">
                                              <Form.Text
                                                className="col"
                                                style={{ color: "red" }}
                                              >
                                                {estimateNameError}
                                              </Form.Text>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="row mt-3">
                                        <div className="col">
                                          {trigger && (
                                            <Alert
                                              variant={variant}
                                              onClose={() =>
                                                AlertBar(defaultAlert)
                                              }
                                              dismissible
                                            >
                                              <b>{message}</b>
                                            </Alert>
                                          )}
                                        </div>
                                      </div>
                                      <div className="row mb-2">
                                        <div className="col">
                                          <Button
                                            variant="primary"
                                            className="text-16"
                                            onClick={(e) =>
                                              CalculateEstimate(e, false, true)
                                            }
                                            style={{
                                              boxShadow: `0 0 5px #ccc`,
                                            }}
                                          >
                                            {"Calculate Estimate"}
                                          </Button>
                                          <Button
                                            variant="light"
                                            className="text-16 mx-2 cursor-for-btn"
                                            onClick={(e) =>
                                              CalculateEstimate(e, true, false)
                                            }
                                            disabled={impersonated}
                                            style={{
                                              boxShadow: `0 0 5px #ccc`,
                                            }}
                                          >
                                            {"Save Estimate "}
                                            {miniLoader ? (
                                              <WhiteCircleLoader
                                                invert={true}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </Button>
                                          <Button
                                            variant="primary"
                                            className="text-16 mt-3 mt-lg-0 cursor-for-btn"
                                            onClick={(e) =>
                                              CalculateEstimate(e, true, true)
                                            }
                                            style={{
                                              boxShadow: `0 0 5px #ccc`,
                                            }}
                                            disabled={impersonated}
                                          >
                                            {"Save & Calculate Estimate "}
                                            {miniLoader ? (
                                              <WhiteCircleLoader />
                                            ) : (
                                              ""
                                            )}
                                          </Button>
                                          <Button
                                            variant="light"
                                            className="text-16 mt-3 mt-lg-0 mx-2 mx-lg-0 float-lg-end"
                                            onClick={GlobalClearAllButton}
                                            style={{
                                              boxShadow: `0 0 5px #ccc`,
                                            }}
                                          >
                                            {"Clear All"}
                                          </Button>
                                        </div>
                                      </div>{" "}
                                    </Fragment>
                                  ) : (
                                    <Fragment></Fragment>
                                  )}
                                </Fragment>
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Fragment>
              )}
            </div>
            <br />
            <PaymentEstimatorCalculationDetail
              calculateEstimate={calculateEstimate}
              contractOptions={contractOptions}
              EstimateRawInput={
                loadBtnClicked
                  ? {
                      contract,
                      estimateName,
                      payment_mappings: inputComponentArr,
                      estimateLabel: estimateLoadLabel,
                      additional_year,
                    }
                  : EstimateRawInput
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentEstimatorDetail;
