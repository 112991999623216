/* eslint-disable */
/**
 * @component_Name : ForecastingDataDetail
 * @description : Forecasting Data
 * @company : Photon
 * @author : Nivethithan Kannan/19-09-2024
 **/

import React, { useEffect, useState, Fragment, useReducer } from "react";
import BreadCrumb from "../../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import moment from "moment";
import CurrentLocation from "../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
  compareString,
  getCurrentYear,
  NumConverter,
  IsNumberChecker,
  IsObjectChecker,
  ReplaceToastStack,
} from "../../../utils/helper";
import {
  Form,
  Accordion,
  Alert,
  InputGroup,
  Button,
  FormGroup,
  CloseButton,
  Table,
} from "react-bootstrap";
import WhiteCircleLoader from "../../whiteCircleLoader";
import MultiDropDown from "../../selectBox/multiDropDown";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
  LocalAccountIDSwapImpersonate,
  DetermineImpersonation,
} from "../../../permissionWrapper";
import constantsVariable from "../../../utils/constantsVariable";
import DatePickerComp from "../../global/datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ForecastingDataDetail = (props) => {
  const impersonated = DetermineImpersonation();
  const { DropDownCBRoles = [] } = props;
  const today = moment().utc().format("MM/DD/YYYY");
  const news_info_box = `Introducing an innovative forecasting tool that predicts cranberry yields using bud count. This feature leverages real-time data and historical trends to help growers optimize resource allocation and boost profitability, representing a significant leap in precision agriculture.`;
  const bud_section_info_box = `This section focuses on entering bud counts by date and provides details about the bud count history table. It allows for easy tracking of bud counts over time. Growers can efficiently manage and review their bud count entries.`;
  const crop_condition_section_info_box = `This section focuses on assessing crop conditions over time, providing detailed insights into various factors affecting growth. It allows for easy tracking of crop health and development. Growers can efficiently manage and review their crop condition data to make informed decisions.`;
  const fruit_section_info_box = `This section focuses on entering cranberry fruit counts by date and provides details about the cranberry count history table. It allows for easy tracking of fruit counts over time. Growers can efficiently manage and review their cranberry count entries.`;

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const { defaultToastMsgProperties, dateFormate } = constantsVariable;

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const localAccountId = LocalAccountIDSwapImpersonate(accounts);

  const [loading, setLoading] = useState(false);
  const defaultAlert = { trigger: false, variant: "primary", message: "" };
  const [saveAlert, setSaveAlert] = useState(defaultAlert);

  function AlertBar(content, timeout = false) {
    if (IsObjectChecker(content)) {
      setSaveAlert(content);
      if (timeout) {
        setTimeout(() => {
          setSaveAlert(defaultAlert);
        }, 10000);
      }
    }
  }

  const [paymentsArr, dispatchPaymentsMapping] = useReducer(
    PaymentsMappingReducer,
    []
  );

  function PaymentsMappingReducer(state, action) {
    const { type, payload } = action || {};
    switch (type) {
      case "replace":
        return [...payload];
      default:
        return state;
    }
  }

  // API Request
  async function fetchContract() {
    setLoading(true);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/${personId}/contracts`
      // `persons/${personId}/contracts?r.ContractStatus=1000,1001`
    )
      .then((resp) => {
        const data = safeArrayCheck(_.get(resp, `data`, []));
        const filteredData = FilterContractByRole(
          data,
          accounts,
          DropDownCBRoles
        );
        console.log({ filteredData });
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (IsTokenChecker(idToken) && personId && localAccountId) {
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, personId, localAccountId]);

  const bud_count_date_name = "bud_count_date",
    bud_count_name = "bud_count",
    fruit_count_date_name = "fruit_count_date",
    fruit_count_name = "fruit_count";

  const initial_bud_data = {
    bud_count_date: null,
    bud_count_date_error: false,
    bud_count: "0",
    bud_count_error: false,
  };

  const initial_fruit_data = {
    fruit_count_date: null,
    fruit_count_date_error: false,
    fruit_count: "0",
    fruit_count_error: false,
  };

  const [bud_entry_list, set_bud_entry_list] = useState([
    { key: 1, bud_count: 8945, bud_count_date: "2/9/2024", counted_by: "NK" },
    { key: 2, bud_count: 9028, bud_count_date: "12/9/2024", counted_by: "NK" },
    { key: 3, bud_count: 19287, bud_count_date: "22/9/2024", counted_by: "NK" },
  ]);
  const [fruit_entry_list, set_fruit_entry_list] = useState([
    {
      key: 1,
      fruit_count: 7945,
      fruit_count_date: "3/9/2024",
      counted_by: "NK",
    },
    {
      key: 2,
      fruit_count: 6028,
      fruit_count_date: "13/9/2024",
      counted_by: "NK",
    },
    {
      key: 3,
      fruit_count: 10287,
      fruit_count_date: "23/9/2024",
      counted_by: "NK",
    },
  ]);

  const [bud_data, dispatch_bud_data] = useReducer(
    BudDataReducer,
    initial_bud_data
  );
  const [fruit_data, dispatch_fruit_data] = useReducer(
    FruitDataReducer,
    initial_fruit_data
  );

  function BudDataReducer(state, action) {
    const { type, payload } = action;
    console.log(state, action);

    switch (type) {
      case "Reset":
        return initial_bud_data;
      case "Replace":
        return { ...payload };
      case "Change":
        return BudHandler(state, payload);
      default:
        return state;
    }
  }

  function FruitDataReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
      case "Reset":
        return initial_fruit_data;
      case "Replace":
        return { ...payload };
      case "Change":
        return FruitHandler(state, payload);
      default:
        return state;
    }
  }

  function BudHandler(state, payload) {
    try {
      const { name, value, error } = payload;
      const temp = { ...state };
      temp[name] = value;
      temp[`${name}_error`] = error;
      return temp;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function FruitHandler(state, payload) {
    try {
      const { name, value, error } = payload;
      const temp = { ...state };
      temp[name] = value;
      temp[`${name}_error`] = error;
      return temp;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function OnBudChangeHandler(e) {
    const { name, value } = e?.target || {};
    dispatch_bud_data({
      type: "Change",
      payload: {
        name,
        value,
        error: false,
      },
    });
  }

  function OnFruitChangeHandler(e) {
    const { name, value } = e?.target || {};
    dispatch_fruit_data({
      type: "Change",
      payload: {
        name,
        value,
        error: false,
      },
    });
  }

  function OnBudEntryAdd(e) {
    console.log({ bud_data });
  }

  function OnFruitEntryAdd(e) {
    console.log({ fruit_data });
  }

  const {
    fruit_count_date = null,
    fruit_count_date_error = false,
    fruit_count = "0",
    fruit_count_error = false,
  } = fruit_data;

  const {
    bud_count_date = null,
    bud_count_date_error = false,
    bud_count = "0",
    bud_count_error = false,
  } = bud_data;

  //Datepicket event
  const handlerDate = (param, type) => {
    console.log({ param, type });
    if (type == "bud_data") {
      dispatch_bud_data({
        type: "Change",
        payload: {
          name: bud_count_date_name,
          value: param,
          error: false,
        },
      });
    } else if (type == "fruit_data") {
      dispatch_fruit_data({
        type: "Change",
        payload: {
          name: fruit_count_date_name,
          value: param,
          error: false,
        },
      });
    }
  };

  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="forecasting-data" />
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug="forecasting-data" />
            </div>
          </div>
          <div className="payment-estimator-form col-12 col-lg-10">
            <div className="row mb-3">
              <div className="col">
                <Alert
                  variant="primary"
                  style={{
                    textAlign: "justify",
                    fontStyle: "italic",
                  }}
                >
                  <Alert.Heading>
                    <i className="fa fa-info"></i> Exciting News:
                  </Alert.Heading>
                  <p>{news_info_box}</p>
                </Alert>
              </div>
            </div>
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-3">
              <h3 className="mob-fw-bold py-3">
                Bud Count Overview
                {/* <span className="drop-offinfo">(All fields are required)</span> */}
              </h3>
              <p>{bud_section_info_box}</p>
              <hr className="Payment-Estimator-Segment" />
              <div className="row mb-2">
                <div className="col">
                  <h4 className={`fw-bold`}>Bud Count Entry</h4>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                      <h5 className="fw-bold">Date Counted</h5>
                      <div
                        className={`form-floating date_picker_aln payment_date cursor_pointer date_from`}
                      >
                        <DatePickerComp
                          id="datepickerFrom"
                          selected={bud_count_date}
                          name={bud_count_date_name}
                          onChange={(date) => handlerDate(date, "bud_data")}
                          placeholderText={dateFormate}
                          className="datepicker_input form-control border-1"
                          maxDate={new Date()}
                        />
                        <label htmlFor="datepickerFrom">
                          Date Counted
                          <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 payment-select-input">
                      <h5 className="fw-bold">Bud Count</h5>
                      <Form.Control
                        className="white_bg"
                        type="text"
                        name={bud_count_name}
                        value={bud_count}
                        onChange={OnBudChangeHandler}
                        isInvalid={!IsStringChecker(bud_count)}
                        required
                        placeholder={"Enter the Bud Count"}
                        autoComplete="off"
                      />
                      {bud_count_error && (
                        <Form.Text style={{ color: "red" }}>
                          A vaild number is required
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <Button
                    variant="primary"
                    className="text-16"
                    onClick={OnBudEntryAdd}
                    style={{
                      boxShadow: `0 0 5px #ccc`,
                    }}
                  >
                    Add Entry
                  </Button>
                </div>
              </div>
              <hr className="Payment-Estimator-Segment" />
              <div className="row mb-2">
                <div className="col">
                  <h4 className={`fw-bold`}>Bud Count Entry List</h4>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <Table
                    responsive
                    className="payment-estimator-table cropyear-sticky-table"
                  >
                    <thead className="pay-est-head">
                      <tr className="pay-est-row">
                        <th className="pay-est-bold cropyear-sticky-column">
                          Date Counted
                        </th>
                        <th className="pay-est-bold">Bud Count</th>
                        <th className="pay-est-bold">Counted By</th>
                      </tr>
                    </thead>
                    <tbody className="pay-est-body">
                      {bud_entry_list.map((item, index) => {
                        const { key, bud_count_date, bud_count, counted_by } =
                          item;
                        return (
                          <Fragment key={key}>
                            <tr className="pay-est-row">
                              <td className="pay-est-data">
                                {bud_count_date}
                              </td>
                              <td className="pay-est-data">
                                {bud_count}
                              </td>
                              <td className="pay-est-data">
                                {counted_by}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <br />
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-3">
              <h3 className="mob-fw-bold py-3">
                Crop Condition Insights
                {/* <span className="drop-offinfo">(All fields are required)</span> */}
              </h3>
              <p>{crop_condition_section_info_box}</p>
            </div>
            <br />
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-3">
              <h3 className="mob-fw-bold py-3">
                Cranberry Fruit Overview
                {/* <span className="drop-offinfo">(All fields are required)</span> */}
              </h3>
              <p>{fruit_section_info_box}</p>
              <hr className="Payment-Estimator-Segment" />
              <div className="row mb-2">
                <div className="col">
                  <h4 className={`fw-bold`}>Fruit Count Entry</h4>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <div className="row mb-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                      <h5 className="fw-bold">Date Counted</h5>
                      <div
                        className={`form-floating date_picker_aln payment_date cursor_pointer date_from`}
                      >
                        <DatePickerComp
                          id="datepickerFrom"
                          selected={fruit_count_date}
                          name={fruit_count_date_name}
                          onChange={(date) => handlerDate(date, "fruit_data")}
                          placeholderText={dateFormate}
                          className="datepicker_input form-control border-1"
                          maxDate={new Date()}
                        />
                        <label htmlFor="datepickerFrom">
                          Date Counted
                          <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 payment-select-input">
                      <h5 className="fw-bold">Fruit Count</h5>
                      <Form.Control
                        className="white_bg"
                        type="text"
                        name={fruit_count_name}
                        value={fruit_count}
                        onChange={OnFruitChangeHandler}
                        isInvalid={!IsStringChecker(fruit_count)}
                        required
                        placeholder={"Enter the Fruit Count"}
                        autoComplete="off"
                      />
                      {fruit_count_error && (
                        <Form.Text style={{ color: "red" }}>
                          A vaild number is required
                        </Form.Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <Button
                    variant="primary"
                    className="text-16"
                    onClick={OnFruitEntryAdd}
                    style={{
                      boxShadow: `0 0 5px #ccc`,
                    }}
                  >
                    Add Entry
                  </Button>
                </div>
              </div>
              <hr className="Payment-Estimator-Segment" />
              <div className="row mb-2">
                <div className="col">
                  <h4 className={`fw-bold`}>Fruit Count Entry List</h4>
                </div>
              </div>
              <div className="row m-2">
                <div className="col">
                  <Table
                    responsive
                    className="payment-estimator-table cropyear-sticky-table"
                  >
                    <thead className="pay-est-head">
                      <tr className="pay-est-row">
                        <th className="pay-est-bold cropyear-sticky-column">
                          Date Counted
                        </th>
                        <th className="pay-est-bold">Fruit Count</th>
                        <th className="pay-est-bold">Counted By</th>
                      </tr>
                    </thead>
                    <tbody className="pay-est-body">
                      {fruit_entry_list.map((item, index) => {
                        const {
                          key,
                          fruit_count_date,
                          fruit_count,
                          counted_by,
                        } = item;
                        return (
                          <Fragment key={key}>
                            <tr className="pay-est-row">
                              <td className="pay-est-data">
                                {fruit_count_date}
                              </td>
                              <td className="pay-est-data">
                                {fruit_count}
                              </td>
                              <td className="pay-est-data">
                                {counted_by}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForecastingDataDetail;
