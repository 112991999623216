import React, { Fragment, useEffect, useState } from "react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IsArrayNotEmpty,
  IsTokenChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsStringChecker,
} from "../../utils/helper";
import constantsVariable from "../../utils/constantsVariable";
import ContentModifier from "./contentModifier";
import { Accordion, Form } from "react-bootstrap";
import CurrentLocation from "./currentLocation";
import moment from "moment";
import WhiteCircleLoader from "../whiteCircleLoader";
import { DetermineImpersonation, ImpersonationAccountsSwap } from "../../permissionWrapper";

const LocalTranslator = (
  moreLangs,
  resolvedLanguage = "en",
  pathName = ``,
  defaultValue = null,
  prefix = "home_page"
) => {
  try {
    if (IsTokenChecker(pathName, defaultValue)) {
      const str = _.get(moreLangs, `${resolvedLanguage}.${prefix}.${pathName}`);
      return IsStringChecker(str) ? str : defaultValue;
    } else {
      return defaultValue;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return defaultValue;
  }
};

const AccordionPrint = () => {
  const HandlePrint = () => {
    window.print();
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="float-end mb-2">
          <span className="float-end">
            <button
              className="printButonCss printIconPadding d-none d-lg-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title=""
              data-bs-original-title="Print"
              aria-label="Print"
            >
              <img
                src={`/assets/images/print_icon.svg`}
                className="img-40 me-2"
                alt="Print icon"
                onClick={HandlePrint}
              />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

const GridListing = (props) => {
  const { data = [], resolvedLanguage = "en" } = props;
  return IsArrayNotEmpty(data) ? (
    <div className="row pt-3">
      {data.map((item, index) => {
        const img = _.get(item, ["feature_image_url"], "");
        const url = _.get(item, ["url"], "");
        const title = _.get(item, ["title_" + resolvedLanguage], "");
        const button = _.get(item, ["button_text_" + resolvedLanguage], "");
        const open_new_window = _.get(item, ["open_new_window"], false);
        // const new_window = button === "" ? false : open_new_window;
        const internal_url = `${window.location.pathname}${url}`;
        return (
          <div className="col-12 col-lg-4" key={index}>
            <div className="card card-shadow mb-3">
              {img && <img src={img} className="card-img-top" alt="..." />}
              <div className="card-body pt-0 px-0">
                <div className="row">
                  <div className="col-11 ms-1">
                    <ul className="list-group text-center">
                      <li className="list-group-item border-0 pt-4">
                        <Link
                          to={
                            open_new_window ? { pathname: url } : internal_url
                          }
                          target={open_new_window ? "_blank" : "_self"}
                          className="text-18 d-block "
                        >
                          {" "}
                          {title}{" "}
                        </Link>
                        <div className={"mt-2"}>
                          <Link
                            to={
                              open_new_window ? { pathname: url } : internal_url
                            }
                            target={open_new_window ? "_blank" : "_self"}
                            className={`
                                ${
                                  button
                                    ? "btn btn-primary"
                                    : "red_color d-block"
                                }
                                  ${img !== null ? "mt-" : ""} `}
                          >
                            {button && <Fragment>{button}</Fragment>}
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

const UserSign = (props) => {
  const impersonated = DetermineImpersonation();
  const { dateFormate } = constantsVariable;
  const {
    user_id,
    user_fullname,
    page_listing_id,
    component_listing_id,
    setShowCTA,
    setError,
    first_name,
    last_name
  } = props;
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);

  const [grower_signature, setGrower_signature] = useState("");
  const [agree, setAgree] = useState(false);
  const [loader, setLoader] = useState(false);

  const PostEsignData = async () => {
    const postData = {
      user_id,
      user_fullname,
      grower_signature,
      page_listing_id,
      component_listing_id,
    };
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `custom-esign-entry`,
      "POST",
      postData
    )
      .then(() => {
        setError(false);
        setShowCTA(true);
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setShowCTA(false);
        setError(true);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const onUserSign = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      agree &&
      IsStringChecker(grower_signature) &&
      user_id &&
      page_listing_id &&
      component_listing_id
    ) {
      setLoader(true);
      PostEsignData();
    } else {
      setShowCTA(false);
    }
  };

  const onInputChange = (e) => {
    const { value } = e?.target || {};
    setGrower_signature(value);
  };

  const onTermsAgree = () => {
    setAgree((prev) => {
      const temp = !!prev;
      return !temp;
    });
  };

  const terms = `I, ${first_name} ${last_name} (Grower-Owner) acknowledge that by clicking “Agree” below, I will be granted access to one or more private Credit Rating Reports provided to Ocean Spray Cranberries, Inc. by Fitch Ratings. I understand that all such Credit Rating Reports are confidential, and that Ocean Spray is only providing them to me in consideration of my agreement to protect and not disclose this confidential information to any third party. By clicking my agreement below, I promise and agree that I will hold all the Credit Ratings Reports provided by Ocean Spray to me in the strictest confidence and will not disclose the nature nor content of them to any third party at any time.  My agreement in this regard applies to all subsequent Credit Ratings Reports that I received from the Cooperative.  I understand that my obligation of confidentiality will not terminate unless Ocean Spray notifies me that such obligation is no longer required.`;
  const submit_btn = LocalTranslator(
    moreLangs,
    resolvedLanguage,
    "agree",
    "Agree"
  );
  const enable_agree_btn = agree && IsStringChecker(grower_signature);
  return (
    <div className="User-Sign-FitchRating">
      <Form className="pt-3" onSubmit={onUserSign}>
        <div className="row">
          <div className="col-12 col-lg-6 fitchRating">
            <div className="position-relative mb-4">
              <span className="lable-text text-14 mandatoryField  ps-3">
                Grower Signature
              </span>
              <input
                type="text"
                aria-label="Grower Signature"
                onChange={onInputChange}
                value={grower_signature}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Group className="mb-4">
              <Form.Check
                type={"checkbox"}
                name={agree}
                label={terms}
                id={`fitchRating_Agree`}
                value={agree}
                checked={agree}
                onClick={onTermsAgree}
                className="mandFieldCheckbox"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <p>
              <span className="fw-bold">Grower Signature: </span>{" "}
              {first_name} {last_name}
            </p>
          </div>
          <div className="col-12 col-sm-6">
            <p className="float-sm-end">
              <span className="fw-bold">Signature date: </span>{" "}
              {moment().format(dateFormate)}
            </p>
          </div>
          <div>
            <button
              className="btn btn-primary text-16 float-end mb-4 cursor-for-btn"
              disabled={impersonated ? impersonated : !enable_agree_btn}
              type="submit"
            >
              {submit_btn} {loader ? <WhiteCircleLoader /> : ""}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

const FitchRating = (props) => {
  const { roleCBDecisionMaker = false, fitchRating, fitchRatingCTA } = props;
  const slug = `coop-finance`;

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const user_id = _.get(accounts, `0.localAccountId`, "");
  const user_fullname = _.get(accounts, `0.idTokenClaims.name`, "");
  const first_name = _.get(accounts, `0.idTokenClaims.firstName`, "");
  const last_name = _.get(accounts, `0.idTokenClaims.lastName`, "");

  const Name = _.get(fitchRating, "accordion_name_en", null);
  const Content = _.get(
    fitchRating,
    `content_block.content_${resolvedLanguage}`,
    null
  );
  const component_listing_id = _.get(fitchRating, `id`, null);
  const { icon_url, show_print_icon, id, component_id } = fitchRating;
  const eventKey = `${component_id}-${id}`;
  const CTARenderData = IsStringChecker(Name)
    ? _.get(fitchRatingCTA, Name, [])
    : [];
  const OrderedContents = _.orderBy(CTARenderData, ["order_by"], ["asc"]);

  const [showCTA, setShowCTA] = useState(false);
  const [error, setError] = useState(false);
  const [page_listing_id, setPageListingID] = useState(false);

  const GetPageListingsID = async () => {
    const esignURL = `page_listings?select=*&slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, esignURL)
      .then((resp) => {
        const page_listingsID = _.get(resp, "data.data.0.id", null);
        if (page_listingsID) {
          setError(false);
          setShowCTA(false);
          setPageListingID(page_listingsID);
        } else {
          setError(true);
          setShowCTA(false);
          setPageListingID(false);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setError(true);
        setShowCTA(false);
        setPageListingID(false);
      });
  };

  const GetEsignData = async () => {
    const start = moment().startOf("date").toISOString();
    const end = moment().endOf("date").toISOString();
    const esignFilter = `created_at.gte.${start},updated_at.lte.${end}`;
    const esignURL = `e_signs?select=*,page_listings!inner(*),component_listings!inner(*)&page_listings.slug=eq.${slug}&component_listings.id=eq.${component_listing_id}&user_id=eq.${user_id}&and=(${esignFilter})`;
    await cmsDataCommonMethod(idToken, accounts, esignURL)
      .then((resp) => {
        const responseData = _.get(resp, "data.data", []);
        if (IsArrayNotEmpty(responseData)) {
          setError(false);
          setShowCTA(true);
          setPageListingID(false);
        } else {
          GetPageListingsID();
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setError(true);
        setShowCTA(false);
        setPageListingID(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken) && component_listing_id) {
      GetEsignData();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, component_listing_id]);

  const errorMsg = LocalTranslator(
    moreLangs,
    resolvedLanguage,
    "fitch_error",
    `Problem loading the component. Please, try again later`
  );
  const DisplayFitchRating = !!(roleCBDecisionMaker && component_listing_id);
  return DisplayFitchRating ? (
    <>
      <Accordion className={`mt-5 mt-lg-0 mb-5`}>
        <Accordion.Item eventKey={eventKey}>
          {IsStringChecker(Name) && (
            <Accordion.Header>
              {IsStringChecker(icon_url) && (
                <img
                  src={icon_url}
                  className={"width-30 right-space-10"}
                  alt={""}
                />
              )}
              {Name}
            </Accordion.Header>
          )}
          <Accordion.Body>
            {show_print_icon && <AccordionPrint />}
            {IsStringChecker(Content) ? (
              <ContentModifier str={Content} />
            ) : (
              <></>
            )}
            <hr className="height05" />
            {error ? (
              <div className="text-center">{errorMsg}</div>
            ) : showCTA ? (
              <div className="fitchratingCTAS">
                <GridListing
                  data={OrderedContents}
                  resolvedLanguage={resolvedLanguage}
                />
              </div>
            ) : (
              <UserSign
                user_id={user_id}
                user_fullname={user_fullname}
                first_name={first_name}
                last_name={last_name}
                page_listing_id={page_listing_id}
                component_listing_id={component_listing_id}
                setShowCTA={setShowCTA}
                setError={setError}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  ) : (
    <></>
  );
};

export default FitchRating;
