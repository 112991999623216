import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import Moment from "react-moment";
import { Carousel } from "react-bootstrap";
import HarvestGraph from "../global/newharvestGraph";
import _ from "lodash";
import ImageView from "../global/imageView";
import { Link } from "react-router-dom";
import { ConstantsVar } from "../../utils/constants";
import {
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  IsNumberChecker,
  HandleErrorTrackAndToast,
  compareString,
} from "../../utils/helper";

const Harvest = (props) => {
  const {
    harvestData = [],
    asyncData = [],
    notification = null,
    page = "",
    CommonPageCBRoles=[]
  } = props;
  const ref1 = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  // const basicHeight = compareString(page, "my-harvest") ? 500 : 300;
  const basicHeight = 300;
  const [height, setHeight] = useState(basicHeight);

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const { 
    defaultLoadingImageVertURL,
    defaultEventHomeImageURL,
   } = ConstantsVar;

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [cIndex, setIndex] = useState(0);
  // const [toggelGraph,] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // const handlerShowHideGraph = (e) => {
  //   e.stopPropagation();
  //   const showHide = e.target.getAttribute("data-action");
  //   if (showHide == "day") {
  //     setToggelGraph(false);
  //   } else if (showHide == "year") {
  //     setToggelGraph(true);
  //   }
  // };

  const LocalTrans = (str, def) => {
    try {
      if (IsStringChecker(str)) {
        return `${_.get(
          moreLangs,
          `${resolvedLanguage}.home_page.${str}`,
          def
        )}`;
      }
      return def;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return def;
    }
  };
  useEffect(() => {
    const offsetHeight = _.get(ref1, "current.offsetHeight", null);
    // console.log({ ref1, offsetHeight });
    const graphHeight = IsNumberChecker(offsetHeight)
      ? Math.ceil(offsetHeight / 2) - 30
      : basicHeight;
    // console.log({graphHeight});
    setHeight(graphHeight);
  }, [ref1.current]);

  // useEffect(() => {
  //   getListSize();
  // }, []);

  // const getListSize = () => {
  //   let { current } = ref;
  //   if (current != null) {
  //     if (current != 0) {
  //       const newHeight = current.clientHeight;
  //       if (newHeight == 0) {
  //         setTimeout(() => {
  //           getListSize();
  //         }, 2000);
  //       } else {
  //         const onediv = newHeight / 3 - 19;
  //         setHeight((onediv * 3) / 2 - 18);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const offsetHeight = detailRef?.current?.offsetHeight;
  //   console.log({
  //     detailRef: detailRef.current,
  //   });

  //   const contentHeight =
  //     IsNumberChecker(offsetHeight2) && IsNumberChecker(offsetHeight3)
  //       ? offsetHeight2 + offsetHeight3
  //       : false;
  //   const differenceHeight =
  //     IsNumberChecker(offsetHeight1) && IsNumberChecker(contentHeight)
  //       ? offsetHeight1 - contentHeight
  //       : false;
  //   const carousalHeight =
  //     IsNumberChecker(offsetHeight2) &&
  //     // IsNumberChecker(offsetHeight3) &&
  //     IsNumberChecker(differenceHeight)
  //       ? offsetHeight2 - differenceHeight
  //       : basicHeight;

  //   const graphHeight = IsNumberChecker(carousalHeight)
  //     ? Math.ceil(carousalHeight / 2)
  //     : basicHeight;

  //   const temp =
  //     IsNumberChecker(graphHeight) && IsNumberChecker(offsetHeight)
  //       ? graphHeight - offsetHeight
  //       : basicHeight;

  //   console.log({
  //     contentHeight,
  //     differenceHeight,
  //     carousalHeight,
  //     graphHeight,
  //     offsetHeight,
  //     finalHeight: temp,
  //   });
  //   setFinalHeight(temp);
  // }, [offsetHeight1, offsetHeight2, offsetHeight3, detailRef.current]);

  if (compareString(page, "my-harvest")) {
    return (
      <div className="row">
        <div className="col-md-6 col-lg-6 right-space-0">
          {IsArrayNotEmpty(harvestData) && (
            <div ref={ref1}>
              <Carousel
                className="home_page_slider"
                activeIndex={cIndex}
                onSelect={handleSelect}
                controls={false}
                interval={width < 500 && autoPlay ? 5000 : null}
              >
                {harvestData.map((item, index) => {
                  const {
                    ReceiptStatus,
                    LoadDeliveryTicketNumber,
                    DeliveryId,
                    RelationshipNumber,
                    Name,
                    IncomingTime,
                    UsableBarrels,
                    ExportBasedOnGrower,
                    beds,
                  } = item;
                  const dataFromProps = IsArrayNotEmpty(asyncData)
                    ? asyncData.find((li) => li.DeliveryId === DeliveryId)
                    : {};
                  const Image = _.get(dataFromProps, "img", null);
                  const Calculation = _.get(
                    dataFromProps,
                    "calculations",
                    null
                  );

                  const Status =
                    ReceiptStatus == "Paid" ||
                    ReceiptStatus == "Released" ||
                    ReceiptStatus == "Inventoried"
                      ? true
                      : false;
                  const Empty =
                    ReceiptStatus != "Paid" &&
                    ReceiptStatus != "Released" &&
                    ReceiptStatus != "Inventoried"
                      ? true
                      : false;
                  return (
                    <Carousel.Item key={index}>
                      <div
                        className={`card border-and-radius-0 bot-mspace-20 remove_card_white_bg d-block w-100 graph_height`}
                      >
                        <div className="card-body home_card_body">
                          <div className="row">
                            <div className="col-5 right-space-0 overflow-hidden">
                              {Image === null ? (
                                <img
                                  src={defaultLoadingImageVertURL}
                                  alt="Recent_Delivery_Image_Loading"
                                  className="w-100"
                                  height={200}
                                />
                              ) : (
                                <>
                                  <span
                                    className={
                                      Status
                                        ? "light_green_bg text-center ver-space-5 d-inline-block width-102 white_color left-border-radius status_style text-uppercase"
                                        : "orange_bg text-center ver-space-5 d-inline-block width-102 white_color left-border-radius status_style text-uppercase"
                                    }
                                  >
                                    {Status
                                      ? LocalTrans("completed", "Completed")
                                      : LocalTrans("pending", "Pending")}
                                  </span>
                                  {
                                    Image.size <= 300  ? <img
                                    src={defaultEventHomeImageURL}
                                    alt="Recent_Delivery_Image_Loading"
                                    className="w-100"
                                    height={window.innerWidth > 772 ? 200 : null}
                                  />
                                  :
                                  <ImageView
                                    url={Image}
                                    setAutoPlay={setAutoPlay}
                                    component="home"
                                  />
                                  }
                                </>
                              )}
                            </div>
                            <div className="col-7">
                              <div className="card-body p-1 ps-3 ps-lg-0">
                                {LoadDeliveryTicketNumber && (
                                  <h4 className="card-title fw-bold mt-2 mt-sm-0 mt-xl-2">
                                    {`${LocalTrans(
                                      "delivery",
                                      "Delivery"
                                    )}: #${LoadDeliveryTicketNumber}`}
                                  </h4>
                                )}
                                {beds && (
                                  <h6 className="horizontal-line bot-space-5">
                                    {`${LocalTrans("bed", "Bed")}: `}
                                    <span className="fw-bold">{beds}</span>
                                  </h6>
                                )}
                                {RelationshipNumber && (
                                  <h6 className="fw-bold">
                                    {RelationshipNumber}
                                  </h6>
                                )}
                                {Name && <h6 className="fw-bold">{Name}</h6>}
                                {IncomingTime && (
                                  <h6>
                                    {`${LocalTrans("date_in", "Date In")}: `}
                                    <span className="fw-bold">
                                      <Moment
                                        utc
                                        format="MM/DD/YYYY [at] HH:mm "
                                      >
                                        {IncomingTime}
                                      </Moment>
                                    </span>
                                  </h6>
                                )}
                                {Empty ? (
                                  <></>
                                ) : (
                                  <>
                                    {UsableBarrels && (
                                      <h6>
                                        {`${LocalTrans(
                                          "usable_barrels",
                                          "Usable Barrels"
                                        )}: `}
                                        <span className="fw-bold">
                                          {UsableBarrels}
                                        </span>
                                      </h6>
                                    )}
                                    {ExportBasedOnGrower && (
                                      <h6>
                                        <span className="fw-bold">
                                          {(ExportBasedOnGrower == "2801" &&
                                            "EU") ||
                                            (ExportBasedOnGrower == "2800" &&
                                              "Non-EU") ||
                                            "NA"}
                                        </span>
                                      </h6>
                                    )}
                                    {IsStringChecker(Calculation) && (
                                      <h6 className="mb-2">{Calculation}</h6>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          <div className="myharvest-view-delivery">
            <Link
              to="/my-harvest/my-delivery-data#recentDelivery"
              className="view-all-data d-none d-md-block text-center fw-bold ver-mspace-10 text-16"
            >
              {LocalTrans("view_all_deliveries", "View All Deliveries")}
            </Link>
          </div>
        </div>
        {/*  Start Graph */}
        <div className="col-md-6 col-lg-6 left-space-0">
          <div className="text-center text-lg-start">
            <h4 className="card-title red_color recent_delivery_text d-inline-block d-sm-none">
              {/* {LocalTrans("harvest_data", "Harvest Data Quick View")} */}
            </h4>
          </div>
          <div className="mob-bottom-line d-block d-sm-none harvest-top"></div>
          <Carousel
            className="home_page_slider"
            controls={false}
            interval={width < 500 && autoPlay ? 5000 : null}
          >
            <Carousel.Item key={0}>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                    <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                      <HarvestGraph
                        category="daily"
                        width="100%"
                        height={height}
                        CommonPageCBRoles={CommonPageCBRoles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item key={1}>
              <div
                id="graphRegionalCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                      <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                        <HarvestGraph
                          category="year"
                          width="100%"
                          height={height}
                          CommonPageCBRoles={CommonPageCBRoles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
          <div className="myharvest-view-harvest">
            <Link
              to="/my-harvest/my-delivery-data#deliveryData"
              className="view-all-data d-none d-md-block text-center fw-bold ver-mspace-10 text-16"
            >
              {LocalTrans("view_all_data", "View All Data")}
            </Link>
          </div>
        </div>
        {/*  End Graph */}
      </div>
    );
  } else {
    return (
      <div
        className={notification == null ? "harvest_top" : "harvest_top_dismiss"}
      >
        <div className="white_bg left_side_block remove_white_bg_mob mob_pm_0 ">
          <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius mt-4 desktop-top-0">
            <img
              src="./assets/images/icon-harvest-data.svg"
              className="svg-image margin-right-10"
            />
            {LocalTrans("harvest_data", "Harvest Data Quick View")}
          </h4>
          <div className="row mt-3">
            <div className="col-md-6 col-lg-6 right-space-0">
              {IsArrayNotEmpty(harvestData) && (
                <div ref={ref1}>
                  <Carousel
                    className="home_page_slider"
                    activeIndex={cIndex}
                    onSelect={handleSelect}
                    controls={false}
                    interval={width < 500 && autoPlay ? 5000 : null}
                  >
                    {harvestData.map((item, index) => {
                      const {
                        ReceiptStatus,
                        LoadDeliveryTicketNumber,
                        DeliveryId,
                        RelationshipNumber,
                        Name,
                        IncomingTime,
                        UsableBarrels,
                        ExportBasedOnGrower,
                        beds,
                      } = item;
                      const dataFromProps = IsArrayNotEmpty(asyncData)
                        ? asyncData.find((li) => li.DeliveryId === DeliveryId)
                        : {};
                      const Image = _.get(dataFromProps, "img", null);
                      const Calculation = _.get(
                        dataFromProps,
                        "calculations",
                        null
                      );

                      const Status =
                        ReceiptStatus == "Paid" ||
                        ReceiptStatus == "Released" ||
                        ReceiptStatus == "Inventoried"
                          ? true
                          : false;
                      const Empty =
                        ReceiptStatus != "Paid" &&
                        ReceiptStatus != "Released" &&
                        ReceiptStatus != "Inventoried"
                          ? true
                          : false;
                      return (
                        <Carousel.Item key={index}>
                          <div
                            className={`card border-and-radius-0 bot-mspace-20 remove_card_white_bg d-block w-100 graph_height`}
                          >
                            <div className="card-body home_card_body">
                              <div className="row">
                                <div className="col-5 right-space-0 overflow-hidden">
                                  {Image === null ? (
                                    <img
                                      src={defaultLoadingImageVertURL}
                                      alt="Recent_Delivery_Image_Loading"
                                      className="w-100"
                                    />
                                  ) : (
                                    <>
                                      <span
                                        className={
                                          Status
                                            ? "light_green_bg text-center ver-space-5 d-inline-block width-102 white_color left-border-radius status_style text-uppercase"
                                            : "orange_bg text-center ver-space-5 d-inline-block width-102 white_color left-border-radius status_style text-uppercase"
                                        }
                                      >
                                        {Status
                                          ? LocalTrans("completed", "Completed")
                                          : LocalTrans("pending", "Pending")}
                                      </span>
                                      {
                                        Image.size <= 300 ?  <img
                                        src={defaultEventHomeImageURL}
                                        alt="Recent_Delivery_Image_Loading"
                                        className="w-100"
                                      /> 
                                      : 
                                      <ImageView
                                        url={Image}
                                        setAutoPlay={setAutoPlay}
                                        component="home"
                                      />
                                      }
                                    </>
                                  )}
                                </div>
                                <div className="col-7">
                                  <div className="card-body p-1 ps-3 ps-lg-0">
                                    {LoadDeliveryTicketNumber && (
                                      <h4 className="card-title fw-bold mt-2 mt-sm-0 mt-xl-2">
                                        {`${LocalTrans(
                                          "delivery",
                                          "Delivery"
                                        )}: #${LoadDeliveryTicketNumber}`}
                                      </h4>
                                    )}
                                    {beds && (
                                      <h6 className="horizontal-line bot-space-5">
                                        {`${LocalTrans("bed", "Bed")}: `}
                                        <span className="fw-bold">{beds}</span>
                                      </h6>
                                    )}
                                    {RelationshipNumber && (
                                      <h6 className="fw-bold">
                                        {RelationshipNumber}
                                      </h6>
                                    )}
                                    {Name && (
                                      <h6 className="fw-bold">{Name}</h6>
                                    )}
                                    {IncomingTime && (
                                      <h6>
                                        {`${LocalTrans(
                                          "date_in",
                                          "Date In"
                                        )}: `}
                                        <span className="fw-bold">
                                          <Moment
                                            utc
                                            format="MM/DD/YYYY [at] HH:mm "
                                          >
                                            {IncomingTime}
                                          </Moment>
                                        </span>
                                      </h6>
                                    )}
                                    {Empty ? (
                                      <></>
                                    ) : (
                                      <>
                                        {UsableBarrels && (
                                          <h6>
                                            {`${LocalTrans(
                                              "usable_barrels",
                                              "Usable Barrels"
                                            )}: `}
                                            <span className="fw-bold">
                                              {UsableBarrels}
                                            </span>
                                          </h6>
                                        )}
                                        {ExportBasedOnGrower && (
                                          <h6>
                                            <span className="fw-bold">
                                              {(ExportBasedOnGrower == "2801" &&
                                                "EU") ||
                                                (ExportBasedOnGrower ==
                                                  "2800" &&
                                                  "Non-EU") ||
                                                "NA"}
                                            </span>
                                          </h6>
                                        )}
                                        {IsStringChecker(Calculation) && (
                                          <h6 className="mb-2">
                                            {Calculation}
                                          </h6>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              )}
              <div className="home-view-delivery">
                <Link
                  to="/my-harvest/my-delivery-data#recentDelivery"
                  className="view-all-data d-none d-md-block text-center fw-bold ver-mspace-10 text-16"
                >
                  {LocalTrans("view_all_deliveries", "View All Deliveries")}
                </Link>
              </div>
            </div>
            {/*  Start Graph */}
            <div className="col-md-6 col-lg-6 left-space-0">
              <div className="text-center text-lg-start mt-2 mt-sm-0">
                <h4 className="card-title red_color recent_delivery_text d-inline-block d-sm-none mb-4">
                  {/* {LocalTrans("harvest_data", "Harvest Data Quick View")} */}
                </h4>
              </div>
              <div className="mob-bottom-line d-block d-sm-none harvest-top"></div>
              {/* <div className="text-center tabs_block">
                <ul
                  className="nav nav-pills mb-3 d-inline-flex"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${!toggelGraph ? " active" : ""} `}
                      id="pills-regional-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-regional"
                      type="button"
                      role="tab"
                      aria-controls="pills-regional"
                      aria-selected="true"
                      onClick={handlerShowHideGraph}
                      data-action="day"
                    >
                      {LocalTrans("total_barrel_by_day_new", "Daily - Last 7 Days")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${toggelGraph ? " active" : ""} `}
                      id="pills-myfarm-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-myfarm"
                      type="button"
                      role="tab"
                      aria-controls="pills-myfarm"
                      aria-selected="false"
                      onClick={handlerShowHideGraph}
                      data-action="year"
                    >
                      {LocalTrans("total_barrel_by_year_new", "Crop Year to Date")}
                    </button>
                  </li>
                </ul>
              </div> */}
              {/* <div
                className="tab-content tabs_content_block"
                id="pills-tabContent"
              >
                <div
                  className={`tab-pane fade ${
                    !toggelGraph ? "show active" : ""
                  } `}
                  id="pills-regional"
                  role="tabpanel"
                  aria-labelledby="pills-regional-tab"
                >
                  <div
                    id="graphCarousel"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="false"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                          <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                            <HarvestGraph
                              category="daily"
                              width="100%"
                              height={height}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${toggelGraph ? "show active" : ""} `}
                  id="pills-myfarm"
                  role="tabpanel"
                  aria-labelledby="pills-myfarm-tab"
                >
                  <div
                    id="graphRegionalCarousel"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="false"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                          <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                            <HarvestGraph
                              category="year"
                              width="100%"
                              height={height}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <Carousel
                className="home_page_slider"
                controls={false}
                interval={width < 500 && autoPlay ? 5000 : null}
              >
                <Carousel.Item key={0}>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                        <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                          <HarvestGraph
                            category="daily"
                            width="100%"
                            height={height}
                            CommonPageCBRoles={CommonPageCBRoles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item key={1}>
                  <div
                    id="graphRegionalCarousel"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="false"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="card border-and-radius-0 bot-mspace-20 remove_card_white_bg graph_height">
                          <div className="card-body home_card_body left-mspace-0 space-10 bot-space-10 top-space-15 d-flex justify-content-center">
                            <HarvestGraph
                              category="year"
                              width="100%"
                              height={height}
                              CommonPageCBRoles={CommonPageCBRoles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
              <div className="home-view-harvest">
                <Link
                  to="/my-harvest/my-delivery-data#deliveryData"
                  className="view-all-data d-none d-md-block text-center fw-bold ver-mspace-10 text-16"
                >
                  {LocalTrans("view_all_data", "View All Data")}
                </Link>
              </div>
            </div>
            {/*  End Graph */}
          </div>
        </div>
      </div>
    );
  }
};

export default Harvest;
