import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion } from "react-bootstrap";
import MultiDropDown from "../../../selectBox/multiDropDown";
import DataGridPagination from "../../../global/dataGridPagination";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import CurrentLocation from "../../../global/currentLocation";
import {
  sortByField,
  IsTokenChecker,
  IsNumberChecker,
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  toDecimal,
  getCurrentYear,
  getYearRange,
  StringToInteger,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  toDecimalORCurrency,
} from "../../../../utils/helper";
import Loading from "../../../global/loading";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import ExportFile from "../../../exportFile/exportFile";
import PdfFile from "../../../exportFile/pdfFile";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
} from "../../../../permissionWrapper";
import { useGbsDataCache } from "../../../../services/gbsDataCache";
import CommonStockQuotaGraph, { NumberToNormal, NumberFormaterWithValidation, WholeRateValidation, PercentCalculator } from "./commonStockQuotaGraph";

const EquityQuota = (props) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const { DropDownCBRoles = [] } = props;

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);
  const tokenId = useSelector((state) => state.reducerToken);

  const [loading, setLoading] = useState(true);
  const [circleLoader, setCircleLoader] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);

  const [reqContract, setReqContracts] = useState({});
  const [defaultContract, setDefaultContract] = useState([]);
  const [contracts, setContracts] = useState([]);

  const [clrBtnDisabled, setClrBtnDisabled] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const [hideExport, setHideExport] = useState(false);

  const [tableList, setTableList] = useState([]);
  const [countKeeper, setCountKeeper] = useState(null);

  const [freshPage, setFreshPage] = useState(true);

  const [isMobFilter, setisMobFilter] = useState(false);
  const MobileViewRenderer = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data.data) {
      fetchTableResultData(data);
    }
    if (status === "error") {
      setLoading(false);
      setTableList([]);
      setCountKeeper(null);
      setHideExport(true);
      // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchContract();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId, personId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const onlyUnique = (value, index, self) => {
    try {
      return self.indexOf(value) === index;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const setObjArrToString = (val) => {
    try {
      if (IsArrayNotEmpty(val)) {
        let ids = val.map((x) => x.id);
        ids = ids.filter((x) => x != "all");
        return `${ids}`;
      } else {
        return ``;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return ``;
    }
  };

  const safeStringRenderer = (str) => {
    try {
      if (IsStringChecker(str)) {
        return str;
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const joinArrToString = (arr = []) => {
    try {
      if (IsArrayNotEmpty(arr)) {
        return arr.join();
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return ``;
    }
  };

  const setOptions = (data, keyPrram, unique, concatValue1, concatValue2) => {
    try {
      if (unique && keyPrram !== "RelationshipId") {
        return data
          .map((li) => li[keyPrram])
          .filter(onlyUnique)
          .map((li) => {
            return { name: li, id: li };
          });
      } else {
        const contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
                name: li[concatValue1] + " " + li[concatValue2],
                id: li[keyPrram],
              }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
        return [
          ...new Map(contracts.map((item) => [item["id"], item])).values(),
        ];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const fetchContract = async () => {
    if (IsNumberChecker(personId)) {
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `persons/${personId}/contracts`
      )
        .then((resp) => {
          const data = safeArrayCheck(_.get(resp, `data`, []));
          let filteredData = FilterContractByRole(
            data,
            accounts,
            DropDownCBRoles
          );
          filteredData = _.orderBy(
            filteredData,
            ["ContractStatus", "RelationshipNumber"],
            ["asc", "asc"]
          );
          const options = IsArrayNotEmpty(filteredData)
            ? setOptions(
                filteredData,
                "RelationshipId",
                false,
                "RelationshipNumber",
                "Name"
              )
            : [];
          setContracts(options);
          if (options.length === 1) {
            const tempDefaultContract = _.get(options, `0.id`, null);
            setDefaultContract(
              tempDefaultContract ? [tempDefaultContract] : []
            );
            setReqContracts(
              tempDefaultContract ? { contract: `${tempDefaultContract}` } : {}
            );
            setClrBtnDisabled(false);
            setSubmitBtnDisabled(false);
          } else {
            setDefaultContract([]);
            setReqContracts({});
          }
        })
        .catch((error) => {
          setContracts([]);
          setDefaultContract([]);
          setReqContracts({});
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
          MultiDropDownFix();
        });
    }
  };

  const FetchButtonHandler = (contract = false) => {
    // console.log({ contract });
    if (IsStringChecker(contract)) {
      setSubmitBtnDisabled(false);
      setClrBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
      setClrBtnDisabled(true);
    }
  };

  const onMultiSelectChange = (selectedList, selectedItem, type) => {
    const list = setObjArrToString(selectedList);
    if (type === "contract") {
      FetchButtonHandler(list);
    }
    ClearTableData();
  };

  const onMultiSelectRemove = (removedList, removedItem, type) => {
    const removed = _.get(removedItem, "id", null);
    const list = setObjArrToString(removedList);
    if (removed === "all" || !IsArrayNotEmpty(removedList)) {
      FetchButtonHandler();
    } else {
      if (type === "contract") {
        FetchButtonHandler(list);
      }
    }
    ClearTableData();
  };

  const fetchTableData = async () => {
    let contract = _.get(reqContract, "contract", null);
    contract = _.orderBy(contract.split(","), [], ["asc"]).toString();
    const cropyear = joinArrToString(getYearRange(getCurrentYear(), 5));

    if (IsTokenChecker(tokenId, contract, cropyear)) {
      setLoading(true);
      setCircleLoader(true);

      const reqURL = `equity-quotas?relationshipid=${contract}&cropyear=${cropyear}`;
      setSearchURL(reqURL);
      reqURL === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const fetchTableResultData = (res) => {
    var final_array = safeArrayCheck(
      sortByField(_.get(res, "data", []), "CropYear", -1)
    );
    var resArr = IsArrayNotEmpty(final_array)
      ? final_array.map((item, index) => {
          const {
            RelationshipId: ContractId = null,
            Name: ContractName = null,
            RelationshipNumber: Contract = null,
            RelationshipSuffix: ContractSuffix = null,
            Quota = null,
            ActualDeliveries = null,
            AverageDeliveries = null,
            CropYear = null,
            // PercentToQuota,
            CommonStockShares = null,
          } = item;
          const PercentToQuota = PercentCalculator(NumberToNormal(CommonStockShares),NumberToNormal(Quota)) || null;
          const percent_to_quota = PercentToQuota ? NumberFormaterWithValidation(
            `${PercentToQuota}`,
            WholeRateValidation,
            0,
            100
          ) : "0";
          return {
            Index: index,
            ContractId,
            ContractName: safeStringRenderer(ContractName),
            Contract: safeStringRenderer(Contract),
            ContractSuffix: safeStringRenderer(ContractSuffix),
            CropYear: StringToInteger(CropYear) || null,
            Quota: IsNumberChecker(Quota) ? toDecimal(Quota, 2) : null,
            ActualDeliveries: IsNumberChecker(ActualDeliveries)
              ? toDecimal(ActualDeliveries, 2)
              : null,
            AverageDeliveries: IsNumberChecker(AverageDeliveries)
              ? toDecimal(AverageDeliveries, 2)
              : null,
            PercentToQuota: percent_to_quota == 0 ?  null : `${percent_to_quota}%`,
            CommonStockShares: IsNumberChecker(CommonStockShares)
              ? CommonStockShares > 0
                ? toDecimal(CommonStockShares, 0)
                : null
              : null,
          };
        })
      : [];

    // console.log("resArr => ", reqContract.contract.split(",").length);
    const result_Arr = IsArrayNotEmpty(resArr)
      ? reqContract.contract.split(",").length === 1
        ? _.orderBy(resArr, ["CropYear", "Contract"], ["desc", "asc"])
        : _.orderBy(resArr, ["Contract", "CropYear"], ["asc", "desc"])
      : [];
    setTableList(result_Arr);

    const length = _.get(resArr, "length", 0);
    const count = IsNumberChecker(length) && length > 0;
    setCountKeeper(count ? length : null);
    setHideExport(count ? false : true);
    setLoading(false);
    setCircleLoader(false);
    MobileViewRenderer("cancel");
    setFreshPage(false);
  };

  const ArrangedData = (resultData) => {
    let arrangedData = resultData.map((li) => {
      const {
        ContractName = null,
        Contract = null,
        ContractSuffix = null,
        CropYear = null,
        Quota = null,
        ActualDeliveries = null,
        AverageDeliveries = null,
        CommonStockShares = null,
        PercentToQuota = null,
      } = li;
      return {
        "Crop Year": CropYear,
        "Contract Number": Contract,
        "Contract Suffix": ContractSuffix,
        "Contract Name": ContractName,
        Barrels: ActualDeliveries,
        "Common Stock Shares": CommonStockShares,
        "Equity Quota": Quota,
        "Average Deliveries": AverageDeliveries,
        "% To Quota": PercentToQuota,
      };
    });
    return arrangedData;
  };

  const onClearSearch = () => {
    setSubmitBtnDisabled(true);
    setClrBtnDisabled(true);

    setReqContracts({});
    setDefaultContract([]);

    ClearTableData();

    MultiDropDownFix();
  };

  const ClearTableData = () => {
    setCountKeeper(null);
    setTableList([]);
    setHideExport(true);
    setFreshPage(true);
  };

  // console.log({
  //   reqContract,
  //   reqCropyear,
  //   currentCropYear,
  //   defaultContract,
  //   tableList,
  //   countKeeper,
  // });

  const columns = [
    {
      key: "Contract",
      name: "Contract #",
      width: 100,
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.Contract", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractSuffix",
      name: "Contract Suffix",
      headerCellClass: "enablesTextWrappingHeading",
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
      width: 90,
      formatter(props) {
        let temp = _.get(props, "row.ContractSuffix", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: 175,
      headerCellClass: "enablesTextWrappingHeading border-right",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      width: 90,
      headerCellClass: "enablesTextWrappingHeading",
      formatter(props) {
        let temp = _.get(props, "row.CropYear", "-");
        return temp || "-";
      },
    },
    {
      key: "ActualDeliveries",
      name: "Barrels",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        // console.log(_.get(props, "row.ActualDeliveries", "-"));
        return _.get(props, "row.ActualDeliveries", "-") !== "-"
          ? toDecimalORCurrency(
              _.get(props, "row.ActualDeliveries", "-").replace(",", ""),
              2
            )
          : " -";
      },
    },    
    {
      key: "AverageDeliveries",
      name: "Average Deliveries",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return _.get(props, "row.AverageDeliveries", "-") !== "-"
          ? toDecimalORCurrency(
              _.get(props, "row.AverageDeliveries", "-").replace(",", ""),
              2
            )
          : " -";
        // let temp = toDecimalORCurrency(_.get(props, "row.AverageDeliveries"));
        // return temp || "-";
      },
    },    
    {
      key: "Quota",
      name: "Common Stock Quota",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.Quota", "-");
        return temp || "-";
      },
    },
    {
      key: "CommonStockShares",
      name: "Common Stock Shares",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.CommonStockShares", "-");
        return temp || "-";
      },
    },
    {
      key: "PercentToQuota",
      name: "% To Quota",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.PercentToQuota", "-");
        return temp || "-";
      },
    },
  ];
  const noData = IsNumberChecker(countKeeper) && !IsArrayNotEmpty(tableList);
  const noDataMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.statement_msg`,
    "No Statement Found"
  )}`;
  const percentToQuotaMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.percent_to_quota_msg`,
    "% To Quota will only display for active contracts holding common stock."
  )}`;
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {(loading || status === "fetching") && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => MobileViewRenderer("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => MobileViewRenderer("filter")}
        >
          <span className="fw-bold px-2">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={contracts}
                    prompt="Select"
                    value={reqContract}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "contract"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "contract")
                    }
                    label="contract"
                    usestate={setReqContracts}
                    disabled=""
                    preSelectedOpt={defaultContract}
                    optionNotFound={{ contract: noContractsFound }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${
              isMobFilter ? "show d-block " : " d-lg-none "
            }`}
          >
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={fetchTableData}
              disabled={submitBtnDisabled}
            >
              {`Apply `}
              {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={fetchTableData}
                disabled={submitBtnDisabled}
              >
                {`Search `}
                {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={() => onClearSearch()}
                disabled={clrBtnDisabled}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </nav>
      <CommonStockQuotaGraph
        defaultContract={defaultContract}
        contractObj={reqContract}
        contracts={contracts}
        countKeeper={countKeeper}
        tableList={tableList}
      />
      {IsNumberChecker(countKeeper) && !displayAccordian && (
        <div className="p-2 bg-light text-dark text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
          <i className="fa fa-info-circle pe-2"></i>
          {percentToQuotaMsg}
        </div>
      )}
      {IsNumberChecker(countKeeper) && (
        <div className="row mt-2 hidden_print">
          <div
            className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} ${
              displayAccordian && "d-lg-none"
            }`}
          >
            <div className="float-start">
              <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
                Total Result Count: {countKeeper}
              </h5>
            </div>
          </div>
          <div
            className={`col-6 ${displayAccordian && "col-12"} d-none ${
              hideExport ? "" : "d-lg-block"
            } `}
          >
            <div className="float-end mb-2">
              <span>
                <PdfFile
                  pdfData={ArrangedData(tableList)}
                  fileName="EquityQuota"
                />
              </span>
              <span>
                <ExportFile
                  downloadFormate="csv"
                  exportData={ArrangedData(tableList)}
                  fileName="EquityQuota"
                />
              </span>
              <span>
                <ExportFile
                  downloadFormate="xlsx"
                  exportData={ArrangedData(tableList)}
                  fileName="EquityQuota"
                />
              </span>
            </div>
          </div>
        </div>
      )}
      {IsNumberChecker(countKeeper) && !displayAccordian && (
        <div className="visible_print_mob_desktop hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
          {IsArrayNotEmpty(tableList) && (
            <DataGridPagination
              uniqueIdentifier="Index"
              gridData={tableList}
              columns={columns}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              autoRowHeight={true}
              paddingRowHeight={10}
              dynamicHeaderHeight={70}
            />
          )}
          {noData && (
            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
              <i className="fa fa-info-circle pe-2"></i>
              {noDataMsg}
            </div>
          )}
        </div>
      )}
      <div
        className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${
          !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
        }`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          <Accordion
            defaultActiveKey="0"
            className="table mobile-accordion accordion_space"
          >
            {IsNumberChecker(countKeeper) && (
              <div className="p-2 bg-light text-dark red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {percentToQuotaMsg}
              </div>
            )}
            {IsNumberChecker(countKeeper) && (
              <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
                Total Result Count:{countKeeper}
              </h5>
            )}
            {IsArrayNotEmpty(tableList) ? (
              tableList.map((data, index) => {
                const {
                  ContractName = null,
                  Contract = null,
                  ContractSuffix = null,
                  CropYear = null,
                  Quota = null,
                  ActualDeliveries = null,
                  AverageDeliveries = null,
                  PercentToQuota = null,
                  CommonStockShares = null,
                } = data;
                return (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>
                      {`Contract #: ${Contract || "-"}`}
                      <br />
                      {`Crop Year: ${CropYear || "-"}`}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td"></div>
                          <div className="td" data-header="Contract #:">
                            <span>{Contract || "-"}</span>
                          </div>
                          <div className="td" data-header="Contract Suffix:">
                            <span>{ContractSuffix || "-"}</span>
                          </div>
                          <div className="td" data-header="Contract Name:">
                            <span>{ContractName || "-"}</span>
                          </div>
                          <div className="td" data-header="Crop Year:">
                            <span>{CropYear || "-"}</span>
                          </div>
                          <div className="td" data-header="Barrels:">
                            <span>
                              {toDecimalORCurrency(
                                ActualDeliveries.replace(",", ""),
                                2
                              ) || "-"}
                            </span>
                          </div>
                          <div className="td" data-header="Average Deliveries:">
                            <span>
                              {toDecimalORCurrency(
                                AverageDeliveries.replace(",", ""),
                                2
                              ) || "-"}
                            </span>
                          </div>
                          <div className="td" data-header="Common Stock Quota:">
                            <span>{Quota || "-"}</span>
                          </div>
                          <div
                            className="td"
                            data-header="Common Stock Shares:"
                          >
                            <span>{CommonStockShares || "-"}</span>
                          </div>
                          <div className="td" data-header="% To Quota:">
                            <span>{PercentToQuota || "-"}</span>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            ) : freshPage ? (
              <></>
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {noDataMsg}
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default EquityQuota;
