import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HandleErrorTrackAndToast, IsTokenChecker } from "../../utils/helper";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "./loading";
// import Icon_webcam from '../../../public/assets/images/icon_webcam.svg';

const WebCams = () => {
    const idToken = useSelector((state) => state.reducerToken);
    const wc = useRef(null);
    const webImg1 = useRef(null);
    const webImg2 = useRef(null);
    const dispatch = useDispatch();
    const { accounts } = useMsal();
    const [showWebcam, setShowWebCam] = useState([]);
    const [webImages, setWebImages] = useState([]);
    const webImagesStatic = [];
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        IsTokenChecker(idToken) && getContentBlock();
        setInterval(() => {
            if (webImagesStatic.length > 0 && webImg1.current !== null && webImg2.current !== null) {
                webImg1.current.attributes.src.value = `${webImagesStatic[0]}?v=${new Date().getTime()}`;
                webImg2.current.attributes.src.value = `${webImagesStatic[1]}?v=${new Date().getTime()}`;
            }
        }, 30000);
    }, [idToken]);

    const getContentBlock = async () => {
        setLoader(true);
        await cmsDataCommonMethod(
            idToken,
            accounts,
            `call_to_actions?select=*,is_active,page_listings!inner(slug)&page_listings.slug=eq.webcams&is_active=eq.true&order=order_by.asc`
        )
            .then((resp) => {
                if (resp?.data?.data?.length > 0) {
                    setShowWebCam(resp?.data?.data || []);
                    setWebImages([resp?.data?.data[0].feature_image_url, resp?.data?.data[0].url]);
                    //webImagesStatic.push(resp?.data?.data[0].feature_image_url);
                    //webImagesStatic.push(resp?.data?.data[0].url);
                }
                else {
                    setShowWebCam([]);
                    setWebImages([]);
                }
                setLoader(false);
            })
            .catch((e) => {
                setLoader(false);
                HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
            })
            .finally(() => {
                setLoader(false);
            });
    };
    return (<>
        {loader && (
            <div className="zindex10000">
                {" "}
                <div className="nav-mobile-overlay-display"></div>
                <Loading />
            </div>
        )}
        {
            showWebcam.length > 0 && (showWebcam[0].feature_image_url !=='' || showWebcam[0].url !=='')?
                < Accordion.Item eventKey="webcams" className="hidden_print" >
                    <Accordion.Header>
                        <img
                            src={'../assets/images/icon_webcam.svg'}
                            className="img-30 right-space-10"
                            alt="acerage_icon"
                        />{" "}
                        Webcams
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row">
                            {webImages[0] !== '' &&
                                <div className="col-12 col-lg-6 bot-mspace-20">
                                    <h5 className="red_color">{showWebcam[0].title_en}</h5>
                                    <iframe ref={wc}
                                        src="https://cam.oceanspray.io/proxied1/"
                                        className="img-fluid border-radius-6"
                                    > 
                                    </iframe>
                                </div>
                            }

                            {webImages[1] !== '' && <div className="col-12 col-lg-6 bot-mspace-20">
                                <h5 className="red_color">{showWebcam[0].subtitle_en}</h5>
                                <iframe ref={wc}
                                    src="https://cam.oceanspray.io/proxied2/"
                                    className="img-fluid border-radius-6"
                                > 
                                </iframe>
                            </div>
                            }
                        </div>
                    </Accordion.Body>
                </Accordion.Item >
                : <></>
        }
    </>
    )
}
export default WebCams