/* eslint-disable */
import React, { useEffect, useState } from "react";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import MultiDropDown from "../../../selectBox/multiDropDown";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import DataGridPagination from "../../../global/dataGridPagination";
import EquityBalanceMobileGrid from "../../../global/equityBalanceMobileGrid";
import ExportFile from "../../../exportFile/exportFile";
import PdfFile from "../../../exportFile/pdfFile";
import {
  IsTokenChecker,
  toDecimal,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsArrayNotEmpty,
} from "../../../../utils/helper";
import { dateFormate, notFoundMsg } from "../../../../utils/constantsVariable";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../../permissionWrapper";
import CurrentLocation from "../../../global/currentLocation";
import { useTranslation } from "react-i18next";
import { useGbsDataCache } from "../../../../services/gbsDataCache";
import Loading from "../../../global/loading";
import MyDataGrid, { MasterDetail } from "./table";
import moment from "moment";

const EquityBalances = (props) => {
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const [multiContId, setMultiContId] = useState({});
  const [resultContract, setResultContract] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [contractIds, setContractIds] = useState();
  const [quotas, setQoutas] = useState([]);
  const [exportData, setExportData] = useState();
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [loader, setLoader] = useState(false);
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const [preSelectedContract, setPreSelectedContract] = useState([]);
  const [clearStatus, setClearStatus] = useState(true);
  const { DropDownCBRoles = [] } = props;

  const [AREexportDetailData, setAREexportDetailData] = useState();
  const [cropYearList, setCropYearList] = useState([]);

  const columns = [
    {
      key: "Contract Number",
      name: "Contract Number",
      width: 165,
    },
    {
      key: "Contract Name",
      name: "Contract Name",
      cellClass: "enablesTextWrapping",
      width: 180,
    },
    {
      key: "Equity Type",
      name: "Equity Type",
      cellClass: "enablesTextWrapping",
      width: 160,
    },
    {
      key: "Form Type",
      name: "Form Type",
      width: 160,
    },
    {
      key: "Shares",
      name: "Shares",
      width: 130,
      cellClass: "text-lg-end",
      formatter(props) {
        return (
          <>{props.row.Shares == 0 ? "-" : toDecimal(props.row.Shares, 3)}</>
        );
      },
    },
    {
      key: "Value",
      name: "Value",
      width: 160,
      formatter(props) {
        return <>{toDecimal(props.row.Value.toFixed(2), 3, "$")}</>;
      },
    },
  ];

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  const [masterData, setMasterData] = useState([]);
  useEffect(() => {
    if (data.data) {
      getAllRecords(data);
      const quotas = data.data.map((i, k) => {
        return {
          ...i,
          "expanded": false,
          "type": "MASTER",
          "id": k
        }
      });
      setMasterData([...quotas])
    }
    if (status === "error") {
      setLoading(false);
      setLoader(false);
      // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      const fetchContract = async () => {
        setLoader(true);
        await gbsDataCommonMethod(
          tokenId,
          accounts,
          `persons/${personId}/contracts`
        )
          .then((resp) => {
            let resultData = safeArrayCheck(resp?.data);
            let filteredData = IsArrayNotEmpty(resultData)
              ? FilterContractByRole(resultData, accounts, DropDownCBRoles)
              : [];
            filteredData = _.orderBy(filteredData,
              ["ContractStatus", "RelationshipNumber"],
              ["asc", "asc"]
            );
            if (IsArrayNotEmpty(filteredData)) {
              if (filteredData.length === 1) {
                // console.log({ filteredData });
                const defaultContractNumber = filteredData[0]?.RelationshipId;
                setClearStatus(false);
                setTimeout(() => {
                  setPreSelectedContract([`${defaultContractNumber}`]);
                  setMultiContId({
                    "r.id": `${defaultContractNumber}`,
                  });
                  setContractIds(`${defaultContractNumber}`)
                  setClearStatus(true);
                  setIsReset(false);
                  setSearchBtnDisabled(false);
                }, 0);
              }
              setResultContract(filteredData);
            }
          })
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          })
          .finally(() => {
            setLoader(false);
          });
      };
      fetchContract();

      const fetchCropYear = async () => {
        await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
          .then((resp) => {
            const data = safeArrayCheck(_.get(resp, `data`, []));
            if (IsArrayNotEmpty(data)) {
              const cropYears = data.splice(0, 15);
              setCropYearList(cropYears.map(i => i.CropYear))
            } else {
              setCropYearList([]);
            }
          })
          .catch((error) => {
            setCropYearList([]);
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      };

      fetchCropYear();

      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
    // eslint-disable-next-line
  }, [tokenId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const handleReset = (e) => {
    e.stopPropagation();
    setNoResultsFound(false);
    setIsReset(true); /*disabled Reset button*/
    setMultiContId(null); /*set null to contract filter */
    setSearchBtnDisabled(true); /*disabled search button*/
    setQoutas([]);
    setPreSelectedContract([]);
    setContractIds(null);

    //setFilterItems([]); /*set null to grid */
  };
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  /*multi select ContractId*/
  const multiSelectContractId = [
    ...new Map(
      Object.values(resultContract).map((item) => [
        item.RelationshipId,
        {
          name: item.RelationshipNumber + " " + item.Name,
          id: item.RelationshipId,
        },
      ])
    ).values(),
  ];
  const handlerContract = (val) => {
    setQoutas([]);
    setNoResultsFound(false);
    setSearchBtnDisabled(false);
    setIsReset(false);
    let ids = _.orderBy(val, ["id"], ["asc"]).map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    setContractIds(ids);
    // console.log("ids" + ids);
  };

  const handlerSubmit = async () => {
    setLoading(true);
    let url = `equity-balances?contractid=${contractIds}`;
    await setSearchURL(url);
    url === searchURL && setChangeEvent(changeEvent + 1);
  };

  const getAREDetailsData = async (contracts) => {
    var resp = await Promise.all(
      contracts.split(",").map(async (contractId) => {
        return await gbsDataCommonMethod(tokenId, accounts, `equity-detail/areRedemption?relationshipid=${contractId}&cropyear=${cropYearList.toString()}`)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    // if (resp.length > 0) {
    //   console.log("resp", resp)
    // }
    return resp.flatMap((i)=>i) || [];
  }
  const getAllRecords = async (resp) => {
    var array = [];
    resp.data.length > 0 ? setNoResultsFound(false) : setNoResultsFound(true);
    const detailData = [];
    const AREDetailData = await getAREDetailsData(contractIds);
    //console.log("AREDetailData",AREDetailData)
    resp.data.map((item, key) => {
      array.push({
        "Contract Number": `${item.RelationshipNumber}-${item.RelationshipSuffix}`,
        "Contract Name": item.Name,
        "Equity Type": item.EquityType,
        "Form Type": item.FormType,
        Shares: item.Shares,
        Value: item.Value,
        expanded: false,
        type: 'MASTER',
        id: key
      });

      // if (item.EquityType === "ARE")
      //   for (let i = 0; i < 10; i++) {
      //     detailData.push({
      //       "Contract Number": item.RelationshipNumber,
      //       "Contract Name": item.Name,
      //       Date: moment().add(i, 'days').format(dateFormate),
      //       "Crop Year": 2021,
      //       "Equity Type": item.FormType,
      //       ARE: item.Value / i + 1,
      //       Description: `2021 Cranberry pool ARE Issuance`,
      //     });
      //   }
    });
    AREDetailData.map((item)=>{
      const contract = resultContract.filter(i=>i.RelationshipId === item.RelationshipId);
      // console.log("detailData contract", contract)
      detailData.push({
        "Contract Number": contract[0]?.RelationshipNumber,
        "Contract Name": contract[0]?.Name,
        Date: dateFormat(item.Date),
        "Crop Year": item.CropYear,
        "Equity Type": item.EquityType,
        ARE: item.AreRedemption,
        Description: item.Description,
        isNegative: item.AreRedemption < 0,
        posNeg:Math.abs(item.AreRedemption)
      });
    });
    
    const groupContract = _.groupBy(detailData,"Contract Number");
    const actualContent = [];
   // console.log("groupContract",groupContract);
    Object.keys(groupContract).map(i=>{
      const groupCropYear = _.groupBy(groupContract[i],"Crop Year");
        Object.keys(groupCropYear).map(k=>{          
          let sum = _.sumBy(groupCropYear[k],'ARE').toFixed(2);          
          if(parseFloat(sum) > 0 ){
            var b = _.groupBy(groupCropYear[k],"posNeg");
            Object.keys(b).map(o=>{
              if(_.sumBy(b[o],'ARE').toFixed(2) !== "0.00"){
                let ar = b[o].map(l=>{
                  delete l.isNegative;
                  delete l.posNeg;
                  return l;
                });
                actualContent.push(...ar);
              }              
            });
          }
        });
    });
    setQoutas(array);
    setExportData(array);
    setAREexportDetailData(actualContent);
    setLoading(false);
    setIsReset(false);
    setisMobFilter(false);
  };

  const dateFormat = (date) =>{
    date = date?.split("T")[0]?.split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  }

  const handlerContractDel = async (val, selectedItem) => {
    setQoutas([]);
    setNoResultsFound(false);
    if (val.length === 0 || selectedItem.id === "all") {
      setSearchBtnDisabled(true);
    } else {
      setSearchBtnDisabled(false);
      setIsReset(false);
      let ids = _.orderBy(val, ["id"], ["asc"]).map((x) => x.id);
      ids = ids.filter((x) => x != "all" && x).toString();
      setContractIds(ids);
    }
  };

  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const formatArray = [
    {
      index: 5,
      format: "#,##0.00_);($#,##0.00)",
    },
  ]
  return (
    <>

      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2 hidden_print">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${isMobFilter ? "active" : ""
            }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${isMobFilter ? "show" : ""
            }`}
          id="selectWrap2" /*style={{ display:"none !important"}}*/
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {clearStatus && (
                  <MultiDropDown
                    optionList={multiSelectContractId}
                    prompt="All"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="r.id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={preSelectedContract}
                    optionNotFound={{ "r.id": noContractsFound }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              {/*Loader on search button*/}
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                data-category="Web"
                disabled={searchBtnDisabled}
              >
                Search{" "}
                {loading || status === "fetching" ? <WhiteCircleLoader /> : ""}{" "}
              </button>

              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${isMobFilter ? "show d-block " : " d-lg-none "
            }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            data-category="mobile"
            disabled={searchBtnDisabled}
          >
            Apply{" "}
            {loading || status === "fetching" ? <WhiteCircleLoader /> : ""}
          </button>
        </div>
      </nav>
      {quotas.length > 0 ? (
        <div className="row mt-2 hidden_print hor-space-20">
          {!displayAccordian && (
            <div className={`col-12 col-lg-6 ${displayAccordian && "col-12"
              } d-lg-block d-none`}>
              <div className="float-start">
                <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
                  Total Result Count : {quotas.length}
                </h5>
              </div>
            </div>
          )}
          <div
            className={`col-6 ${displayAccordian && "col-12"
              } d-lg-block d-none`}
          >
            <div className="float-end mb-2">
              <span>
                <PdfFile
                  tooltip={"Print"}
                  pdfData={exportData}
                  fileName="EquityBalances"
                />
              </span>
              <span>
                <ExportFile
                  downloadFormate="csv"
                  exportData={exportData}
                  fileName="EquityBalances"
                />
              </span>
              <span>
                <ExportFile
                  downloadFormate="xlsx"
                  exportData={exportData}
                  fileName="EquityBalances"
                />
              </span>
              <span style={{ marginLeft: '8px' }} className="are-detail-export">
                <ExportFile
                  downloadFormate="xlsx"
                  exportData={AREexportDetailData}
                  fileName="AREDetails"
                  tooltipText="Export ARE Details as Excel"
                  innerText="Details"
                  formatArray={formatArray}
                />
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {!displayAccordian && (
        <div
          className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space height-100 d-none ${!displayAccordian && "d-lg-block"
            }`}
        >
          {quotas.length > 0 ? (
            <div className="top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table equity_balances">
              {/* <DataGridPagination
                uniqueIdentifier="Id"
                gridData={quotas}
                columns={columns}
                isPagination={true}
                itemsPerPage={10}
                columnResize={false}
                classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                autoRowHeight={true}
                dynamicHeaderHeight={45}
                paddingRowHeight={10}
              /> */}
              <MasterDetail quotas={masterData} childData={AREexportDetailData} />
            </div>
          ) : null}
        </div>
      )}
      <div
        className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? "d-lg-none" : "desktop-accordion-table"
          }`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          {quotas.length > 0 ? (
            <EquityBalanceMobileGrid listingData={masterData} childData={AREexportDetailData} />
          ) : null}
        </div>
      </div>
      {/* no record found section start */}
      {quotas.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          {notFoundMsg}
        </div>
      )}
      {/* no record found section End */}
      {/* {masterData.length > 0 && <MasterDetail quotas={masterData} />} */}
    </>
  );
};

export default EquityBalances;
