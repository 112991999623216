import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { toDecimal, toDecimalWithNegative } from "../../utils/helper";
// import moment from "moment";
// import { dateFormate } from "../../utils/constantsVariable";

const EquityBalanceMobileGrid = ({ listingData, childData }) => {
  return (
    <Accordion
      defaultActiveKey="0"
      className="table mobile-accordion accordion_space"
    >
      {listingData.length > 0 &&
      <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
        Total Result Count:{listingData.length}
      </h5>}
      {listingData.length > 0 &&
        listingData.map((data, index) => {
          let detailData = childData?.filter(i => i['Contract Number'] === data["RelationshipNumber"]);
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  Contract #: {data["RelationshipNumber"]}<br />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>

                      <div className="td" data-header="Contract Number:">
                        <span>{data["RelationshipNumber"]}</span>
                      </div>
                      <div className="td" data-header="Contract Name:">
                        <span>{data["Name"]}</span>
                      </div>

                      <div className="td" data-header="Equity Type:">
                        <span>{data["EquityType"]}</span>
                      </div>

                      <div className="td" data-header="Form Type:">
                        <span>{data["FormType"]}</span>
                      </div>

                      <div className="td" data-header="Shares:">
                        <span>{toDecimal(data["Shares"], 3)}</span>
                      </div>

                      {/* <div className="td" data-header="Value:">
                        <span>{toDecimal(data["Value"].toFixed(2), 3, "$")}</span> <span className="" onClick={() => { }}> {data.expanded ? <i className="fa fa-minus-circle cursor_pointer table_accordion"></i> : <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>} </span>
                      </div> */}
                      {
                        data["EquityType"] === "ARE" && <Accordion
                        defaultActiveKey="0"
                        className="ePURs-BedAccordian"
                      >
                        <Accordion.Item eventKey={1}>
                          <Accordion.Header>
                            <div
                              className="td epurs_nested_table cursor_pointer"
                              data-header="Value:"
                            >
                              <span>{toDecimal(data["Value"].toFixed(2), 3, "$")}</span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="td d-block d-lg-none mt-3 p-1 border-radius-6 show">
                              <div className="tr equity-balance-mobile-accordion">
                                {detailData.map((item, index) => (
                                  <div
                                    className="odd_even_bg"
                                    key={index}
                                  >
                                    <div>
                                      <span>Date:</span>
                                      <span className="">
                                        {item.Date}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Crop Year:</span>
                                      <span className="">
                                        {item['Crop Year']}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Equity Type:</span>
                                      <span className="">
                                        {item['Equity Type']}
                                      </span>
                                    </div>
                                    <div>
                                      <span>Description:</span>
                                      <span className="">
                                        {item.Description}
                                      </span>
                                    </div>
                                    <div>
                                      <span>ARE:</span>
                                      <span className="">
                                        {toDecimalWithNegative(item.ARE?.toFixed(2), 3, "$")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      }
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        })}
    </Accordion>
  );
};

export default EquityBalanceMobileGrid;
