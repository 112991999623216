import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CurrentLocation from "./currentLocation";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod } from "../../services/gbsData";
import {
  IsTokenChecker,
  IsStringChecker,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsObjectChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../utils/helper";
import {
  ContractsFromJSONParser,
  RoleCheckerForArrayData,
  ImpersonationAccountsSwap
} from "../../permissionWrapper";

const LeftMenu = (props) => {
  const { slug = null, className = null, RoleAccessDefinitions = null, SpecialRoleExist } = props;
  const idToken = useSelector((state) => state.reducerToken);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [getPath] = CurrentLocation();
  const dispatch = useDispatch();

  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);
  const [subMenu, setSubmenu] = useState([]);

  const GetCurrentPage = (url = null) => {
    try {
      if (IsStringChecker(url)) {
        return url.slice(1).split("/").pop();
      } else {
        return url;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return url;
    }
  };

  const ValueProvider = (obj = null, prefix = "title_") => {
    return IsObjectChecker(obj)
      ? _.get(obj, `${prefix}${resolvedLanguage}`, null)
      : null;
  };

  const MenuItem = async () => {
    //Need to add "new_content" in the below URL for dev
    let url = `side_navigations?select=id,title_en,title_fr,title_es,url,open_new_window,order_by,is_active,new_content,page_listings!inner(slug)&page_listings.slug=eq.${slug}&order=order_by`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const tempData = _.get(resp, "data.data", []);
        // const result = tempData;
        const result = RoleCheckerForArrayData(
          tempData,
          "url",
          gbsRolesByPerson,
          gbsRolesByContract,
          SpecialRoleExist,
          RoleAccessDefinitions
        );
        // console.log({LeftMenu: result});
        setSubmenu(safeArrayCheck(result));
      })
      .catch((error) => {
        setSubmenu([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken, slug)) {
      MenuItem();
    }
  }, [idToken, slug]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const currentPage = GetCurrentPage(getPath);
  return (
    <>
      {IsArrayNotEmpty(subMenu) && (
        <ul
          className={`nav flex-column side-nav-links bot-mspace-20 ${
            IsStringChecker(className) ? className : ``
          }`}
        >
          {subMenu.map((link, index) => {
            const { url = null } = link;
            const title = ValueProvider(link);
            const current = GetCurrentPage(url);
            const active = current === currentPage;
            const new_content = link.new_content?.search('true') > -1
            if (IsTokenChecker(title, url)) {
              return (
                <li className="nav-item" key={index}>
                  <Link
                    className={`${active ? `nav-link active fw-bold` : "nav-link"} ${new_content && 'new-content-flag'}`}
                    id={`leftDropdown-${index}`}
                    aria-current={active ? "page" : ""}
                    to={url}
                  >
                    {title}
                    {new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
                  </Link>
                </li>
              );
            } else {
              return <Fragment key={index} />;
            }
          })}
        </ul>
      )}
    </>
  );
};

export default LeftMenu;
