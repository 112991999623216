import React, { useState, useEffect, Fragment } from "react";
import "../../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import MultiDropDown from "../../../selectBox/multiDropDown";
import { Accordion } from "react-bootstrap";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import Moment from "react-moment";
import moment from "moment";
import Loading from "../../../../components/global/loading";
import DataGridPagination from "../../../global/dataGridPagination";
import {
  IsTokenChecker,
  toDecimalORCurrency,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker,
  compareString,
} from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../../../components/global/currentLocation";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
} from "../../../../permissionWrapper";
import { useGbsDataCache } from "../../../../services/gbsDataCache";
import constantsVariable from "../../../../utils/constantsVariable";

export const Deferments = (props) => {
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const dispatch = useDispatch();
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const tokenId = useSelector((state) => state.reducerToken);

  const default_cpd_rate_api = "CONTINUOUS POOL DISTRIBUTION";
  const { dateFormate, SA_Regions } = constantsVariable;

  const [ContractIds, setContractIds] = useState([]);
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [preSelectedCropyear, setPreSelectedCropyear] = useState([]);
  const [CropYears, setCropYears] = useState([]);
  const [NAContracts, setNAContracts] = useState([]);
  const [SAContracts, setSAContracts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);

  const [sa_rates_payload, setSARatesPayload] = useState([]);
  const [na_rates_payload, setNARatesPayload] = useState([]);
  const [sa_rates_preload, setSARatesPreload] = useState(false);
  const [na_rates_preload, setNARatesPreload] = useState(false);
  const [defermentsData, setDefermentsData] = useState([]);
  const [defermentsDataStatus, setDefermentsDataStatus] = useState(true);
  const [filterItems, setFilterItems] = useState([]);

  const { DropDownCBRoles = [] } = props;

  //Defining Grid Column
  const columnsArray = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      width: 80,
    },
    {
      key: "Name",
      name: "Contract Name",
      cellClass: "enablesTextWrapping",
      width: 120,
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping",
      width: 165,
      formatter(props) {
        return <>{props.row.Description || "-"}</>;
      },
    },
    {
      key: "DefermentPercentage",
      name: "% Deferred",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center enablesTextWrapping",
      width: 80,
      formatter(props) {
        return (
          <>
            {props.row.DefermentPercentage
              ? props.row.DefermentPercentage * 100 + "%"
              : "-"}
          </>
        );
      },
    },
    {
      key: "DefferedDate",
      name: "Deferred Date",
      width: 100,
      dateFormate: dateFormate,
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      formatter(props) {
        return props.row.DefferedDate ? (
          <Moment utc format={dateFormate}>
            {props.row.DefferedDate}
          </Moment>
        ) : (
          "-"
        );
      },
    },
    {
      key: "YtdDeferment",
      name: "Amount Deferred",
      headerCellClass: "grid_column_right enablesTextWrappingHeading",
      cellClass: "text-lg-end",
      width: 130,
      formatter(props) {
        return (
          <>
            {props.row.YtdDeferment
              ? toDecimalORCurrency(props.row.YtdDeferment, 2, "$", true) || "-"
              : "-"}
          </>
        );
      },
    },
    {
      key: "ExpectedPayoutDate",
      name: "Expected payout date",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 120,
      dateFormate: dateFormate,
      formatter(props) {
        return props.row.DefferedDate ? (
          <>
            {/* {props.row.ExpectedPayoutDate
              ? props.row.ExpectedPayoutDate.substring(0, 2) +
                "/" +
                props.row.ExpectedPayoutDate.substring(2)
              : "-"} */}
            {moment(props.row.DefferedDate)
              .add(props.row.DefermentPeriod, "M")
              .format("MM/YYYY")}
          </>
        ) : (
          "-"
        );
      },
    },
    {
      key: "DefermentPeriod",
      name: "Month Deferred",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 80,
      formatter(props) {
        return <>{props.row.DefermentPeriod || "-"}</>;
      },
    },
    {
      key: "Paid",
      name: "Paid",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 80,
      formatter(props) {
        return props.row.Paid ? (
          <i
            className={`fa text-20 grid_status_icon fa-check-circle light_green_color`}
            aria-hidden="true"
          ></i>
        ) : (
          ""
        );
      },
    },
  ];

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data?.data) {
      getDeferments(data?.data);
    } else {
      getDeferments([]);
    }
    // if (status === "error") {
    //   HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data, changeEvent, status]);
  //Cache Implementation End
  const [displayAccordian, setDisplayAccordian] = useState(false);

  function RegionBasedContract(Region) {
    return SA_Regions.find((li) => compareString(li, Region));
  }

  function defaultPaymentRatesArrange(data = []) {
    try {
      if (IsArrayNotEmpty(data)) {
        const cpd_data = data.filter((li) => {
          const { Description } = li;
          return (
            IsStringChecker(Description) &&
            Description.includes(default_cpd_rate_api)
          );
        });
        if (IsArrayNotEmpty(cpd_data)) {
          return cpd_data
            .map((li) => {
              const { CropYear, SettlementDate, Description } = li;
              const deffered_date = SettlementDate || null;
              const expected_payout_date = SettlementDate
                ? moment(SettlementDate).add(1, "years").format("MM/YYYY")
                : null;
              const cropyear = CropYear || NaN;
              return {
                cropyear,
                deffered_date,
                expected_payout_date,
                description: Description,
              };
            })
            .filter((li) => {
              return !isNaN(li?.cropyear);
            });
        }
        return [];
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  async function fetchCropYearContract() {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then(async (resp) => {
        const cropyears = safeArrayCheck(resp?.data);
        if (IsArrayNotEmpty(cropyears)) {
          const getcurrentcrop = cropyears.find((x) => x.CurrentCrop === true);
          const currentcropData =
            getcurrentcrop?.CropYear || cropyears[0]?.CropYear;
          const tempCropyears = [...cropyears].splice(0, 15);
          setCropYears(setOptions(tempCropyears, "CropYear", true));
          await gbsDataCommonMethod(
            tokenId,
            accounts,
            // `persons/${personId}/contracts`
            `persons/${personId}/contracts?r.ContractStatus=1000,1001`
          )
            .then(async (resp) => {
              const data = safeArrayCheck(_.get(resp, `data`, []));
              const filteredContracts = FilterContractByRole(
                data,
                accounts,
                DropDownCBRoles
              );
              if (IsArrayNotEmpty(filteredContracts)) {
                const contractids = filteredContracts
                  ?.map((li) => li.RelationshipId)
                  ?.join();
                setContractIds(contractids);
                const sa_region_based_contracts = [],
                  na_region_based_contracts = [];
                filteredContracts.map((li) => {
                  const { RegionDesc } = li;
                  const is_sa = RegionBasedContract(RegionDesc);
                  if (is_sa) {
                    sa_region_based_contracts.push(li);
                  } else {
                    na_region_based_contracts.push(li);
                  }
                });
                setNAContracts(na_region_based_contracts);
                setSAContracts(sa_region_based_contracts);
                const defaultContractNumber = `${_.orderBy(
                  contractids.split(","),
                  [],
                  ["asc"]
                )}`;
                if (defaultContractNumber && currentcropData) {
                  setClearStatus(true);
                  setTimeout(() => {
                    setPreSelectedCropyear([`${currentcropData}`]);
                    setCY_MultiValue({ cropyear: `${currentcropData}` });
                    setDisableSubmitCancel(false);
                    setClearStatus(false);
                  }, 0);
                  if (IsArrayNotEmpty(na_region_based_contracts)) {
                    const random_contract_number =
                      na_region_based_contracts?.[0]?.RelationshipId;
                    await fetchPaymentRates(
                      `${random_contract_number}`,
                      `${currentcropData}`,
                      false
                    );
                  }
                  if (IsArrayNotEmpty(sa_region_based_contracts)) {
                    const random_contract_number =
                      sa_region_based_contracts?.[0]?.RelationshipId;
                    await fetchPaymentRates(
                      `${random_contract_number}`,
                      `${currentcropData}`,
                      true
                    );
                  }
                  const url = `payments/deferments?relationshipid=${defaultContractNumber}&p.cropyear=${currentcropData}`;
                  setSearchURL(url);
                  url === searchURL && setChangeEvent(changeEvent + 1);
                }
              }
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  async function fetchPaymentRates(
    contract = "",
    cropyear = "",
    sa_region = false
  ) {
    if (contract && cropyear) {
      sa_region ? setSARatesPreload(true) : setNARatesPreload(true);
      const url = `pool-Rate-Detail?relationshipId=${contract}&cropYear=${cropyear}`;
      await gbsDataCommonMethod(tokenId, accounts, url)
        .then((resp) => {
          const data = resp?.data;
          if (IsArrayNotEmpty(data)) {
            const formatted_rates_arr = defaultPaymentRatesArrange(data);
            sa_region
              ? setSARatesPayload(formatted_rates_arr)
              : setNARatesPayload(formatted_rates_arr);
          } else {
            sa_region ? setSARatesPayload([]) : setNARatesPayload([]);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          sa_region ? setSARatesPayload([]) : setNARatesPayload([]);
        })
        .finally(() => {
          sa_region ? setSARatesPreload(false) : setNARatesPreload(false);
        });
    } else {
      if (sa_region) {
        setSARatesPayload([]);
        setSARatesPreload(false);
      } else {
        setNARatesPayload([]);
        setNARatesPreload(false);
      }
    }
  }

  function DefermentsNullPopulation(
    data,
    na_rates,
    sa_rates,
    na_contracts,
    sa_contracts
  ) {
    try {
      if (IsArrayNotEmpty(sa_contracts) || IsArrayNotEmpty(na_contracts)) {
        return data.map((list) => {
          const {
            RelationshipId,
            CropYear,
            Description,
            ExpectedPayoutDate,
            DefferedDate,
          } = list;
          if (DefferedDate === null || ExpectedPayoutDate === null) {
            const exist_in_na = na_contracts.find(
              (li) => li.RelationshipId == RelationshipId
            );
            if (exist_in_na) {
              const rate_data = IsArrayNotEmpty(na_rates)
                ? na_rates.find(
                    (li) =>
                      li.cropyear == CropYear &&
                      compareString(li.description, Description)
                  )
                : null;
              const new_list = { ...list };
              new_list.DefferedDate = rate_data
                ? rate_data?.deffered_date || null
                : null;
              new_list.ExpectedPayoutDate = rate_data
                ? rate_data?.expected_payout_date || null
                : null;
              return new_list;
            } else {
              const exist_in_sa = sa_contracts.find(
                (li) => li.RelationshipId == RelationshipId
              );
              if (exist_in_sa) {
                const rate_data = IsArrayNotEmpty(sa_rates)
                  ? sa_rates.find(
                      (li) =>
                        li.cropyear == CropYear &&
                        compareString(li.description, Description)
                    )
                  : null;
                const new_list = { ...list };
                new_list.DefferedDate = rate_data
                  ? rate_data?.deffered_date || null
                  : null;
                new_list.ExpectedPayoutDate = rate_data
                  ? rate_data?.expected_payout_date || null
                  : null;
                return new_list;
              } else {
                return list;
              }
            }
          } else {
            return list;
          }
        });
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      fetchCropYearContract();

      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  // creating option for mulit select
  const setOptions = (data, keyPrram) => {
    return data
      .map((li) => li[keyPrram])
      .filter(onlyUnique)
      .map((li) => {
        return { name: li, id: li };
      });
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  // Get Grid API values
  const getDeferments = async (data) => {
    let resultData = _.orderBy(
      [...data],
      ["RelationshipId", "Description"],
      ["asc", "asc"]
    );
    let filteredData = FilterContractByRole(
      resultData,
      accounts,
      DropDownCBRoles
    );
    if (
      filteredData.length > 0 &&
      data !== "Contract and CropYear must be provided"
    ) {
      setFilterItems(filteredData);
    } else {
      setFilterItems([]);
      setDefermentsData([]);
    }
    setLoading(false);
    setLoader(false);
  };

  //crop year select change event
  const onCropYearChange = async (val) => {
    setNoResultsFound(false);
    let cropyear = setObjToString(val);
    let cropyears = `${_.orderBy(cropyear.split(","), [], ["asc"])}`;
    let contracts = `${_.orderBy(ContractIds.split(","), [], ["asc"])}`;
    if (IsStringChecker(cropyears) && IsStringChecker(contracts)) {
      setDisableSubmitCancel(false);
      if (IsArrayNotEmpty(NAContracts)) {
        const random_contract_number = NAContracts?.[0]?.RelationshipId;
        await fetchPaymentRates(`${random_contract_number}`, cropyears, false);
      }
      if (IsArrayNotEmpty(SAContracts)) {
        const random_contract_number = SAContracts?.[0]?.RelationshipId;
        await fetchPaymentRates(`${random_contract_number}`, cropyears, true);
      }
      let url = `payments/deferments?relationshipid=${contracts}&p.cropyear=${cropyears}`;
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  //crop year deselect change event
  const onCropYearRemove = async (selectedList, removedItem) => {
    setNoResultsFound(false);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisableSubmitCancel(true);
    } else {
      let cropyear = setObjToString(selectedList);
      let cropyears = `${_.orderBy(cropyear.split(","), [], ["asc"])}`;
      let contracts = `${_.orderBy(ContractIds.split(","), [], ["asc"])}`;
      if (IsStringChecker(cropyears) && IsStringChecker(contracts)) {
        setDisableSubmitCancel(false);
        if (IsArrayNotEmpty(NAContracts)) {
          const random_contract_number = NAContracts?.[0]?.RelationshipId;
          await fetchPaymentRates(
            `${random_contract_number}`,
            cropyears,
            false
          );
        }
        if (IsArrayNotEmpty(SAContracts)) {
          const random_contract_number = SAContracts?.[0]?.RelationshipId;
          await fetchPaymentRates(`${random_contract_number}`, cropyears, true);
        }
        let url = `payments/deferments?relationshipid=${contracts}&p.cropyear=${cropyears}`;
        setSearchURL(url);
        url === searchURL && setChangeEvent(changeEvent + 1);
      }
    }
  };

  // convert object to string
  const setObjToString = (val) => {
    let ids = val.map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    return ids;
  };

  // Mobile filter change event
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  // on click clear
  const onClearSearch = () => {
    setDefermentsData([]);
    setDefermentsDataStatus(false);
    setNoResultsFound(false);
    setFilterItems([]);
    setCY_MultiValue(null);
    setPreSelectedCropyear([]);
    setDisableSubmitCancel(true);
  };

  // On click search
  const onAllSearch = () => {
    let filter = [...filterItems];
    if (
      filter.length > 0 &&
      filter !== "Contract and CropYear must be provided"
    ) {
      const formatted_data = DefermentsNullPopulation(
        filter,
        na_rates_payload,
        sa_rates_payload,
        NAContracts,
        SAContracts
      );
      setDefermentsData(formatted_data);
      setDefermentsDataStatus(true);
      setNoResultsFound(false);
    } else {
      setDefermentsData([]);
      setDefermentsDataStatus(false);
      setNoResultsFound(true);
    }
    handlerMobFilter("cancel");
  };

  const global_loader =
    loader || status === "fetching" || na_rates_preload || sa_rates_preload;
  const white_loader = loading || na_rates_preload || sa_rates_preload;
  return (
    <>
      {global_loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 p-1 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock payment-page-mobile-filter ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(val) => onCropYearChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onCropYearRemove(selectedList, removedItem)
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={preSelectedCropyear}
                    disabled=""
                  />
                )}
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={onAllSearch}
                disabled={disableSubmitCancel}
              >
                Search{!white_loader ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={onClearSearch}
                disabled={disableSubmitCancel}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={onAllSearch}
            disabled={disableSubmitCancel}
          >
            Apply{!white_loader ? "" : <WhiteCircleLoader />}{" "}
          </button>
        </div>
      </nav>
      {/*<!-- Desktop table starts -->*/}
      {defermentsData.length > 0 &&
        defermentsDataStatus &&
        !displayAccordian && (
          <div
            className={`
      hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
          >
            <DataGridPagination
              uniqueIdentifier="Id"
              gridData={defermentsData}
              columns={columnsArray}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              autoRowHeight={true}
            />
          </div>
        )}
      {/*<!-- Desktop table ends -->*/}
      {/* <!-- Mobile table starts --> */}
      {defermentsData.length > 0 && defermentsDataStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${
            !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
          }`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {defermentsData.length > 0 &&
                Object.values(defermentsData).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          {`Contract #: ${data.RelationshipNumber}, 
                      Deferred Date: ${data.DefferedDate ? moment(data.DefferedDate).format(
                        dateFormate
                      ) : "-"}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div
                                className="td"
                                data-header="Contract #:"
                              ></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div className="td" data-header="Description:">
                                {data.Description}
                              </div>
                              <div className="td" data-header="% Deferred:">
                                {" "}
                                {data.DefermentPercentage
                                  ? data.DefermentPercentage * 100 + "%"
                                  : "-"}
                              </div>
                              <div className="td" data-header="Deferred Date:">
                                {data.DefferedDate ? (
                                  <Moment utc format={dateFormate}>
                                    {data.DefferedDate}
                                  </Moment>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div
                                className="td"
                                data-header="Amount Deferred:"
                              >
                                {data.YtdDeferment
                                  ? toDecimalORCurrency(
                                      data.YtdDeferment,
                                      2,
                                      "$",
                                      true
                                    )
                                  : "-"}
                              </div>
                              <div
                                className="td"
                                data-header="Expected Payout Date:"
                              >
                                {data.DefferedDate
                                  ? moment(data.DefferedDate)
                                      .add(data.DefermentPeriod, "M")
                                      .format("MM/YYYY")
                                  : "-"}
                                {/* {data.ExpectedPayoutDate
                                  ? data.ExpectedPayoutDate.substring(0, 2) +
                                    "/" +
                                    data.ExpectedPayoutDate.substring(2)
                                  : "-"} */}
                              </div>
                              <div className="td" data-header="Month Deferred:">
                                {data.DefermentPeriod || "-"}
                              </div>
                              <div className="td" data-header="Paid:">
                                {data.Paid ? (
                                  <i
                                    className={`fa text-20 grid_status_icon fa-check-circle light_green_color`}
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {defermentsData.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          {moreLangs[i18n.language].payments_page.defermentsNotFoundMsg}
        </div>
      )}
      {/* no record found section End */}
      {/*<!-- Keeping quality block ends -->*/}
    </>
  );
};
