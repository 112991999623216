import React, { Fragment } from "react";

function RichTextWrap(props) {
  const { children, ...rest } = props;
  const {
    key = null,
    fontSize = "14px",
    fontFamily = "unset",
    height = "auto",
    border = "none",
  } = rest || {};

  if (children) {
    return (
      <div
        key={key}
        className="quill-editor ql-container ql-snow"
        style={{
          height,
          border,
          fontFamily,
          fontSize,
        }}
      >
        {children}
      </div>
    );
  }
  return <Fragment key={key}></Fragment>;
}

export default RichTextWrap;
