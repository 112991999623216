/**
 * @component_Name : UserNotification
 * @description : Users can see thier own Notification
 * @company : Photon
 * @author : Gobi/03-10-2022
 **/
import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { useSelector, useDispatch } from "react-redux";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import {
  Alert,
  //  Alert, Tooltip, OverlayTrigger, 
  Modal
} from "react-bootstrap";
import _ from "lodash";
import { Prompt } from "react-router-dom";
import moment from "moment";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import { ImpersonationAccountsSwap, DetermineImpersonation } from "../../permissionWrapper";
const DataSharing = (props) => {
  const  impersonated = DetermineImpersonation();
  const asyncTypeaheadRef = useRef();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const { contractNumbers = [], isAgScientist = false } = props;

  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const tokenId = useSelector((state) => state.reducerToken);
  const auditCreatedBy = accounts[0].name;
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [tableActive, settableActie] = useState(false);
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const [ContractNumbers, setContractNumbers] = useState([]);
  const [activeContractNumbers, setActiveContractNumbers] = useState([]);  
  const [disabledContractNumbers, setDisabledContractNumbers] = useState([]);
  const [constructCheckedObj, setConstructCheckedObj] = useState([]);
  const [initialConstructCheckedObj, setInitialConstructCheckedObj] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    message: "",
  });
  const [refresh, setRefresh] = useState(true);
  const [checkBoxLength, setCheckBoxLendth] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const [ isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      tableHeader.length === 0 && getHeader();
      if(contractNumbers.length > 0 && !isLoaded && !isAgScientist){
        setIsLoaded(true);
        arriangeContractBasedValues(contractNumbers);
      }
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId, contractNumbers]);

  const arriangeContractBasedValues = (contract) => {
    let contracts = contract.filter(i => i.ContractRole !== "").map((item) => item.RelationshipId).toString();
    let url = `/authorization/authorizationMaster?contractId=${contracts}`;
    getDataSharingAuthorization(url, "tableData");

    setActiveContractNumbers(contract.filter(i => i.ContractRole === "active"));
    setDisabledContractNumbers(contract.filter(i => i.ContractRole === "disabled"));
    setContractNumbers(
      _.orderBy(
        contract.filter(i => i.ContractRole !== ""),
        ["ContractRole", "ContractStatus", "RelationshipNumber"],
        ["asc", "asc", "asc"]
      )
    );
  }

  //Get table header
  const getHeader = async () => {
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `/authorization/authorizationToAndType`
    )
      .then((resp) => {
        if (resp.data.length > 0) {
          settableActie(true);
          setLoader(false);
          let group = _.groupBy(resp.data, (item) => {
            return item['AuthorizationType']
          });
          let checkBoxCount = [];
          Object.keys(group).map((i) => {
            return group[i].map((k) => checkBoxCount.push(k));
          });
          setCheckBoxLendth(checkBoxCount);
          setTableHeader(group);
        }
        else {
          setTableHeader({});
          setCheckBoxLendth([]);
        }
      })
      .catch((error) => {
        setCheckBoxLendth([]);
        setTableHeader({});
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  }

  // Get Grid API values
  const getDataSharingAuthorization = async (url, datatype, data, method) => {
    setLoader(true);
    await gbsDataCommonMethod(tokenId, accounts, url, method, data)
      .then((resp) => {
        setLoader(false);
        if (resp.status === 200) {
          if (datatype === "tableData") {
            const dataArray = [];
            resp.data.filter((item) => {
              if (item.data.length > 0) {
                item.data?.map((i) => {
                  dataArray.push(
                    {
                      relationshipid: i.Relationshipid,
                      authorizationToId: i.AuthorizationToId,
                      authorizationTypeId: i.AuthorizationTypeId,
                    }
                  );
                });
              }
            });
            setConstructCheckedObj([...dataArray]);
            setInitialConstructCheckedObj([...dataArray])
            setMasterData(resp.data);
          }
          else if (datatype === "save") {
            setShowModal({
              isOpen: false,
            })
            setAlertOpen({
              isActive: true,
              message:
                moreLangs[i18n.resolvedLanguage].userProfile
                  .notification_preferences_save_msg,
            });
            setTimeout(() => {
              setAlertOpen({ isActive: false, message: "" });
            }, 5000);
            setDisableSubmitCancel(true);
            let contracts = ContractNumbers.filter(i => i.ContractRole !== "").map((item) => item.RelationshipId).toString();
            let url = `/authorization/authorizationMaster?contractId=${contracts}`;
            getDataSharingAuthorization(url, "tableData");
          }

        }

      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const saveChanges = () => {
    setShowModal({
      isOpen: true,
    });
  };

  const save = () => {
    let contractIds = activeContractNumbers.map(i => i.RelationshipId);
    let url = `authorization/${contractIds}/${auditCreatedBy}/upsert-authorizationMaster`;
    setInitialConstructCheckedObj([...constructCheckedObj]);
    getDataSharingAuthorization(
      url,
      "save",
      { data: constructCheckedObj },
      "PUT"
    );
  }

  const checkboxChecked = (obj) => {
    const { AuthorizationTypeId, AuthorizationToId } = obj;
    let checked = false, count = 0;

    let lengthToCheck = ContractNumbers.filter(i => i.ContractRole === "active").length;
    let disabledContractID = disabledContractNumbers.map(i=>i.RelationshipId);
    constructCheckedObj.map((item) => {
      if (
        AuthorizationTypeId === item.authorizationTypeId &&
        AuthorizationToId === item.authorizationToId && !disabledContractID.includes(item.relationshipid)
      ) {
        return count++;
      }
        disabledContractNumbers
      
    });
    checked = count === lengthToCheck && lengthToCheck > 0;

    return (
      <>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            sllectAll(e.target.checked, obj);
          }}
        />
        <span className="slider_progress round"></span>
      </>
    );
  };

  const sllectAll = (e, obj) => {
    // console.log(activeContractNumbers, e, obj)
    setDisableSubmitCancel(false);
    if (e) {
      let newObj = activeContractNumbers.map((item) => {
        return {
          relationshipid: item.RelationshipId,
          authorizationToId: obj.AuthorizationToId,
          authorizationTypeId: obj.AuthorizationTypeId,
        };
      });
      let uniqueArr = _.uniqWith(
        [...constructCheckedObj, ...newObj],
        _.isEqual
      );
      setConstructCheckedObj([...uniqueArr]);
      if (JSON.stringify(initialConstructCheckedObj) === JSON.stringify(uniqueArr)) {
        setDisableSubmitCancel(true);
      }
    } else {
      activeContractNumbers.map((item) => {
        constructCheckedObj.splice(
          constructCheckedObj.findIndex(
            (i) =>
              item.RelationshipId === i.relationshipid &&
              obj.AuthorizationToId === i.authorizationToId &&
              obj.AuthorizationTypeId === i.authorizationTypeId
          ),
          1
        );
      });
      setConstructCheckedObj([...constructCheckedObj]);
      if (JSON.stringify(initialConstructCheckedObj) === JSON.stringify(constructCheckedObj)) {
        setDisableSubmitCancel(true);
      }
    }
  };

  const checkboxCheckedChild = (checkBoxData, i, contract) => {
    let checked = false;
    constructCheckedObj?.map((item) => {
      if (
        contract.RelationshipId === item.relationshipid &&
        checkBoxData.AuthorizationToId === item.authorizationToId &&
        checkBoxData.AuthorizationTypeId === item.authorizationTypeId
      ) {
        checked = true;
      }
    });
    return (
      <td key={i} className="text-center">
        <input
          onChange={(e) => checkboxclick(e.target.checked, checkBoxData, contract)}
          checked={checked}
          className={`form-check-input cursor-pointer`}
          type="checkbox"
        />
      </td>
    );
  };

  const checkboxclick = (e, mtObj, contract) => {
    setDisableSubmitCancel(false);
    const { RelationshipId } = contract;

    if (e) {
      let newObj = {
        relationshipid: RelationshipId,
        authorizationToId: mtObj.AuthorizationToId,
        authorizationTypeId: mtObj.AuthorizationTypeId,
      };
      setConstructCheckedObj([...constructCheckedObj, newObj]);
      if (JSON.stringify(initialConstructCheckedObj) === JSON.stringify([...constructCheckedObj, newObj])) {
        setDisableSubmitCancel(true);
      }
    } else {
      constructCheckedObj.splice(
        constructCheckedObj.findIndex(
          (item) =>
            RelationshipId === item.relationshipid &&
            mtObj.AuthorizationToId === item.authorizationToId &&
            mtObj.AuthorizationTypeId === item.authorizationTypeId
        ),
        1
      );
      setConstructCheckedObj([...constructCheckedObj]);
      if (JSON.stringify(initialConstructCheckedObj) === JSON.stringify([...constructCheckedObj])) {
        setDisableSubmitCancel(true);
      }
    }
  };

  // const access = IsArrayNotEmpty(sortedMasterData);
  const cancelSave = () => {
    setConstructCheckedObj([...initialConstructCheckedObj]);
    setDisableSubmitCancel(true);
    return;
    setCount(count + 1);
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 100);
  }
  useEffect(() => {
    const unloadCallback = (event) => {
      if (!disableSubmitCancel) {
        const confirmationMessage = moreLangs[i18n.resolvedLanguage].forum_page.browser_back_button_msg;
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [disableSubmitCancel]);

  const onHide = () => {
    setShowModal({
      isOpen: false,
      message: "",
    })
  }

  const getHistoryTable = (
    contract = {}
  ) => {
    let flterData = masterData.filter(i => i.relationshipId.toString() === contract.RelationshipId.toString());
    if (flterData?.length === 1) {
      let filterData = flterData[0]?.history.sort((a,b)=>moment(b.date) - moment(a.date));
      if(window.innerWidth <= 992 & filterData.length > 2){
        filterData = filterData?.slice(0,2);
      }
      return <>
        <td className="p-0">
          <table className="border-botton">
            <tbody data-j = {JSON.stringify(filterData)}>
              {
                filterData?.map((i, k) => <tr key={k}>
                  <td>{moment(i.date).format("MM/DD/YYYY")}</td>
                  <td className="w-100">{i.historyData}</td>
                </tr>
                )
              }
            </tbody>
          </table>
        </td>
      </>
    }
    else
      return <td className="p-0"></td>
  }
  const handleSearch = (query) => {
    if (/^\d+$/.test(query)) {
      setIsLoading(true);
      gbsDataCommonMethod(tokenId, accounts, `/contracts?r.relationshipnumber=${query}`, "get", {})
        .then((res) => {
          let data = res.data.filter((a) => {
            return [1000, 1001].includes(a.ContractStatus);
          });
          data = res.data.map((i) => {
            i.ContractRelationshipNumber = `${i.RelationshipNumber} ${i.Name || ''} ${i.Name2 || ''} ${i.Name3 || ''}`;
            return i;
          })
          setContractOptions(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    }
  };
  const contractTypeheadChangeEvent = (contracts) => {
    if (contracts.length > 0) {
      setContractNumbers(contracts);
      let relationshipIds = contracts.map(i => i.RelationshipId).toString();
      let url = `/authorization/authorizationMaster?contractId=${relationshipIds}`;
      getDataSharingAuthorization(url, "tableData");
    }
  }

  return (
    <>
      <Prompt
        when={!disableSubmitCancel}
        message={(location, action) => {
          if (action === "POP" || action === "PUSH") {
            return disableSubmitCancel
              ? true
              : moreLangs[i18n.resolvedLanguage].forum_page
                .browser_back_button_msg;
          } else {
            return true;
          }
        }}
      />
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}

      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      {tableActive && refresh && (
        <div className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 data-sharing-autorization-table">
          <h3 className="mb-4">
            {" "}
            {
              moreLangs[i18n.resolvedLanguage].userProfile
                .data_sharing_header
            }
            :{" "}
          </h3>
          {isAgScientist && <div className="col-12 col-lg-4 personal_info">
            <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
              <span className="text-14 z-index-1 mandatoryField ps-3">
                Contract Number
              </span>
              {/* {fieldReset && */}
              <AsyncTypeahead
                // clearButton              
                ref={asyncTypeaheadRef}
                id="async-example"
                isLoading={isLoading}
                labelKey="ContractRelationshipNumber"
                minLength={3}
                onSearch={handleSearch}
                options={contractOptions}
                placeholder="Search contract number"
                className="AG_contracts"
                onChange={(data) => contractTypeheadChangeEvent(data)}
              >
                {({ onClear, selected }) =>
                  !!selected.length && (
                    <div className="rbt-aux">
                      <ClearButton
                        onClick={() => {
                          setContractNumbers([]);
                          setConstructCheckedObj([]);
                          setInitialConstructCheckedObj([])
                          setMasterData([]);
                          onClear();
                        }}
                      />
                    </div>
                  )
                }
              </AsyncTypeahead>
            </div>
          </div>
          }
          <>
            {!isAgScientist &&
              <div className="mb-4 hor-space-20 notification-button">
                <button
                  className="btn btn-primary text-16 me-3 cursor-for-btn"
                  disabled={impersonated ? impersonated : disableSubmitCancel}
                  onClick={saveChanges}
                >
                  {moreLangs[i18n.resolvedLanguage].button_text.save}
                </button>
                <button
                  className="btn text-16 btn-primary cursor-for-btn"
                  disabled={impersonated ? impersonated : disableSubmitCancel}
                  onClick={cancelSave}
                >
                  Cancel
                  {/* {moreLangs[i18n.resolvedLanguage].button_text.cancel_text} */}
                </button>
              </div>
            }
            <div className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20">
              <div className="table-responsive bordered-table">
                <table className="grid_block mb-0 width-100">
                  <thead>
                    <tr className="blue_bg">
                      <th>
                        {
                          moreLangs[i18n.resolvedLanguage].userProfile.Relationship
                        }
                      </th>
                      {
                        Object.keys(tableHeader).map((i, k) => <>
                          <th colSpan={tableHeader[i].length} key={k}>
                            {i}
                          </th>
                        </>)
                      }
                      <th colSpan="3">
                        {
                          moreLangs[i18n.resolvedLanguage].userProfile.authorization
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={`${isAgScientist && 'disabled-event'}`}>
                      <td rowSpan={2} className="border-botton"></td>
                      {
                        checkBoxLength.map((i, k) => <>
                          <td className="text-center min-w-90" key={k}>Select All
                            <label className="switch toggle_on_off">
                              {checkboxChecked(i)}
                            </label>
                          </td>
                        </>)
                      }
                      <td rowSpan={2} className="min-width-authorization border-botton"></td>
                      {/* <td rowSpan={2} className="border-botton">Authorized By</td>
                    <td rowSpan={2} className="border-botton">Authorized On</td>
                    <td rowSpan={2} className="border-botton">{`Authorizing User's Email`}</td> */}
                    </tr>
                    <tr>
                      {
                        Object.keys(tableHeader).map((i) => {
                          return tableHeader[i].map((j, k) => <>
                            <td className="text-center" key={k}>
                              {j.AuthorizationToName}
                            </td>
                          </>)
                        })
                      }
                    </tr>

                    {ContractNumbers.length > 0 &&
                      ContractNumbers.map((contract, i) => {
                        let index = i + 1;
                        return (
                          <tr key={i} className={`${(contract.ContractRole === "disabled" || isAgScientist) && 'disabled-event'} ${index % 2 === 0 ? 'evenClass' : 'oddClass'}`}>
                            <td className="text-start">{`${contract.RelationshipNumber}-${contract.RelationshipSuffix}-${contract.Name}`}</td>
                            {
                              checkBoxLength.map((i, index) => {
                                return checkboxCheckedChild(i, index, contract)
                              })
                            }
                            {
                              getHistoryTable(contract)
                            }
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {!isAgScientist &&
              <div className="mb-4 hor-space-20 notification-button">
                <button
                  className="btn btn-primary text-16 me-3 cursor-for-btn"
                  disabled={impersonated ? impersonated : disableSubmitCancel}
                  onClick={saveChanges}
                >
                  {moreLangs[i18n.resolvedLanguage].button_text.save}
                </button>
                <button
                  className="btn text-16 btn-primary cursor-for-btn"
                  disabled={impersonated ? impersonated : disableSubmitCancel}
                  onClick={cancelSave}
                >
                  Cancel
                  {/* {moreLangs[i18n.resolvedLanguage].button_text.cancel_text} */}
                </button>
              </div>
            }
          </>
        </div>
      )}
      <Modal
        show={showModal.isOpen}
        className={`notification-modal data-sharing-modal`}
        id={"notificationModal"}
        aria-labelledby={"notificationModal"}
      >
        <Modal.Header
          className={" blue_bg"}
        >
          <div className="modal-title h4"><h4 className="modal-title white_color">Alert!</h4></div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onHide}
          >
          </button>
        </Modal.Header>
        <Modal.Body className={"modalBodyImage"}>
          <p>Do you want to save change(s)?</p>
          <button className="btn btn-primary float-end cursor-for-btn" onClick={save} disabled={impersonated}>Confirm</button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DataSharing;
