/* eslint-disable */
/**
 * @component_Name : ContractedAcreageMapDetail
 * @description : Show Contracted Acreage Map grid list
 * @company : Photon
 * @author : Nivethithan Kannan/14-11-2024
 **/

import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import Mapp, {
  Marker,
  Source,
  Layer,
  NavigationControl,
  FullscreenControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapColors, {
  ReportOptionsKeyMap,
} from "../../myFarm/myAcreage/interactiveExhibitMapColor";
import "../../../css/loading.css";
import BreadCrumb from "../../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import moment from "moment";
import CurrentLocation from "../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  sortByField,
  HandleErrorTrackAndToast,
  compareString,
  getCurrentYear,
  NumConverter,
  IsNumberChecker,
  IsObjectChecker,
  ReplaceToastStack,
  lowStr,
} from "../../../utils/helper";
import {
  Form,
  Accordion,
  Alert,
  InputGroup,
  Button,
  FormGroup,
  CloseButton,
  Table,
} from "react-bootstrap";
import WhiteCircleLoader from "../../whiteCircleLoader";
import { MultiDropDownForReducer as MultiDropDown } from "../../selectBox/multiDropDown";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
  LocalAccountIDSwapImpersonate,
  DetermineImpersonation,
} from "../../../permissionWrapper";
import constantsVariable from "../../../utils/constantsVariable";
import DatePickerComp from "../../global/datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";

const dataLayer = {
  id: "data-Layer",
  type: "line",
  source: "my-data",
  paint: {
    "line-color": [
      "match",
      ["get", "BorderRGBA"],
      "light",
      "rgba(0,0,0,0.3)",
      "dark",
      "rgba(0,0,0,1)",
      "rgba(0,0,0,0.7)",
    ],
    "line-width": ["match", ["get", "BorderWidth"], "thin", 2, 3],
  },
};

const dataLayerFill = {
  id: "data-Layer-Fill",
  type: "fill",
  source: "my-data",
  paint: {
    "fill-color": "transparent",
    "fill-opacity": 0.7,
  },
};

const ContractedAcreageMapDetail = (props) => {
  const cropyear_name = "cropyear",
    contract_id_name = "contract_id",
    regions_name = "regions",
    bogs_name = "bogs",
    varieties_name = "varieties",
    maps_name = "maps",
    report_types_name = "report_types",
    min_cropyear_name = "min_cropyear",
    max_cropyear_name = "max_cropyear",
    cropyear_list_name = "cropyear_list",
    region_list_name = "region_list",
    contract_list_name = "contract_list",
    contract_disabled_name = "contract_disabled",
    bogs_list_name = "bogs_list",
    bogs_disabled_name = "bogs_disabled",
    varieties_list_name = "varieties_list",
    varieties_disabled_name = "varieties_disabled",
    maps_list_name = "maps_list",
    maps_disabled_name = "maps_disabled",
    report_types_list_name = "report_types_list",
    report_types_disabled_name = "report_types_disabled",
    original_data_name = "original_data",
    bogs_data_name = "bogs_data",
    maps_data_name = "maps_data",
    varieties_data_name = "varieties_data",
    report_types_data_name = "report_types_data";

  const onGoingYear = getCurrentYear();
  const impersonated = DetermineImpersonation();
  const { DropDownCBRoles = [], RolePBPrivileges } = props;
  const { rolePBAgScientist = false } = RolePBPrivileges;
  const today = moment().utc().format("MM/DD/YYYY");

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const {
    defaultToastMsgProperties,
    dateFormate,
    notFoundMsg,
    contracted_acreage_map_report_options,
  } = constantsVariable;

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  // const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  // const localAccountId = LocalAccountIDSwapImpersonate(accounts);

  const asyncTypeaheadRef = useRef();
  const [contractLoading, setContractsLoading] = useState(false);
  const [GridItems, setGridItems] = useState([]);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultAlert = { trigger: false, variant: "primary", message: "" };
  const [saveAlert, setSaveAlert] = useState(defaultAlert);

  const [hideTheMap, setHideTheMap] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isMobFilter, setisMobFilter] = useState(false);

  const [FillMapColor, setFillMapColor] = useState(dataLayerFill);
  const [LayerType, setLayerType] = useState([]);
  const [LongLat, setLongLat] = useState({ longitude: 0, latitude: 0 });
  const [displayDropDown, setDisplayDropDown] = useState({
    bogs_reload: true,
    varieties_reload: true,
    maps_reload: true,
    report_types_reload: true,
    regions_reload: true,
  });
  function NumberToNormal(num, float = false) {
    try {
      const new_num = `${num}`;
      return float ? parseFloat(new_num) : parseInt(new_num);
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return 0;
    }
  }

  function FilterDataStateSetter(data) {
    try {
      const data_list = [];
      if (IsObjectChecker(data)) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];
            data_list.push({ key, value });
          }
        }
      }
      dispatch_filter_data({ type: "data_list", payload: { data_list } });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      dispatch_filter_data({ type: "data_list", payload: { data_list: [] } });
    }
  }

  const DoRegionExist = (Region = null, ArrayToCheck = []) => {
    try {
      const regionIndex = ArrayToCheck.findIndex((li) =>
        compareString(li, Region)
      );
      return regionIndex >= 0;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return false;
    }
  };

  const CheckArrayRegionExist = (Contracts = [], RegionToCheck = []) => {
    try {
      if (IsArrayNotEmpty(Contracts) && IsArrayNotEmpty(RegionToCheck)) {
        return Contracts.filter((list) => {
          const { RegionDesc = "" } = list || {};
          return DoRegionExist(RegionDesc, RegionToCheck);
        });
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  function IsAllArgumentsTrue() {
    try {
      const arr = [...arguments];
      return arr.every((li) => li);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function IsSomeArgumentsTrue() {
    try {
      const arr = [...arguments];
      return arr.some((li) => li);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function CropYearFail() {
    const value = NumberToNormal(onGoingYear);
    const data_list = [];
    data_list.push({
      key: cropyear_list_name,
      value: [{ current: true, cropyear: value }],
    });
    data_list.push({ key: min_cropyear_name, value: value });
    data_list.push({ key: max_cropyear_name, value: value });
    dispatch_filter_data({
      type: "change",
      payload: { key: cropyear_name, value },
    });
    dispatch_filter_list_data({
      type: "data_list",
      payload: {
        data_list,
      },
    });
  }

  function FilterArrayDataByArrayStrings(data = [], arr = [], field = "") {
    try {
      const access = IsAllArgumentsTrue(
        IsArrayNotEmpty(data),
        IsArrayNotEmpty(arr),
        IsStringChecker(field)
      );

      if (access) {
        const arr_data = [];
        arr.forEach((list) => {
          const elements = data.filter((li) =>
            compareString(`${li[field]}`, list)
          );
          arr_data.push(...elements);
        });

        return arr_data;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function FilterArrayDataByArrayObjects(data = [], arr = [], field = "") {
    try {
      const access = IsAllArgumentsTrue(
        IsArrayNotEmpty(data),
        IsArrayNotEmpty(arr),
        IsStringChecker(field)
      );

      if (access) {
        const arr_data = [];
        arr.forEach((list) => {
          const { id } = list;
          const elements = data.filter((li) => li[field] == id);
          arr_data.push(...elements);
        });

        return arr_data;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const GetReportOptionsFill = (data = []) => {
    try {
      if (IsArrayNotEmpty(data)) {
        const fillOptions = ["match", ["get", "ColorType"]];
        data.map((list) => {
          const { ColorType, HEX } = list;
          fillOptions.push(ColorType);
          fillOptions.push(HEX);
        });
        fillOptions.push("transparent");
        return fillOptions;
      }
      return "transparent";
    } catch (e) {
      return "transparent";
    }
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const OnReset = (e) => {
    e.stopPropagation();
    const { cropyear_list = [] } = filter_list_data;
    const cropyear =
      cropyear_list.find((li) => li.current == true)?.cropyear ||
      NumberToNormal(onGoingYear);
    const data_list = [];
    data_list.push({ key: cropyear_name, value: cropyear });
    data_list.push({ key: regions_name, value: "" });
    ReloadDropDowns(false, false, false, false, true);
    const data = [];
    data.push({ key: contract_list_name, value: [] });
    data.push({ key: contract_disabled_name, value: true });
    dispatch_filter_list_data({
      type: "data_list",
      payload: { data_list: data },
    });
    EmptyMasterFilters(data_list);
    MapListDecide();
  };

  const OnSubmit = (e) => {
    e.stopPropagation();
    try {
      const { original_data = [] } = master_data;
      const {
        bogs: o_bogs = "",
        maps: o_maps = "",
        varieties: o_varieties = "",
        report_types: o_report_types = "",
      } = filter_data;

      if (IsArrayNotEmpty(original_data)) {
        const bogs = `${o_bogs}`;
        const maps = `${o_maps}`;
        const varieties = `${o_varieties}`;
        const report_types = `${o_report_types}`;

        var filtered_data = [...original_data];
        if (IsStringChecker(bogs)) {
          const bogs_arr = bogs.split(",");
          const temp = FilterArrayDataByArrayStrings(
            filtered_data,
            bogs_arr,
            "BogName"
          );
          filtered_data = [...temp];
        }

        if (IsStringChecker(maps)) {
          const maps_arr = maps.split(",");
          const temp = FilterArrayDataByArrayStrings(
            filtered_data,
            maps_arr,
            "MapId"
          );
          filtered_data = [...temp];
        }

        if (IsStringChecker(varieties)) {
          const varieties_arr = varieties.split(",");
          const temp = FilterArrayDataByArrayStrings(
            filtered_data,
            varieties_arr,
            "VarietyId"
          );
          filtered_data = [...temp];
        }

        setGridItems(filtered_data);
        const location_data = filtered_data[0] || {};
        setLongLat({
          longitude: location_data?.Longitude || 0,
          latitude: location_data?.Latitude || 0,
        });
        setHideTheMap(false);
        setNoRecordErrorMsg("");

        if (IsStringChecker(report_types)) {
          const colorData = MapColors.find((li) =>
            compareString(li?.ReportOption, report_types)
          );
          const typeOfLayer = _.get(colorData, "MapColor", []);
          const layerData = GetReportOptionsFill(typeOfLayer);
          const temp = { ...dataLayerFill };
          _.set(temp, "paint.fill-color", layerData);
          setFillMapColor(temp);
          setLayerType(typeOfLayer);
        } else {
          setFillMapColor(dataLayerFill);
          setLayerType([]);
        }
      } else {
        setGridItems([]);
        setHideTheMap(true);
        setNoRecordErrorMsg(notFoundMsg);
        setFillMapColor(dataLayerFill);
        setLayerType([]);
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      setGridItems([]);
      setHideTheMap(true);
      setNoRecordErrorMsg(notFoundMsg);
      setFillMapColor(dataLayerFill);
      setLayerType([]);
    }
  };

  const initial_filter_data = {
    cropyear: "",
    regions: "",
    contract_id: "",
    bogs: "",
    varieties: "",
    maps: "",
    report_types: "",
  };

  const initial_filter_list_data = {
    cropyear_list: [],
    min_cropyear: "",
    max_cropyear: "",
    region_list: [],
    contract_list: [],
    contract_disabled: true,
    bogs_list: [],
    bogs_disabled: true,
    varieties_list: [],
    varieties_disabled: true,
    maps_list: [],
    maps_disabled: true,
    report_types_list: [],
    report_types_disabled: true,
  };

  const [master_data, dispatch_master_data] = useReducer(CommonReducer, {});
  const [filter_data, dispatch_filter_data] = useReducer(
    CommonReducer,
    initial_filter_data
  );
  const [filter_list_data, dispatch_filter_list_data] = useReducer(
    CommonReducer,
    initial_filter_list_data
  );

  function CommonReducer(state, action) {
    try {
      const { type, payload } = action || {};
      switch (type) {
        case "replace":
          return { ...payload };
        case "change":
          return CommonSingleDataMapper(state, payload);
        case "data_list":
          return CommonMultiDataMapper(state, payload);
        default:
          return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function CommonSingleDataMapper(state, payload) {
    try {
      const temp = { ...state };
      const { key, value } = payload || {};
      temp[key] = value;
      return temp;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function CommonMultiDataMapper(state, payload) {
    try {
      const temp = { ...state };
      const { data_list = [] } = payload || {};
      if (IsArrayNotEmpty(data_list)) {
        data_list.forEach((li) => {
          const { key, value } = li;
          temp[key] = value;
        });
        return temp;
      }
      return temp;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function FilterDataSetter(label, data) {
    const payload = { key: label, value: "" };
    try {
      if (IsObjectChecker(data)) {
        payload["value"] = data[label];
      }
      dispatch_filter_data({ type: "change", payload });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      dispatch_filter_data({ type: "change", payload });
    }
    MapListDecide();
  }

  function OnCropYearChange(e) {
    const { value = "" } = e?.target || {};
    dispatch_filter_data({
      type: "change",
      payload: { key: cropyear_name, value },
    });
    fetchBedhistories({ ...filter_data, cropyear: value });
    EmptyOtherFiltersDataOnly();
    MapListDecide();
  }

  function RegionChangeHandler(type, value, item) {
    EmptyMasterFilters();
    const data_list = [];
    data_list.push({ key: contract_list_name, value: [] });
    if (compareString(type, "select")) {
      if (IsArrayNotEmpty(value)) {
        data_list.push({ key: contract_disabled_name, value: false });
      }
    } else {
      const { id = "" } = item || {};
      if (compareString(id, "all")) {
        data_list.push({ key: contract_disabled_name, value: true });
      } else {
        if (IsArrayNotEmpty(value)) {
          data_list.push({ key: contract_disabled_name, value: false });
        } else {
          data_list.push({ key: contract_disabled_name, value: true });
        }
      }
    }
    dispatch_filter_list_data({ type: "data_list", payload: { data_list } });
  }

  function OnContractSearch(query) {
    const { regions = "" } = filter_data;
    const test_query = /^\d+$/.test(query);
    if (IsStringChecker(regions) && test_query) {
      fetchContracts(regions, query);
    }
  }

  function OnContractClear() {
    dispatch_filter_data({
      type: "change",
      payload: { key: contract_id_name, value: "" },
    });
    EmptyOtherFilters();
    MapListDecide();
  }

  function OnContractChange(data) {
    if (IsArrayNotEmpty(data) && data.length === 1) {
      const value = _.get(data, `0.RelationshipId`, "");
      dispatch_filter_data({
        type: "change",
        payload: { key: contract_id_name, value },
      });
      fetchBedhistories({ ...filter_data, [contract_id_name]: value });
      MapListDecide();
    }
  }

  function BogsChangeHandler(type, value, item) {
    const { original_data = [] } = master_data || {};
    if (IsArrayNotEmpty(original_data)) {
      EmptyOtherFiltersDataOnly(false);
      if (compareString(type, "select")) {
        if (IsArrayNotEmpty(value)) {
          const filtered_data = FilterArrayDataByArrayObjects(
            original_data,
            value,
            "BogName"
          );
          OtherFilterList(filtered_data, false);
          OtherFilterMasterDataSetter(filtered_data, false);
        }
      } else {
        const { id = "" } = item || {};
        if (compareString(id, "all")) {
          OtherFilterList(original_data, false);
          OtherFilterMasterDataSetter(original_data);
        } else {
          if (IsArrayNotEmpty(value)) {
            const filtered_data = FilterArrayDataByArrayObjects(
              original_data,
              value,
              "BogName"
            );
            OtherFilterList(filtered_data, false);
            OtherFilterMasterDataSetter(filtered_data, false);
          } else {
            OtherFilterList(original_data, false);
            OtherFilterMasterDataSetter(original_data);
          }
        }
      }
    }
  }

  function MapsChangeHandler(type, value, item) {
    const { bogs_data = [] } = master_data || {};
    if (IsArrayNotEmpty(bogs_data)) {
      EmptyOtherFiltersDataOnly(false, false);
      if (compareString(type, "select")) {
        if (IsArrayNotEmpty(value)) {
          const filtered_data = FilterArrayDataByArrayObjects(
            bogs_data,
            value,
            "MapId"
          );
          OtherFilterList(filtered_data, false, false);
          OtherFilterMasterDataSetter(filtered_data, false, false);
        }
      } else {
        const { id = "" } = item || {};
        if (compareString(id, "all")) {
          OtherFilterList(bogs_data, false, false);
          OtherFilterMasterDataSetter(bogs_data, false);
        } else {
          if (IsArrayNotEmpty(value)) {
            const filtered_data = FilterArrayDataByArrayObjects(
              bogs_data,
              value,
              "MapId"
            );
            OtherFilterList(filtered_data, false, false);
            OtherFilterMasterDataSetter(filtered_data, false, false);
          } else {
            OtherFilterList(bogs_data, false, false);
            OtherFilterMasterDataSetter(bogs_data, false);
          }
        }
      }
    }
  }

  function OtherFilterMasterDataSetter(
    data = [],
    original = true,
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true
  ) {
    try {
      if (IsArrayNotEmpty(data)) {
        const data_list = [];

        if (original) {
          data_list.push({ key: original_data_name, value: data });
        }

        if (bogs) {
          data_list.push({ key: bogs_data_name, value: data });
        }

        if (maps) {
          data_list.push({ key: maps_data_name, value: data });
        }

        if (varieties) {
          data_list.push({ key: varieties_data_name, value: data });
        }

        if (report_types) {
          data_list.push({ key: report_types_data_name, value: data });
        }

        dispatch_master_data({
          type: "data_list",
          payload: { data_list },
        });
      } else {
        EmptyMasterData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      EmptyMasterData();
    }
  }

  function OtherFilterList(
    data = [],
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true
  ) {
    try {
      if (IsArrayNotEmpty(data)) {
        const data_list = [];

        if (bogs) {
          const bogs_obj = _.groupBy(data, "BogName");
          const bogs_list = Object.keys(bogs_obj).map((x) => ({
            id: x,
            name: x,
          }));

          data_list.push({ key: bogs_disabled_name, value: false });
          data_list.push({ key: bogs_list_name, value: bogs_list });
        }

        if (maps) {
          const maps_obj = _.groupBy(data, "MapId");
          const maps_list = Object.keys(maps_obj).map((x) => ({
            id: x,
            name: x,
          }));

          data_list.push({ key: maps_disabled_name, value: false });
          data_list.push({ key: maps_list_name, value: maps_list });
        }

        if (varieties) {
          const varieties_obj = _.groupBy(data, "VarietyId");
          const varieties_list = [];
          for (const key in varieties_obj) {
            if (Object.prototype.hasOwnProperty.call(varieties_obj, key)) {
              const element = varieties_obj[key][0];
              varieties_list.push({
                id: key,
                name: element?.VarietyDescription,
              });
            }
          }

          data_list.push({ key: varieties_disabled_name, value: false });
          data_list.push({ key: varieties_list_name, value: varieties_list });
        }

        if (report_types) {
          data_list.push({ key: report_types_disabled_name, value: false });
        }

        dispatch_filter_list_data({
          type: "data_list",
          payload: { data_list },
        });
      } else {
        EmptyOtherFilters();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      EmptyOtherFilters();
    }
  }

  function EmptyMasterData() {
    dispatch_master_data({
      type: "replace",
      payload: {},
    });
  }

  function EmptyMasterFilters(data_list = []) {
    data_list.push({ key: contract_id_name, value: "" });
    dispatch_filter_data({
      type: "data_list",
      payload: { data_list },
    });
    asyncTypeaheadRef?.current?.clear();
    EmptyMasterData();
    EmptyOtherFilters();
  }

  function EmptyOtherFiltersDataOnly(
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true
  ) {
    const data_list = [];
    if (bogs) {
      data_list.push({ key: bogs_name, value: "" });
    }
    if (maps) {
      data_list.push({ key: maps_name, value: "" });
    }
    if (varieties) {
      data_list.push({ key: varieties_name, value: "" });
    }
    if (report_types) {
      data_list.push({ key: report_types_name, value: "" });
    }
    dispatch_filter_data({
      type: "data_list",
      payload: { data_list },
    });
    ReloadDropDowns(bogs, maps, varieties, report_types);
  }

  function EmptyOtherFiltersDataListOnly(
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true
  ) {
    const data_list = [];
    if (bogs) {
      data_list.push({ key: bogs_disabled_name, value: true });
      data_list.push({ key: bogs_list_name, value: [] });
    }
    if (maps) {
      data_list.push({ key: maps_disabled_name, value: true });
      data_list.push({ key: maps_list_name, value: [] });
    }
    if (varieties) {
      data_list.push({ key: varieties_disabled_name, value: true });
      data_list.push({ key: varieties_list_name, value: [] });
    }
    if (report_types) {
      data_list.push({ key: report_types_disabled_name, value: true });
    }
    dispatch_filter_list_data({
      type: "data_list",
      payload: { data_list },
    });
    ReloadDropDowns(bogs, maps, varieties, report_types);
  }

  function EmptyOtherFilters(
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true
  ) {
    EmptyOtherFiltersDataListOnly(bogs, maps, varieties, report_types);
    EmptyOtherFiltersDataOnly(bogs, maps, varieties, report_types);
  }

  function MapListDecide() {
    setNoRecordErrorMsg("");
    setHideTheMap(true);
  }

  function ReloadDropDowns(
    bogs = true,
    maps = true,
    varieties = true,
    report_types = true,
    regions = false
  ) {
    function BuildObj(obj, bool = false) {
      const temp = { ...obj };
      if (bogs) {
        temp["bogs_reload"] = bool;
      }
      if (maps) {
        temp["maps_reload"] = bool;
      }
      if (varieties) {
        temp["varieties_reload"] = bool;
      }
      if (report_types) {
        temp["report_types_reload"] = bool;
      }
      if (regions) {
        temp["regions_reload"] = bool;
      }
      return temp;
    }
    setDisplayDropDown((prev) => BuildObj(prev));
    setTimeout(() => {
      setDisplayDropDown((prev) => BuildObj(prev, true));
    }, 100);
  }

  useEffect(() => {
    setLoader(true);
    if (IsTokenChecker(idToken)) {
      fetchCropYear();
      fetchRegions();
      SetReportOptions();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  async function fetchContracts(regions = "", query = "") {
    setContractsLoading(true);
    const url = `contracts?r.region=${regions}&r.contractstatus=1000,1001&r.relationshipnumber=${query}`;
    gbsDataCommonMethod(idToken, accounts, url, "get", {})
      .then((res) => {
        const data = safeArrayCheck(res.data);
        const value = IsArrayNotEmpty(data)
          ? data.map((i) => {
              i.ContractRelationshipNumber = `${i.RelationshipNumber} ${
                i.Name || ""
              } ${i.Name2 || ""} ${i.Name3 || ""}`;
              return i;
            })
          : [];
        dispatch_filter_list_data({
          type: "change",
          payload: { key: contract_list_name, value },
        });
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        dispatch_filter_list_data({
          type: "change",
          payload: { key: contract_list_name, value: [] },
        });
      })
      .finally(() => {
        setContractsLoading(false);
      });
  }

  async function fetchCropYear() {
    const url = `crop-years`;
    await gbsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const cropyears = safeArrayCheck(resp?.data);
        if (IsArrayNotEmpty(cropyears)) {
          const currentCropData =
            cropyears?.find((x) => x.CurrentCrop === true)?.CropYear ||
            onGoingYear;
          const value = NumberToNormal(currentCropData);

          const cropYear = cropyears.splice(0, 4);
          const multiSelectCropYears = cropYear.map((x) => ({
            cropyear: x.CropYear,
            current: x.CurrentCrop,
          }));
          const data_list = [];
          data_list.push({
            key: cropyear_list_name,
            value: multiSelectCropYears,
          });
          data_list.push({
            key: min_cropyear_name,
            value: Math.min(...multiSelectCropYears.map((x) => x.cropyear)),
          });
          data_list.push({
            key: max_cropyear_name,
            value: Math.max(...multiSelectCropYears.map((x) => x.cropyear)),
          });
          dispatch_filter_data({
            type: "change",
            payload: { key: cropyear_name, value },
          });
          dispatch_filter_list_data({
            type: "data_list",
            payload: {
              data_list,
            },
          });
        } else {
          CropYearFail();
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        CropYearFail();
      });
  }

  async function fetchRegions() {
    const url = "regions";
    await gbsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const value = safeArrayCheck(sortByField(_.get(resp, "data", [])))?.map(
          (li) => ({ id: li?.Value, name: li?.Description })
        );
        dispatch_filter_list_data({
          type: "change",
          payload: { key: region_list_name, value },
        });
      })
      .catch((error) => {
        dispatch_filter_list_data({
          type: "change",
          payload: { key: region_list_name, value: [] },
        });
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function SetReportOptions() {
    try {
      const value = Object.keys(contracted_acreage_map_report_options).map(
        (x) => ({
          id: x,
          name: contracted_acreage_map_report_options[x],
        })
      );
      if (IsArrayNotEmpty(value)) {
        dispatch_filter_list_data({
          type: "change",
          payload: { key: report_types_list_name, value },
        });
      } else {
        dispatch_filter_list_data({
          type: "change",
          payload: { key: report_types_list_name, value: [] },
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      dispatch_filter_list_data({
        type: "change",
        payload: { key: report_types_list_name, value: [] },
      });
    }
  }

  async function fetchBedhistories(payload = {}) {
    const { cropyear = "", regions = "", contract_id = "" } = payload || {};
    const grant_access = IsAllArgumentsTrue(
      `${cropyear}`,
      `${regions}`,
      `${contract_id}`
    );
    if (grant_access) {
      const url = `bed-histories?cropyear=${cropyear}&relationshipid=${contract_id}`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const data = safeArrayCheck(_.get(resp, "data", []));
          OtherFilterMasterDataSetter(data);
          OtherFilterList(data);
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          OtherFilterMasterDataSetter([]);
          OtherFilterList([]);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  }

  function EnableActionButtons(obj = {}) {
    const { cropyear = "", regions = "", contract_id = "" } = obj;
    try {
      const arg_a = IsStringChecker(`${cropyear}`);
      const arg_b = IsStringChecker(`${regions}`);
      const arg_c = IsStringChecker(`${contract_id}`);
      const some = IsSomeArgumentsTrue(arg_b, arg_c);
      const all = IsAllArgumentsTrue(arg_a, arg_b, arg_c);
      return { clear_btn: some, submit_btn: all };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { clear_btn: false, submit_btn: false };
    }
  }

  const { cropyear = "", report_types = "" } = filter_data;
  const {
    min_cropyear = "",
    max_cropyear = "",
    contract_list = [],
    region_list = [],
    contract_disabled = true,
    bogs_list = [],
    bogs_disabled = true,
    varieties_list = [],
    varieties_disabled = true,
    maps_list = [],
    maps_disabled = true,
    report_types_list = [],
    report_types_disabled = true,
  } = filter_list_data;
  const {
    bogs_reload = true,
    varieties_reload = true,
    maps_reload = true,
    report_types_reload = true,
    regions_reload = true,
  } = displayDropDown;
  const { clear_btn = false, submit_btn = false } =
    EnableActionButtons(filter_data);
  // console.log({ GridItems, LongLat, FillMapColor, LayerType });
  // console.log({ master_data, filter_data, filter_list_data });
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="field-tools" />
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug="field-tools" />
            </div>
          </div>
          <div className="payment-estimator-form col-12 col-lg-10">
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-3">
              <h3 className="mob-fw-bold py-3">
                Contracted Acreage Map
                {/* <span className="drop-offinfo">(All fields are required)</span> */}
              </h3>
              {/* <hr className="Payment-Estimator-Segment" /> */}
              {/*<!-- Filter Dropdown starts -->*/}
              <nav className="navbar navbar-expand-lg p-0 mt-lg-2 mb-lg-0 mb-2">
                <div className={`cancel_reset ${isMobFilter ? " show " : ""}`}>
                  <span
                    className="float-start blue_color cancel_click"
                    onClick={() => handlerMobFilter("cancel")}
                  >
                    Cancel
                  </span>
                  <span
                    className="float-end blue_color fw-bold"
                    onClick={OnReset}
                    disabled={!clear_btn}
                  >
                    Reset
                  </span>
                </div>
                <a
                  className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
                    isMobFilter ? " active " : ""
                  }`}
                  data-bs-toggle="collapse"
                  data-bs-target="#selectWrap2"
                  aria-controls="selectWrap2"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  href="#"
                  onClick={() => handlerMobFilter("filter")}
                >
                  <span className="fw-bold">Filter</span>{" "}
                  <span className="grey_color hide_text">(All) </span>
                  <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
                </a>
                <div
                  className={`collapse navbar-collapse selectWrapBlock  ${
                    isMobFilter ? " show " : ""
                  } bottom-0-imp height-auto-imp mb-5 mb-lg-0`}
                  id="selectWrap2"
                >
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="row">
                        <div
                          htmlFor="customRange1"
                          className="form-label text-uppercase text-10 grey_color acreage_crop_year"
                        >
                          <span>Crop Year</span>
                          <i className="fa fa-chevron-down p-3 px-1 blue_color position-relative r-10 float-end text-14 d-block d-lg-none"></i>
                        </div>
                        <div className="slider position-relative acreage_crop_year_dropdown">
                          <input
                            type="range"
                            name="ageInputName"
                            id="ageInputId"
                            value={cropyear}
                            min={min_cropyear}
                            max={max_cropyear}
                            onChange={OnCropYearChange}
                          />
                          <output
                            name="ageOutputName"
                            className="position-absolute top-50 start-50 text-10 position_transform"
                            id="ageOutputId"
                          >
                            {cropyear}
                          </output>
                          <span className="position-absolute start-0 mt-4 text-10 start_label">
                            {min_cropyear}
                          </span>
                          <span className="position-absolute end-0 mt-4 text-10 end_label">
                            {max_cropyear}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div className="region_label custom_dropdown cam-multiselect">
                        {regions_reload && (
                          <MultiDropDown
                            optionList={region_list}
                            prompt="Select"
                            value={filter_data}
                            onChange={(e) => RegionChangeHandler("select", e)}
                            label={regions_name}
                            dispatchState={FilterDataSetter}
                            disabled=""
                            onDelete={(e, selectedItem) =>
                              RegionChangeHandler("deselect", e, selectedItem)
                            }
                            preSelectedOpt={["all"]}
                            optionNotFound={{ regions: "No Regions Found" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-5 mt-2 mt-lg-0 personal_info">
                      <div className="position-relative">
                        <span className="text-14 z-index-1 col-cement">
                          Contract Number & Name
                        </span>
                        <AsyncTypeahead
                          ref={asyncTypeaheadRef}
                          id="async-example"
                          contractLoading={contractLoading}
                          labelKey="ContractRelationshipNumber"
                          minLength={3}
                          onSearch={OnContractSearch}
                          options={contract_list}
                          placeholder="Search contract number"
                          className="AG_contracts"
                          onChange={OnContractChange}
                          disabled={contract_disabled}
                        >
                          {({ onClear, selected }) =>
                            !!selected.length && (
                              <div className="rbt-aux">
                                <ClearButton
                                  onClick={() => {
                                    onClear();
                                    OnContractClear();
                                  }}
                                />
                              </div>
                            )
                          }
                        </AsyncTypeahead>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-2">
                      <div className="bog_name custom_dropdown cam-multiselect">
                        {bogs_reload && (
                          <MultiDropDown
                            optionList={bogs_list}
                            prompt="Select"
                            value={filter_data}
                            onChange={(e) => BogsChangeHandler("select", e)}
                            onDelete={(e, selectedItem) =>
                              BogsChangeHandler("deselect", e, selectedItem)
                            }
                            label={bogs_name}
                            dispatchState={FilterDataSetter}
                            disabled={bogs_disabled}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-2">
                      <div className="map_id custom_dropdown cam-multiselect">
                        {maps_reload && (
                          <MultiDropDown
                            optionList={maps_list}
                            prompt="Select"
                            value={filter_data}
                            onChange={(e) => MapsChangeHandler("select", e)}
                            onDelete={(e, selectedItem) =>
                              MapsChangeHandler("deselect", e, selectedItem)
                            }
                            label={maps_name}
                            dispatchState={FilterDataSetter}
                            disabled={maps_disabled}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-2">
                      <div className="variety custom_dropdown cam-multiselect">
                        {varieties_reload && (
                          <MultiDropDown
                            optionList={varieties_list}
                            prompt="Select"
                            value={filter_data}
                            label={varieties_name}
                            dispatchState={FilterDataSetter}
                            disabled={varieties_disabled}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-2">
                      <div className="report_options custom_dropdown cam-multiselect">
                        {report_types_reload && (
                          <MultiDropDown
                            optionList={report_types_list}
                            prompt="Select"
                            value={filter_data}
                            label={report_types_name}
                            dispatchState={FilterDataSetter}
                            disabled={report_types_disabled}
                            singleSelect={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <button
                        className="btn btn-primary text-16 float-end d-none d-lg-block"
                        onClick={OnSubmit}
                        disabled={!submit_btn}
                      >
                        Search {!loading ? "" : <WhiteCircleLoader />}{" "}
                      </button>
                      <button
                        className="btn btn-default text-16 float-end me-2 d-none d-lg-block border-1"
                        onClick={OnReset}
                        disabled={!clear_btn}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`bottom-0  filter_apply_button white_bg ${
                    isMobFilter ? " show d-block " : " d-lg-none "
                  }`}
                >
                  <button
                    className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
                    onClick={OnSubmit}
                    disabled={!submit_btn}
                  >
                    Apply {!loading ? "" : <WhiteCircleLoader />}
                  </button>
                </div>
              </nav>
              <ContractedAcreageMapRender
                GridItems={GridItems}
                LongLat={LongLat}
                FillMapColor={FillMapColor}
                LayerType={LayerType}
                hideTheMap={hideTheMap}
                noRecordErrorMsg={noRecordErrorMsg}
                report_types={report_types}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractedAcreageMapDetail;

export const ContractedAcreageMapRender = (props) => {
  const {
    report_types = "",
    LongLat = {},
    GridItems = [],
    FillMapColor = {},
    LayerType = [],
    hideTheMap = true,
    noRecordErrorMsg = "",
    ZoomBasedOnContracts = 1,
  } = props;
  const { MAPBOX_TOKEN } = constantsVariable;

  const [hoverInfo, setHoverInfo] = useState(null);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
      lngLat: { lat, lng },
    } = event;
    const hoveredFeature = features && features[0];
    // prettier-ignore
    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, lat, lng });
  }, []);

  const SetLayerStyle = (Obj = {}) => {
    try {
      const reporttype = `${report_types}`;
      if (IsArrayNotEmpty(LayerType) && IsStringChecker(reporttype)) {
        const reportField = _.get(ReportOptionsKeyMap, reporttype, null);
        const Value = _.get(Obj, reportField, null);
        const ColorType = LayerType.find((list) => {
          const { ValueFrom, ValueTo } = list;
          if (ValueFrom === null && ValueTo === null) {
            if (Value === ValueFrom && Value === ValueTo) {
              return list;
            }
          } else if (ValueFrom === null || ValueTo === null) {
            if (ValueFrom === null) {
              if (Value === ValueFrom && Value <= ValueTo) {
                return list;
              }
            } else if (ValueTo === null) {
              if (Value >= ValueFrom && Value === ValueTo) {
                return list;
              }
            }
          } else {
            if (Value >= ValueFrom && Value <= ValueTo) {
              return list;
            }
          }
        });
        const {
          ColorType: ct = null,
          BorderRGBA = "light",
          BorderWidth = "thin",
        } = ColorType;
        return { ColorType: ct, BorderRGBA, BorderWidth };
      }
      return { ColorType: null, BorderRGBA: null, BorderWidth: null };
    } catch (error) {
      // HandleErrorTrackAndToast(error, true);
      return { ColorType: null, BorderRGBA: null, BorderWidth: null };
    }
  };

  const mapdata = useMemo(() => {
    if (IsArrayNotEmpty(GridItems)) {
      const fetchGeoJson = GridItems.reduce(function (arr, obj) {
        try {
          if (obj.GeoJson != null) {
            let objGeoJson = JSON.parse(obj.GeoJson);
            objGeoJson["properties"].HarvestBed = obj.HarvestBed;
            objGeoJson["properties"].VarietyDescription =
              obj.VarietyDescription;
            objGeoJson["properties"].TotalBarrels = obj.TotalBarrels;
            objGeoJson["properties"].ExhibitAcres = obj.ExhibitAcres;
            objGeoJson["properties"].Yield = obj.Yield;
            objGeoJson["properties"].PercentDryTrash = obj.PercentDryTrash;
            objGeoJson["properties"].PercentLowestMoist =
              obj.PercentLowestMoist;
            objGeoJson["properties"].AveragePercentPoor =
              obj.AveragePercentPoor;
            objGeoJson["properties"].PercentClass1 = obj.PercentClass1;
            objGeoJson["properties"].AveragePercentUsable =
              obj.AveragePercentUsable;
            objGeoJson["properties"].PercentGreaterThanHalf =
              obj.PercentGreaterThanHalf;
            objGeoJson["properties"].AverageFirmness = obj.AverageFirmness;
            const { ColorType, BorderRGBA, BorderWidth } = SetLayerStyle(obj);
            objGeoJson["properties"].ColorType = ColorType;
            objGeoJson["properties"].BorderRGBA = BorderRGBA;
            objGeoJson["properties"].BorderWidth = BorderWidth;
            arr.push(objGeoJson);
          }
          return arr;
        } catch (e) {
          HandleErrorTrackAndToast(e, true);
          return arr;
        }
      }, []);
      if (fetchGeoJson.length > 0) {
        const geoJson = {
          type: "FeatureCollection",
          features: fetchGeoJson,
        };
        return geoJson;
      }
    }
  }, [GridItems]);

  const getProperty = (str = null) => {
    try {
      if (IsStringChecker(str)) {
        const result = _.get(hoverInfo, `feature.properties.${str}`, null);
        if (lowStr(str).includes("percent")) {
          return result ? `${result}%` : null;
        }
        return result || null;
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const getPropertyFromReport = (str = null, defaultField = null) => {
    try {
      const reporttype = `${report_types}` || null;
      const field2 = defaultField ? defaultField : str;
      const access =
        IsStringChecker(reporttype) && compareString(reporttype, field2);
      if (access) {
        return getProperty(str);
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };
  const HarvestBed = getProperty("HarvestBed");
  const VarietyDescription = getProperty("VarietyDescription");
  const ExhibitAcres = getProperty("ExhibitAcres");
  const TotalBarrels = getPropertyFromReport("TotalBarrels", "Barrels");
  const Yield = getPropertyFromReport("Yield");
  const PercentDryTrash = getPropertyFromReport("PercentDryTrash");
  const PercentLowestMoist = getPropertyFromReport(
    "PercentLowestMoist",
    "PercentMoisture"
  );
  const AveragePercentPoor = getPropertyFromReport(
    "AveragePercentPoor",
    "PercentPoor"
  );
  const PercentClass1 = getPropertyFromReport(
    "PercentClass1",
    "PercentClassOne"
  );
  const AveragePercentUsable = getPropertyFromReport(
    "AveragePercentUsable",
    "PercentUsable"
  );
  const PercentGreaterThanHalf = getPropertyFromReport(
    "PercentGreaterThanHalf",
    "Percent>1/2"
  );
  const AverageFirmness = getPropertyFromReport("AverageFirmness", "Firmness");

  function TooltipValue() {
    const args = [...arguments];
    if (IsArrayNotEmpty(args)) {
      return args.reduce((acc, li, index) => {
        if (li) {
          try {
            if (index === 0) {
              return `${li}`;
            }
            return `${acc} - ${li}`;
          } catch (e) {
            return acc;
          }
        }
        return acc;
      }, ``);
    } else {
      return ``;
    }
  }
  const tooltip = TooltipValue(
    HarvestBed,
    VarietyDescription,
    ExhibitAcres,
    TotalBarrels,
    Yield,
    PercentDryTrash,
    PercentLowestMoist,
    AveragePercentPoor,
    PercentClass1,
    AveragePercentUsable,
    PercentGreaterThanHalf,
    AverageFirmness
  );

  const { latitude = 0, longitude = 0 } = LongLat;
  const { x, y, lat = 0, lng = 0 } = hoverInfo || {};
  if (hideTheMap) {
    return <Fragment></Fragment>;
  } else if (mapdata == undefined || IsStringChecker(noRecordErrorMsg)) {
    return (
      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
        <i className="fa fa-info-circle pe-2"></i>
        {noRecordErrorMsg}
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <div id="map">
            <Mapp
              initialViewState={{
                longitude: longitude,
                latitude: latitude,
                zoom: ZoomBasedOnContracts == 1 ? 14 : 11,
              }}
              style={{ height: 400 }}
              // mapStyle="mapbox://styles/mapbox/satellite-v9"
              // mapStyle="mapbox://styles/mapbox/streets-v12"
              mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
              mapboxAccessToken={MAPBOX_TOKEN}
              interactiveLayerIds={["data-Layer-Fill"]}
              onMouseMove={onHover}
            >
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <Source id="my-data" type="geojson" data={mapdata}>
                <Layer {...dataLayer} />
                <Layer {...FillMapColor} />
              </Source>
              {hoverInfo != null && (
                <>
                  <div className="map_tooltip" style={{ left: x, top: y }}>
                    <div>{tooltip}</div>
                  </div>
                  <Marker longitude={lng} latitude={lat} color="red" />
                </>
              )}
            </Mapp>
          </div>
        </div>
      </div>
    );
  }
};
