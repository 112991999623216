/**
 * @component_Name : ForecastingData
 * @description : Forecasting Data
 * @company : Photon
 * @author : Nivethithan Kannan/19-09-2024
 **/
import React from "react";
import PageLayout from "../../pageLayout";
import ForecastingDataDetail from "./forecastingDataDetail";

const ForecastingData = (props) => {
  return <PageLayout {...props} body={<ForecastingDataDetail {...props} />} />;
};

export default ForecastingData;
