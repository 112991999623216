/** 
@CreatedBy : Nivethithan Kannan/07-12-2022
@Description : Define all roles here.
*/
export const ContractBasedRoles = {
  roleCBAcreage: "OscGcnAcreage",
  roleCBPayment: "OscGcnPayments",
  roleCBDecisionMaker: "OscGcnDecisionMaker",
  roleCBThirdPartyFarmService: "OSCThirdPartyFarmService",
};

export const PersonBasedRoles = {
  rolePBCommitteeMember: "OscGcnCommitteeMember",
  rolePBAcademyMember: "OscGcnAcademyMember",
  rolePBBoardMember: "OscGcnBoardMember",
  rolePBNoContract: "OscGcnNoContract",
  rolePBAgScientist: "OscAgScientist",
  rolePBGrowerCouncil: "OscGcnGrowerCouncil",
  rolePBAllowImpersonation: "OscAllowImpersonation",
};

export const ConsolidatedRoles = { ...ContractBasedRoles, ...PersonBasedRoles };

export const {
  roleCBAcreage,
  roleCBPayment,
  roleCBDecisionMaker,
  roleCBThirdPartyFarmService,
  rolePBCommitteeMember,
  rolePBAcademyMember,
  rolePBBoardMember,
  rolePBNoContract,
  rolePBAgScientist,
  rolePBGrowerCouncil,
  rolePBAllowImpersonation,
} = ConsolidatedRoles;

// const CommonPersonRoles = [
//   rolePBCommitteeMember,
//   rolePBBoardMember,
//   rolePBNoContract,
//   rolePBGrowerCouncil,
// ];

export const SpecialRolesToFilter = [roleCBThirdPartyFarmService];

const Roles = [
  {
    page: "My Farm",
    pathNames: ["/my-farm"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
  },
  {
    page: "My People",
    pathNames: ["/my-farm/my-people"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
  },
  {
    page: "My Contracts",
    pathNames: ["/my-farm/my-contracts", "/my-contracts"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "My Acreage",
    pathNames: ["/my-farm/my-acreage"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "My Payments and Equity",
    pathNames: [
      "/my-farm/payments-and-equity",
      "/my-farm/payments-and-equity/payments",
      "/my-farm/payments-and-equity/equity",
      "/my-farm/payments-and-equity/statements-and-reports",
      "/my-farm/payments-and-equity/payment-estimator",
    ],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [roleCBPayment, roleCBDecisionMaker],
  },
  {
    page: "My Harvest",
    pathNames: ["/my-harvest"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "Pre Harvest",
    pathNames: ["/my-harvest/pre-harvest"],
    CheckBasedOn: "",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [rolePBAgScientist],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBPayment,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "My Delivery Data",
    pathNames: ["/my-harvest/my-delivery-data"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "Harvest News",
    pathNames: ["/my-harvest/harvest-news"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
    RoutePBRoles: [],
    DropDownCBRoles: [
      roleCBAcreage,
      roleCBPayment,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    page: "OS Academy - Past Presentations",
    pathNames: ["/os-academy/past-presentations"],
    CheckBasedOn: "PB",
    RouteCBRoles: [],
    RoutePBRoles: [rolePBAcademyMember],
    DropDownCBRoles: [],
  },
  {
    page: "Forecasting Data",
    pathNames: ["/forecasting-data"],
    CheckBasedOn: "OSEmployee",
    RouteCBRoles: [],
    RoutePBRoles: [],
    DropDownCBRoles: [],
  },
];

export default Object.freeze(Roles);

export const NotificationPreferencesRoleMappings = [
  {
    header: "News Feed",
    RolesToCheck: [
      roleCBPayment,
      roleCBDecisionMaker,
      roleCBAcreage,
      rolePBBoardMember,
      rolePBAcademyMember,
      rolePBCommitteeMember,
      rolePBAgScientist,
      rolePBGrowerCouncil,
    ],
  },
  {
    header: "Acreage Status Change",
    RolesToCheck: [roleCBAcreage, roleCBDecisionMaker],
  },
  {
    header: "Payment Notifications",
    RolesToCheck: [roleCBPayment, roleCBDecisionMaker],
  },
  {
    header: "Harvest Announcements",
    RolesToCheck: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    header: "Delivery Added",
    RolesToCheck: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
  {
    header: "Delivery Finalized",
    RolesToCheck: [
      roleCBAcreage,
      roleCBDecisionMaker,
      roleCBThirdPartyFarmService,
    ],
  },
];

const OSCThirdPartyFarmServiceRoles = [
  {
    page: "Site Search",
    pathNames: ["/search-results"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "My Acreage",
    pathNames: ["/my-farm/my-acreage"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "My OS Contacts",
    pathNames: [
      "/my-farm/my-os-contacts",
      "/my-farm/my-os-contacts/:contactID/:pageSection/:contactName",
    ],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "My Harvest",
    pathNames: ["/my-harvest"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Pre Harvest",
    pathNames: ["/my-harvest/pre-harvest"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "My Delivery Data",
    pathNames: ["/my-harvest/my-delivery-data"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Harvest News",
    pathNames: ["/my-harvest/harvest-news"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Community - Ag Sciences",
    pathNames: [
      "/community/ag-sciences",
      "/community/ag-sciences/region",
      "/community/ag-sciences/region/:region",
      "/community/ag-sciences/region/:contactID/:pageSection/:contactName",
    ],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    DropDownCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Community - Policies & Forms",
    pathNames: ["/community/ag-sciences/policies-and-forms"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Cooperative - Ocean Spray Contacts",
    pathNames: [
      "/cooperative/oceanspray-contacts",
      "/cooperative/oceanspray-contacts/:contactID/:pageSection/:contactName",
    ],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Documents - Pick Up Page",
    pathNames: ["/documents/pickup-page"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
  },
  {
    page: "Documents - Archive - Codebooks",
    pathNames: ["/documents/archives/:categorySlug?"],
    CheckBasedOn: "CB",
    RouteCBRoles: [roleCBThirdPartyFarmService],
    RouteParamCBRoles: ["codebooks"],
  },
];

export const SpecialRolesDefinition = {
  [roleCBThirdPartyFarmService]: Object.freeze(OSCThirdPartyFarmServiceRoles),
};
