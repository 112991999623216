/* eslint-disable */
/**
 * @component_Name : FieldToolsDetail
 * @description : Field Tools Page
 * @company : Photon
 * @author : Nivethithan Kannan/14-11-2024
 **/

import React, { useEffect, useState, Fragment, useReducer } from "react";
import BreadCrumb from "../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../global/loading";
import moment from "moment";
import CurrentLocation from "../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
  compareString,
  getCurrentYear,
  NumConverter,
  IsNumberChecker,
  IsObjectChecker,
  ReplaceToastStack,
} from "../../utils/helper";
import {
  Form,
  Accordion,
  Alert,
  InputGroup,
  Button,
  FormGroup,
  CloseButton,
  Table,
} from "react-bootstrap";
import WhiteCircleLoader from "../whiteCircleLoader";
import MultiDropDown from "../selectBox/multiDropDown";
import QuickLinks from "../home/quickLinks";
import LeftMenu from "../global/leftMenu";
import {
  FilterContractByRole,
  ImpersonationAccountsSwap,
  LocalAccountIDSwapImpersonate,
  DetermineImpersonation,
} from "../../permissionWrapper";
import constantsVariable from "../../utils/constantsVariable";
import DatePickerComp from "../global/datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FieldToolsDetail = (props) => {
  const impersonated = DetermineImpersonation();
  const { DropDownCBRoles = [] } = props;
  const today = moment().utc().format("MM/DD/YYYY");
  const news_info_box = `Introducing an innovative forecasting tool that predicts cranberry yields using bud count. This feature leverages real-time data and historical trends to help growers optimize resource allocation and boost profitability, representing a significant leap in precision agriculture.`;
  const bud_section_info_box = `This section focuses on entering bud counts by date and provides details about the bud count history table. It allows for easy tracking of bud counts over time. Growers can efficiently manage and review their bud count entries.`;
  const crop_condition_section_info_box = `This section focuses on assessing crop conditions over time, providing detailed insights into various factors affecting growth. It allows for easy tracking of crop health and development. Growers can efficiently manage and review their crop condition data to make informed decisions.`;
  const fruit_section_info_box = `This section focuses on entering cranberry fruit counts by date and provides details about the cranberry count history table. It allows for easy tracking of fruit counts over time. Growers can efficiently manage and review their cranberry count entries.`;

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const { defaultToastMsgProperties, dateFormate } = constantsVariable;

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const localAccountId = LocalAccountIDSwapImpersonate(accounts);

  const [loading, setLoading] = useState(false);
  const defaultAlert = { trigger: false, variant: "primary", message: "" };
  const [saveAlert, setSaveAlert] = useState(defaultAlert);



  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="field-tools" />
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug="field-tools" />
            </div>
          </div>
          <div className="payment-estimator-form col-12 col-lg-10">
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-3">
              <h3 className="mob-fw-bold py-3">
                Field Tools Page
                {/* <span className="drop-offinfo">(All fields are required)</span> */}
              </h3>
              <p>Development in progress</p>
              <hr className="Payment-Estimator-Segment" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldToolsDetail;
