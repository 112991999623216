/**
 * @component_Name : PaymentEstimatorDetail
 * @description : Payment Estimator
 * @company : Photon
 * @author : Nivethithan Kannan/02-01-2024
 **/

import React, { useState, Fragment, useEffect } from "react";
import _ from "lodash";
import {
  IsArrayNotEmpty,
  IsStringChecker,
  HandleErrorTrackAndToast,
  compareString,
  IsNumberChecker,
  IsObjectChecker,
} from "../../../../utils/helper";
import {
  CommaNumberToNormal,
  IsNumber,
  WholeRateValidation,
  NumberFormaterWithValidation,
  Digit4NumberFormaterWithValidation,
  BarrelsNumberFormater,
  KeyDownHandler,
  NineMillionLimit,
  OneMillionLimit,
  HundredLimit,
  ZeroLimit,
  OnChangeDecimalWrapper,
  FiveMillionLimit,
  OnFocusOutHandler,
  EstimateNumberFormater,
  default_par_value,
} from "./paymentEstimatorFunctionsCommon";
import { Form, Accordion, InputGroup, Button, Alert } from "react-bootstrap";
const PaymentEstimatorAREStockRetain = (props) => {
  const {
    are_stock_retain = {},
    dispatchAREStockRetain,
    enable_calculate_section = null,
    SectionHandler,
    are_stock_preload = {},
    EmptyAREStockPreloadData,
  } = props;

  const max_limit_err_msg = `A valid number [0 - 9,000,000] is required`;
  const percent_limit_err_msg = "A valid number [0 - 100] is required";
  const lakh_limit_err_msg = "A valid number [0 - 1,000,000] is required";
  const are_err_msg = "A valid number [0 - 5,000,000] is required";

  const common_barrels_delivered_name = "common_barrels_delivered";
  const are_rate_name = "are_rate";
  const are_issuance_name = "are_issuance";
  const are_redemption_name = "are_redemption";
  const average_deliveries_name = "average_deliveries";
  const quota_rate_name = "quota_rate";
  const par_value_name = "par_value";
  const quota_shares_name = "quota_shares";
  const common_stock_shares_held_name = "common_stock_shares_held";
  const percent_to_quota_name = "percent_to_quota";
  const retain_rate_name = "retain_rate";
  const retain_dollars_name = "retain_dollars";
  const potential_retain_shares_name = "potential_retain_shares";
  const shares_under_quota_name = "shares_under_quota";
  const shares_under_quota_value_name = "shares_under_quota_value";
  const retain_shares_to_be_issued_name = "retain_shares_to_be_issued";
  const retain_shares_to_be_issued_value_name =
    "retain_shares_to_be_issued_value";
  const cash_in_lieu_of_stock_name = "cash_in_lieu_of_stock";
  const percent_list = [
    quota_rate_name,
    par_value_name,
    percent_to_quota_name,
    retain_rate_name,
  ];
  const dollars_list = [
    are_issuance_name,
    are_redemption_name,
    retain_dollars_name,
    shares_under_quota_value_name,
    retain_shares_to_be_issued_value_name,
    cash_in_lieu_of_stock_name,
  ];
  const lakh_list = [shares_under_quota_name, retain_shares_to_be_issued_name];
  const [are_stock_error_msg, set_are_stock_error_msg] = useState("");

  function onAREChange(e) {
    const { name, value } = OnChangeDecimalWrapper(e);
    AREChangeHandler(name, value);
  }

  function onStockRetainChange(e) {
    const { name, value } = OnChangeDecimalWrapper(e);
    StockRetainChangeHandler(name, value, "event");
  }

  function AREChangeHandler(name, value) {
    const found = dollars_list.find((li) => compareString(li, name));
    const num = compareString("are_rate", name)
      ? Digit4NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          HundredLimit
        )
      : found
      ? BarrelsNumberFormater(
          NumberFormaterWithValidation(
            value,
            WholeRateValidation,
            ZeroLimit,
            FiveMillionLimit
          )
        )
      : NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          FiveMillionLimit
        );
    const error = IsNumber(
      num,
      true,
      true,
      true,
      ZeroLimit,
      compareString("are_rate", name) ? HundredLimit : FiveMillionLimit
    )
      ? false
      : true;

    if (compareString("are_rate", name)) {
      const common_barrels_delivered = _.get(
        are_stock_retain,
        "common_data.common_barrels_delivered",
        ""
      );
      const new_num =
        CommaNumberToNormal(common_barrels_delivered) * parseFloat(num);
      const new_num_val = BarrelsNumberFormater(
        NumberFormaterWithValidation(
          `${new_num}`,
          WholeRateValidation,
          ZeroLimit,
          FiveMillionLimit
        )
      );
      const new_num_error = IsNumber(
        `${new_num_val}`,
        true,
        true,
        true,
        ZeroLimit,
        FiveMillionLimit
      )
        ? false
        : true;
      dispatchAREStockRetain({
        type: "AREChange",
        payload: {
          data_list: [
            {
              name,
              value: num,
              error,
            },
            {
              name: "are_issuance",
              value: new_num_val,
              error: new_num_error,
            },
          ],
        },
      });
    } else {
      dispatchAREStockRetain({
        type: "AREChange",
        payload: {
          data_list: [
            {
              name,
              value: num,
              error,
            },
          ],
        },
      });
    }
  }

  function StockRetainChangeHandler(name, value) {
    const percent_access = percent_list.some((li) => compareString(li, name));
    const lakh_access = lakh_list.some((li) => compareString(li, name));
    const max_limit = percent_access
      ? HundredLimit
      : lakh_access
      ? OneMillionLimit
      : NineMillionLimit;
    const found = dollars_list.find((li) => compareString(li, name));
    const num = compareString(name, retain_rate_name)
      ? Digit4NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          max_limit
        )
      : found
      ? BarrelsNumberFormater(
          NumberFormaterWithValidation(
            value,
            WholeRateValidation,
            ZeroLimit,
            max_limit
          )
        )
      : NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          ZeroLimit,
          max_limit
        );
    const error = IsNumber(num, true, true, true, ZeroLimit, max_limit)
      ? false
      : true;

    if (error) {
      dispatchAREStockRetain({
        type: "StockRetainChange",
        payload: {
          data_list: [{ name, value: num, error }],
        },
      });
    } else {
      const master_data = { ...are_stock_retain };
      const common_data = master_data?.common_data || {};
      const current_data = master_data?.stock_retain_data || {};
      const state = { ...current_data };

      state[name] = num;
      state[`${name}_error`] = error;

      const data_list = [];
      data_list.push({ name, value: num, error });
      StockRetainMapper(data_list, state, common_data, name);

      dispatchAREStockRetain({
        type: "StockRetainChange",
        payload: {
          data_list,
        },
      });
    }
  }

  function StockRetainMapper(data_list, state, common_data, name) {
    try {
      const {
        average_deliveries = "",
        quota_rate = "",
        par_value = "",
        quota_shares = "",
        common_stock_shares_held = "",
        retain_rate = "",
        retain_dollars = "",
        potential_retain_shares = "",
        shares_under_quota = "",
        retain_shares_to_be_issued = "",
        retain_shares_to_be_issued_value = "",
      } = state;

      if (
        compareString(name, average_deliveries_name) ||
        compareString(name, quota_rate_name) ||
        compareString(name, par_value_name)
      ) {
        StockRetainCalculatorLevel1(
          data_list,
          common_data,
          average_deliveries,
          quota_rate,
          par_value,
          common_stock_shares_held,
          retain_rate
        );
      } else if (
        compareString(name, quota_shares_name) ||
        compareString(name, common_stock_shares_held_name)
      ) {
        return StockRetainCalculatorLevel2(
          data_list,
          common_data,
          par_value,
          quota_shares,
          common_stock_shares_held,
          retain_rate
        );
      } else if (compareString(name, retain_rate_name)) {
        StockRetainCalculatorLevel3(
          data_list,
          common_data,
          par_value,
          retain_rate,
          shares_under_quota
        );
      } else if (
        compareString(name, retain_dollars_name) ||
        compareString(name, potential_retain_shares_name) ||
        compareString(name, shares_under_quota_name)
      ) {
        return StockRetainCalculatorLevel4(
          data_list,
          par_value,
          retain_dollars,
          potential_retain_shares,
          shares_under_quota
        );
      } else if (compareString(name, retain_shares_to_be_issued_name)) {
        return StockRetainCalculatorLevel5(
          data_list,
          par_value,
          retain_dollars,
          retain_shares_to_be_issued
        );
      } else if (compareString(name, retain_shares_to_be_issued_value_name)) {
        return StockRetainCalculatorLevel6(
          data_list,
          par_value,
          retain_dollars,
          retain_shares_to_be_issued_value
        );
      } else {
        return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function StockRetainCalculatorLevel1(
    data_list,
    common_data,
    average_deliveries,
    quota_rate,
    par_value,
    common_stock_shares_held,
    retain_rate
  ) {
    try {
      const n_average_deliveries = CommaNumberToNormal(average_deliveries),
        n_quota_rate = parseFloat(quota_rate),
        n_par_value = parseFloat(par_value);
      if (
        IsNumberChecker(n_average_deliveries) &&
        IsNumberChecker(n_quota_rate) &&
        IsNumberChecker(n_par_value)
      ) {
        const n_quota_shares = Math.round(
          (n_average_deliveries * n_quota_rate) / n_par_value
        );
        const t_quota_shares = WholeRateValidation(
          n_quota_shares,
          ZeroLimit,
          NineMillionLimit
        );
        const e_quota_shares = IsNumber(
          t_quota_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: quota_shares_name,
          value: t_quota_shares,
          error: e_quota_shares,
        });

        if (!e_quota_shares) {
          return StockRetainCalculatorLevel2(
            data_list,
            common_data,
            par_value,
            n_quota_shares,
            common_stock_shares_held,
            retain_rate
          );
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel2(
    data_list,
    common_data,
    par_value,
    quota_shares,
    common_stock_shares_held,
    retain_rate
  ) {
    try {
      const n_par_value = parseFloat(par_value);
      const n_quota_shares = parseFloat(quota_shares);
      const n_common_stock_shares_held = CommaNumberToNormal(
        common_stock_shares_held
      );
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_quota_shares) &&
        IsNumberChecker(n_common_stock_shares_held)
      ) {
        const n_percent_to_quota =
          (n_common_stock_shares_held / n_quota_shares) * 100;
        const t_percent_to_quota = NumberFormaterWithValidation(
          `${n_percent_to_quota}`,
          WholeRateValidation,
          ZeroLimit,
          HundredLimit
        );
        const e_percent_to_quota = IsNumber(
          t_percent_to_quota,
          true,
          true,
          true,
          ZeroLimit,
          HundredLimit
        )
          ? false
          : true;

        const n_shares_under_quota =
          n_quota_shares > n_common_stock_shares_held
            ? n_quota_shares - n_common_stock_shares_held
            : 0;
        const t_shares_under_quota = NumberFormaterWithValidation(
          `${n_shares_under_quota}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const e_shares_under_quota = IsNumber(
          t_shares_under_quota,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;

        const n_shares_under_quota_value = n_shares_under_quota * n_par_value;
        const t_shares_under_quota_value = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_shares_under_quota_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_shares_under_quota_value = IsNumber(
          t_shares_under_quota_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: percent_to_quota_name,
          value: t_percent_to_quota,
          error: e_percent_to_quota,
        });
        data_list.push({
          name: shares_under_quota_name,
          value: t_shares_under_quota,
          error: e_shares_under_quota,
        });
        data_list.push({
          name: shares_under_quota_value_name,
          value: t_shares_under_quota_value,
          error: e_shares_under_quota_value,
        });

        if (!e_shares_under_quota) {
          StockRetainCalculatorLevel3(
            data_list,
            common_data,
            par_value,
            retain_rate,
            n_shares_under_quota
          );
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel3(
    data_list,
    common_data,
    par_value,
    retain_rate,
    shares_under_quota
  ) {
    try {
      const n_barrels_delivered = CommaNumberToNormal(
          common_data?.common_barrels_delivered
        ),
        n_par_value = parseFloat(par_value),
        n_retain_rate = parseFloat(retain_rate),
        n_shares_under_quota = parseFloat(shares_under_quota);
      if (
        IsNumberChecker(n_barrels_delivered) &&
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_rate) &&
        IsNumberChecker(n_shares_under_quota)
      ) {
        const n_retain_dollars = n_barrels_delivered * n_retain_rate;
        const n_potential_retain_shares = Math.floor(
          n_retain_dollars / n_par_value
        );
        const n_retain_shares_to_be_issued =
          n_shares_under_quota < n_potential_retain_shares
            ? n_shares_under_quota
            : n_potential_retain_shares;
        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_retain_dollars = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_retain_dollars}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const t_potential_retain_shares = WholeRateValidation(
          n_potential_retain_shares,
          ZeroLimit,
          NineMillionLimit
        );
        const e_retain_dollars = IsNumber(
          t_retain_dollars,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const e_potential_retain_shares = IsNumber(
          t_potential_retain_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued_value = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_retain_shares_to_be_issued_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_cash_in_lieu_of_stock}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_dollars_name,
          value: t_retain_dollars,
          error: e_retain_dollars,
        });
        data_list.push({
          name: potential_retain_shares_name,
          value: t_potential_retain_shares,
          error: e_potential_retain_shares,
        });
        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });
        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });
        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel4(
    data_list,
    par_value,
    retain_dollars,
    potential_retain_shares,
    shares_under_quota
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = CommaNumberToNormal(retain_dollars),
        n_potential_retain_shares = parseFloat(potential_retain_shares),
        n_shares_under_quota = parseFloat(shares_under_quota);
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_potential_retain_shares) &&
        IsNumberChecker(n_shares_under_quota)
      ) {
        const n_retain_shares_to_be_issued =
          n_shares_under_quota < n_potential_retain_shares
            ? n_shares_under_quota
            : n_potential_retain_shares;
        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_shares_under_quota = NumberFormaterWithValidation(
          `${n_shares_under_quota}`,
          WholeRateValidation,
          ZeroLimit,
          NineMillionLimit
        );
        const t_retain_dollars = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_retain_dollars}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const t_potential_retain_shares = WholeRateValidation(
          n_potential_retain_shares,
          ZeroLimit,
          NineMillionLimit
        );

        const e_shares_under_quota = IsNumber(
          t_shares_under_quota,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const e_retain_dollars = IsNumber(
          t_retain_dollars,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const e_potential_retain_shares = IsNumber(
          t_potential_retain_shares,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued_value = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_retain_shares_to_be_issued_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_cash_in_lieu_of_stock}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: shares_under_quota_name,
          value: t_shares_under_quota,
          error: e_shares_under_quota,
        });
        data_list.push({
          name: retain_dollars_name,
          value: t_retain_dollars,
          error: e_retain_dollars,
        });
        data_list.push({
          name: potential_retain_shares_name,
          value: t_potential_retain_shares,
          error: e_potential_retain_shares,
        });
        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });
        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });
        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel5(
    data_list,
    par_value,
    retain_dollars,
    retain_shares_to_be_issued
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = CommaNumberToNormal(retain_dollars),
        n_retain_shares_to_be_issued = parseFloat(retain_shares_to_be_issued);
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_retain_shares_to_be_issued)
      ) {
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          ZeroLimit,
          OneMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });

        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;

        return StockRetainCalculatorLevel6(
          data_list,
          par_value,
          retain_dollars,
          n_retain_shares_to_be_issued_value
        );
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel6(
    data_list,
    par_value,
    retain_dollars,
    retain_shares_to_be_issued_value
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = CommaNumberToNormal(retain_dollars),
        n_retain_shares_to_be_issued_value = CommaNumberToNormal(
          retain_shares_to_be_issued_value
        );
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_retain_shares_to_be_issued_value)
      ) {
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_retain_shares_to_be_issued_value = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_retain_shares_to_be_issued_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = BarrelsNumberFormater(
          NumberFormaterWithValidation(
            `${n_cash_in_lieu_of_stock}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          ZeroLimit,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });

        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function AREStockPreloadMultiYear(from_preload = true, splitted_data = {}) {
    try {
      // console.log({from_preload, splitted_data});
      
      const input_data = { ...are_stock_retain };
      if (splitted_data && input_data) {
        const {
          cropyear,
          are_issuance_crop_year,
          retain_rate_crop_year,
          redemption_crop_year,
          redemption_year,
          // percent_to_quota,
          are_redemption = "0.00",
          are_rate = "0",
          quota_rate = "0",
          common_stock_shares_held = "0",
          retain_rate = "0",
          average_deliveries = "0.00",
          common_barrels_delivered = "0.00",
          par_value = default_par_value,
          are_region,
          are_issuance_month,
          are_crop_year,
          are_issuance_year,
          are_redemption_year,
          are_redemption_crop_year,
          stock_retain_region,
          stock_retain_month,
          stock_retain_crop_year,
          stock_retain_year,
        } = splitted_data || {};

        const data_list = [];

        const { are_data, stock_retain_data } = input_data || {};

        const final_are_data = from_preload
          ? {
              are_rate,
              are_rate_error: IsNumber(
                are_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              )
                ? false
                : true,
            }
          : are_data;
        const final_stock_retain_data = from_preload
          ? {
              quota_rate,
              quota_rate_error: IsNumber(
                quota_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              )
                ? false
                : true,
              par_value,
              par_value_error: IsNumber(
                par_value,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              )
                ? false
                : true,
              common_stock_shares_held,
              common_stock_shares_held_error: IsNumber(
                common_stock_shares_held,
                true,
                true,
                true,
                ZeroLimit,
                NineMillionLimit
              )
                ? false
                : true,
              retain_rate,
              retain_rate_error: IsNumber(
                retain_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              )
                ? false
                : true,
            }
          : stock_retain_data;

        data_list.push({
          field: "common_data",
          name: common_barrels_delivered_name,
          value: `${common_barrels_delivered}`,
          error: false,
        });
        if (from_preload) {
          data_list.push({
            field: "are_data",
            name: are_redemption_name,
            value: EstimateNumberFormater(`${are_redemption}`),
            error: IsNumber(
              are_redemption,
              true,
              true,
              true,
              ZeroLimit,
              FiveMillionLimit
            )
              ? false
              : true,
          });
          data_list.push({
            field: "are_data",
            name: "are_region",
            value: are_region,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "are_issuance_month",
            value: are_issuance_month,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "are_crop_year",
            value: are_crop_year,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "are_issuance_year",
            value: are_issuance_year,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "are_redemption_year",
            value: are_redemption_year,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "are_redemption_crop_year",
            value: are_redemption_crop_year,
            error: false,
          });
          data_list.push({
            field: "are_data",
            name: "redemption_year",
            value: redemption_year,
            error: false,
          });
          data_list.push({
            field: "stock_retain_data",
            name: "stock_retain_region",
            value: stock_retain_region,
            error: false,
          });
          data_list.push({
            field: "stock_retain_data",
            name: "stock_retain_month",
            value: stock_retain_month,
            error: false,
          });
          data_list.push({
            field: "stock_retain_data",
            name: "stock_retain_crop_year",
            value: stock_retain_crop_year,
            error: false,
          });
          data_list.push({
            field: "stock_retain_data",
            name: "stock_retain_year",
            value: stock_retain_year,
            error: false,
          });          
        }

        AREStockRetainCalculatorForCopying(
          data_list,
          final_are_data,
          final_stock_retain_data,
          {
            average_deliveries: BarrelsNumberFormater(
              `${average_deliveries}`,
              true,
              true
            ),
          },
          { common_barrels_delivered: `${common_barrels_delivered}` },
          "all"
        );

        const payload = {
          master_data_list: data_list,
        }

        if(from_preload){
          payload.cropyear = cropyear;
          payload.are_issuance_crop_year = are_issuance_crop_year;
          payload.retain_rate_crop_year = retain_rate_crop_year;
          payload.redemption_crop_year = redemption_crop_year;
        }
        
        // console.log(payload);
        dispatchAREStockRetain({
          type: "AREStockRetainChange",
          payload
        });
        EmptyAREStockPreloadData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      EmptyAREStockPreloadData();
    }
  }

  function AREStockRetainCalculatorForCopying(
    data_list,
    parent_are_data,
    parent_stock_retain_data,
    current_stock_retain_data,
    common_data,
    area = "all"
  ) {
    try {
      const { common_barrels_delivered: t_common_barrels_delivered = "" } =
        common_data || {};
      const common_barrels_delivered = CommaNumberToNormal(
        t_common_barrels_delivered
      );

      if (area === "are_data" || area === "all") {
        const { are_rate, are_rate_error } = parent_are_data || {};
        data_list.push({
          field: "are_data",
          name: are_rate_name,
          value: are_rate,
          error: are_rate_error,
        });

        const raw_are_issuance =
          common_barrels_delivered * parseFloat(are_rate);
        const value_are_issuance = EstimateNumberFormater(
          NumberFormaterWithValidation(
            `${raw_are_issuance}`,
            WholeRateValidation,
            ZeroLimit,
            FiveMillionLimit
          )
        );
        const are_issuance = CommaNumberToNormal(value_are_issuance);

        data_list.push({
          field: "are_data",
          name: are_issuance_name,
          value: value_are_issuance,
          error: IsNumber(
            are_issuance,
            true,
            true,
            true,
            ZeroLimit,
            FiveMillionLimit
          )
            ? false
            : true,
        });
      }

      if (area === "stock_retain_data" || area === "all") {
        const {
          quota_rate: t_quota_rate = "",
          quota_rate_error,
          par_value: t_par_value = "",
          par_value_error,
          common_stock_shares_held: t_common_stock_shares_held = "",
          common_stock_shares_held_error,
          retain_rate: t_retain_rate = "",
          retain_rate_error,
        } = parent_stock_retain_data;

        const {
          average_deliveries: t_average_deliveries = "",
          average_deliveries_error,
        } = current_stock_retain_data;

        data_list.push({
          field: "stock_retain_data",
          name: quota_rate_name,
          value: t_quota_rate,
          error: quota_rate_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: par_value_name,
          value: t_par_value,
          error: par_value_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: common_stock_shares_held_name,
          value: t_common_stock_shares_held,
          error: common_stock_shares_held_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_rate_name,
          value: t_retain_rate,
          error: retain_rate_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: average_deliveries_name,
          value: t_average_deliveries,
          error: average_deliveries_error,
        });

        const quota_rate = parseFloat(t_quota_rate);
        const par_value = parseFloat(t_par_value);
        const common_stock_shares_held = CommaNumberToNormal(
          t_common_stock_shares_held
        );
        const retain_rate = parseFloat(t_retain_rate);
        const average_deliveries = CommaNumberToNormal(t_average_deliveries);

        const raw_quota_shares = Math.round(
          (average_deliveries * quota_rate) / par_value
        );
        const value_quota_shares = NumberFormaterWithValidation(
          `${raw_quota_shares}`,
          WholeRateValidation,
          ZeroLimit,
          NineMillionLimit
        );
        const quota_shares = parseFloat(value_quota_shares);

        const raw_percent_to_quota =
          (common_stock_shares_held / quota_shares) * 100;
        const value_percent_to_quota = NumberFormaterWithValidation(
          `${raw_percent_to_quota}`,
          WholeRateValidation,
          ZeroLimit,
          HundredLimit
        );
        const percent_to_quota = parseFloat(value_percent_to_quota);

        const raw_shares_under_quota =
          quota_shares > common_stock_shares_held
            ? quota_shares - common_stock_shares_held
            : 0;
        const value_shares_under_quota = NumberFormaterWithValidation(
          `${raw_shares_under_quota}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const shares_under_quota = parseFloat(value_shares_under_quota);

        const raw_shares_under_quota_value = shares_under_quota * par_value;
        const value_shares_under_quota_value = EstimateNumberFormater(
          NumberFormaterWithValidation(
            `${raw_shares_under_quota_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const shares_under_quota_value = CommaNumberToNormal(
          value_shares_under_quota_value
        );

        const raw_retain_dollars = common_barrels_delivered * retain_rate;
        const value_retain_dollars = EstimateNumberFormater(
          NumberFormaterWithValidation(
            `${raw_retain_dollars}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const retain_dollars = CommaNumberToNormal(value_retain_dollars);

        const raw_potential_retain_shares = Math.floor(
          retain_dollars / par_value
        );
        const value_potential_retain_shares = NumberFormaterWithValidation(
          `${raw_potential_retain_shares}`,
          WholeRateValidation,
          ZeroLimit,
          NineMillionLimit
        );
        const potential_retain_shares = parseFloat(
          value_potential_retain_shares
        );

        const raw_retain_shares_to_be_issued =
          shares_under_quota < potential_retain_shares
            ? shares_under_quota
            : potential_retain_shares;
        const value_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${raw_retain_shares_to_be_issued}`,
          WholeRateValidation,
          ZeroLimit,
          OneMillionLimit
        );
        const retain_shares_to_be_issued = parseFloat(
          value_retain_shares_to_be_issued
        );

        const raw_retain_shares_to_be_issued_value =
          retain_shares_to_be_issued * par_value;
        const value_retain_shares_to_be_issued_value = EstimateNumberFormater(
          NumberFormaterWithValidation(
            `${raw_retain_shares_to_be_issued_value}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const retain_shares_to_be_issued_value = CommaNumberToNormal(
          value_retain_shares_to_be_issued_value
        );

        const raw_cash_in_lieu_of_stock =
          retain_dollars - retain_shares_to_be_issued_value;
        const value_cash_in_lieu_of_stock = EstimateNumberFormater(
          NumberFormaterWithValidation(
            `${raw_cash_in_lieu_of_stock}`,
            WholeRateValidation,
            ZeroLimit,
            NineMillionLimit
          )
        );
        const cash_in_lieu_of_stock = CommaNumberToNormal(
          value_cash_in_lieu_of_stock
        );

        data_list.push({
          field: "stock_retain_data",
          name: quota_shares_name,
          value: value_quota_shares,
          error: IsNumber(
            quota_shares,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: percent_to_quota_name,
          value: value_percent_to_quota,
          error: IsNumber(
            percent_to_quota,
            true,
            true,
            true,
            ZeroLimit,
            HundredLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: shares_under_quota_name,
          value: value_shares_under_quota,
          error: IsNumber(
            shares_under_quota,
            true,
            true,
            true,
            ZeroLimit,
            OneMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: shares_under_quota_value_name,
          value: value_shares_under_quota_value,
          error: IsNumber(
            shares_under_quota_value,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_dollars_name,
          value: value_retain_dollars,
          error: IsNumber(
            retain_dollars,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: potential_retain_shares_name,
          value: value_potential_retain_shares,
          error: IsNumber(
            potential_retain_shares,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_shares_to_be_issued_name,
          value: value_retain_shares_to_be_issued,
          error: IsNumber(
            retain_shares_to_be_issued,
            true,
            true,
            true,
            ZeroLimit,
            OneMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_shares_to_be_issued_value_name,
          value: value_retain_shares_to_be_issued_value,
          error: IsNumber(
            retain_shares_to_be_issued_value,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: cash_in_lieu_of_stock_name,
          value: value_cash_in_lieu_of_stock,
          error: IsNumber(
            cash_in_lieu_of_stock,
            true,
            true,
            true,
            ZeroLimit,
            NineMillionLimit
          )
            ? false
            : true,
        });
      }

      return data_list;
    } catch (e) {
      HandleErrorTrackAndToast(e);
      return [];
    }
  }

  function EnableCalculateSection(action) {
    if (compareString(action, "edit")) {
      dispatchAREStockRetain({
        type: "AREStockSkip",
        payload: true,
      });
      SectionHandler(false, "calculate_reverse");
    } else {
      dispatchAREStockRetain({
        type: "AREValidate",
      });
      dispatchAREStockRetain({
        type: "StockRetainValidate",
      });

      const allow_access = AREStockValidator(are_stock_retain);
      if (allow_access) {
        SectionHandler(true, "calculate");
      } else {
        SectionHandler(false, "calculate_reverse");
      }
    }
  }

  function SkipStockRetainSection(e) {
    e.stopPropagation();
    dispatchAREStockRetain({
      type: "StockSkip",
      payload: false,
    });
    dispatchAREStockRetain({
      type: "AREValidate",
    });

    const allow_access = AREValidator(are_stock_retain);
    if (allow_access) {
      SectionHandler(true, "calculate");
    } else {
      SectionHandler(false, "calculate_reverse");
    }
  }

  function SkipAREAndStockRetainSection() {
    dispatchAREStockRetain({
      type: "AREStockSkip",
      payload: false,
    });
    SectionHandler(true, "calculate");
  }

  function AREValidator(obj = {}) {
    try {
      const { cropyear, are_data } = obj;
      const {
        are_data: check_are_data,
        are_redemption_month,
        are_redemption_year,
        are_redemption_crop_year,
        are_issuance_month,
        are_issuance_year,
      } = are_data;
      const are_redemption_time =
        are_redemption_month && are_redemption_year && are_redemption_crop_year;

      if (check_are_data) {
        if (are_redemption_time) {
          const are_issuance_time = are_issuance_month && are_issuance_year;
          if (are_issuance_time) {
            const are_redemption_access = IsNumber(
              are_data.are_redemption,
              true,
              true,
              true,
              ZeroLimit,
              FiveMillionLimit
            );
            if (are_redemption_access) {
              const are_rate_access = IsNumber(
                are_data.are_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              );
              const are_issuance_access = IsNumber(
                are_data.are_issuance,
                true,
                true,
                true,
                ZeroLimit,
                FiveMillionLimit
              );
              if (are_issuance_access && are_rate_access) {
                set_are_stock_error_msg("");
                return true;
              } else {
                set_are_stock_error_msg(
                  `Error: Under Crop Year ${cropyear} in ARE Issuance details`
                );
                return false;
              }
            } else {
              set_are_stock_error_msg(
                `Error: Under Crop Year ${cropyear} in ARE Redemption details`
              );
              return false;
            }
          } else {
            set_are_stock_error_msg(
              `Error: Under Crop Year ${cropyear} in ARE Issuance payout year and month`
            );
            return false;
          }
        } else {
          set_are_stock_error_msg(
            `Error: Under Crop Year ${cropyear} in ARE Redemption payout year and month`
          );
          return false;
        }
      } else {
        set_are_stock_error_msg("");
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function AREStockValidator(obj = {}) {
    try {
      const { cropyear, are_data, stock_retain_data } = obj;
      const {
        are_data: check_are_data,
        are_redemption_month,
        are_redemption_year,
        are_redemption_crop_year,
        are_issuance_month,
        are_issuance_year,
      } = are_data;
      const { stock_data, stock_retain_month, stock_retain_year } =
        stock_retain_data;
      const are_redemption_time =
        are_redemption_month && are_redemption_year && are_redemption_crop_year;
      const validate = check_are_data || stock_data;
      if (validate) {
        if (are_redemption_time) {
          const are_issuance_time = are_issuance_month && are_issuance_year;
          if (are_issuance_time) {
            const are_redemption_access = IsNumber(
              are_data.are_redemption,
              true,
              true,
              true,
              ZeroLimit,
              FiveMillionLimit
            );
            if (are_redemption_access) {
              const are_rate_access = IsNumber(
                are_data.are_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              );
              const are_issuance_access = IsNumber(
                are_data.are_issuance,
                true,
                true,
                true,
                ZeroLimit,
                FiveMillionLimit
              );
              if (are_issuance_access && are_rate_access) {
                const stock_retain_time =
                  stock_retain_month && stock_retain_year;
                if (stock_retain_time) {
                  const average_deliveries_access = IsNumber(
                    stock_retain_data.average_deliveries,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    NineMillionLimit
                  );
                  const quota_rate_access = IsNumber(
                    stock_retain_data.quota_rate,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  const par_value_access = IsNumber(
                    stock_retain_data.par_value,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  const quota_shares_access = IsNumber(
                    stock_retain_data.quota_shares,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    NineMillionLimit
                  );
                  const top_level_access =
                    average_deliveries_access &&
                    quota_rate_access &&
                    par_value_access &&
                    quota_shares_access;

                  if (top_level_access) {
                    const common_stock_shares_held_access = IsNumber(
                      stock_retain_data.common_stock_shares_held,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const percent_to_quota_access = IsNumber(
                      stock_retain_data.percent_to_quota,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      HundredLimit
                    );
                    const retain_rate_access = IsNumber(
                      stock_retain_data.retain_rate,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      HundredLimit
                    );
                    const retain_dollars_access = IsNumber(
                      stock_retain_data.retain_dollars,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const potential_retain_shares_access = IsNumber(
                      stock_retain_data.potential_retain_shares,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const second_level_access =
                      common_stock_shares_held_access &&
                      percent_to_quota_access &&
                      retain_rate_access &&
                      retain_dollars_access &&
                      potential_retain_shares_access;

                    if (second_level_access) {
                      const shares_under_quota_access = IsNumber(
                        stock_retain_data.shares_under_quota,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        OneMillionLimit
                      );
                      const shares_under_quota_value_access = IsNumber(
                        stock_retain_data.shares_under_quota_value,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );
                      const retain_shares_to_be_issued_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        OneMillionLimit
                      );
                      const retain_shares_to_be_issued_value_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued_value,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );
                      const cash_in_lieu_of_stock_access = IsNumber(
                        stock_retain_data.cash_in_lieu_of_stock,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );

                      const access =
                        shares_under_quota_access &&
                        shares_under_quota_value_access &&
                        retain_shares_to_be_issued_access &&
                        retain_shares_to_be_issued_value_access &&
                        cash_in_lieu_of_stock_access;

                      if (access) {
                        set_are_stock_error_msg("");
                        return true;
                      } else {
                        set_are_stock_error_msg(
                          `Error: Under Crop Year ${cropyear} in Stock Retain details`
                        );
                        return false;
                      }
                    } else {
                      set_are_stock_error_msg(
                        `Error: Under Crop Year ${cropyear} in Stock Retain details`
                      );
                      return false;
                    }
                  } else {
                    set_are_stock_error_msg(
                      `Error: Under Crop Year ${cropyear} in Stock Retain details`
                    );
                    return false;
                  }
                } else {
                  set_are_stock_error_msg(
                    `Error: Under Crop Year ${cropyear} in Stock Retain payout year and month`
                  );
                  return false;
                }
              } else {
                set_are_stock_error_msg(
                  `Error: Under Crop Year ${cropyear} in ARE Issuance details`
                );
                return false;
              }
            } else {
              set_are_stock_error_msg(
                `Error: Under Crop Year ${cropyear} in ARE Redemption details`
              );
              return false;
            }
          } else {
            set_are_stock_error_msg(
              `Error: Under Crop Year ${cropyear} in ARE Issuance payout year and month`
            );
            return false;
          }
        } else {
          set_are_stock_error_msg(
            `Error: Under Crop Year ${cropyear} in ARE Redemption payout year and month`
          );
          return false;
        }
      } else {
        set_are_stock_error_msg("");
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function ProceedToNextSectionAREStockValidate(obj = {}) {
    try {
      const { are_data, stock_retain_data } = obj;
      const {
        are_data: check_are_data,
        are_redemption_month,
        are_redemption_year,
        are_redemption_crop_year,
        are_issuance_month,
        are_issuance_year,
      } = are_data;
      const { stock_data, stock_retain_month, stock_retain_year } =
        stock_retain_data;
      const are_redemption_time =
        are_redemption_month && are_redemption_year && are_redemption_crop_year;
      const validate = check_are_data || stock_data;
      if (validate) {
        if (are_redemption_time) {
          const are_issuance_time = are_issuance_month && are_issuance_year;
          if (are_issuance_time) {
            const are_redemption_access = IsNumber(
              are_data.are_redemption,
              true,
              true,
              true,
              ZeroLimit,
              FiveMillionLimit
            );
            if (are_redemption_access) {
              const are_rate_access = IsNumber(
                are_data.are_rate,
                true,
                true,
                true,
                ZeroLimit,
                HundredLimit
              );
              const are_issuance_access = IsNumber(
                are_data.are_issuance,
                true,
                true,
                true,
                ZeroLimit,
                FiveMillionLimit
              );
              if (are_issuance_access && are_rate_access) {
                const stock_retain_time =
                  stock_retain_month && stock_retain_year;
                if (stock_retain_time) {
                  const average_deliveries_access = IsNumber(
                    stock_retain_data.average_deliveries,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    NineMillionLimit
                  );
                  const quota_rate_access = IsNumber(
                    stock_retain_data.quota_rate,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  const par_value_access = IsNumber(
                    stock_retain_data.par_value,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    HundredLimit
                  );
                  const quota_shares_access = IsNumber(
                    stock_retain_data.quota_shares,
                    true,
                    true,
                    true,
                    ZeroLimit,
                    NineMillionLimit
                  );
                  const top_level_access =
                    average_deliveries_access &&
                    quota_rate_access &&
                    par_value_access &&
                    quota_shares_access;

                  if (top_level_access) {
                    const common_stock_shares_held_access = IsNumber(
                      stock_retain_data.common_stock_shares_held,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const percent_to_quota_access = IsNumber(
                      stock_retain_data.percent_to_quota,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      HundredLimit
                    );
                    const retain_rate_access = IsNumber(
                      stock_retain_data.retain_rate,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      HundredLimit
                    );
                    const retain_dollars_access = IsNumber(
                      stock_retain_data.retain_dollars,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const potential_retain_shares_access = IsNumber(
                      stock_retain_data.potential_retain_shares,
                      true,
                      true,
                      true,
                      ZeroLimit,
                      NineMillionLimit
                    );
                    const second_level_access =
                      common_stock_shares_held_access &&
                      percent_to_quota_access &&
                      retain_rate_access &&
                      retain_dollars_access &&
                      potential_retain_shares_access;

                    if (second_level_access) {
                      const shares_under_quota_access = IsNumber(
                        stock_retain_data.shares_under_quota,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        OneMillionLimit
                      );
                      const shares_under_quota_value_access = IsNumber(
                        stock_retain_data.shares_under_quota_value,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );
                      const retain_shares_to_be_issued_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        OneMillionLimit
                      );
                      const retain_shares_to_be_issued_value_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued_value,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );
                      const cash_in_lieu_of_stock_access = IsNumber(
                        stock_retain_data.cash_in_lieu_of_stock,
                        true,
                        true,
                        true,
                        ZeroLimit,
                        NineMillionLimit
                      );

                      const access =
                        shares_under_quota_access &&
                        shares_under_quota_value_access &&
                        retain_shares_to_be_issued_access &&
                        retain_shares_to_be_issued_value_access &&
                        cash_in_lieu_of_stock_access;

                      if (access) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  useEffect(() => {
    const { from_preload = null, splitted_data = {} } = are_stock_preload;
    const load = from_preload === true || from_preload === false;
    if (load && IsObjectChecker(splitted_data) && !_.isEmpty(splitted_data)) {
      AREStockPreloadMultiYear(from_preload, splitted_data);
    }
  }, [are_stock_preload]);

  function GetFaultyData() {
    const args = [...arguments];
    return args.filter((li) => !li.value);
  }

  const {
    cropyear,
    are_issuance_crop_year,
    retain_rate_crop_year,
    // redemption_crop_year,
    common_data = {},
    stock_retain_data = {},
    are_data = {},
  } = are_stock_retain;

  const {
    common_barrels_delivered = "0",
    // barrels_delivered_crop_year = ""
  } = common_data;

  const {
    are_redemption_crop_year = "",
    are_issuance = "",
    are_issuance_error = false,
    are_redemption = "",
    are_redemption_error = false,
    are_rate = "",
    are_rate_error = false,
  } = are_data;

  const {
    average_deliveries = "",
    quota_rate = "",
    par_value = "",
    quota_shares = "",
    common_stock_shares_held = "",
    percent_to_quota = "",
    retain_rate = "",
    retain_dollars = "",
    potential_retain_shares = "",
    shares_under_quota = "",
    shares_under_quota_value = "",
    retain_shares_to_be_issued = "",
    retain_shares_to_be_issued_value = "",
    cash_in_lieu_of_stock = "",
    average_deliveries_error,
    quota_rate_error,
    par_value_error,
    quota_shares_error,
    common_stock_shares_held_error,
    percent_to_quota_error,
    retain_rate_error,
    retain_dollars_error,
    potential_retain_shares_error,
    shares_under_quota_error,
    shares_under_quota_value_error,
    retain_shares_to_be_issued_error,
    retain_shares_to_be_issued_value_error,
    cash_in_lieu_of_stock_error,
  } = stock_retain_data;
  const faulty_data = GetFaultyData(
    { name: "Average Deliveries", value: average_deliveries },
    { name: "Quota Rate", value: quota_rate },
    { name: "Par Value", value: par_value },
    { name: "Common Stock Shares Held", value: common_stock_shares_held },
    { name: "Retain Rate", value: retain_rate },
    { name: "Barrels Delivered", value: common_barrels_delivered },
    { name: "Percent To Quota", value: percent_to_quota }
  );
  const stock_error_info_box = `The Stock Retain for this year ${cropyear} is not calculated as the following field(s) has null values: ${faulty_data
    .map((li) => li.name)
    .join(", ")}`;
  const are_stock_access =
    !ProceedToNextSectionAREStockValidate(are_stock_retain);
  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col">
          <h4 className={`fw-bold`}>
            ARE & Stock Retain
            <span className="drop-offinfo" style={{
                                              marginRight: `10px`,
                                            }}>
              (Enter crop year ARE Redemption, ARE Issuance & Stock Retain
              details below)
            </span>
            <Button
              variant="light"
              className={`text-16 text-start ${
                enable_calculate_section ? "cursor-not-allowed" : ""
              } mt-2`}
              onClick={() => SkipAREAndStockRetainSection()}
              style={{
                boxShadow: `0 0 5px #ccc`,
              }}
              disabled={enable_calculate_section}
            >
              {`Skip This & Proceed To Next Section`}
          </Button>
          </h4>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade show active`}
              id={`pills-cyear-arestock-${cropyear}`}
              role="tabpanel"
              aria-labelledby={`pills-cyear-arestock-${cropyear}-tab`}
            >
              <div
                className={`border-radius-6 accordion_tabs_bg_space mob-space-0 desktop-accordion-table`}
              >
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion alwaysOpen>
                    <Accordion.Item className="mb-2" eventKey={"1"}>
                      <Accordion.Header>
                        {`Enter ARE Redemption details for calculation`}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row my-2">
                          <div className="col">
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">
                                  ARE Redemption
                                  {` (from ${are_redemption_crop_year} Crop Year)`}
                                </h5>
                                <Form.Control
                                  className="white_bg"
                                  type="text"
                                  name={are_redemption_name}
                                  value={are_redemption}
                                  onChange={onAREChange}
                                  onBlur={(e) =>
                                    OnFocusOutHandler(onAREChange, e)
                                  }
                                  onKeyDown={KeyDownHandler}
                                  isInvalid={!IsStringChecker(are_redemption)}
                                  required
                                  placeholder={"Enter the ARE Redemption"}
                                  readOnly={enable_calculate_section}
                                  autoComplete="off"
                                />
                                {are_redemption_error && (
                                  <Form.Text style={{ color: "red" }}>
                                    {are_err_msg}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="mb-2" eventKey={"2"}>
                      <Accordion.Header>
                        {`Enter ARE Issuance details for calculation`}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row my-2">
                          <div className="col">
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                <h5 className="fw-bold">
                                  Barrels Delivered
                                  {` (${are_issuance_crop_year} Crop Year)`}
                                  {/* {` (${barrels_delivered_crop_year} Crop Year)`} */}
                                </h5>
                                <Form.Control
                                  type="text"
                                  name={common_barrels_delivered_name}
                                  value={BarrelsNumberFormater(
                                    `${common_barrels_delivered}`
                                  )}
                                  onKeyDown={KeyDownHandler}
                                  placeholder={"Enter the Barrels Delivered"}
                                  readOnly={true}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">ARE Issuance Rate</h5>
                                <Form.Control
                                  className="white_bg"
                                  type="text"
                                  name={are_rate_name}
                                  value={are_rate}
                                  onChange={onAREChange}
                                  onKeyDown={KeyDownHandler}
                                  isInvalid={!IsStringChecker(are_rate)}
                                  required
                                  placeholder={"Enter the ARE Issuance Rate"}
                                  readOnly={enable_calculate_section}
                                  autoComplete="off"
                                />
                                {are_rate_error && (
                                  <Form.Text style={{ color: "red" }}>
                                    {percent_limit_err_msg}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">ARE Issuance</h5>
                                <Form.Control
                                  className="white_bg"
                                  type="text"
                                  name={are_issuance_name}
                                  value={are_issuance}
                                  onChange={onAREChange}
                                  onBlur={(e) =>
                                    OnFocusOutHandler(onAREChange, e)
                                  }
                                  onKeyDown={KeyDownHandler}
                                  isInvalid={!IsStringChecker(are_issuance)}
                                  required
                                  placeholder={"Enter the ARE Issuance"}
                                  readOnly={enable_calculate_section}
                                  autoComplete="off"
                                />
                                {are_issuance_error && (
                                  <Form.Text style={{ color: "red" }}>
                                    {are_err_msg}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="mb-2" eventKey={"3"}>
                      <Accordion.Header>
                        {`Enter Stock Retain details for calculation`}
                        {IsArrayNotEmpty(faulty_data) && (
                          <>
                            <span className={"pe-faulty-error-pill"}>
                              Error
                            </span>{" "}
                            <Button
                              variant="primary"
                              className={`pe-accordion-stock-retain-btn ${
                                enable_calculate_section
                                  ? "cursor-not-allowed"
                                  : ""
                              }`}
                              onClick={SkipStockRetainSection}
                              disabled={enable_calculate_section}
                            >
                              {`Skip Stock Retain`}
                            </Button>
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row my-2">
                          <div className="col">
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Average Deliveries
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={average_deliveries_name}
                                    value={BarrelsNumberFormater(
                                      `${average_deliveries}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Average Deliveries"}
                                    isInvalid={
                                      !IsStringChecker(average_deliveries)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {average_deliveries_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Quota Rate
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={quota_rate_name}
                                    value={quota_rate}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Quota Rate"}
                                    isInvalid={!IsStringChecker(quota_rate)}
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {quota_rate_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {percent_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Par Value
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={par_value_name}
                                    value={par_value}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Par Value"}
                                    isInvalid={!IsStringChecker(par_value)}
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {par_value_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {percent_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Quota Shares
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={quota_shares_name}
                                    value={BarrelsNumberFormater(
                                      `${quota_shares}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Quota Shares"}
                                    isInvalid={!IsStringChecker(quota_shares)}
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {quota_shares_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">
                                  Barrels Delivered
                                  {/* {` (${barrels_delivered_crop_year} Crop Year)`} */}
                                  {` (${retain_rate_crop_year} Crop Year)`}
                                </h5>
                                <Form.Control
                                  type="text"
                                  name={common_barrels_delivered_name}
                                  value={BarrelsNumberFormater(
                                    `${common_barrels_delivered}`
                                  )}
                                  onKeyDown={KeyDownHandler}
                                  placeholder={"Enter the Barrels Delivered"}
                                  readOnly={true}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Common Stock Shares Held
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={common_stock_shares_held_name}
                                    value={BarrelsNumberFormater(
                                      `${common_stock_shares_held}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={
                                      "Enter the Common Stock Shares Held"
                                    }
                                    isInvalid={
                                      !IsStringChecker(common_stock_shares_held)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {common_stock_shares_held_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    % to Quota
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={percent_to_quota_name}
                                    value={percent_to_quota}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the % to Quota"}
                                    isInvalid={
                                      !IsStringChecker(percent_to_quota)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {percent_to_quota_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {percent_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-4 text-wrap text-start">
                                    Retain Rate
                                  </InputGroup.Text>
                                  <InputGroup.Text className="text-wrap text-start">
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={retain_rate_name}
                                    value={retain_rate}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Retain Rate"}
                                    isInvalid={!IsStringChecker(retain_rate)}
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {retain_rate_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {percent_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-4 text-wrap text-start">
                                    Retain Dollars
                                  </InputGroup.Text>
                                  <InputGroup.Text className="text-wrap text-start">
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={retain_dollars_name}
                                    value={retain_dollars}
                                    onChange={onStockRetainChange}
                                    onBlur={(e) =>
                                      OnFocusOutHandler(onStockRetainChange, e)
                                    }
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Retain Dollars"}
                                    isInvalid={!IsStringChecker(retain_dollars)}
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {retain_dollars_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input">
                                <InputGroup>
                                  <InputGroup.Text className="col-5 text-wrap text-start">
                                    Potential Retain Shares
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={potential_retain_shares_name}
                                    value={BarrelsNumberFormater(
                                      `${potential_retain_shares}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={
                                      "Enter the Potential Retain Shares"
                                    }
                                    isInvalid={
                                      !IsStringChecker(potential_retain_shares)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {potential_retain_shares_error && (
                                  <div className="row">
                                    <Form.Text
                                      className="col"
                                      style={{ color: "red" }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input mb-3 mb-lg-0">
                                <h5 className="fw-bold">Shares Under Quota</h5>
                                <InputGroup>
                                  <Form.Text
                                    className="col-6"
                                    style={{
                                      visibility: "hidden",
                                    }}
                                  ></Form.Text>
                                  <Form.Text className="col-6 fw-bold">
                                    (Dollars)
                                  </Form.Text>
                                </InputGroup>
                                <InputGroup>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={shares_under_quota_name}
                                    value={BarrelsNumberFormater(
                                      `${shares_under_quota}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={"Enter the Shares Under Quota"}
                                    isInvalid={
                                      !IsStringChecker(shares_under_quota)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                  <Form.Control
                                    className="white_bg col-5"
                                    type="text"
                                    name={shares_under_quota_value_name}
                                    value={shares_under_quota_value}
                                    onChange={onStockRetainChange}
                                    onBlur={(e) =>
                                      OnFocusOutHandler(onStockRetainChange, e)
                                    }
                                    onKeyDown={KeyDownHandler}
                                    placeholder={
                                      "Enter the Final Shares Under Quota Value"
                                    }
                                    isInvalid={
                                      !IsStringChecker(shares_under_quota_value)
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {(shares_under_quota_error ||
                                  shares_under_quota_value_error) && (
                                  <InputGroup>
                                    <Form.Text
                                      className={`col-6`}
                                      style={{
                                        color: "red",
                                        visibility: shares_under_quota_error
                                          ? "visible"
                                          : "hidden",
                                      }}
                                    >
                                      {lakh_limit_err_msg}
                                    </Form.Text>
                                    <Form.Text
                                      className={`col-6`}
                                      style={{
                                        color: "red",
                                        visibility:
                                          shares_under_quota_value_error
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </InputGroup>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">
                                  Retain Shares to be Issued
                                </h5>
                                <InputGroup>
                                  <Form.Text
                                    className="col-6"
                                    style={{
                                      visibility: "hidden",
                                    }}
                                  ></Form.Text>
                                  <Form.Text className="col-6 fw-bold">
                                    (Dollars)
                                  </Form.Text>
                                </InputGroup>
                                <InputGroup>
                                  <Form.Control
                                    className="white_bg col-3"
                                    type="text"
                                    name={retain_shares_to_be_issued_name}
                                    value={BarrelsNumberFormater(
                                      `${retain_shares_to_be_issued}`
                                    )}
                                    onChange={onStockRetainChange}
                                    onKeyDown={KeyDownHandler}
                                    placeholder={
                                      "Enter the Retain Shares to be Issued"
                                    }
                                    isInvalid={
                                      !IsStringChecker(
                                        retain_shares_to_be_issued
                                      )
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={retain_shares_to_be_issued_value_name}
                                    value={retain_shares_to_be_issued_value}
                                    onChange={onStockRetainChange}
                                    onBlur={(e) =>
                                      OnFocusOutHandler(onStockRetainChange, e)
                                    }
                                    onKeyDown={KeyDownHandler}
                                    placeholder={
                                      "Enter the Final Retain Shares to be Issued Value"
                                    }
                                    isInvalid={
                                      !IsStringChecker(
                                        retain_shares_to_be_issued_value
                                      )
                                    }
                                    required
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {(retain_shares_to_be_issued_error ||
                                  retain_shares_to_be_issued_value_error) && (
                                  <InputGroup>
                                    <Form.Text
                                      className="col-6"
                                      style={{
                                        color: "red",
                                        visibility:
                                          retain_shares_to_be_issued_error
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      {lakh_limit_err_msg}
                                    </Form.Text>
                                    <Form.Text
                                      className="col-6"
                                      style={{
                                        color: "red",
                                        visibility:
                                          retain_shares_to_be_issued_value_error
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      {max_limit_err_msg}
                                    </Form.Text>
                                  </InputGroup>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-12 col-lg-6 payment-select-input">
                                <h5 className="fw-bold">
                                  Cash in lieu of Stock
                                </h5>
                                <InputGroup>
                                  <InputGroup.Text className="text-wrap text-start">
                                    $
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="white_bg"
                                    type="text"
                                    name={cash_in_lieu_of_stock_name}
                                    value={cash_in_lieu_of_stock}
                                    onChange={onStockRetainChange}
                                    onBlur={(e) =>
                                      OnFocusOutHandler(onStockRetainChange, e)
                                    }
                                    onKeyDown={KeyDownHandler}
                                    isInvalid={
                                      !IsStringChecker(cash_in_lieu_of_stock)
                                    }
                                    required
                                    placeholder={
                                      "Enter the Cash in lieu of Stock"
                                    }
                                    readOnly={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                                {cash_in_lieu_of_stock_error && (
                                  <Form.Text style={{ color: "red" }}>
                                    {max_limit_err_msg}
                                  </Form.Text>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {IsArrayNotEmpty(faulty_data) && (
                          <div className="row my-2">
                            <div className="col">
                              <Alert
                                variant="danger"
                                style={{
                                  textAlign: "justify",
                                  fontStyle: "italic",
                                }}
                              >
                                <Alert.Heading>
                                  <i className="fa fa-close"></i> Error:
                                </Alert.Heading>
                                <p>{stock_error_info_box}</p>
                                <Button
                                  variant="primary"
                                  className={`text-16 mb-2 ${
                                    enable_calculate_section
                                      ? "cursor-not-allowed"
                                      : ""
                                  }`}
                                  onClick={SkipStockRetainSection}
                                  style={{
                                    boxShadow: `0 0 5px #ccc`,
                                  }}
                                  disabled={enable_calculate_section}
                                >
                                  {`Skip Stock Retain`}
                                </Button>
                              </Alert>
                            </div>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {IsStringChecker(are_stock_error_msg) && (
        <div className="row mt-2 mb-2">
          <div className="col">
            <h5 className={`mb-0 text-center red_color`}>
              * {are_stock_error_msg}
            </h5>
          </div>
        </div>
      )}
      <div className="row mt-2 mb-2">
        <div className="col">
          <Button
            variant="primary"
            className="text-16 my-2 my-lg-0"
            onClick={() =>
              EnableCalculateSection(
                enable_calculate_section ? "edit" : "enter"
              )
            }
            style={{
              boxShadow: `0 0 5px #ccc`,
            }}
            disabled={enable_calculate_section ? false : are_stock_access}
          >
            {`${
              enable_calculate_section ? "Edit This" : "Proceed To Next"
            } Section`}
          </Button>
          {/* <Button
            variant="light"
            className={`text-16 mx-lg-2 text-start ${
              enable_calculate_section ? "cursor-not-allowed" : ""
            }`}
            onClick={() => SkipAREAndStockRetainSection()}
            style={{
              boxShadow: `0 0 5px #ccc`,
            }}
            disabled={enable_calculate_section}
          >
            {`Skip This & Proceed To Next Section`}
          </Button> */}
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentEstimatorAREStockRetain;
