/**
 * @component_Name : SearchForumBody
 * @description : This component used to shw the list of searched forums
 * @company : Photon
 * @author : Gobi
 **/
import React, { useState, useEffect } from "react";
import Breadcrumb from "../global/breadCrumb";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import CurrentLocation from "../global/currentLocation";
import parse from "html-react-parser";
import moment from "moment";
import Moment from "react-moment";
import { CommentImageUrl } from "../../utils/constants";
import {
  getproper_Atag_URLfromRichContent,
  HandleErrorTrackAndToast,
  IsTokenChecker,
} from "../../utils/helper";
import RichTextWrap from "../global/richTextWrap";

const SearchForumBody = (props) => {
  const { search } = useLocation();
  const searchVal = search ? new URLSearchParams(search).get("q") : "";
  const history = useHistory();

  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const tokenId = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  const [loader, setLoader] = useState(false);
  const [forumSearchResults, setForumSearchResults] = useState([]);
  const [searchText, setSearchText] = useState(searchVal);
  const [searchField, setSearchField] = useState(searchVal);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) getSearchResult();
  }, [searchText, tokenId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const getSearchResult = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `v_forum_search?forum_search=plfts.${searchText}`
    )
      .then((res) => {
        let response = res?.data?.data || [];
        if (response.length > 0) {
          setForumSearchResults(response);
        } else {
          setForumSearchResults([]);
        }
        setShowMessage(true);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const sortFunction = (e) => {
    let ordered = [];
    if (e.target.value === "old") {
      ordered = forumSearchResults.sort(
        (a, b) => moment(a.updated_at).utc() - moment(b.updated_at).utc()
      );
    } else {
      ordered = forumSearchResults.sort(
        (a, b) => moment(b.updated_at).utc() - moment(a.updated_at).utc()
      );
    }
    setForumSearchResults([...ordered]);
  };
  const handleSearchSubmit = (event) => {
    if (searchField !== "") {
      setSearchText(searchField);
    }
    event.preventDefault();
  };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className=" col-12 col-lg-12 d-none d-lg-block">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="overflow-hidden mt-5 mt-lg-3 p-0">
                <div className="float-none float-sm-start back_btn">
                  <button
                    onClick={() => history.goBack()}
                    className="btn btn-primary transparent_btn me-3 text-14"
                  >
                    {moreLangs[i18n.resolvedLanguage]?.back}
                  </button>
                </div>
                <div className="float-none float-sm-end mb-0 mb-sm-2">
                  <div className="row">
                    <div className="col-auto pe-2 sm-width-100 me-2">
                      <form onSubmit={handleSearchSubmit}>
                        <span className="input-group border-1 border-radius-6 my-2 my-lg-0">
                          <span
                            className="input-group-text border-0 white_bg"
                            id="basic-addon1"
                          >
                            <i
                              className="fa fa-search blue_color text-18"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <input
                            onChange={(e) => setSearchField(e.target.value)}
                            value={searchField}
                            type="text"
                            name="q"
                            className="form-control border-0 py-2 left-space-0"
                            placeholder="Search Forum"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showMessage && (
              <div className="row">
                <div className="alert alert-primary" role="alert">
                  {`${
                    moreLangs[i18n.resolvedLanguage]?.forum_page
                      ?.forum_search_page?.search_result_text1
                  } "${searchText}" ${
                    moreLangs[i18n.resolvedLanguage]?.forum_page
                      ?.forum_search_page?.search_result_text2
                  } : ${forumSearchResults.length} ${
                    moreLangs[i18n.resolvedLanguage]?.forum_page
                      ?.forum_search_page?.search_result_text3
                  }.`}
                </div>
              </div>
            )}

            {forumSearchResults.length > 0 && (
              <div className="row">
                <div className="card card-shadow mt-0 mb-0 mb-lg-3 p-0">
                  <h4 className="card-header white_color ver-space-10 left-border-radius right-border-radius">
                    <span className="ver-space-5 d-inline-block mb-2 mb-lg-0">
                      {
                        moreLangs[i18n.resolvedLanguage]?.forum_page
                          ?.forum_search_page?.search_results_title
                      }
                    </span>
                    <div className="float-none float-sm-end">
                      <select
                        onChange={(e) => {
                          sortFunction(e);
                        }}
                        className="form-select text-14 filter_text d-inline-block sm_width-78_p float-end"
                        aria-label="Default select example"
                      >
                        <option value="new">
                          {moreLangs[i18n.resolvedLanguage]?.sort_newest}
                        </option>
                        <option value="old">
                          {moreLangs[i18n.resolvedLanguage]?.sort_oldest}
                        </option>
                      </select>
                    </div>
                    <span className="float-start float-sm-end p-2 ps-0 text-14">
                      {moreLangs[i18n.resolvedLanguage]?.home_page?.sort_by}:{" "}
                    </span>
                  </h4>

                  <div className="card-body p-0 white_bg border-radius-6 mb-3">
                    <div className="row m-2 m-lg-3">
                      <div className="col-9 col-sm-8">
                        <div className="d-flex align-items-center justify-content-lg-start pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
                          <img
                            src={
                              CommentImageUrl +
                              forumSearchResults[0]?.post_userid +
                              ".jpg"
                            }
                            alt="user_forum"
                            className="card-img-top border-radius-50 news_profile_img float-start"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                            }}
                          />
                          <h5 className="float-start px-2 px-lg-3 d-block print_font">
                            <span className="fw-bold">
                              {forumSearchResults[0].post_user_fullname}
                            </span>
                            <span className="d-block pt-1 text-14">
                              {forumSearchResults[0].title}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="col-3 col-sm-4">
                        <p className="float-end mt-3 mt-sm-0">
                          <Moment local utc format="MM/DD/YYYY hh:mm A">
                            {forumSearchResults[0]?.updated_at}
                          </Moment>
                        </p>
                      </div>
                      <div className="pt-4 forum-detail-rich-content">
                        <RichTextWrap>
                          {parse(
                            getproper_Atag_URLfromRichContent(
                              forumSearchResults[0]?.description || ""
                            )
                          )}
                        </RichTextWrap>
                      </div>
                      <div>
                        <Link
                          to={`${
                            moreLangs[i18n.resolvedLanguage]["menu_forums"].url
                          }/${forumSearchResults[0]?.forum_type_title_en
                            .toLowerCase()
                            .replace(/ /g, "-")}/${
                            forumSearchResults[0]?.id
                          }/${forumSearchResults[0]?.title
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-")}`}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          >
                            View Thread
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {forumSearchResults.length &&
                  forumSearchResults.slice(1).map((result, index) => (
                    <div
                      key={index}
                      className="card card-shadow mt-3 mt-lg-0 mb-0 mb-lg-3"
                    >
                      <div className="card-body p-0 white_bg border-radius-6 mb-3">
                        <div className="row m-2 m-lg-3">
                          <div className="col-9 col-sm-8">
                            <div className="d-flex align-items-center justify-content-lg-start pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
                              <img
                                src={
                                  CommentImageUrl + result?.post_userid + ".jpg"
                                }
                                alt="user_forum"
                                className="card-img-top border-radius-50 news_profile_img float-start"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                                }}
                              />
                              <h5 className="float-start px-2 px-lg-3 d-block print_font">
                                <span className="fw-bold">
                                  {result.post_user_fullname}
                                </span>
                                <span className="d-block pt-1 text-14">
                                  {result.title}
                                </span>
                              </h5>
                            </div>
                          </div>
                          <div className="col-3 col-sm-4">
                            <p className="float-end mt-3 mt-sm-0">
                              <Moment local utc format="MM/DD/YYYY hh:mm A">
                                {result.updated_at}
                              </Moment>
                            </p>
                          </div>
                          <div className="pt-4 forum-detail-rich-content">
                            <RichTextWrap>
                              {parse(
                                getproper_Atag_URLfromRichContent(
                                  result?.description || ""
                                )
                              )}
                            </RichTextWrap>
                          </div>
                          <div>
                            <Link
                              to={`${
                                moreLangs[i18n.resolvedLanguage]["menu_forums"]
                                  .url
                              }/${result?.forum_type_title_en
                                .toLowerCase()
                                .replace(/ /g, "-")}/${
                                result?.id
                              }/${result?.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/\//g, "-")}`}
                            >
                              <button
                                type="submit"
                                className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                              >
                                {
                                  moreLangs[i18n.resolvedLanguage][
                                    "view_thread"
                                  ]
                                }
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchForumBody;
