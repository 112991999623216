/**
 * @component_Name : FieldTools
 * @description : Field Tools
 * @company : Photon
 * @author : Nivethithan Kannan/14-11-2024
 **/
import React from "react";
import PageLayout from "../pageLayout";
import FieldToolsDetail from "./fieldToolsDetail";

const FieldTools = (props) => {
  return <PageLayout {...props} body={<FieldToolsDetail {...props} />} />;
};

export default FieldTools;
