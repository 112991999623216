/** 
@CreatedBy : Nivethithan Kannan/13-03-2023
@Description : Define all routes here.
*/
import _ from "lodash";
import {
  roleCBAcreage,
  roleCBDecisionMaker,
  roleCBPayment,
  roleCBThirdPartyFarmService,
} from "./utils/roles";

import Home from "./components/home/home";
import UserProfile from "./components/userProfile/userProfile";

import MyFarm from "./components/myFarm/myFarm";
import MyContracts from "./components/myContracts/myContracts";
import MyAcreage from "./components/myFarm/myAcreage/myAcreage";
import MyPeopleMain from "./components/myFarm/myPeople/myPeopleMain";
import Payments from "./components/home/payment/payments/payments";
import Equity from "./components/home/payment/equity/equity";
import PaymentEquity from "./components/home/payment/paymentEquity";
import StatementReport from "./components/home/payment/statementReport/statementReport";
import PaymentEstimator from "./components/home/payment/paymentEstimator/paymentEstimator";

import MyHarvest from "./components/myHarvest/myHarvest";
import PreHarvest from "./components/myHarvest/preHarvest";
import MyDeliveryData from "./components/myHarvest/myDeliveryData";
import HarvestNews from "./components/myHarvest/harvestNews/harvestNews";

import NewsDetails from "./components/home/newsDetails/newsDetails";
import NewsAndEvents from "./components/community/newsAndEvents/newsAndEvents"; // 36923 - NK
import Events from "./components/community/events/events"; //56637 - NK
import ForumsAndClassifieds from "./components/Forums/ForumsClassifieds";
import ForumsDetail from "./components/Forums/ForumDetails";
import SearchForum from "./components/Forums/searchForum";
import Regions from "./components/community/regions/regions"; // 39672 - NK
import AGSciences from "./components/community/agSciences/agSciences"; // 39672 - NK
import CranberryManagement from "./components/community/cranberryManagement/cranberryManagement"; // 41368 - NK
import CranberryManagementTopics from "./components/community/cranberryManagementTopics/cranberryManagementTopics"; // 41368 - NK
import PoliciesAndForms from "./components/community/policiesAndForms/policiesAndForms"; // 41371 - NK
import GrowerBenefits from "./components/community/growerBenefits/growerBenefits";
import GoverningDocuments from "./components/documents/governingDocuments/governingDocuments";
import Ospac from "./components/cooperative/ospac/ospac";

import BoardCouncilContacts from "./components/cooperative/boardCouncilContacts/boardCouncilContacts";
import BoardCouncilContactsDetails from "./components/cooperative/boardCouncilContacts/boardCouncilContactsDetails";
import CoopFinance from "./components/cooperative/coopFinances/coopFinance";
import MyOSContacts from "./components/myFarm/myOSContacts/myOSContracts";
import MyOSContactDetail from "./components/myFarm/myOSContacts/myOSContactDetail";

import DocumentsLanding from "./components/documents/documents"; //58904 - NK
import FormsLanding from "./components/documents/forms/forms"; //45559 - NK
import OnlineForm from "./components/documents/forms/onlineForm/onlineForm"; //58919 - NK
import Archive from "./components/community/archive/archive";
import PickupPage from "./components/documents/pickupPage/pickupPage"; //58921 - NK

import OSAcademyLanding from "./components/osAcademy/osAcademy"; //58434 - NK
import OSAcademyCourses from "./components/osAcademy/osAcademyCourses/osAcademyCourses"; //45608 - NK
import OSAcademySignup from "./components/osAcademy/osAcademySignup/osAcademySignup"; // 44034 - NK
import OSAcademyPastPresentations from "./components/osAcademy/osAcademyPastPresentations/osAcademyPastPresentations"; // 45607 - NK

import SiteSearch from "./components/search/siteSearch";
import Feedback from "./components/feedback/feedback";

import ContactUs from "./components/contactUs/contactUs"; //63569 - NK
import LegalTerms from "./components/legalTerms/legalTerms"; //63570 - NK
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy"; //63572 - NK

import Logout from "./components/logout"; // 55546 - Logout
import CodeOfConduct from "./components/codeOfConduct/codeOfConduct";

import Sustainability from "./components/cooperative/sustainability/sustainability";
import DropoffPage from "./components/documents/dropoffPage/dropoffPage";
import HowTosPage from "./components/documents/howTos/howTosPage";

import ForecastingData from "./components/myHarvest/forecastingData/forecastingData"; //43017 - NK

const Routes = [
  {
    component: Home,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/",
    order: 1,
    title: {
      title_en: "Home",
      title_fr: "Maison",
      title_es: "Hogar",
    },
    exact: true,
    CommonPageCBRoles: [roleCBAcreage, roleCBDecisionMaker, roleCBThirdPartyFarmService],
  },
  {
    component: UserProfile,
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    path: "/user-Profile",
    order: 2,
    title: {
      title_en: "User Profile",
      title_fr: "Profil De L'utilisateur",
      title_es: "Perfil Del Usuario",
    },
    exact: true,
    CommonPageCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker, roleCBThirdPartyFarmService],
  },
  {
    component: UserProfile,
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    path: "/user-Profile/:tabName",
    order: 3,
    title: {
      title_en: "User Profile",
      title_fr: "Profil De L'utilisateur",
      title_es: "Perfil Del Usuario",
    },
    exact: true,
    CommonPageCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker, roleCBThirdPartyFarmService],
  },
  {
    component: MyFarm,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm",
    order: 4,
    title: {
      title_en: "My Farm",
      title_fr: "Ma Ferme",
      title_es: "Mi Granja",
    },
    exact: true,
  },
  {
    component: MyContracts,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-contracts",
    order: 5,
    title: {
      title_en: "My Contracts",
      title_fr: "Mes Contrats",
      title_es: "Mis Contratos",
    },
    exact: true,
  },
  {
    component: MyContracts,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/my-contracts",
    order: 6,
    title: {
      title_en: "My Contracts",
      title_fr: "Mes Contrats",
      title_es: "Mis Contratos",
    },
    exact: true,
  },
  {
    component: MyAcreage,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/my-acreage",
    order: 7,
    title: {
      title_en: "My Acreage",
      title_fr: "Ma Superficie",
      title_es: "Mi Superficie",
    },
    exact: true,
  },
  {
    component: MyPeopleMain,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/my-people",
    order: 8,
    title: {
      title_en: "My People",
      title_fr: "Mon Peuple",
      title_es: "Mi Gente",
    },
    exact: true,
  },
  {
    component: PaymentEquity,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/payments-and-equity",
    order: 9,
    title: {
      title_en: "My Payments And Equity",
      title_fr: "Mes Paiements Et Mon Équité",
      title_es: "Mis Pagos Y Capital",
    },
    exact: true,
  },
  {
    component: Payments,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/payments-and-equity/payments",
    order: 10,
    title: {
      title_en: "Payments",
      title_fr: "Paiements",
      title_es: "Pagos",
    },
    exact: true,
  },
  {
    component: Equity,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/payments-and-equity/equity",
    order: 11,
    title: {
      title_en: "Equity",
      title_fr: "Équité",
      title_es: "Equidad",
    },
    exact: true,
  },
  {
    component: StatementReport,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/payments-and-equity/statements-and-reports",
    order: 12,
    title: {
      title_en: "Statements And Reports",
      title_fr: "Déclarations Et Rapports",
      title_es: "Estados De Cuenta E Informes",
    },
    exact: true,
  },
  {
    component: PaymentEstimator,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/payments-and-equity/payment-estimator",
    order: 67,
    title: {
      title_en: "Payment Estimator",
      title_fr: "Estimateur de Paiement",
      title_es: "Estimador de Pagos",
    },
    exact: true,
  },
  {
    component: MyOSContacts,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/my-os-contacts",
    order: 13,
    title: {
      title_en: "My OS Contacts",
      title_fr: "Contacts De Mon OS",
      title_es: "Mis Contactos Del OS",
    },
    exact: true,
  },
  {
    component: MyOSContactDetail,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-farm/my-os-contacts/:contactID/:pageSection/:contactName",
    order: 14,
    title: {
      title_en: "My OS Contact Detail",
      title_fr: "Coordonnées De Mon OS",
      title_es: "Detalles De Contacto De Mi OS",
    },
    exact: true,
  },
  {
    component: MyHarvest,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-harvest",
    order: 15,
    title: {
      title_en: "My Harvest",
      title_fr: "Ma Récolte",
      title_es: "Mi Cosecha",
    },
    exact: true,
  },
  {
    component: PreHarvest,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-harvest/pre-harvest",
    order: 16,
    title: {
      title_en: "Pre Harvest",
      title_fr: "Pré Récolte",
      title_es: "Antes De La Cosecha",
    },
    exact: true,
  },
  {
    component: MyDeliveryData,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-harvest/my-delivery-data",
    order: 17,
    title: {
      title_en: "My Delivery Data",
      title_fr: "Mes Données De Livraison",
      title_es: "Mis Datos De Entrega",
    },
    exact: true,
  },
  {
    component: HarvestNews,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/my-harvest/harvest-news",
    order: 18,
    title: {
      title_en: "Harvest News",
      title_fr: "Nouvelles De La Récolte",
      title_es: "Noticias De Cosecha",
    },
    exact: true,
  },
  {
    component: NewsAndEvents,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/news-events",
    order: 19,
    title: {
      title_en: "News And Events",
      title_fr: "Nouvelles Et Evènements",
      title_es: "Noticias Y Eventos",
    },
    exact: true,
  },
  {
    component: NewsDetails,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/news-events/:postDateYear/:postDateMonth/:postDateDay/:postId/:postSlug",
    order: 20,
    title: {
      title_en: "News And Events Details",
      title_fr: "Détails Des Nouvelles Et Des Événements",
      title_es: "Detalles De Noticias Y Eventos",
    },
    exact: false,
  },
  {
    component: NewsAndEvents,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/news",
    order: 21,
    title: {
      title_en: "News",
      title_fr: "Nouvelles",
      title_es: "Noticias",
    },
    exact: true,
  },
  {
    component: NewsDetails,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/news/:postDateYear/:postDateMonth/:postDateDay/:postId/:postSlug",
    order: 22,
    title: {
      title_en: "News Details",
      title_fr: "Détails De L'actualité",
      title_es: "Detalles De Noticias",
    },
    exact: false,
  },
  {
    component: Events,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/events",
    order: 23,
    title: {
      title_en: "Events",
      title_fr: "Événements",
      title_es: "Eventos",
    },
    exact: true,
  },
  {
    component: NewsDetails,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/events/:postDateYear/:postDateMonth/:postDateDay/:postId/:postSlug",
    order: 24,
    title: {
      title_en: "Events Details",
      title_fr: "Détails Des Événements",
      title_es: "Detalles De Eventos",
    },
    exact: false,
  },
  {
    component: ForumsAndClassifieds,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/forums",
    order: 25,
    title: {
      title_en: "Forums And Classifieds",
      title_fr: "Forums Et Petites Annonces",
      title_es: "Foros Y Clasificados",
    },
    exact: true,
  },
  {
    component: SearchForum,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/forums/search",
    order: 26,
    title: {
      title_en: "Search Forum",
      title_fr: "Rechercher Dans Le Forum",
      title_es: "Buscar En El Foro",
    },
    exact: true,
  },
  {
    component: ForumsDetail,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/forums/:forumType/:threadId/:threadName",
    order: 27,
    title: {
      title_en: "Forums & Classifieds Details",
      title_fr: "Détails Des Forums Et Petites Annonces",
      title_es: "Detalles De Foros Y Clasificados",
    },
    exact: true,
  },
  {
    component: AGSciences,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/region",
    order: 28,
    title: {
      title_en: "AG Sciences Region",
      title_fr: "AG Région Sciences",
      title_es: "Región De Ciencias AG",
    },
    exact: true,
  },
  {
    component: Regions,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/region/:region",
    order: 29,
    title: {
      title_en: "Region",
      title_fr: "Région",
      title_es: "Región",
    },
    exact: true,
  },
  {
    component: MyOSContactDetail,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/region/:contactID/:pageSection/:contactName",
    order: 30,
    title: {
      title_en: "My OS Contact Details",
      title_fr: "Coordonnées De Mon OS",
      title_es: "Detalles De Contacto De Mi OS",
    },
    exact: true,
  },
  {
    component: PoliciesAndForms,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/policies-and-forms",
    order: 31,
    title: {
      title_en: "Policies And Forms",
      title_fr: "Politiques Et Formulaires",
      title_es: "Políticas Y Formularios",
    },
    exact: true,
  },
  {
    component: CranberryManagement,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/cranberry-management",
    order: 32,
    title: {
      title_en: "Cranberry Management",
      title_fr: "Gestion Des Canneberges",
      title_es: "Gestión De Arándanos",
    },
    exact: true,
  },
  {
    component: CranberryManagementTopics,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/cranberry-management/:Id/:topic",
    order: 33,
    title: {
      title_en: "Cranberry Management Topics",
      title_fr: "Sujets De Gestion De La Canneberge",
      title_es: "Temas De Manejo De Arándanos",
    },
    exact: true,
  },
  {
    component: Ospac,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ospac",
    order: 34,
    title: {
      title_en: "OSPAC",
      title_fr: "OSPAC",
      title_es: "OSPAC",
    },
    exact: true,
  },
  {
    component: GrowerBenefits,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/grower-benefits",
    order: 35,
    title: {
      title_en: "Grower Benefits",
      title_fr: "Avantages Pour Le Producteur",
      title_es: "Beneficios Para El Productor",
    },
    exact: true,
  },
  {
    component: GrowerBenefits,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/grower-benefits/:ID/:componentName",
    order: 36,
    title: {
      title_en: "Grower Benefits Details",
      title_fr: "Détails Des Avantages Pour Les Producteurs",
      title_es: "Detalles De Beneficios Para Productores",
    },
    exact: true,
  },
  {
    component: GoverningDocuments,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative",
    order: 37,
    title: {
      title_en: "Cooperative",
      title_fr: "Coopérative",
      title_es: "Cooperativa",
    },
    exact: true,
  },
  {
    component: GoverningDocuments,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/purpose-and-governance",
    order: 38,
    title: {
      title_en: "Purpose And Governance",
      title_fr: "Objectif Et Gouvernance",
      title_es: "Propósito Y Gobernanza",
    },
    exact: true,
  },
  {
    component: BoardCouncilContacts,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/board-council-contacts",
    order: 39,
    title: {
      title_en: "Board And Council Contacts",
      title_fr: "Coordonnées Du Conseil Et Du Conseil",
      title_es: "Contactos De La Junta Y El Consejo",
    },
    exact: true,
  },
  {
    component: BoardCouncilContactsDetails,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/board-council-contacts/:contactID/:pageSection/:contactName",
    order: 40,
    title: {
      title_en: "Board And Council Contacts Details",
      title_fr: "Coordonnées Du Conseil Et Du Conseil",
      title_es: "Detalles De Contacto De La Junta Y El Consejo",
    },
    exact: true,
  },
  {
    component: Archive,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/board-communications",
    order: 41,
    title: {
      title_en: "Board Communications",
      title_fr: "Communications Du Conseil",
      title_es: "Comunicaciones De La Junta",
    },
    exact: true,
  },
  {
    component: CoopFinance,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/coop-finance",
    order: 42,
    title: {
      title_en: "Cooperative Finances",
      title_fr: "Finances Coopératives",
      title_es: "Finanzas Cooperativas",
    },
    exact: true,
  },
  {
    component: MyOSContacts,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/oceanspray-contacts",
    order: 43,
    title: {
      title_en: "Ocean Spray Contacts",
      title_fr: "Contacts De Pulvérisation D'océan",
      title_es: "Ocean Spray Contactos",
    },
    exact: true,
  },
  {
    component: DocumentsLanding,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents",
    order: 44,
    title: {
      title_en: "Documents",
      title_fr: "Documents",
      title_es: "Documentos",
    },
    exact: true,
  },
  {
    component: FormsLanding,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/forms",
    order: 45,
    title: {
      title_en: "Forms",
      title_fr: "Formes",
      title_es: "Formularios",
    },
    exact: true,
  },
  {
    component: OnlineForm,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/forms/:formDetail",
    order: 46,
    title: {
      title_en: "Forms Details",
      title_fr: "Détails Des Formulaires",
      title_es: "Detalles De Formularios",
    },
    exact: true,
  },
  {
    component: Archive,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/archives",
    order: 47,
    title: {
      title_en: "Archives",
      title_fr: "Les Archives",
      title_es: "Archivo",
    },
    exact: true,
  },
  {
    component: Archive,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/archives/:categorySlug?",
    order: 48,
    title: {
      title_en: "Archives",
      title_fr: "Les Archives",
      title_es: "Archivo",
    },
    exact: true,
  },
  {
    component: Archive,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/archives/:categorySlug?",
    order: 49,
    title: {
      title_en: "Archives",
      title_fr: "Les Archives",
      title_es: "Archivo",
    },
    exact: true,
  },
  {
    component: Archive,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences/archives/:categorySlug/detail",
    order: 50,
    title: {
      title_en: "Archives",
      title_fr: "Les Archives",
      title_es: "Archivo",
    },
    exact: true,
  },
  {
    component: PickupPage,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/pickup-page",
    order: 51,
    title: {
      title_en: "Pick Up Page",
      title_fr: "Page De Ramassage",
      title_es: "Página De Recogida",
    },
    exact: true,
  },
  {
    component: OSAcademyLanding,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/os-academy",
    order: 52,
    title: {
      title_en: "OS Academy",
      title_fr: "Académie Du OS",
      title_es: "Academia Del OS",
    },
    exact: true,
  },
  {
    component: OSAcademySignup,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/os-academy/sign-up",
    order: 53,
    title: {
      title_en: "OS Academy Application",
      title_fr: "Application De L'OS Academy",
      title_es: "Aplicación De La Academia OS",
    },
    exact: true,
    CommonPageCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker],
  },
  {
    component: OSAcademyPastPresentations,
    CheckRoleCB: true,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/os-academy/past-presentations",
    order: 54,
    title: {
      title_en: "Past Attendees Presentations",
      title_fr: "Présentations Des Anciens Participants",
      title_es: "Presentaciones De Asistentes Anteriores",
    },
    exact: true,
  },
  {
    component: OSAcademyCourses,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/os-academy/:courseId/:courseTypeSlug/:courseSlug",
    order: 55,
    title: {
      title_en: "OS Academy Courses",
      title_fr: "Cours De L'OS Academy",
      title_es: "Cursos De La Academia OS",
    },
    exact: true,
  },
  {
    component: SiteSearch,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/search-results",
    order: 56,
    title: {
      title_en: "Search Results",
      title_fr: "Résultats De Recherche",
      title_es: "Resultados De La Búsqueda",
    },
    exact: true,
  },
  {
    component: Feedback,
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    path: "/feedback",
    order: 57,
    title: {
      title_en: "Feedback",
      title_fr: "Retour",
      title_es: "Comentario",
    },
    exact: true,
  },
  {
    order: 58,
    path: "/contact-us",
    exact: true,
    title: {
      title_en: "Contact Us",
      title_fr: "Contactez-Nous",
      title_es: "Contáctenos",
    },
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    component: ContactUs,
  },
  {
    order: 59,
    path: "/legal-terms",
    exact: true,
    title: {
      title_en: "Legal Terms",
      title_fr: "Termes Légaux",
      title_es: "Términos Legales",
    },
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    component: LegalTerms,
  },
  {
    order: 60,
    path: "/privacy-policy",
    exact: true,
    title: {
      title_en: "Privacy Policy",
      title_fr: "Politique De Confidentialité",
      title_es: "Política De Privacidad",
    },
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    component: PrivacyPolicy,
  },
  {
    component: Logout,
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    path: "/account/logout",
    order: 61,
    title: {
      title_en: "Logout",
      title_fr: "Se Déconnecter",
      title_es: "Cerrar Sesión",
    },
    exact: true,
  },
  {
    component: MyOSContactDetail,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/oceanspray-contacts/:contactID/:pageSection/:contactName",
    order: 62,
    title: {
      title_en: "My OS Contact Detail",
      title_fr: "Coordonnées De Mon OS",
      title_es: "Detalles De Contacto De Mi OS",
    },
    exact: true,
  },
  {
    component: CodeOfConduct,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/purpose-and-governance/grower-code-of-conduct-training",
    order: 63,
    title: {
      title_en: "Grower Code of Conduct Training",
      title_fr: "Coordonnées De Mon OS",
      title_es: "Detalles De Contacto De Mi OS",
    },
    exact: true,
  },
  {
    component: Sustainability,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/cooperative/sustainability",
    order: 64,
    title: {
      title_en: "Sustainability",
      title_fr: "Durabilité",
      title_es: "Sostenibilidad",
    },
    exact: true,
  },
  {
    component: DropoffPage,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/dropoff-page",
    order: 65,
    title: {
      title_en: "Drop Off Page",
      title_fr: "Page De Dépôt",
      title_es: "Página De Entrega",
    },
    exact: true,
  },
  {
    component: HowTosPage,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/documents/how-to",
    order: 66,
    title: {
      title_en: "How To’s",
      title_fr: "Mode d'emploi",
      title_es: "Cómo hacer",
    },
    exact: true,
  },
  {
    component: UserProfile,
    CheckRoleCB: false,
    CheckSpecialRoleCB: false,
    IsCommonPage: true,
    path: "/user-Profile/:tabName/:notificationIndex",
    order: 67,
    title: {
      title_en: "User Profile",
      title_fr: "Profil De L'utilisateur",
      title_es: "Perfil Del Usuario",
    },
    exact: true,
    CommonPageCBRoles: [roleCBAcreage, roleCBPayment, roleCBDecisionMaker, roleCBThirdPartyFarmService],
  },
  {
    component: ForecastingData,
    CheckRoleCB: true,
    CheckSpecialRoleCB: false,
    IsCommonPage: false,
    path: "/forecasting-data",
    order: 68,
    title: {
      title_en: "Forecasting Data",
      title_fr: "Données de prévision",
      title_es: "Pronóstico de datos",
    },
    exact: true,
    CommonPageCBRoles: [],
  },
];

const orderedRoutes = _.sortBy(Routes, "order", "asc");
export default Object.freeze(orderedRoutes);

// This RoutesInRedirect arr is for adding route check entries for the routes that are not in orederedRoutes object
export const RoutesInRedirect = [
  {
    component: AGSciences,
    CheckRoleCB: false,
    CheckSpecialRoleCB: true,
    IsCommonPage: false,
    path: "/community/ag-sciences",
    order: orderedRoutes.length,
    title: {
      title_en: "AG Sciences",
      title_fr: "AG Sciences",
      title_es: "Ciencias AG",
    },
    exact: true,
  },
];
