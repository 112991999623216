import React from "react";
import { Modal } from "react-bootstrap";

const FullScreenPopup = ({title = "",active, hidePopup, children}) => {
    return <>
        <Modal
            className="full-screen-popup"
            show={active}
            onHide={hidePopup}
            backdrop="static"
            keyboard={false}
            fullscreen="xxl-down"
            size="xl"
        >
            <Modal.Header className="modalhead" closeButton>
                <Modal.Title className="modaltitle">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbody">
                {children}
            </Modal.Body>
        </Modal>
    </>
}

export default FullScreenPopup;