import React, { Fragment, useState, useEffect } from "react";
import {
  IsStringChecker,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import parse from "html-react-parser";
import RichTextWrap from "../../global/richTextWrap";
import { ContentModifierLogic } from "../../global/contentModifier";

const EventLocation = ({ event_location = null, format = true }) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (IsStringChecker(event_location)) {
      try {
        if (format) {
          const content = ContentModifierLogic(event_location);
          setResult(IsStringChecker(content) ? content : null);
        } else {
          setResult(event_location);
        }
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        setResult(null);
      }
    } else {
      setResult(null);
    }
  }, [event_location, format]);

  if (IsStringChecker(result)) {
    return <RichTextWrap>{parse(result)}</RichTextWrap>;
  } else {
    return <Fragment />;
  }
};

export default EventLocation;
