import React, { useEffect, useState } from "react";
import Events from "../home/siteNotification";
import { useHistory } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import DeliveryApproval from "./deliveryApproval";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
// import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import Loading from "../../components/global/loading";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsObjectChecker,
} from "../../utils/helper";
import EPURsLandingPage from "./ePURs/epursLandingPage";
import _ from "lodash";
import ContentModifier from "../global/contentModifier";

const PreHarvestDetails = (props) => {
  const {
    RoleCBPrivileges = {},
    RolePBPrivileges = {},
    SpecialRoleExist,
  } = props;
  function RoleBasedAccessDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return roleCBThirdPartyFarmService;
      } else {
        const { roleCBAcreage = false, roleCBDecisionMaker = false } =
          priviledges;
        return roleCBAcreage || roleCBDecisionMaker;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  const { rolePBAgScientist = false } = RolePBPrivileges;

  const location = useHistory().location;
  const arrayLocation = location.hash;
  const [seletedTab, setSeletedTab] = useState([]); //expandedItem

  const idToken = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  const [contentBlock, setcontentBlock] = useState(null);
  const { i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedItem", e);
      let selectTab = [...seletedTab, ...e];
      setSeletedTab([...selectTab]);
    } else {
      localStorage.setItem("expandedItem", 0);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : ["5"];
    }
  }

  let retainAccordionForSet = "";
  useEffect(() => {
    if (arrayLocation != "" || localStorage.expandedItem != "") {
      if (arrayLocation != "") {
        retainAccordionForSet = arrayLocation.replace(/#/g, "");
        setSeletedTab([retainAccordionForSet]);
      } else {
        retainAccordionForSet = localStorage.expandedItem
          ? localStorage.expandedItem.split(",")
          : ["5"];
        setSeletedTab([...retainAccordionForSet]);
      }
    }
  }, []);

  useEffect(() => {
    IsTokenChecker(idToken) && getContentBlock();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const getContentBlock = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      "content_blocks?select=id,content_en,content_fr,content_es,order_by,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id)&component_listings.component_id=eq.dc4f5fc6-e07b-4799-8d27-6dd7faf2a37b"
    )
      .then((resp) => {
        const cb = _.get(resp, `data.data.0`, null);
        setcontentBlock(IsObjectChecker(cb) && !_.isEmpty(cb) ? cb : null);
        // resp?.data?.data?.map((i)=>{
        //   if(i?.page_listings?.slug =="pre-harvest"){
        //     setcontentBlock(i);
        //     setLoader(false);
        //   }
        // })
      })
      .catch((e) => {
        setcontentBlock(null);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const displaySection = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);
  return (
    <>
      {loader && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/* <!-- Accordion blocks starts --> */}
      <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
        {/* <!-- Accordion blocks starts --> */}
        <Events type={"pre-harvest"} />
        <Accordion
          defaultActiveKey={retainAccordion}
          alwaysOpen
          id="accordionPayments"
          onSelect={handlerAccordion}
          className="mt-0 harvest_news"
        >
          <Accordion.Item eventKey="lastPayment">
            <Accordion.Header>
              <img
                src="/assets/images/icon_harvester.svg"
                className="img-35 right-space-10"
                alt="Contracts_icon"
              />{" "}
              Harvest Obligations & Code Rules
            </Accordion.Header>

            <Accordion.Body>
              {/*Harvest Obligations & Code Rules*/}
              {seletedTab &&
              seletedTab.indexOf("lastPayment") > -1 &&
              contentBlock != null ? (
                <>{/* <div className="rich-editor-content-block">*/}
                  <ContentModifier
                    key={0}
                    str={contentBlock["content_" + i18n.resolvedLanguage]}
                  />
                  {/* {parse(contentBlock["content_" + i18n.resolvedLanguage])}
                </div>  */}</>
              ) : null}
              {/*End DeliveryApproval*/}
            </Accordion.Body>
          </Accordion.Item>
          {displaySection && (
            <Accordion.Item eventKey="paymentSearch">
              <Accordion.Header>
                <img
                  src="/assets/images/icon_approved.svg"
                  className="img-35 right-space-10"
                  alt="Contracts_icon"
                />{" "}
                Delivery Approval
              </Accordion.Header>
              <Accordion.Body>
                {/*DeliveryApproval*/}
                {seletedTab && seletedTab.indexOf("paymentSearch") > -1 && (
                  <DeliveryApproval {...props} action="preHarvest" />
                )}
                {/*End DeliveryApproval*/}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {(rolePBAgScientist || displaySection) && (
            <Accordion.Item eventKey="cashAssignments">
              <Accordion.Header>
                <img
                  src="/assets/images/icon_epurs.svg"
                  className="img-35 right-space-10"
                  alt="Contracts_icon"
                />{" "}
                ePURS
              </Accordion.Header>
              <Accordion.Body>
                {/*DeliveryApproval*/}
                {seletedTab && seletedTab.indexOf("cashAssignments") > -1 && (
                  <EPURsLandingPage {...props} />
                )}
                {/*End DeliveryApproval*/}
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </div>
    </>
  );
};

export default PreHarvestDetails;
