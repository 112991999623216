import React, { Fragment, useEffect, useReducer, useState } from "react";
import {
  compareString,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  IsNumberChecker,
  IsStringChecker,
} from "../../../../utils/helper";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
  registerables,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Form, InputGroup, Button } from "react-bootstrap";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip
);

export const ZeroLimit = 0,
  HundredLimit = 100,
  OneMillionLimit = 1000000;
const hundred_err_msg = "A valid number [0 - 100] is required";
const million_err_msg = "A valid number [0 - 1,000,000] is required";
const label_CommonStockSharesHeld = "Common Stock Shares held";
const label_CommonSharesNeededToReachQuota =
  "Common Shares needed to reach Quota";
const label_CurrentCommonStock = "Current Common Stock";

const col_common_stock_shares_held = `rgba(189, 215, 238, 1)`,
  op_col_common_stock_shares_held = `rgba(189, 215, 238, 0.8)`;
const col_common_shares_needed = `rgba(192, 0, 0, 1)`,
  op_col_common_shares_needed = `rgba(192, 0, 0, 0.8)`;
const col_quota = `rgba(244, 177, 131, 1)`,
  op_col_quota = `rgba(244, 177, 131, 0.8)`;
const col_deliveries = `rgba(198, 224, 180, 1)`,
  op_col_deliveries = `rgba(198, 224, 180, 0.8)`;
// const col_common_stock_shares_held = `rgba(27, 134, 162, 1)`,
//   op_col_common_stock_shares_held = `rgba(27, 134, 162, 0.6)`;
// const col_common_shares_needed = `rgba(250, 220, 185, 1)`,
//   op_col_common_shares_needed = `rgba(250, 220, 185, 0.6)`;
// const col_quota = `rgba(239, 192, 61, 1)`,
//   op_col_quota = `rgba(239, 192, 61, 0.6)`;
// const col_deliveries = `rgba(142, 33, 64, 1)`,
//   op_col_deliveries = `rgba(142, 33, 64, 0.6)`;
// const col_deliveries = `rgba(96, 203, 19, 1)`,
//   op_col_deliveries = `rgba(96, 203, 19, 0.8)`;
// const col_common_stock_shares_held = `rgba(1, 24, 77, 1.0)`,
//   op_col_common_stock_shares_held = `rgba(1, 24, 77, 0.8)`;
// const col_common_shares_needed = "rgba(216, 0, 39, 1.0)",
//   op_col_common_shares_needed = "rgba(216, 0, 39, 0.8)";
// const col_quota = `rgba(248, 175, 24, 1.0)`,
//   op_col_quota = `rgba(248, 175, 24, 0.8)`;
// const col_deliveries = `rgba(91, 155, 13, 1.0)`,
//   op_col_deliveries = `rgba(91, 155, 13, 0.8)`;
export function EventMGMT(e, def = true, propagate = true) {
  try {
    if (def && propagate) {
      e.stopPropagation();
      e.preventDefault();
    } else if (def || propagate) {
      if (def) {
        e.preventDefault();
      } else {
        e.stopPropagation();
      }
    }
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
  }
}

export function KeyDownHandler(e) {
  const { key, target = {} } = e;
  const { value } = target;
  if (key === " ") {
    EventMGMT(e);
  }
  if (key.length === 1) {
    if (key === ".") {
      const isDecimal = value.includes(".");
      if (isDecimal) {
        EventMGMT(e);
      }
    } else {
      if (isNaN(key)) {
        EventMGMT(e);
      }
    }
  }
}

export function OnChangeDecimalWrapper(e) {
  try {
    const { name, value } = e?.target || {};
    if (value === ".") {
      return { name, value: "0." };
    } else if (value.startsWith(".")) {
      return { name, value: `0${value}` };
    }
    return { name, value };
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return e?.target || {};
  }
}

export function RemoveNumberFormater(value) {
  try {
    if (IsStringChecker(value)) {
      const withoutComma = value.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    }
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  }
}

export function WholeRateValidation(value, min = 0, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num >= min && num <= max) {
        return `${value}`;
      } else if (num <= max) {
        return `${min}`;
      } else if (num >= min) {
        return `${max}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function NumberFormaterWithValidation(
  value,
  Validator = () => {},
  min = 0,
  max = 100
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const num = `${inputNum}.${decimals}`;
          if (num === "0.0") {
            return num;
          } else {
            return Validator(num, min, max);
          }
        } else {
          const num = Validator(inputNum, min, max);
          return IsStringChecker(num) ? `${num}.` : "";
        }
      } else {
        return Validator(inputNum, min, max);
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function IsNumber(
  value,
  oMinCheck = false,
  oMaxCheck = false,
  isEquals = false,
  oMin = 0,
  oMax = 100
) {
  try {
    if (IsNumberChecker(value)) {
      return CalcValidate(value, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
    } else if (IsStringChecker(value)) {
      const num = parseFloat(value);
      if (IsNumberChecker(num)) {
        return CalcValidate(num, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function CalcValidate(val, equals, minCheck, min, maxCheck, max) {
  try {
    if (minCheck && maxCheck) {
      if (equals) {
        return val >= min && val <= max;
      } else {
        return val > min && val < max;
      }
    } else if (minCheck || maxCheck) {
      if (minCheck) {
        if (equals) {
          return val >= min;
        } else {
          return val > min;
        }
      } else {
        if (equals) {
          return val <= max;
        } else {
          return val < max;
        }
      }
    } else {
      return true;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function NumberToNormal(num, float = true) {
  try {
    const new_num = `${num}`.replace(/,/g, "");
    return float ? parseFloat(new_num) : parseInt(new_num);
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
}

export function BarrelsNumberFormater(
  value,
  validate = false,
  decimal = false
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      const options = {};
      if (decimal) {
        options.style = "decimal";
        options.maximumFractionDigits = 2;
      }
      const USNum = new Intl.NumberFormat("en-US", { ...options });

      const num = USNum.format(inputNum);
      if (num == "NaN") {
        return "";
      } else {
        if (validate) {
          const { inputNum: toValidate } = RemoveNumberFormater(num);
          const inputValidate = NumConverter(toValidate);
          if (inputValidate < maxBarrels) {
            if (isDecimal) {
              const decimals = IsStringChecker(decimalSeq)
                ? decimalSeq.substring(0, 2)
                : "";
              return `${num}.${decimals}`;
            } else {
              return num;
            }
          } else {
            return `9,000,000`;
          }
        } else {
          if (isDecimal) {
            const decimals = IsStringChecker(decimalSeq)
              ? decimalSeq.substring(0, 2)
              : "";
            return `${num}.${decimals}`;
          } else {
            return num;
          }
        }
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function PercentCalculator(num1, num2) {
  try {
    const num = (num1 / num2) * 100;
    // return num;
    return num ? num.toFixed(2) : 0;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
}

function CommonStockQuotaGraph(props) {
  const {
    contractObj = {},
    tableList = [],
    countKeeper = "",
    contracts = [],
  } = props;
  const { contract = "" } = contractObj || {};

  const [display, set_display] = useState(false);
  const [contract_name, set_contract_name] = useState("");

  function GetContractDetails(arr = [], id = "") {
    try {
      if (id && IsArrayNotEmpty(arr)) {
        const data = arr.find((li) => li.id == id);
        if (data) {
          return data?.name || "";
        }
        return "";
      }
      return "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  }

  function IsArrayElementsTrue() {
    try {
      const arr = [...arguments];
      return arr.every((li) => li);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  function ShowCSQG() {
    if (IsStringChecker(contract)) {
      const split_contract = contract.split(",");
      if (split_contract.length === 1) {
        const cname = GetContractDetails(contracts, split_contract[0]);
        set_contract_name(cname);
        return IsArrayElementsTrue(
          IsArrayNotEmpty(tableList),
          IsNumberChecker(countKeeper)
        );
      }
      return false;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const show = ShowCSQG();
    set_display(show);
  }, [contract, tableList, countKeeper]);

  if (display) {
    return (
      <Fragment>
        <div
          className={`card-shadow white_bg border-radius-6 mt-3 mt-lg-0 mb-3 mb-lg-4`}
        >
          <h4
            className={`card-header white_color ver-space-15 left-border-radius right-border-radius`}
          >
            {`Contract: ${contract_name}`}
          </h4>
          <div className="card-body mob-pleft-pright-0">
            <div className="row">
              <div className="col-12">
                <div className={`text-16 px-2 px-lg-0`}>
                  <GaugeChartWithCalculator tableList={tableList} />
                  <HistoryChart contract={contract} tableList={tableList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

export default CommonStockQuotaGraph;

function GaugeChartWithCalculator(props) {
  const { tableList = [] } = props;

  const cropyear_name = "cropyear",
    average_deliveries_name = "average_deliveries",
    quota_name = "quota",
    quota_percent_name = "quota_percent",
    common_shares_held_name = "common_shares_held",
    common_shares_held_percent_name = "common_shares_held_percent",
    common_shares_held_needed_name = "common_shares_held_needed",
    common_shares_held_needed_percent_name =
      "common_shares_held_needed_percent",
    purchased_stock_name = "purchased_stock",
    purchased_stock_percent_name = "purchased_stock_percent",
    shares_to_purchase_name = "shares_to_purchase",
    shares_to_purchase_percent_name = "shares_to_purchase_percent",
    percent_to_quota_name = "percent_to_quota";

  const percent_list = [
    percent_to_quota_name,
    quota_percent_name,
    common_shares_held_percent_name,
    common_shares_held_needed_percent_name,
    shares_to_purchase_percent_name,
  ];

  const initial_gauge_data = {
    cropyear: "",
    average_deliveries: "",
    quota: "0",
    quota_error: false,
    quota_percent: "0",
    quota_percent_error: false,
    common_shares_held_needed: "0",
    common_shares_held_needed_error: false,
    common_shares_held_needed_percent: 0,
    common_shares_held_needed_percent_error: false,
    common_shares_held: "0",
    common_shares_held_error: false,
    common_shares_held_percent: 0,
    common_shares_held_percent_error: false,
    shares_to_purchase: "0",
    shares_to_purchase_error: false,
    shares_to_purchase_percent: 0,
    shares_to_purchase_percent_error: false,
    purchased_stock: "0",
    purchased_stock_error: false,
    purchased_stock_percent: 0,
    purchased_stock_percent_error: false,
    percent_to_quota: "0",
    percent_to_quota_error: false,
  };

  const initial_gauge_chart = {
    current_common_stock_label: label_CurrentCommonStock,
    common_shares_needed_label: label_CommonSharesNeededToReachQuota,
    common_stock_shares_label: label_CommonStockSharesHeld,
    cropyear: "",
    average_deliveries: "",
    current_common_stock: 0,
    current_common_stock_percent: 0,
    common_shares_needed: 0,
    common_shares_needed_percent: 0,
    common_stock_shares: 0,
    common_stock_shares_percent: 0,
  };

  const [gauge_data, DispatchGaugeData] = useReducer(GaugeDataReducer, {});
  const [gauge, DispatchGauge] = useReducer(GaugeReducer, {});
  const [original_gauge, set_original_gauge] = useState({});

  function GaugeDataReducer(state, action) {
    try {
      const { type, payload } = action;
      switch (type) {
        case "replace":
          return { ...payload };
        case "change":
          return GaugeDataMapper(state, payload);
        default:
          return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function GaugeReducer(state, action) {
    try {
      const { type, payload } = action;
      switch (type) {
        case "replace":
          return { ...payload };
        default:
          return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function GaugeDataMapper(state, payload) {
    try {
      const { data_list = [] } = payload;
      if (IsArrayNotEmpty(data_list)) {
        const temp = { ...state };
        data_list.forEach((list) => {
          const { name, value, error } = list;
          temp[name] = value;
          temp[`${name}_error`] = error;
        });
        return temp;
      }
      return state;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function onChange(e) {
    const { name, value } = OnChangeDecimalWrapper(e);
    GaugeDataChangeHandler(name, value);
  }

  function ValidationLimitSetter(name) {
    try {
      const is_percent = percent_list.find((li) => compareString(li, name));
      const max_limit = is_percent ? HundredLimit : OneMillionLimit;
      const min_limit = ZeroLimit;
      return { min_limit, max_limit };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { min_limit: ZeroLimit, max_limit: OneMillionLimit };
    }
  }

  function Validator(name, value) {
    try {
      const { min_limit, max_limit } = ValidationLimitSetter(name);
      const num = NumberFormaterWithValidation(
        `${value}`,
        WholeRateValidation,
        min_limit,
        max_limit
      );
      const error = IsNumber(num, true, true, true, min_limit, max_limit)
        ? false
        : true;
      return { name, value: num, error };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { name, value: "0", error: false };
    }
  }

  function GaugeDataChangeHandler(oname, ovalue) {
    const { name, value, error } = Validator(oname, ovalue);
    DispatchGaugeData({
      type: "change",
      payload: { data_list: [{ name, value, error }] },
    });
    if (error || compareString(name, percent_to_quota_name)) {
      DispatchGaugeData({
        type: "change",
        payload: { data_list: [{ name, value, error }] },
      });
    } else {
      const temp = { ...gauge_data };
      temp[name] = value;
      temp[`${name}_error`] = error;
      const data_list = [];
      data_list.push({ name, value, error });
      GaugeCalculator(data_list, temp);
      DispatchGaugeData({
        type: "change",
        payload: { data_list },
      });
    }
  }

  function GaugeCalculator(data_list = [], state = {}) {
    try {
      const {
        quota = "0",
        common_shares_held = "0",
        shares_to_purchase = "0",
      } = state;

      const n_quota = NumberToNormal(quota);
      const n_common_shares_held = NumberToNormal(common_shares_held);
      const n_shares_to_purchase = NumberToNormal(shares_to_purchase);
      const purchased_stock = n_common_shares_held + n_shares_to_purchase;
      const common_shares_held_needed = n_quota - purchased_stock;

      data_list.push(Validator(purchased_stock_name, purchased_stock));
      data_list.push(
        Validator(common_shares_held_needed_name, common_shares_held_needed)
      );

      const quota_percent = n_quota ? PercentCalculator(n_quota, n_quota) : 0;
      data_list.push(Validator(quota_percent_name, quota_percent));

      const purchased_stock_percent = purchased_stock
        ? PercentCalculator(purchased_stock, n_quota)
        : 0;
      data_list.push(
        Validator(purchased_stock_percent_name, purchased_stock_percent)
      );

      const common_shares_held_percent = n_common_shares_held
        ? PercentCalculator(n_common_shares_held, n_quota)
        : 0;
      data_list.push(
        Validator(common_shares_held_percent_name, common_shares_held_percent)
      );

      const common_shares_held_needed_percent = common_shares_held_needed
        ? PercentCalculator(common_shares_held_needed, n_quota)
        : 0;
      data_list.push(
        Validator(
          common_shares_held_needed_percent_name,
          common_shares_held_needed_percent
        )
      );

      const shares_to_purchase_percent = n_shares_to_purchase
        ? PercentCalculator(n_shares_to_purchase, n_quota)
        : 0;
      data_list.push(
        Validator(shares_to_purchase_percent_name, shares_to_purchase_percent)
      );

      const n_percent_to_quota = purchased_stock
        ? PercentCalculator(purchased_stock, n_quota)
        : 0;
      const percent_to_quota = Validator(
        percent_to_quota_name,
        n_percent_to_quota
      );
      data_list.push(percent_to_quota);
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return data_list;
    }
  }

  function GetLatestData(arr = []) {
    try {
      if (IsArrayNotEmpty(arr)) {
        const current = Math.max(...arr.map((li) => li?.CropYear || 0));
        if (current) {
          const latest = arr.find((li) => li?.CropYear == current);
          if (latest) {
            const {
              CropYear,
              Quota,
              AverageDeliveries,
              CommonStockShares,
              // PercentToQuota,
            } = latest || {};
            const average_deliveries = NumberToNormal(AverageDeliveries);
            const cropyear = NumberToNormal(CropYear, false);
            const n_quota = NumberToNormal(Quota);
            const n_common_shares_held = NumberToNormal(CommonStockShares);

            const quota = Validator(quota_name, n_quota);
            const common_shares_held = Validator(
              common_shares_held_name,
              n_common_shares_held
            );

            const data = {
              cropyear,
              average_deliveries,
              quota: quota?.value,
              quota_error: quota?.error,
              common_shares_held: common_shares_held?.value,
              common_shares_held_error: common_shares_held?.error,
              shares_to_purchase: "0",
              shares_to_purchase_error: false,
            };

            const data_list = [];
            GaugeCalculator(data_list, {
              common_shares_held: common_shares_held.value,
              quota: quota.value,
              shares_to_purchase: 0,
            });
            data_list.forEach((li) => {
              const { name, value, error } = li;
              data[name] = value;
              data[`${name}_error`] = error;
            });
            DispatchGaugeData({ type: "replace", payload: data });
            set_original_gauge(data);
            GaugeToChart(data);
          } else {
            DispatchDefaultData();
          }
        } else {
          DispatchDefaultData();
        }
      } else {
        DispatchDefaultData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      DispatchDefaultData();
    }
  }

  function DispatchDefaultData() {
    DispatchGaugeData({ type: "replace", payload: initial_gauge_data });
    DispatchGauge({ type: "replace", payload: initial_gauge_chart });
    set_original_gauge(initial_gauge_data);
  }

  function GaugeToChart(gauge_data = {}) {
    try {
      const {
        cropyear = "",
        average_deliveries = "",
        quota = 0,
        quota_error = true,
        quota_percent = 0,
        quota_percent_error = true,
        common_shares_held_needed = 0,
        common_shares_held_needed_error = true,
        common_shares_held_needed_percent = 0,
        common_shares_held_needed_percent_error = true,
        purchased_stock = 0,
        purchased_stock_error = true,
        purchased_stock_percent = 0,
        purchased_stock_percent_error = true,
      } = gauge_data;

      const gauge = { ...initial_gauge_chart };
      gauge[cropyear_name] = cropyear;
      gauge[average_deliveries_name] = average_deliveries;
      gauge["current_common_stock"] = quota_error ? 0 : quota;
      gauge["current_common_stock_percent"] = quota_percent_error
        ? 0
        : quota_percent;
      gauge["common_shares_needed"] = common_shares_held_needed_error
        ? 0
        : common_shares_held_needed;
      gauge["common_shares_needed_percent"] =
        common_shares_held_needed_percent_error
          ? 0
          : common_shares_held_needed_percent;
      gauge["common_stock_shares"] = purchased_stock_error
        ? 0
        : purchased_stock;
      gauge["common_stock_shares_percent"] = purchased_stock_percent_error
        ? 0
        : purchased_stock_percent;

      DispatchGauge({ type: "replace", payload: gauge });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      DispatchGauge({ type: "replace", payload: initial_gauge_chart });
    }
  }

  function onCalculate() {
    GaugeToChart(gauge_data);
  }

  function onReset() {
    DispatchGaugeData({ type: "replace", payload: original_gauge });
    GaugeToChart(original_gauge);
  }

  const {
    cropyear = "",
    quota = 0,
    quota_error = true,
    common_shares_held = 0,
    common_shares_held_error = true,
    shares_to_purchase = 0,
    shares_to_purchase_error = true,
    percent_to_quota = 0,
    percent_to_quota_error = true,
  } = gauge_data;

  useEffect(() => {
    GetLatestData(tableList);
  }, [tableList]);

  const {
    current_common_stock_label,
    cropyear: gauge_cropyear = "",
    current_common_stock = 0,
  } = gauge;
  return (
    <Fragment>
      <div className="row">
        <div className="csqg-calculator col-12 col-lg-7 mb-3 mb-lg-0">
          <div className="row mb-3">
            <div className="col">
              <h4 className={`fw-bold`}>
                Percent to Quota Calculator
                <span className="drop-offinfo">
                  {`(calculated for crop year ${cropyear})`}
                </span>
              </h4>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Text className="col-5 text-wrap text-start">
                  Quota
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name={quota_name}
                  value={BarrelsNumberFormater(`${quota}`)}
                  onChange={onChange}
                  onKeyDown={KeyDownHandler}
                  isInvalid={!IsStringChecker(quota)}
                  required
                  placeholder={"Enter the quota"}
                  readOnly={true}
                  autoComplete="off"
                />
              </InputGroup>
              {quota_error && (
                <Form.Text className="csqg-error">{million_err_msg}</Form.Text>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Text className="col-5 text-wrap text-start">
                  Common Shares Held
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name={common_shares_held_name}
                  value={BarrelsNumberFormater(`${common_shares_held}`)}
                  onChange={onChange}
                  onKeyDown={KeyDownHandler}
                  isInvalid={!IsStringChecker(common_shares_held)}
                  required
                  placeholder={"Enter the common shares held"}
                  readOnly={true}
                  autoComplete="off"
                />
              </InputGroup>
              {common_shares_held_error && (
                <Form.Text className="csqg-error">{million_err_msg}</Form.Text>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Text className="col-5 text-wrap text-start">
                  Shares to Purchase
                </InputGroup.Text>
                <Form.Control
                  className="white_bg"
                  type="text"
                  name={shares_to_purchase_name}
                  value={BarrelsNumberFormater(`${shares_to_purchase}`)}
                  onChange={onChange}
                  onKeyDown={KeyDownHandler}
                  isInvalid={!IsStringChecker(shares_to_purchase)}
                  required
                  placeholder={"Enter the shares to purchase"}
                  readOnly={false}
                  autoComplete="off"
                />
              </InputGroup>
              {shares_to_purchase_error && (
                <Form.Text className="csqg-error">{million_err_msg}</Form.Text>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Text className="col-5 text-wrap text-start">
                  Percent to Quota
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name={percent_to_quota_name}
                  value={percent_to_quota}
                  onChange={onChange}
                  onKeyDown={KeyDownHandler}
                  isInvalid={!IsStringChecker(percent_to_quota)}
                  required
                  placeholder={"Enter the percent to quota"}
                  readOnly={true}
                  autoComplete="off"
                />
              </InputGroup>
              {percent_to_quota_error && (
                <Form.Text className="csqg-error">{hundred_err_msg}</Form.Text>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <Button
                variant="primary"
                className={`csqg-btn-shadow text-16 ${
                  false ? "cursor-not-allowed" : ""
                } float-end`}
                onClick={onCalculate}
              >
                {`Calculate`}
              </Button>
              <Button
                variant="light"
                className={`csqg-btn-shadow text-16 mx-2 ${
                  false ? "cursor-not-allowed" : ""
                } float-end`}
                onClick={onReset}
                // disabled={true}
              >
                {`Reset`}
              </Button>
            </div>
          </div>
        </div>
        <hr className="Payment-Estimator-Segment d-block d-lg-none" />
        <div className="col-12 col-lg-5">
          <div className="row mb-2">
            <div className="col-12">
              <h4 className={`fw-bold`}>
                Percent to Quota
                <span className="drop-offinfo">{`(for crop year ${gauge_cropyear})`}</span>
              </h4>
            </div>
            <div className="col-12">
              <h5 className={`fw-bold`}>
                {current_common_stock_label}:{" "}
                {BarrelsNumberFormater(`${current_common_stock}`)}
              </h5>
            </div>
          </div>
          <div className="csqg-gauge-chart-wrap">
            <RenderGauge gauge={gauge} />
          </div>
        </div>
      </div>
      <hr className="Payment-Estimator-Segment" />
    </Fragment>
  );
}

function HistoryChart(props) {
  const { contract = "", tableList = [] } = props;

  const [chart_data, dispatch_chart_data] = useReducer(ChartDataReducer, {});

  function ChartDataReducer(state, action) {
    // console.log({ state, action });
    try {
      const { type, payload } = action;
      switch (type) {
        case "replace":
          return { ...payload };
        default:
          return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return state;
    }
  }

  function HistoryChartData(contract = "", data = []) {
    try {
      if (contract && IsArrayNotEmpty(data)) {
        const temp_arr = data.filter((li) => li.ContractId == contract);
        const contract_data = [...temp_arr].reverse();
        if (IsArrayNotEmpty(contract_data)) {
          const temp = {
            chart: true,
            labels: contract_data.map((li) => NumberToNormal(li?.CropYear)),
            label_1: "Average Deliveries",
            data_1: contract_data
              .map((li) => li?.AverageDeliveries)
              .filter((li) => li)
              .map((it) => NumberToNormal(it)),
            label_2: "Common Stock Quota",
            data_2: contract_data.map((li) => NumberToNormal(li?.Quota)),
            label_3: "Common Shares Held",
            data_3: contract_data.map((li) =>
              NumberToNormal(li?.CommonStockShares)
            ),
          };
          return temp;
        }
        return {};
      }
      return {};
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  }

  useEffect(() => {
    const payload = HistoryChartData(contract, tableList);
    dispatch_chart_data({ type: "replace", payload });
  }, []);

  return <RenderBar chart_data={chart_data} />;
}

function RenderBar(props) {
  const { chart_data = {} } = props;
  // console.log({ chart_data });
  const {
    chart = false,
    labels = [],
    label_1 = "",
    data_1 = [],
    label_2 = "",
    data_2 = [],
    label_3 = "",
    data_3 = [],
  } = chart_data || {};
  const data = {
    labels,
    datasets: [
      {
        label: label_1,
        data: data_1,
        backgroundColor: [
          op_col_deliveries,
          op_col_deliveries,
          op_col_deliveries,
          op_col_deliveries,
          op_col_deliveries,
        ],
        borderColor: [
          col_deliveries,
          col_deliveries,
          col_deliveries,
          col_deliveries,
          col_deliveries,
        ],
        borderWidth: 1,
      },
      {
        label: label_2,
        data: data_2,
        backgroundColor: [
          op_col_quota,
          op_col_quota,
          op_col_quota,
          op_col_quota,
          op_col_quota,
        ],
        borderColor: [col_quota, col_quota, col_quota, col_quota, col_quota],
        borderWidth: 1,
      },
      {
        label: label_3,
        data: data_3,
        backgroundColor: [
          op_col_common_stock_shares_held,
          op_col_common_stock_shares_held,
          op_col_common_stock_shares_held,
          op_col_common_stock_shares_held,
          op_col_common_stock_shares_held,
        ],
        borderColor: [
          col_common_stock_shares_held,
          col_common_stock_shares_held,
          col_common_stock_shares_held,
          col_common_stock_shares_held,
          col_common_stock_shares_held,
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        onClick: false,
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: false,
          pointStyle: "rect",
        },
        // reverse: true,
      },
      Tooltip: {
        position: "nearest",
      },
    },
  };
  return chart ? (
    <Fragment>
      <div className="row mb-2">
        <div className="col">
          <h4 className={`fw-bold`}>
            Historical Average Deliveries and Common Stock Quota
            {/* <span className="drop-offinfo">(for last 5 cropyears)</span> */}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="csqg-bar-chart-wrap">
            <Chart
              className="csqg-bar-chart"
              type="bar"
              options={options}
              data={data}
            />
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment />
  );
}

function RenderGauge(props) {
  const { gauge = {} } = props;

  const {
    common_shares_needed_label,
    common_stock_shares_label,
    common_shares_needed = 0,
    common_shares_needed_percent = 0,
    common_stock_shares = 0,
    common_stock_shares_percent = 0,
  } = gauge;

  function tooltipLabelEdit(tooltipItem) {
    try {
      const { label = "", formattedValue = "" } = tooltipItem || {};
      const subfix = compareString(label_CommonStockSharesHeld, label)
        ? common_stock_shares_percent
        : common_shares_needed_percent;
      const text = `${label}: ${formattedValue}; (${subfix}%)`;
      return text;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return tooltipItem;
    }
  }

  const data = {
    labels: [common_stock_shares_label, common_shares_needed_label],
    datasets: [
      {
        label: "Percent to Quota",
        data: [common_stock_shares, common_shares_needed],
        backgroundColor: [
          op_col_common_stock_shares_held,
          op_col_common_shares_needed,
        ],
        borderColor: [col_common_stock_shares_held, col_common_shares_needed],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        onClick: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
        // reverse: true,
      },
      tooltip: {
        position: "nearest",
        callbacks: {
          label: tooltipLabelEdit,
        },
      },
    },
    // spacing: 10,
  };
  return (
    <Chart
      className="csqg-gauge-chart"
      type="doughnut"
      options={options}
      data={data}
    />
  );
}
