import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../../global/breadCrumb";
import Loading from "../../global/loading";
import { Link } from "react-router-dom";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import {
  lowStr,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsObjectChecker,
  IsTokenChecker,
  IsStringChecker,
  sortByField,
  groupArrayByKey,
  IsFunctionChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../../utils/helper";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import OSAcademyVideoModal from "./osAcademyVideoModal";
import { ContentModifierLogic } from "../../global/contentModifier";

const OSAcademyPastPresentationsDetail = (props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const [presentations, setPresentations] = useState([]);
  const [presentationSet, setPresentationSet] = useState([]);
  const [presentationGroup, setPresentationGroup] = useState({});
  const academy_course_en_path = "academy_courses.title_en";

  const [search, setSearch] = useState("");

  const [show, setShow] = useState(false);
  const [content, setContent] = useState(null);

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "os_academy"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const UniqueObjectArray = (
    item,
    index,
    original,
    field = "academy_courses.id"
  ) => {
    try {
      return (
        original.findIndex(
          (list) => _.get(list, field) === _.get(item, field)
        ) === index && item
      );
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const ValueProvider = (obj = null, prefix = "title_") => {
    return IsObjectChecker(obj)
      ? _.get(obj, `${prefix}${resolvedLanguage}`, null)
      : null;
  };

  const onChange = (e) => {
    const { target: { value = null } = {} } = e;
    setSearch(value);
    const search = lowStr(value);
    if (IsArrayNotEmpty(presentations)) {
      if (IsStringChecker(search)) {
        const filtered = presentations.filter((list) => {
          const title = lowStr(ValueProvider(list));
          if (IsStringChecker(title)) {
            return title.includes(search);
          }
        });
        PresentationRenderLogic(filtered);
      } else {
        PresentationRenderLogic(presentations);
      }
    } else {
      PresentationRenderLogic();
    }
  };

  const FetchPresentations = async () => {
    setLoading(true);
    const url = `academy_past_presentations?select=*,academy_courses(id,title_en,title_fr,title_es,order_by)&academy_courses.order=updated_at.desc`;
    await cmsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));
        const presentationData = IsArrayNotEmpty(slugRefinedData)
          ? slugRefinedData.map((list) => {
              const { link_to } = list;
              const content = ContentModifierLogic(link_to);
              const is_video = IsStringChecker(content)
                ? lowStr(content).includes("iframe")
                : false;
              return { ...list, content, is_video };
            })
          : [];
        setPresentations(presentationData);
        PresentationRenderLogic(presentationData);
      })
      .catch((error) => {
        setPresentations([]);
        EmptyView();
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const PresentationRenderLogic = (presentationData = []) => {
    try {
      if (IsArrayNotEmpty(presentationData)) {
        const groupedData = groupArrayByKey(
          presentationData,
          academy_course_en_path
        );
        const courseData = safeArrayCheck(
          presentationData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const courseArr = sortByField(
          courseData.map((list) => _.get(list, "academy_courses", {}))
        );
        setPresentationGroup(groupedData);
        setPresentationSet(courseArr);
      } else {
        EmptyView();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      EmptyView();
    }
  };

  const EmptyView = () => {
    setPresentationGroup({});
    setPresentationSet([]);
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      FetchPresentations();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const OpenModal = (content = null) => {
    setContent(content);
    setShow(true);
  };

  const CloseModal = () => {
    setContent(null);
    setShow(false);
  };

  const title = LocalTranslator(
    "past_presentations_title",
    "Past Attendees Presentations"
  );
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="col-12">
          <h4 className="float-none float-sm-start fw-bold py-2 mt-5 mt-lg-0">
            {title}
          </h4>
          <div className="float-none float-sm-end pb-2">
            <span className="input-group border-1 border-radius-6 my-2 my-lg-0 mt-0 mt-sm-5 mt-lg-0">
              <span
                className="input-group-text border-0 white_bg"
                id="basic-addon1"
              >
                <i
                  className="fa fa-search blue_color text-18"
                  aria-hidden="true"
                ></i>
              </span>
              <input
                type="text"
                className="form-control border-0 py-2 left-space-0"
                placeholder="Search"
                aria-label="Search"
                name={"search"}
                value={search}
                onChange={onChange}
                aria-describedby="basic-addon1"
              />
            </span>
          </div>
        </div>
        <div className="horizontal-line mb-4"></div>
        <div className="col-12">
          <div className="row">
            {IsArrayNotEmpty(presentationSet) &&
              presentationSet.map((list, index) => {
                const { title_en = null } = list;
                const objList = IsStringChecker(title_en)
                  ? presentationGroup[title_en]
                  : [];
                const heading = ValueProvider(list);
                return (
                  <Fragment key={index}>
                    <h4 className="float-start text-start fw-bold pt-2 pb-4 mt-3 mt-lg-0 line-height-24">
                      {heading}
                    </h4>
                    {IsArrayNotEmpty(objList) &&
                      objList.map((li, ind) => {
                        const { is_video = null } = li;
                        if (is_video === true) {
                          return (
                            <VideoRenderer
                              key={ind}
                              list={li}
                              title={ValueProvider(li)}
                              OpenModal={OpenModal}
                            />
                          );
                        } else if (is_video === false) {
                          return (
                            <DocumentRenderer
                              key={ind}
                              list={li}
                              title={ValueProvider(li)}
                            />
                          );
                        } else {
                          return <Fragment key={ind} />;
                        }
                      })}
                  </Fragment>
                );
              })}
          </div>
        </div>
        <OSAcademyVideoModal
          show={show}
          content={content}
          closeModal={CloseModal}
        />
      </div>
    </>
  );
};

const DocumentRenderer = ({ list = {}, title = null }) => {
  if (IsObjectChecker(list)) {
    const { feature_image_url = null, content: url = null } = list;
    const image = IsStringChecker(feature_image_url) ? feature_image_url : null;
    return IsTokenChecker(title, url) ? (
      <div className="col-12 col-lg-3">
        <div className="card card-shadow mb-3 mb-lg-5">
          {image && <img src={image} className="card-img-top" alt="" />}
          <div className="card-body py-2 px-0">
            <div className="row">
              <div className="col-11 ms-1">
                <ul className="list-group text-center min-height-101px">
                  <li className="list-group-item border-0 pt-2">
                    <Link
                      to={{ pathname: url }}
                      target={"_blank"}
                      className="text-18"
                    >
                      {title}
                    </Link>
                    <Link
                      to={{ pathname: url }}
                      target={"_blank"}
                      className="red_color mt-2 mb-1 d-block"
                    >
                      <i
                        className="fa fa-file-pdf-o me-1"
                        aria-hidden="true"
                      ></i>
                      {` PDF`}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Fragment />
    );
  } else {
    return <Fragment />;
  }
};

const VideoRenderer = ({ list = {}, title = null, OpenModal = null }) => {
  if (IsObjectChecker(list)) {
    const { feature_image_url = null, content: url = null } = list;
    const image = IsStringChecker(feature_image_url) ? feature_image_url : null;
    const access = IsTokenChecker(title, url) && IsFunctionChecker(OpenModal);
    return access ? (
      <div
        className="col-12 col-lg-3 modal_video"
        onClick={() => OpenModal(url)}
      >
        <div className="card card-shadow mb-3 mb-lg-5">
          <div className="position-relative">
            {image && (
              <img src={image} className="card-img-top cursor_pointer" alt="" />
            )}
            <div className="position-absolute outer-grey-bg-for-video"></div>
            <i
              className="position-absolute fa fa-play-circle video_play_icon cursor_pointer"
              aria-hidden="true"
            ></i>
          </div>
          <div className="card-body py-2 px-0">
            <div className="row">
              <div className="col-11 ms-1">
                <ul className="list-group text-center min-height-101px">
                  <li className="list-group-item border-0 pt-2">
                    <a href="#" className="text-18">
                      {title}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Fragment />
    );
  } else {
    return <Fragment />;
  }
};

export default OSAcademyPastPresentationsDetail;
