/**
 * @component_Name : InteractiveExhibit
 * @description : Show Interactive Exhibit grid list
 * @company : Photon
 * @author : Nivethithan Kannan/04-10-2023 | Old author Ashish Ranjan/30-08-2022
 **/
import React, { useEffect, useState, useMemo, useCallback } from "react";

//import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
//import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // Load worker code separately with worker-loader

/* NK - Fixed the mapboxgl library transpliation issue using browserlist updation in package.json by adding three lines which are
 "not chrome < 51",
 "not safari < 10",
 "not android < 51" */

import Mapp, {
  Marker,
  Source,
  Layer,
  NavigationControl,
  FullscreenControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  getFilteredData,
  setOptions,
  setObjToString,
  IsTokenChecker,
  getResolvedLanguage,
  IsStringChecker,
  compareString,
  IsArrayNotEmpty,
  lowStr,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import { ConstantsVar } from "../../../utils/constants";
import WhiteCircleLoader from "../../whiteCircleLoader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loading from "../../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import MapColors, { ReportOptionsKeyMap } from "./interactiveExhibitMapColor";
const { MAPBOX_TOKEN = "" } = ConstantsVar;

// const dataLayer = {
//   id: "data-Layer",
//   type: "line",
//   source: "my-data",
//   paint: {
//     /* 'line-color': [
//       'match', ['get','Need'],
//       'Urgent', 'red',
//       'Medium', 'yellow',
//       'Starting','green',
//       'blue'
//     ], */
//     "line-color": {
//       stops: [[0, "rgba(0, 0, 0, 0.3)"]],
//       // stops: [[0, "#0000FF"]],
//     },
//     "line-width": 2,
//   },
// };
// const dataLayerFill = {
//   id: "data-Layer-Fill",
//   type: "fill",
//   source: "my-data",
//   paint: {
//     "fill-color": {
//       stops: [
//         //[0, '#343434'],
//         //[0,'#898989'],
//         //[0,'#666666'],
//         [0, "rgb(162,0,170)"],
//         // [0, "#5e5e5e"],
//       ],
//     },
//     "fill-opacity": 0.7,
//   },
// };

const dataLayer = {
  id: "data-Layer",
  type: "line",
  source: "my-data",
  paint: {
    "line-color": [
      "match",
      ["get", "BorderRGBA"],
      "light",
      "rgba(0,0,0,0.3)",
      "dark",
      "rgba(0,0,0,1)",
      "rgba(0,0,0,0.7)",
    ],
    "line-width": ["match", ["get", "BorderWidth"], "thin", 2, 3],
  },
};
const dataLayerFill = {
  id: "data-Layer-Fill",
  type: "fill",
  source: "my-data",
  paint: {
    "fill-color": "transparent",
    "fill-opacity": 0.7,
  },
};

const InteractiveExhibit = (props) => {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;

  const [gridItems, setGridItems] = useState([]);
  const [multiContId, setMultiContId] = useState([]);
  const [countContId, setCountContId] = useState(0);
  const [multiValue, setMultiValue] = useState({});
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [disabled, setDisabled] = useState("disabled");
  const [isReset, setIsReset] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isMobFilter, setisMobFilter] = useState(false);

  const [minMaxYear, setMinMaxYear] = useState([]);
  const [listCropYear, setListCropYear] = useState([]);
  const [currentCropYear, setCurrentCropYear] = useState(0);
  const [filterItems, setFilterItems] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [mapIdOption, setMapIdOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);
  const [reloadCropyear, setReloadCropyear] = useState(true);
  const [cropYearRange, setCropYearRange] = useState(0);
  const [longLat, setLongLat] = useState({ longitude: 0, latitude: 0 });
  const [hoverInfo, setHoverInfo] = useState(null);
  const [preSelectedContract, setPreSelectedContract] = useState([]);
  const [reloadContract, setReloadContract] = useState(true);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const { DropDownCBRoles = [] } = props;
  const dispatch = useDispatch();
  const ongoingYear = getCurrentYear();

  const [FillMapColor, setFillMapColor] = useState(dataLayerFill);
  const [LayerType, setLayerType] = useState([]);

  useEffect(() => {
    setLoader(true);
    IsTokenChecker(tokenId) && fetchCropYear();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  // useEffect(() => {
  //   if (ContractOption.length > 0 && ContractOption.length == 1) {
  //     setReloadContract(false);
  //     setTimeout(() => {
  //       setPreSelectedContract([ContractOption[0].id]);
  //       setMultiContId({ contract_id: `${ContractOption[0].id}` });
  //       setReloadContract(true);
  //       setIsReset(false);
  //     }, 0);
  //   }
  // }, [ContractOption]);

  useEffect(() => {
    setBogNameOption([]);
    setMapIdOption([]);
    setVerityOption([]);
    if (IsTokenChecker(tokenId) && currentCropYear > 0) {
      const fetchContract = async () => {
        await gbsDataCommonMethod(
          tokenId,
          accounts,
          `persons/${personId}/contracts`
        )
          .then((resp) => {
            const data = [...resp.data];
            const filteredData = FilterContractByRole(
              data,
              accounts,
              DropDownCBRoles
            );
            const contractId = filteredData
              .map((x) => x["RelationshipId"])
              ?.toString(); //'2821'
            let url = `bed-histories?cropyear=${listCropYear.toString()}&relationshipid=${contractId}`;
            filterinteractiveExData(url);
          })
          .catch((error) => {
            setLoading(false);
            /*setLoader(false);*/
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      };
      fetchContract();
    }
  }, [listCropYear, tokenId]);

  useEffect(() => {
    let minYear = minMaxYear["minYear"];
    let maxYear = minMaxYear["maxYear"];
    if (minYear) {
      /* setfromMinDate(new Date(ConstantsVar.startingDateOfYear + ', ' + minYear));
      setendMinDate(new Date(ConstantsVar.startingDateOfYear + ', ' + minYear));
      setStartDate(new Date(ConstantsVar.startingDateOfYear + ', ' + minYear)); */
    } else if (currentCropYear != "") {
      /* setfromMinDate(new Date(ConstantsVar.startingDateOfYear + ', ' + currentCropYear));
      setendMinDate(new Date(ConstantsVar.startingDateOfYear + ', ' + currentCropYear));
      setStartDate(new Date(ConstantsVar.startingDateOfYear + ', ' + currentCropYear)); */
    }
    if (maxYear) {
      if (maxYear == ongoingYear) {
        //setEndDate(new Date());
        //setendMaxDate(new Date());
      } else {
        //setendMaxDate(new Date(ConstantsVar.endingDateOfYear + ', ' + maxYear));
        //setEndDate(new Date(ConstantsVar.endingDateOfYear + ', ' + maxYear));
      }
    } else if (currentCropYear != "") {
      //setendMaxDate(new Date());
      //setEndDate(new Date());
    }
  }, [minMaxYear, reloadCropyear]);

  /*multi select cropYears*/
  const fetchCropYear = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const currentcropData = resp.data.filter((x) => x.CurrentCrop === true);
        const getcurrentcrop = currentcropData
          ? currentcropData[0].CropYear
          : ongoingYear;
        setCurrentCropYear(getcurrentcrop);
        setCropYearRange(getcurrentcrop);
        setMinMaxYear({ maxYear: getcurrentcrop, minYear: getcurrentcrop });
        const cropYear = resp.data.length > 0 && resp.data.splice(0, 4);
        const multiSelectCropYears = cropYear.map((x) => x.CropYear);
        setListCropYear(multiSelectCropYears);
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const filterinteractiveExData = async (url) => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      const fetchApiUrl = url;
      await gbsDataCommonMethod(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          let resultData = [...resp.data];
          let filteredData = FilterContractByRole(
            resultData,
            accounts,
            DropDownCBRoles,
            "Id"
          );
          if (
            filteredData.length > 0 &&
            resp.data !== "Contract and CropYear must be provided"
          ) {
            const tempContract = setOptions(
              filteredData,
              "Id",
              "RelationshipNumber",
              "Name"
            );
            setContractOption(tempContract);
            setFilterItems(filteredData);
            if (tempContract.length > 0 && tempContract.length == 1) {
              let filter = filteredData.filter((item) => {
                return (
                  setObjToString(tempContract[0])
                    ?.toLowerCase()
                    ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
                );
              });
              setReloadContract(false);
              setTimeout(() => {
                setPreSelectedContract([tempContract[0].id]);
                setMultiContId({ contract_id: `${tempContract[0].id}` });
                setBogNameOption(setOptions(filter, "BogName", "BogName"));
                setMapIdOption(setOptions(filter, "MapId", "MapId"));
                setVerityOption(
                  setOptions(filter, "VarietyId", "VarietyDescription")
                );
                setReloadContract(true);
                setDisabled(false);
                setSearchBtnDisabled(false);
                setIsReset(false);
              }, 0);
            }
          } else {
            setContractOption([]);
            setBogNameOption([]);
            setMapIdOption([]);
            setVerityOption([]);
            setDisabled(true);
            setGridItems([]);
            setFilterItems([]);
          }
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  /*ReportType*/
  const multiReportType = Object.keys(
    ConstantsVar.interactive_exhibit_report_options
  ).map((x) => ({
    id: x,
    name: ConstantsVar.interactive_exhibit_report_options[x],
  }));

  // Contract select change event
  const handlerContract = (val) => {
    setCountContId(val.length);
    setMultiValue(null);
    let filter = filterItems.filter((item) => {
      return (
        setObjToString(val)
          ?.toLowerCase()
          ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
      );
    });
    setIsReset(false);
    setDisabled(false);
    setSearchBtnDisabled(false);
    setIsListingHide(true);
    setBogNameOption(setOptions(filter, "BogName", "BogName"));
    setMapIdOption(setOptions(filter, "MapId", "MapId"));
    setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setCountContId(selectedList.length);
    setMultiValue(null);
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setIsReset(true); /*disabled Reset button*/
      setMultiContId(null); /*set null to contract filter */
      setSearchBtnDisabled(true);
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
        );
      });
      setIsReset(false);
      setDisabled(false);
      setSearchBtnDisabled(false);
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setMapIdOption(setOptions(filter, "MapId", "MapId"));
      setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
    }
  };

  const getFilterCropyear = (arrayData, commaValue, apiKey) => {
    if (commaValue != "" && apiKey != "" && arrayData != "") {
      const filter = arrayData.filter((item) => {
        return (
          commaValue.toString().toLowerCase() ===
          item[apiKey]?.toString().toLowerCase()
        );
      });
      return filter;
    }
  };

  const getFilterOptions = (data = []) => {
    try {
      if (IsArrayNotEmpty(data)) {
        const fillOptions = ["match", ["get", "ColorType"]];
        data.map((list) => {
          const { ColorType, HEX } = list;
          fillOptions.push(ColorType);
          fillOptions.push(HEX);
        });
        fillOptions.push("transparent");
        return fillOptions;
      }
      return "transparent";
    } catch (e) {
      return "transparent";
    }
  };

  // On click search
  const handlerSubmit = (cropYearVal = 0) => {
    setLoading(true); //set loader to search button
    let filter = [...filterItems];
    if (cropYearRange || cropYearVal > 0) {
      const cropVal = cropYearVal > 0 ? cropYearVal : cropYearRange;
      filter = getFilterCropyear(filter, cropVal, "CropYear");
    }
    if (multiContId?.contract_id) {
      filter = getFilteredData(filter, multiContId.contract_id, "Id");
    }
    if (multiValue?.bognames) {
      filter = getFilteredData(filter, multiValue.bognames, "BogName");
    }
    if (multiValue?.mapids) {
      filter = getFilteredData(filter, multiValue.mapids, "MapId");
    }
    if (multiValue?.varityids) {
      filter = getFilteredData(filter, multiValue.varityids, "VarietyId");
    }
    //datefrom: '2022/01/02', dateto: '2022/12/31'
    if (multiValue?.datefrom || multiValue?.dateto) {
      filter = filter.filter((item) => {
        let dateFrom =
          multiValue?.datefrom &&
          moment(multiValue.datefrom).format(ConstantsVar.dateFormate);
        let dateto =
          multiValue?.dateto &&
          moment(multiValue.dateto).format(ConstantsVar.dateFormate);
        let SettlementDate = moment(item.SettlementDate).format(
          ConstantsVar.dateFormate
        );
        return moment(SettlementDate).isBetween(
          dateFrom,
          dateto,
          undefined,
          "[]"
        );
      });
    }

    if (typeof filter !== undefined && filter.length > 0) {
      //loadFilter(filter);
      setLongLat({
        longitude: filter[0].Longitude,
        latitude: filter[0].Latitude,
      });
      setLoading(false); /*set loader to search button*/
      setIsListingHide(false);
      setNoRecordErrorMsg("");
      setisMobFilter(false);
      setGridItems(filter);

      const reporttype = _.get(multiValue, "reporttype", null);
      if (reporttype) {
        const colorData = MapColors.find((li) =>
          compareString(li?.ReportOption, reporttype)
        );
        const typeOfLayer = _.get(colorData, "MapColor", []);
        const layerData = getFilterOptions(typeOfLayer);
        const temp = { ...dataLayerFill };
        _.set(temp, "paint.fill-color", layerData);
        setFillMapColor(temp);
        setLayerType(typeOfLayer);
      } else {
        setFillMapColor(dataLayerFill);
        setLayerType([]);
      }
    } else {
      setIsListingHide(true);
      setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
      setFillMapColor(dataLayerFill);
      setLayerType([]);
    }
    setLoading(false); /*set loader to search button*/
  };

  const handlerCropYearRange = (e) => {
    if (multiContId?.contract_id) {
      setCropYearRange(e.target.value);
      const cropyears = e.target.value;
      setMinMaxYear({ maxYear: cropyears, minYear: cropyears });
      setIsListingHide(true);
      handlerSubmit(cropyears);
    } else {
      setIsListingHide(true);
      setNoRecordErrorMsg("Select Contract Number before proceed");
    }
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (action == "selected") {
      setNoRecordErrorMsg("");
    }

    const searchParam = {
      Contract_id: ["Id"],
      Bog_name: ["BogName", "BogName"],
      Map_id: ["MapId", "MapId"],
      Varity_id: ["VarietyId", "VarietyDescription"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setMapIdOption,
      setVerityOption,
    });
  };

  const handlerReportFilter = () => {
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setMultiContId(null); /*set null to contract filter */
    setMultiValue(null); /*set null to all filter */
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    // setStartDate();
    //setEndDate();
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    /*set default year, after reset*/
    setReloadCropyear(false);
  };

  /* const handlerDate = (param, type) => {
    let startFulldate = '';
    let endFulldate = '';
    if (type == 'start') {
      setendMinDate(param);
      setStartDate(param)
      startFulldate = moment(param).format('YYYY/MM/DD');
      endFulldate = moment(endDate).format('YYYY/MM/DD');
    } else if (type == 'end') {
      setEndDate(param)
      startFulldate = moment(startDate).format('YYYY/MM/DD');
      endFulldate = moment(param).format('YYYY/MM/DD');
    }
    setMultiValue(multiValue => ({ ...multiValue, ['datefrom']: startFulldate, ['dateto']: endFulldate }));
  } */

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  /*   useEffect(()=>{
      if(gridItems.length>0){
        const fetchGeoJson = gridItems.map((x)=> (JSON.parse(x.GeoJson)))
        const geoJson = { ["type"]: "FeatureCollection", ["features"]: fetchGeoJson };
        var stringToJsonObject = JSON.stringify(geoJson);
        var stringToJsonObject = JSON.parse(stringToJsonObject);
        setMapGeoJson(stringToJsonObject); 
      }
    },[gridItems]);  */

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
      lngLat: { lat, lng },
    } = event;
    /* const featuresVal = features;
    const xaxis = event.point.x;
    const yaxis = event.point.y;
    console.log("xaxis", xaxis);
    console.log("featuresVal", featuresVal); */
    const hoveredFeature = features && features[0];
    // prettier-ignore
    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y, lat, lng });
  }, []);

  const SetLayerStyle = (Obj = {}) => {
    try {
      const reporttype = _.get(multiValue, "reporttype", null);
      if (IsArrayNotEmpty(LayerType) && reporttype) {
        const reportField = _.get(ReportOptionsKeyMap, reporttype, null);
        const Value = _.get(Obj, reportField, null);
        const ColorType = LayerType.find((list) => {
          const { ValueFrom, ValueTo } = list;
          if (ValueFrom === null && ValueTo === null) {
            if (Value === ValueFrom && Value === ValueTo) {
              return list;
            }
          } else if (ValueFrom === null || ValueTo === null) {
            if (ValueFrom === null) {
              if (Value === ValueFrom && Value <= ValueTo) {
                return list;
              }
            } else if (ValueTo === null) {
              if (Value >= ValueFrom && Value === ValueTo) {
                return list;
              }
            }
          } else {
            if (Value >= ValueFrom && Value <= ValueTo) {
              return list;
            }
          }
        });
        const {
          ColorType: ct = null,
          BorderRGBA = "light",
          BorderWidth = "thin",
        } = ColorType;
        return { ColorType: ct, BorderRGBA, BorderWidth };
      }
      return { ColorType: null, BorderRGBA: null, BorderWidth: null };
    } catch (error) {
      // HandleErrorTrackAndToast(error, true);
      return { ColorType: null, BorderRGBA: null, BorderWidth: null };
    }
  };

  const mapdata = useMemo(() => {
    if (gridItems.length > 0) {
      const fetchGeoJson = gridItems.reduce(function (arr, obj) {
        try {
          if (obj.GeoJson != null) {
            let objGeoJson = JSON.parse(obj.GeoJson);
            objGeoJson["properties"].HarvestBed = obj.HarvestBed;
            objGeoJson["properties"].VarietyDescription =
              obj.VarietyDescription;
            objGeoJson["properties"].TotalBarrels = obj.TotalBarrels;
            objGeoJson["properties"].ExhibitAcres = obj.ExhibitAcres;
            objGeoJson["properties"].Yield = obj.Yield;
            objGeoJson["properties"].PercentDryTrash = obj.PercentDryTrash;
            objGeoJson["properties"].PercentLowestMoist =
              obj.PercentLowestMoist;
            objGeoJson["properties"].AveragePercentPoor =
              obj.AveragePercentPoor;
            objGeoJson["properties"].PercentClass1 = obj.PercentClass1;
            objGeoJson["properties"].AveragePercentUsable =
              obj.AveragePercentUsable;
            objGeoJson["properties"].PercentGreaterThanHalf =
              obj.PercentGreaterThanHalf;
            objGeoJson["properties"].AverageFirmness = obj.AverageFirmness;
            const { ColorType, BorderRGBA, BorderWidth } = SetLayerStyle(obj);
            objGeoJson["properties"].ColorType = ColorType;
            objGeoJson["properties"].BorderRGBA = BorderRGBA;
            objGeoJson["properties"].BorderWidth = BorderWidth;
            arr.push(objGeoJson);
          }
          return arr;
        } catch (e) {
          HandleErrorTrackAndToast(e, true);
          return arr;
        }
      }, []);
      if (fetchGeoJson.length > 0) {
        const geoJson = {
          type: "FeatureCollection",
          features: fetchGeoJson,
        };
        return geoJson;
      }
    }
  }, [gridItems]);

  const getProperty = (str = null) => {
    try {
      if (IsStringChecker(str)) {
        const result = _.get(hoverInfo, `feature.properties.${str}`, null);
        if (lowStr(str).includes("percent")) {
          return result ? `${result}%` : null;
        }
        return result || null;
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const getPropertyFromReport = (str = null, defaultField = null) => {
    try {
      const field = _.get(multiValue, "reporttype", null);
      const field2 = defaultField ? defaultField : str;
      const access =
        _.has(multiValue, "reporttype") && compareString(field, field2);
      if (access) {
        return getProperty(str);
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const HarvestBed = getProperty("HarvestBed");
  const VarietyDescription = getProperty("VarietyDescription");
  const ExhibitAcres = getProperty("ExhibitAcres");
  const TotalBarrels = getPropertyFromReport("TotalBarrels", "Barrels");
  const Yield = getPropertyFromReport("Yield");
  const PercentDryTrash = getPropertyFromReport("PercentDryTrash");
  const PercentLowestMoist = getPropertyFromReport(
    "PercentLowestMoist",
    "PercentMoisture"
  );
  const AveragePercentPoor = getPropertyFromReport(
    "AveragePercentPoor",
    "PercentPoor"
  );
  const PercentClass1 = getPropertyFromReport(
    "PercentClass1",
    "PercentClassOne"
  );
  const AveragePercentUsable = getPropertyFromReport(
    "AveragePercentUsable",
    "PercentUsable"
  );
  const PercentGreaterThanHalf = getPropertyFromReport(
    "PercentGreaterThanHalf",
    "Percent>1/2"
  );
  const AverageFirmness = getPropertyFromReport("AverageFirmness", "Firmness");

  function TooltipValue() {
    const args = [...arguments];
    if (IsArrayNotEmpty(args)) {
      return args.reduce((acc, li, index) => {
        if (li) {
          try {
            if (index === 0) {
              return `${li}`;
            }
            return `${acc} - ${li}`;
          } catch (e) {
            return acc;
          }
        }
        return acc;
      }, ``);
    } else {
      return ``;
    }
  }
  const tooltip = TooltipValue(
    HarvestBed,
    VarietyDescription,
    ExhibitAcres,
    TotalBarrels,
    Yield,
    PercentDryTrash,
    PercentLowestMoist,
    AveragePercentPoor,
    PercentClass1,
    AveragePercentUsable,
    PercentGreaterThanHalf,
    AverageFirmness
  );
  return (
    <>
      {/*<!-- InteractiveExhibit block starts -->*/}
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-lg-2 mb-lg-0 mb-2">
        <div className={`cancel_reset ${isMobFilter ? " show " : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? " active " : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap2"
          aria-controls="selectWrap2"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text">(All) </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock  ${
            isMobFilter ? " show " : ""
          } bottom-0-imp height-auto-imp mb-5 mb-lg-0`}
          id="selectWrap2"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="row">
                <div
                  htmlFor="customRange1"
                  className="form-label text-uppercase text-10 grey_color acreage_crop_year"
                >
                  <span>Crop Year</span>
                  <i className="fa fa-chevron-down p-3 px-1 blue_color position-relative r-10 float-end text-14 d-block d-lg-none"></i>
                </div>
                <div className="slider position-relative acreage_crop_year_dropdown">
                  <input
                    type="range"
                    name="ageInputName"
                    id="ageInputId"
                    value={cropYearRange}
                    min={Math.min(...listCropYear)}
                    max={Math.max(...listCropYear)}
                    onChange={handlerCropYearRange}
                  />
                  {/* <input type="range" name="ageInputName" id="ageInputId" value="" min="2010" max="2022" onInput="ageOutputId.value = ageInputId.value" onChange={cropYearRange}/> */}
                  <output
                    name="ageOutputName"
                    className="position-absolute top-50 start-50 text-10 position_transform"
                    id="ageOutputId"
                  >
                    {cropYearRange}
                  </output>
                  <span className="position-absolute start-0 mt-4 text-10 start_label">
                    {Math.min(...listCropYear)}
                  </span>
                  <span className="position-absolute end-0 mt-4 text-10 end_label">
                    {Math.max(...listCropYear)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {reloadContract && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={preSelectedContract}
                    optionNotFound={{ contract_id: noContractsFound }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- bogname -->*/}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="map_id custom_dropdown">
                {/*<!-- map_id -->*/}
                <MultiDropDown
                  optionList={mapIdOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "mapid"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "mapid"
                    )
                  }
                  label="mapids"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- variety -->*/}
                <MultiDropDown
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                  label="varityids"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="report_options custom_dropdown">
                {/*<!-- <span>Report options</span> -->*/}
                <MultiDropDown
                  optionList={multiReportType}
                  prompt="Select"
                  value={multiValue}
                  onChange={(val, sectedVal) =>
                    handlerReportFilter(
                      "selected",
                      "reporttype",
                      val,
                      sectedVal
                    )
                  }
                  label="reporttype"
                  usestate={setMultiValue}
                  disabled={disabled}
                  singleSelect={true}
                  onDelete={() => handlerReportFilter("deselected")}
                />
              </div>
            </div>

            {/* <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_from">
                <DatePicker
                  id="datepickerFrom"
                  selected={startDate}
                  onChange={(date) => handlerDate(date, 'start')}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  minDate={fromMinDate}//{new Date(),10}//{new Date(),10}
                  maxDate={endDate ? endDate : new Date()}//{new Date()}
                />
                <label htmlFor="datepickerFrom">Date From <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i> </label>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_to">
                <DatePicker
                  id="datepickerTo"
                  selected={endDate}
                  onChange={(date) => handlerDate(date, 'end')}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  startDate={endMinDate}
                  minDate={endMinDate}//{new Date(),10}
                  maxDate={endMaxDate ? endMaxDate : new Date()}
                  showDisabledMonthNavigation
                />
                <label htmlFor="datepickerTo">Date To <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i> </label>
              </div>
            </div> */}
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}

      {/*<!-- Map placeholder starts-->*/}
      <div className="row">
        <div className="col-12">
          <div id="map">
            {isListingHide == false && mapdata != undefined ? (
              <Mapp
                initialViewState={{
                  longitude: longLat.longitude,
                  latitude: longLat.latitude,
                  zoom: countContId == 1 ? 14 : 11,
                }}
                style={{ /* width: "700", */ height: 400 }}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                mapboxAccessToken={MAPBOX_TOKEN}
                interactiveLayerIds={["data-Layer-Fill"]}
                // onClick={onHover}
                onMouseMove={onHover}
                // onTouchMove={onHover}
              >
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <Source id="my-data" type="geojson" data={mapdata}>
                  <Layer {...dataLayer} />
                  <Layer {...FillMapColor} />
                </Source>
                {hoverInfo != null && (
                  <>
                    <div
                      className="map_tooltip"
                      style={{ left: hoverInfo.x, top: hoverInfo.y }}
                    >
                      <div>{tooltip}</div>
                      {/* <div>Longitude: {hoverInfo.lng}</div>
                    <div>latitude: {hoverInfo.lat}</div> */}
                    </div>
                    <Marker
                      longitude={hoverInfo.lng}
                      latitude={hoverInfo.lat}
                      color="red"
                    />
                  </>
                )}
              </Mapp>
            ) : (
              noRecordErrorMsg != "" && (
                <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                  <i className="fa fa-info-circle pe-2"></i>
                  {noRecordErrorMsg}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {/*<!-- Map placeholder ends -->*/}
    </>
  );
};

export default InteractiveExhibit;
