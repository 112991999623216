import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion } from "react-bootstrap";
import MultiDropDown from "../../../selectBox/multiDropDown";
import DataGridPagination from "../../../global/dataGridPagination";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import CurrentLocation from "../../../global/currentLocation";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  IsObjectChecker,
  StringToInteger,
  HandleErrorTrackAndToast,
  IsNumberChecker,
} from "../../../../utils/helper";
import Loading from "../../../global/loading";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import { ConstantsVar, GbsBaseUrl } from "../../../../utils/constants";
import DatePickerComp from "../../../global/datepicker";
import { ImpersonationAccountsSwap } from "../../../../permissionWrapper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { utils, writeFile } from "xlsx";

const Payment = ({
  cropYears = [],
  contracts = [],
  selectedCropYear = null,
  selectedContract = null,
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);

  // const { dateFormate, statement_type, startingDateOfYear, endingDateOfYear } =
  //   ConstantsVar;
  const { dateFormate, statement_type } = ConstantsVar;

  const [loading, setLoading] = useState(false);
  const [circleLoader, setCircleLoader] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);
  const [gridstatus, setGridStatus] = useState(false);

  const [statements] = useState(statement_type);
  const [dateDisabled] = useState(false);
  const [minDate] = useState(null);
  const [maxDate] = useState(new Date());
  // const [minDate, setMinDate] = useState(null);
  // const [maxDate, setMaxDate] = useState(new Date());

  const [currentCropYear, setCurrentCropYear] = useState([]);
  const [defaultContract, setDefaultContract] = useState([]);
  const [defaultStatement, setDefaultStatement] = useState(["payment"]);

  const [reqCropyear, setReqCropYear] = useState({});
  const [reqStatement, setReqStatement] = useState({ statement: "payment" });
  const [reqContract, setReqContract] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());

  const [clrBtnDisabled, setClrBtnDisabled] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const [tableList, setTableList] = useState([]);
  const [nameKeeper, setNameKeeper] = useState(null);

  const [freshPage, setFreshPage] = useState(true);

  const [isMobFilter, setisMobFilter] = useState(false);
  const MobileViewRenderer = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  useEffect(() => {
    if (selectedCropYear && selectedContract) {
      setReqCropYear({ cropyear: `${selectedCropYear}` });
      setReqContract({ contract: `${selectedContract}` });
      setCurrentCropYear([selectedCropYear]);
      setDefaultContract([selectedContract]);
      setClrBtnDisabled(false);
    } else {
      if (selectedCropYear) {
        setReqCropYear({ cropyear: `${selectedCropYear}` });
        setReqContract({});
        setCurrentCropYear([selectedCropYear]);
        setDefaultContract([]);
        setClrBtnDisabled(true);
      } else if (selectedContract) {
        setReqCropYear({});
        setReqContract({ contract: `${selectedContract}` });
        setCurrentCropYear([]);
        setDefaultContract([selectedContract]);
        setClrBtnDisabled(false);
      } else {
        setReqContract({});
        setReqCropYear({});
        setCurrentCropYear([]);
        setDefaultContract([]);
        setClrBtnDisabled(true);
      }
    }
    MultiDropDownFix();
  }, [selectedCropYear, selectedContract]);
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const setObjArrToString = (val) => {
    try {
      if (IsArrayNotEmpty(val)) {
        let ids = val.map((x) => x.id);
        ids = ids.filter((x) => x != "all");
        return `${ids}`;
      } else {
        return ``;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return ``;
    }
  };

  const RegulateFileURL = (val) => {
    try {
      if (IsStringChecker(val)) {
        if (val.includes("https://")) {
          return val;
        } else {
          return `${GbsBaseUrl}${val}`;
        }
      } else {
        return val;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return val;
    }
  };

  const joinedStringToArr = (val) => {
    try {
      if (IsStringChecker(val)) {
        let ids = val.split(",");
        return ids;
      } else {
        return [];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const getCropYearFromFileName = (filename = null) => {
    try {
      if (IsStringChecker(filename)) {
        const fn = filename.split(".");
        const text = fn[0];
        const year = text.slice(-4);
        return IsStringChecker(year) ? year : "-";
      }
      return "-"
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-"
    }
  }

  // const joinedStringNumtoArrNum = (val) => {
  //   try {
  //     const plainArr = joinedStringToArr(val);
  //     const numArr = plainArr.map((li) => parseInt(li));
  //     return numArr;
  //   } catch (e) {
  //     HandleErrorTrackAndToast(e, true);
  //     return [];
  //   }
  // };

  const getFormattedUTCDate = (date = null, dateFormat = dateFormate) => {
    try {
      if (date) {
        return moment.utc(date).format(dateFormat);
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const DataGridFix = () => {
    setGridStatus(true);
    setTimeout(() => {
      setGridStatus(false);
    }, 0);
  };

  // const DateConstraintReset = () => {
  //   setMinDate("");
  //   setMaxDate("");
  // };

  const DateReset = () => {
    setStartDate("");
    setEndDate("");
  };

  const onMultiSelectChange = (selectedList, selectedItem, type) => {
    const selected = _.get(selectedItem, "id", null);
    const list = setObjArrToString(selectedList);
    if (type === "statement") {
      let contract = _.get(reqContract, "contract", "");
      let cropyear = _.get(reqCropyear, "cropyear", "");
      FetchButtonHandler(selected, contract, cropyear, startDate, endDate);
    } else if (type === "contract") {
      let statement = _.get(reqStatement, "statement", "");
      let cropyear = _.get(reqCropyear, "cropyear", "");
      FetchButtonHandler(statement, list, cropyear, startDate, endDate);
    } else if (type === "cropyear") {
      let contract = _.get(reqContract, "contract", "");
      let statement = _.get(reqStatement, "statement", "");
      FetchButtonHandler(statement, contract, list, startDate, endDate);
    }
  };

  const onMultiSelectRemove = (removedList, removedItem, type) => {
    const removed = _.get(removedItem, "id", null);
    const list = setObjArrToString(removedList);

    if (type === "statement") {
      let contract = _.get(reqContract, "contract", "");
      if (removed === "payment") {
        DateReset();
      }
      setCurrentCropYear([]);
      setReqCropYear({});
      FetchButtonHandler(false, contract, false, false, false);
    } else if (type === "cropyear") {
      let statement = _.get(reqStatement, "statement", "");
      let contract = _.get(reqContract, "contract", "");
      if (removed === "all" || !IsArrayNotEmpty(removedList)) {
        if (statement === "payment") {
          DateReset();
        }
        FetchButtonHandler(statement, contract, false, startDate, endDate);
      } else {
        FetchButtonHandler(statement, contract, list, startDate, endDate);
      }
    } else if (type === "contract") {
      let statement = _.get(reqStatement, "statement", "");
      let cropyear = _.get(reqCropyear, "cropyear", "");
      if (removed === "all" || !IsArrayNotEmpty(removedList)) {
        FetchButtonHandler(statement, false, cropyear, startDate, endDate);
      } else {
        FetchButtonHandler(statement, list, cropyear, startDate, endDate);
      }
    }
  };

  const FetchButtonHandler = (
    statement = false,
    contract = false,
    cropyear = false,
    startDate = false,
    endDate = false
  ) => {
    // console.log({statement , contract , cropyear , startDate , endDate});
    const group = statement || contract || cropyear || startDate || endDate;
    const paymentGroup = statement || contract || startDate || endDate;
    const clear = statement === "payment" ? paymentGroup : group;
    if (group) {
      const date = startDate && endDate;
      const access =
        statement === "payment"
          ? IsTokenChecker(contract) && date
          : IsTokenChecker(statement, contract, cropyear);
      if (access) {
        setSubmitBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
      }
    } else {
      setSubmitBtnDisabled(true);
    }
    setClrBtnDisabled(clear ? false : true);
    ClearTableData();
  };

  const onDateChange = (param, type) => {
    let statement = _.get(reqStatement, "statement", "");
    let contract = _.get(reqContract, "contract", "");
    let cropyear = _.get(reqCropyear, "cropyear", "");
    try {
      if (type == "start") {
        setStartDate(param);
        if (endDate) {
          try {
            let isb = moment(param).isSameOrBefore(moment(endDate));
            if (!isb) {
              setEndDate(param);
            }
            FetchButtonHandler(statement, contract, cropyear, param, true);
          } catch (e) {
            HandleErrorTrackAndToast(e, true);
            setEndDate("");
            FetchButtonHandler(statement, contract, cropyear, param, false);
          }
        } else {
          // setEndDate(param);
          setEndDate(new Date());
          FetchButtonHandler(statement, contract, cropyear, param, param);
        }
      } else if (type == "end") {
        setEndDate(param);
        FetchButtonHandler(statement, contract, cropyear, startDate, param);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      DateReset();
      FetchButtonHandler(statement, contract, cropyear, false, false);
    }
  };

  // const DateRangeSetter = (cropyear, setDate = false) => {
  //   try {
  //     if (IsStringChecker(cropyear)) {
  //       const currentYear = _.get(currentCropYear, "0", getCurrentYear());
  //       const crop_years = joinedStringNumtoArrNum(cropyear);
  //       const max = Math.max(...crop_years),
  //         min = Math.min(...crop_years);
  //       if (IsNumberChecker(min) && IsNumberChecker(max)) {
  //         setMinDate(new Date(`${startingDateOfYear},${min}`));
  //         if (setDate) {
  //           setStartDate(new Date(`${startingDateOfYear},${min}`));
  //         }
  //         if (max == currentYear) {
  //           setMaxDate(new Date());
  //           if (setDate) {
  //             setEndDate(new Date());
  //           }
  //         } else {
  //           setMaxDate(new Date(`${endingDateOfYear},${max}`));
  //           if (setDate) {
  //             setEndDate(new Date(`${endingDateOfYear},${max}`));
  //           }
  //         }
  //       } else {
  //         DateConstraintReset();
  //         if (setDate) {
  //           DateReset();
  //         }
  //       }
  //     } else {
  //       DateConstraintReset();
  //       if (setDate) {
  //         DateReset();
  //       }
  //     }
  //   } catch (e) {
  //     HandleErrorTrackAndToast(e, true);
  //     DateConstraintReset();
  //     if (setDate) {
  //       DateReset();
  //     }
  //   }
  // };

  const fetchTableData = async () => {
    const contract = _.get(reqContract, "contract", null);
    const statement = _.get(reqStatement, "statement", null);
    const cropyear = _.get(reqCropyear, "cropyear", null);

    try {

      if (statement === "incentive_assessment_receipt") {
        // console.log("insentiveData", insentiveData, contract, statement, cropyear);
        setLoading(true);
        setCircleLoader(true);
        const reqURL = `deliveries/by-receipt?cropyear=${cropyear}&contractid=${contract}&status=3402,3403,3404`;
        await gbsDataCommonMethod(tokenId, accounts, reqURL)
          .then((res) => {
            // console.log(res.data);
            let contractGroup = _.groupBy(res.data, "RelationshipNumber");
            // console.log("contractGroup",contractGroup);
            let data = [];
            Object.keys(contractGroup).map((i) => {
              // console.log(contractGroup[i])
              let cropYearGroup = _.groupBy(contractGroup[i], "CropYear");
              // console.log("cropYearGroup", cropYearGroup);
              Object.keys(cropYearGroup).map((k) => {
                data.push({
                  Contract: cropYearGroup[k][0].RelationshipNumber,
                  ContractName: cropYearGroup[k][0].Name,
                  // CropYear: _.uniq(cropYearGroup[i].map((i)=>i.CropYear)),
                  CropYear: cropYearGroup[k][0].CropYear,
                  StatementType: '',
                  Pool: cropYearGroup[k][0].Pool,
                  // item1:cropYearGroup[k],
                  item: mergeData(_.groupBy(cropYearGroup[k], "ReceiptIdentifier"))
                })
              })

            });
            // console.log("data", data)
            setTableList(data);
            setNameKeeper("Incentive and Assessments by receipt");
            MobileViewRenderer("cancel");
            DataGridFix();
            setFreshPage(false);
          })
          .catch((e) => {
            setTableList([]);
            MobileViewRenderer("cancel");
            DataGridFix();
            setFreshPage(false);
            HandleErrorTrackAndToast(e, true, "replace", dispatch);
          })
          .finally(() => {
            setCircleLoader(false);
            setLoading(false);
            MobileViewRenderer("cancel");
            DataGridFix();
            setFreshPage(false);
          });;
        return;
      }

      const contractIds = joinedStringToArr(contract);
      const date = startDate && endDate;
      const access =
        statement === "payment"
          ? IsTokenChecker(tokenId) && date
          : IsTokenChecker(tokenId, statement, cropyear);
      if (access && IsArrayNotEmpty(contractIds)) {
        setLoading(true);
        setCircleLoader(true);

        const urlSuffix =
          statement === "payment"
            ? `datefrom=${moment(startDate).format(
              "YYYY-MM-DD"
            )}&dateto=${moment(endDate).format("YYYY-MM-DD")}`
            : `cropyear=${cropyear}`;
        var responseGroup = Promise.all(
          contractIds.map(async (contractNum) => {
            const reqURL = `contracts/${contractNum}/${statement}-statements?${urlSuffix}`;
            var arr = await gbsDataCommonMethod(tokenId, accounts, reqURL)
              .then((res) => {
                return res.data;
              })
              .catch((e) => {
                HandleErrorTrackAndToast(e, true, "replace", dispatch);
              });
            return arr;
          })
        )
          .then((result) => {
            return result;
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          });

        var response = await responseGroup
          .then((res) => {
            return res;
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          })
          .finally(() => {
            setCircleLoader(false);
            setLoading(false);
          });

        var final_array = IsArrayNotEmpty(response)
          ? response.reduce((accumulator, current) => {
            return [...accumulator, ...current];
          }, [])
          : [];
        var output_array = final_array.filter((li) => IsObjectChecker(li));
        // console.log("output_array => ", output_array);
        var resArr = IsArrayNotEmpty(output_array)
          ? output_array.map((item, index) => {
            let filename = _.get(item, "FileName", null);
            let fileurl = _.get(item, "Url", null);
            let cropy = IsStringChecker(filename)
              ? statement === "payment"
                ? "-"
                : getCropYearFromFileName(filename)
              : "-";
            let Contract = _.get(item, "RelationshipNumber", "-");
            return {
              Index: index,
              Id: _.get(item, "Id", null),
              FileExtension: _.get(item, "FileExtension", "-"),
              FileName: IsStringChecker(filename) ? filename : "-",
              // StatementType: statement === "incentive" ? "IncentiveStatement" : statement === "assessment" ?  "AssessmentStatement": IsStringChecker(filename)
              //   ? filename.split("For")[0]
              //   : "-",
              StatementType:
                statement === "incentive"
                  ? "IncentiveStatement"
                  : statement === "assessment"
                    ? "AssessmentStatement"
                    : statement === "payment"
                      ? "PaymentStatement"
                      : "-",
              CropYear: StringToInteger(cropy) || "-",
              Contract: IsStringChecker(Contract) ? Contract : "-",
              ContractName: _.get(item, "Name", "-"),
              ReportDate: _.get(item, "ReportDate", null),
              FileUrl: IsStringChecker(fileurl)
                ? RegulateFileURL(fileurl)
                : null,
            };
          })
          : [];

        // console.log("resArr => ", resArr);
        if (statement === "payment") {
          const result_Arr = IsArrayNotEmpty(resArr)
            ? _.orderBy(resArr, ["Contract", "ReportDate"], ["asc", "desc"])
            : [];
          setTableList(result_Arr);
          setNameKeeper(statement);
        } else {
          const result_Arr = IsArrayNotEmpty(resArr)
            ? _.orderBy(resArr, ["Contract", "CropYear"], ["asc", "desc"])
            : [];
          let groupContract = _.groupBy(result_Arr, (item) => {
            return [item["Contract"]];
          });
          let result = Object.keys(groupContract).map((i) =>
            _.groupBy(
              _.orderBy(groupContract[i], ["ReportDate"], ["desc"]),
              "CropYear"
            )
          );
          // console.log(result);
          let finalResult = [];
          result.map((i) =>
            Object.keys(i).map((k) => finalResult.push(i[k][0]))
          );
          // console.log(finalResult);
          finalResult = _.orderBy(
            finalResult,
            ["Contract", "CropYear"],
            ["asc", "desc"]
          );
          setTableList(finalResult);
          setNameKeeper(statement);
        }
        MobileViewRenderer("cancel");
        DataGridFix();
        setFreshPage(false);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setTableList([]);
      setNameKeeper(null);
      setLoading(false);
      setCircleLoader(false);
      MobileViewRenderer("cancel");
      DataGridFix();
      setFreshPage(false);
    }
  };

  const mergeData = (data) => {
    const items = [];
    Object.keys(data).map((li) => {
      items.push({
        Id: `${data[li][0]['LocationCode']}${data[li][0]['LoadDeliveryTicketNumberNoMemo']}`,
        TotalBarrels: data[li].reduce((n, { TotalBarrels }) => n + TotalBarrels, null),
        EUFruitIncentive: data[li].reduce((n, { EUFruitIncentive }) => n + EUFruitIncentive, null),
        OrganicIncentive: data[li].reduce((n, { OrganicIncentive }) => n + OrganicIncentive, null),
        GlobalGapIncentive: data[li].reduce((n, { GlobalGapIncentive }) => n + GlobalGapIncentive, null),
        GlobalGapFarmIncentive: data[li].reduce((n, { GlobalGapFarmIncentive }) => n + GlobalGapFarmIncentive, null),
        EarlySequencedIncentive: data[li].reduce((n, { EarlySequencedIncentive }) => n + EarlySequencedIncentive, null),
        EPursIncentive: data[li].reduce((n, { ePURsIncentive }) => n + ePURsIncentive, null),
        SustainabilitySurveyIncentive: data[li].reduce((n, { SustainabilitySurveyIncentive }) => n + SustainabilitySurveyIncentive, null),
        ScreeningAssessmentRevised2020: -data[li].reduce((n, { ScreeningAssessmentRevised2020 }) => n + ScreeningAssessmentRevised2020, null),
        TrashCharge: -data[li].reduce((n, { TrashCharge }) => n + TrashCharge, null),
        CloseDateAssessment: -data[li].reduce((n, { CloseDateAssessment }) => n + CloseDateAssessment, null),
        PurAssessment: -data[li].reduce((n, { PurAssessment }) => n + PurAssessment, null),
        GrowerPackedQualificationIncentive: data[li].reduce((n, { GrowerPackedQualificationIncentive }) => n + GrowerPackedQualificationIncentive, null),
        GrowerPackedTrueUpIncentive: data[li].reduce((n, { GrowerPackedTrueUpIncentive }) => n + GrowerPackedTrueUpIncentive, null),
        GrowerPackedKeepingQualityNovemberIncentive: data[li].reduce((n, { GrowerPackedKeepingQualityNovemberIncentive }) => n + GrowerPackedKeepingQualityNovemberIncentive, null),
        GrowerPacked3WeekIncentive: data[li].reduce((n, { GrowerPacked3WeekIncentive }) => n + GrowerPacked3WeekIncentive, null),
        KeepingQualityQualificationIncentive: data[li].reduce((n, { KeepingQualityQualificationIncentive }) => n + KeepingQualityQualificationIncentive, null),
        KeepingQualityTrueUpIncentive: data[li].reduce((n, { KeepingQualityTrueUpIncentive }) => n + KeepingQualityTrueUpIncentive, null),
        KeepingQuality3WeekIncentive: data[li].reduce((n, { KeepingQuality3WeekIncentive }) => n + KeepingQuality3WeekIncentive, null),
        KeepingQualityLateIncentive: data[li].reduce((n, { KeepingQualityLateIncentive }) => n + KeepingQualityLateIncentive, null),
      })
    });
    // console.log("items", items)
    return items;

  }

  const onClearSearch = () => {
    setSubmitBtnDisabled(true);
    setClrBtnDisabled(true);

    setCurrentCropYear([]);
    setDefaultContract([]);
    setDefaultStatement([]);

    setReqContract({});
    setReqCropYear({});
    setReqStatement({});
    DateReset();

    ClearTableData();

    MultiDropDownFix();
    DataGridFix();
  };

  const ClearTableData = () => {
    setNameKeeper(null);
    setTableList([]);
    setFreshPage(true);
  };

  // console.log({
  //   statement: reqStatement?.statement,
  //   contract: reqContract?.contract,
  //   cropyear: reqCropyear?.cropyear,
  //   currentCropYear,
  //   defaultContract,
  //   nameKeeper,
  //   tableList,
  // });

  const statement = _.get(reqStatement, "statement", null);
  const columns = [
    {
      key: "Contract",
      name: "Contract #",
      width: "15%",
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "35%",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "ReportDate",
      name: "Payment Date",
      width: "20%",
      formatter(props) {
        let temp = getFormattedUTCDate(_.get(props, "row.ReportDate", null));
        return <>{temp || "-"}</>;
      },
    },
    {
      key: "StatementType",
      name: "Statement Type",
      width: "30%",
      formatter(props) {
        let temp1 = _.get(props, "row.FileUrl", null),
          temp2 = _.get(props, "row.StatementType", "-");
        return (
          <>
            {IsStringChecker(temp1) ? (
              <span>
                <a
                  href={temp1}
                  target={"_blank"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
                  {IsStringChecker(temp2) ? temp2 : "-"}
                </a>
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];
  const columns2 = [
    {
      key: "Contract",
      name: "Contract #",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.Contract", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "25%",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.CropYear", "-");
        return temp || "-";
      },
    },
    {
      key: "ReportDate",
      name: "Uploaded On",
      width: "15%",
      formatter(props) {
        let temp = getFormattedUTCDate(_.get(props, "row.ReportDate", null));
        return <>{temp || "-"}</>;
      },
    },
    {
      key: "StatementType",
      name: "Statement Type",
      width: "30%",
      formatter(props) {
        let temp1 = _.get(props, "row.FileUrl", null),
          temp2 = _.get(props, "row.StatementType", "-");
        return (
          <>
            {IsStringChecker(temp1) ? (
              <span>
                <a
                  href={temp1}
                  target={"_blank"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
                  {IsStringChecker(temp2) ? temp2 : "-"}
                </a>
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];

  const columns3 = [
    {
      key: "Contract",
      name: "Contract #",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.Contract", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "35%",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.CropYear", "-");
        return temp || "-";
      },
    },
    {
      key: "StatementType",
      name: "Report",
      width: "35%",
      formatter(props) {
        // console.log("props.row", props.row)
        return (
          <span>
            <Link to={"#"} onClick={() => downloadLink(props.row)}>
              <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
              IncentiveAssessmentsByReceipt{props.row?.CropYear}
            </Link>
          </span>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];

  const ArrangedData = (resultData) => {
    // console.log("resultData", resultData)
    let arrangedData = resultData.map((li) => {
      return {
        "Receipt": li.Id,
        "Total Barrels": li.TotalBarrels,
        "EU Incentive": li.EUFruitIncentive || null,
        "Organic Incentive": li.OrganicIncentive || null,
        "Global Gap Incentive": li.GlobalGapIncentive || null,
        "Global Gap Farm Incentive": li.GlobalGapFarmIncentive || null,
        "Early Sequence Incentive": li.EarlySequencedIncentive || null,
        "EPurs Incentive": li.EPursIncentive || null,
        "Sustainability Survey Incentive": li.SustainabilitySurveyIncentive || null,
        "Screening Assessment Revised 2020": li.ScreeningAssessmentRevised2020 || null,
        "Trash Assessment": li.TrashCharge || null,
        "Close Date Assessment": li.CloseDateAssessment || null,
        "Pur Assessment": li.PurAssessment || null,
        "Grower Packed Qualification Incentive": li.GrowerPackedQualificationIncentive || null,
        "Grower Packed True Up Incentive": li.GrowerPackedTrueUpIncentive || null,
        "Grower Packed Late Incentive": li.GrowerPackedKeepingQualityNovemberIncentive || null,
        "Grower Packed 3 Week Incentive": li.GrowerPacked3WeekIncentive || null,
        "Keeping Quality Qualification Incentive": li.KeepingQualityQualificationIncentive || null,
        "Keeping Quality True Up Incentive": li.KeepingQualityTrueUpIncentive || null,
        "Keeping Quality 3 Week Incentive": li.KeepingQuality3WeekIncentive || null,
        "Keeping Quality Late Incentive": li.KeepingQualityLateIncentive || null,
        "": "",
        "Receipt Total": (li.EUFruitIncentive || 0) + (li.OrganicIncentive || 0) + (li.GlobalGapIncentive || 0) + (li.GlobalGapFarmIncentive || 0) + (li.EarlySequenceIncentive || 0) +
          (li.EPursIncentive || 0) + (li.SustainabilitySurveyIncentive || 0) + (li.ScreeningAssessmentRevised2020 || 0) + (li.TrashCharge || 0) + (li.CloseDateAssessment || 0) +
          (li.PurAssessment || 0) + (li.GrowerPackedQualificationIncentive || 0) + (li.GrowerPackedTrueUpIncentive || 0) + (li.GrowerPackedKeepingQualityNovemberIncentive || 0) +
          (li.GrowerPacked3WeekIncentive || 0) + (li.KeepingQualityQualificationIncentive || 0) + (li.KeepingQualityTrueUpIncentive || 0) + (li.KeepingQuality3WeekIncentive || 0) +
          (li.KeepingQualityLateIncentive || 0)
      };
    });
    // console.log("arrangedData", arrangedData);
    arrangedData = _.orderBy(arrangedData, ['Receipt'], ['asc'])
    return arrangedData;
  };

  const downloadLink = (data) => {
    const reqData = {
      CropYear: data.CropYear,
      Pool: data.Pool,
      Relationship: `${data.Contract}-${data.ContractName}`
    };

    let exportData = ArrangedData(data.item),
      fileName = `IncentiveAssessmentsByReceipt_${data.CropYear}`,
      sheetTitle = "Sheet1",
      showParameter = true,
      parameterData = ParameterDataGetter({
        ...reqData,
      }),
      formatArray = [
        {
          index: 1,
          format: "#,##0.00_);(#,##0.00)",
        },
        {
          index: 2,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 3,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 4,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 5,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 6,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 7,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 8,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 9,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 10,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 11,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 12,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 13,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 14,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 15,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 16,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 17,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 18,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 19,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 20,
          format: "$#,##0.00_);($#,##0.00)",
        },
        {
          index: 22,
          format: "$#,##0.00_);($#,##0.00)",
        }
      ];
    var emptyField = [];
    Object.keys(exportData[0]).map((i) => {
      if (i !== "") {
        var count = exportData.filter(k => {
          return (k[i] !== null && k[i] !== '' && k[i] !== 0)
        })
        // console.log(i,count[0])
        if (count.length === 0) {
          // console.log(i)
          emptyField.push(i)
        }
      }
    })
    exportData = exportData.map(i => {
      emptyField.map(k => delete i[k])
      return {
        ...i
      }
    })
    const TotalBarrels = data.item.reduce((sum, row) => sum + (row.TotalBarrels || 0), 0);
    const EUFruitIncentive = data.item.reduce((sum, row) => sum + (row.EUFruitIncentive || 0), 0);
    const OrganicIncentive = data.item.reduce((sum, row) => sum + (row.OrganicIncentive || 0), 0);
    const GlobalGapIncentive = data.item.reduce((sum, row) => sum + (row.GlobalGapIncentive || 0), 0);
    const GlobalGapFarmIncentive = data.item.reduce((sum, row) => sum + (row.GlobalGapFarmIncentive || 0), 0);
    const EarlySequencedIncentive = data.item.reduce((sum, row) => sum + (row.EarlySequencedIncentive || 0), 0);
    const EPursIncentive = data.item.reduce((sum, row) => sum + (row.EPursIncentive || 0), 0);
    const SustainabilitySurveyIncentive = data.item.reduce((sum, row) => sum + (row.SustainabilitySurveyIncentive || 0), 0);
    const ScreeningAssessmentRevised2020 = data.item.reduce((sum, row) => sum + (row.ScreeningAssessmentRevised2020 || 0), 0);
    const TrashCharge = data.item.reduce((sum, row) => sum + (row.TrashCharge || 0), 0);
    const CloseDateAssessment = data.item.reduce((sum, row) => sum + (row.CloseDateAssessment || 0), 0);
    const PurAssessment = data.item.reduce((sum, row) => sum + (row.PurAssessment || 0), 0);


    const GrowerPackedQualificationIncentive = data.item.reduce((sum, row) => sum + (row.GrowerPackedQualificationIncentive || 0), 0);
    const GrowerPackedTrueUpIncentive = data.item.reduce((sum, row) => sum + (row.GrowerPackedTrueUpIncentive || 0), 0);
    const GrowerPackedKeepingQualityNovemberIncentive = data.item.reduce((sum, row) => sum + (row.GrowerPackedKeepingQualityNovemberIncentive || 0), 0);

    const GrowerPacked3WeekIncentive = data.item.reduce((sum, row) => sum + (row.GrowerPacked3WeekIncentive || 0), 0);
    const KeepingQualityQualificationIncentive = data.item.reduce((sum, row) => sum + (row.KeepingQualityQualificationIncentive || 0), 0);
    const KeepingQualityTrueUpIncentive = data.item.reduce((sum, row) => sum + (row.KeepingQualityTrueUpIncentive || 0), 0);

    const KeepingQuality3WeekIncentive = data.item.reduce((sum, row) => sum + (row.KeepingQuality3WeekIncentive || 0), 0);
    const KeepingQualityLateIncentive = data.item.reduce((sum, row) => sum + (row.KeepingQualityLateIncentive || 0), 0);

    const totalRow = {
      "Row Labels": 'Grand Total',
      "Total Barrels": TotalBarrels,
      "EU Incentive": EUFruitIncentive,
      "Organic Incentive": OrganicIncentive,
      "Global Gap Incentive": GlobalGapIncentive,
      "Global Gap Farm Incentive": GlobalGapFarmIncentive,
      "Early Sequence Incentive": EarlySequencedIncentive,
      "EPurs Incentive": EPursIncentive,
      "Sustainability Survey Incentive": SustainabilitySurveyIncentive,
      "Screening Assessment Revised 2020": ScreeningAssessmentRevised2020,
      "Trash Assessment": TrashCharge,
      "Close Date Assessment": CloseDateAssessment,
      "Pur Assessment": PurAssessment,
      "Grower Packed Qualification Incentive": GrowerPackedQualificationIncentive,
      "Grower Packed True Up Incentive": GrowerPackedTrueUpIncentive,
      "Grower Packed Late Incentive": GrowerPackedKeepingQualityNovemberIncentive,
      "Grower Packed 3 Week Incentive": GrowerPacked3WeekIncentive,
      "Keeping Quality Qualification Incentive": KeepingQualityQualificationIncentive,
      "Keeping Quality True Up Incentive": KeepingQualityTrueUpIncentive,
      "Keeping Quality 3 Week Incentive": KeepingQuality3WeekIncentive,
      "Keeping Quality Late Incentive": KeepingQualityLateIncentive,
      "": "",
      "Receipt Total": EUFruitIncentive + OrganicIncentive + GlobalGapIncentive + GlobalGapFarmIncentive + EarlySequencedIncentive + EPursIncentive +
        SustainabilitySurveyIncentive + ScreeningAssessmentRevised2020 + TrashCharge + CloseDateAssessment + PurAssessment + GrowerPackedQualificationIncentive +
        GrowerPackedTrueUpIncentive + GrowerPackedKeepingQualityNovemberIncentive + GrowerPacked3WeekIncentive + KeepingQualityQualificationIncentive + KeepingQualityTrueUpIncentive + KeepingQuality3WeekIncentive + KeepingQualityLateIncentive
    };
    emptyField.map(k => delete totalRow[k]);
    handleClickExcel(exportData, fileName, sheetTitle, showParameter, parameterData, formatArray, totalRow);
    // console.log(data.item)
  }
  const ParameterDataGetter = (reqData = {}) => {
    const MasterData = {
      CropYear: {
        key: "Crop Year",
        value: "",
        index: 0,
      },
      Pool: {
        key: "Pool",
        value: "",
        index: 1,
      },
      Relationship: {
        key: "Relationship",
        value: "",
        index: 2,
      }
    };
    try {
      const ReturnValueLogic = (param = null) => {
        if (IsStringChecker(param)) {
          const data = _.get(MasterData, param, {});
          const { key, value } = data;
          const reqValue = _.get(reqData, param, null) || value;
          switch (param) {
            case "currentYear":
              return { A: key, B: `${reqValue}` };
            default:
              return { A: key, B: `${reqValue}` };
          }
        } else {
          return { B: `` };
        }
      };

      if (IsObjectChecker(reqData) && IsObjectChecker(MasterData)) {
        const values = Object.values(reqData);
        if (IsArrayNotEmpty(values)) {
          const returnArr = [];
          for (const key in MasterData) {
            const index = _.get(MasterData, `${key}.index`);
            if (IsNumberChecker(index)) {
              _.set(returnArr, index, ReturnValueLogic(key));
            }
          }
          returnArr.push(ReturnValueLogic());
          return returnArr;
        }
        return [];
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const handleClickExcel = async (
    exportData,
    fileName,
    sheetTitle = "Sheet1",
    showParameter,
    parameterData = [],
    formatArray = null,
    totalRow = {}
  ) => {
    // console.log(exportData, fileName, sheetTitle, showParameter, parameterData, formatArray, IsArrayNotEmpty(parameterData))

    if (showParameter) {
      if (IsArrayNotEmpty(parameterData) && IsArrayNotEmpty(exportData)) {
        const wb = utils.book_new();
        const wsh = utils.json_to_sheet(parameterData, {
          header: ["A", "B"],
          skipHeader: true,
        });
        // console.log("exportData", exportData)
        const ws = utils.sheet_add_json(wsh, exportData, {
          origin: -1,
        });
        // console.log(exportData.length)
        const totalRowIndex = exportData.length + 7;
        utils.sheet_add_json(ws, [totalRow], { origin: `A${totalRowIndex}`, skipHeader: true });

        if (formatArray !== null) {
          let index = formatArray.map(i => i.index);
          for (let col of index) {
            const format = formatArray.filter(i => i.index === col)[0]?.format || '';
            formatcolumn(ws, col, format)
          }
        }

        // Apply styles to the total row
        const totalRowCellAddresses = [`A${totalRowIndex}`, `B${totalRowIndex}`, `C${totalRowIndex}`];
        totalRowCellAddresses.forEach((item) => {
          // console.log(ws[item]);
          ws[item].s = {
            font: { bold: true },                    // Make text bold
            fill: { bgColor: { rgb: "c0e6f5" } },    // Yellow background color
            // alignment: { horizontal: "center" }      // Center align text
          };
        });
        utils.book_append_sheet(wb, ws, sheetTitle);
        // console.log(wb, fileName + ".xlsx")
        writeFile(wb, fileName + ".xlsx");
      }
    }
  };

  const formatcolumn = (worksheet, col, fmt) => {
    const range = utils.decode_range(worksheet['!ref'])
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = utils.encode_cell({ r: row, c: col })
      if (worksheet[ref] && worksheet[ref].t === 'n' && worksheet[ref].v !== 0) {
        worksheet[ref].z = fmt
      }
    }
  }

  const noData = IsStringChecker(nameKeeper) && !IsArrayNotEmpty(tableList);
  const finalColumns = reqStatement?.statement === "incentive_assessment_receipt" ? columns3 : nameKeeper === "payment" ? columns : columns2;
  const noDataMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.statement_msg`,
    "No Statement Found"
  )}`;
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => MobileViewRenderer("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${isMobFilter ? "active" : ""
            }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => MobileViewRenderer("filter")}
        >
          <span className="fw-bold px-2">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${isMobFilter ? "show" : ""
            }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3 statement_type">
              <div className="payment_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={statements}
                    prompt="Statement Type"
                    value={reqStatement}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "statement"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "statement")
                    }
                    label="statement"
                    usestate={setReqStatement}
                    preSelectedOpt={defaultStatement}
                    disabled=""
                    singleSelect={true}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={contracts}
                    prompt="Select"
                    value={reqContract}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "contract"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "contract")
                    }
                    label="contract"
                    usestate={setReqContract}
                    disabled=""
                    preSelectedOpt={defaultContract}
                    optionNotFound={{ contract: noContractsFound }}
                  />
                )}
              </div>
            </div>
            {(statement === "incentive" || statement === "assessment" || statement === "incentive_assessment_receipt") && (
              <div className="col-12 col-lg-3">
                <div className="crop_year_label custom_dropdown white_bg">
                  {!clearstatus && (
                    <MultiDropDown
                      optionList={cropYears}
                      prompt="Select"
                      value={reqCropyear}
                      onChange={(selectedList, selectedItem) =>
                        onMultiSelectChange(
                          selectedList,
                          selectedItem,
                          "cropyear"
                        )
                      }
                      onDelete={(removedList, removedItem) =>
                        onMultiSelectRemove(
                          removedList,
                          removedItem,
                          "cropyear"
                        )
                      }
                      label="cropyear"
                      usestate={setReqCropYear}
                      preSelectedOpt={currentCropYear}
                      disabled=""
                    />
                  )}
                </div>
              </div>
            )}
            {statement === "payment" && (
              <>
                <div className="col-12 col-lg-3">
                  <div
                    className={`form-floating date_picker_aln cursor_pointer date_from ${dateDisabled && "opacity_0_5"
                      }`}
                  >
                    <DatePickerComp
                      id="datepickerFrom"
                      selected={startDate}
                      onChange={(date) => onDateChange(date, "start")}
                      placeholderText={dateFormate}
                      className="datepicker_input form-control border-1"
                      disabled={dateDisabled}
                      minDate={minDate}
                      maxDate={endDate || maxDate}
                    />
                    <label htmlFor="datepickerFrom">
                      Date From{" "}
                      <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-lg-3">
                  <div
                    className={`form-floating date_picker_aln cursor_pointer date_to ${dateDisabled && "opacity_0_5"
                      }`}
                  >
                    <DatePickerComp
                      id="datepickerTo"
                      selected={endDate}
                      onChange={(date) => onDateChange(date, "end")}
                      placeholderText={dateFormate}
                      className="datepicker_input form-control border-1"
                      disabled={dateDisabled}
                      minDate={startDate || minDate}
                    // maxDate={maxDate}
                    />
                    <label htmlFor="datepickerTo">
                      Date To{" "}
                      <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${isMobFilter ? "show d-block " : " d-lg-none "
              }`}
          >
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={fetchTableData}
              disabled={submitBtnDisabled}
            >
              {`Apply `}
              {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={fetchTableData}
                disabled={submitBtnDisabled}
              >
                {`Search `}
                {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block clear-btn-grey"
                onClick={() => onClearSearch()}
                disabled={clrBtnDisabled}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </nav>
      {IsStringChecker(nameKeeper) && !displayAccordian && (
        <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
          <h3 className="pt-3 pb-1 pb-lg-4">
            <span className="modify_text text-capitalize">{`${nameKeeper} Statement`}</span>
          </h3>
          {IsArrayNotEmpty(tableList) && (
            <>
              {!gridstatus && (
                <DataGridPagination
                  uniqueIdentifier="Index"
                  gridData={tableList}
                  columns={finalColumns}
                  isPagination={true}
                  itemsPerPage={10}
                  columnResize={false}
                  classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                  dynamicHeight={45}
                />
              )}
            </>
          )}
          {noData && (
            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
              <i className="fa fa-info-circle pe-2"></i>
              {noDataMsg}
            </div>
          )}
        </div>
      )}
      <div
        className={`hor-space-20 top-space-20  border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          <Accordion
            defaultActiveKey="0"
            className="table mobile-accordion accordion_space"
          >
            {IsArrayNotEmpty(tableList) ? (
              tableList.map((data, index) => {
                const {
                  StatementType = null,
                  FileUrl = null,
                  Contract = null,
                  ContractName = null,
                  CropYear = null,
                  ReportDate = null,
                } = data;
                const dateRender = getFormattedUTCDate(ReportDate);
                if (reqStatement?.statement === "incentive_assessment_receipt") {
                  return (
                    <Accordion.Item key={index} eventKey={index}>
                      <Accordion.Header>
                        {`Contract #: ${Contract || "-"}`}
                        <br />
                        {`Contract Name: ${ContractName || "-"}`}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tbody">
                          <div className="tr">
                            <div className="td"></div>
                            <div className="td" data-header="Report:">
                              <Link to={"#"} onClick={() => downloadLink(data)}>
                                <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
                                Incentive Assessments By Receipt {CropYear}
                              </Link>
                            </div>
                            <div className="td" data-header="Contract #:">
                              <span>
                                {IsStringChecker(Contract) ? Contract : "-"}
                              </span>
                            </div>
                            <div className="td" data-header="Contract Name:">
                              <span>
                                {IsStringChecker(ContractName)
                                  ? ContractName
                                  : "-"}
                              </span>
                            </div>
                            <div className="td" data-header="Crop Year:">
                              <span>
                                {CropYear}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
                else
                  return (
                    <Accordion.Item key={index} eventKey={index}>
                      <Accordion.Header>
                        {`Contract #: ${Contract || "-"}`}
                        <br />
                        {`Statement Type: ${StatementType || "-"}`}
                        <br />
                        {nameKeeper === "payment"
                          ? `Payment Date: ${dateRender || "-"}`
                          : `Uploaded On: ${dateRender || "-"}`}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tbody">
                          <div className="tr">
                            <div className="td"></div>
                            <div className="td" data-header="Statement Type:">
                              {IsStringChecker(FileUrl) ? (
                                <span className="sm-word-break">
                                  <a
                                    href={FileUrl}
                                    target="_blank"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Export"
                                    rel="noreferrer"
                                  >
                                    <i
                                      className="fa fa-file-o"
                                      alt="ExcelIcon"
                                    ></i>
                                    &ensp;
                                    {IsStringChecker(StatementType)
                                      ? StatementType
                                      : "-"}
                                  </a>
                                </span>
                              ) : (
                                <span>{"-"}</span>
                              )}
                            </div>
                            <div className="td" data-header="Contract #:">
                              <span>
                                {IsStringChecker(Contract) ? Contract : "-"}
                              </span>
                            </div>
                            <div className="td" data-header="Contract Name:">
                              <span>
                                {IsStringChecker(ContractName)
                                  ? ContractName
                                  : "-"}
                              </span>
                            </div>
                            <div className="td" data-header="Crop Year:">
                              <span>
                                {IsStringChecker(CropYear) ? CropYear : "-"}
                              </span>
                            </div>
                            <div
                              className="td"
                              data-header={
                                nameKeeper === "payment"
                                  ? `Payment Date:`
                                  : `Uploaded On:`
                              }
                            >
                              <span>{dateRender || "-"}</span>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
              })
            ) : freshPage ? (
              <></>
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {noDataMsg}
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Payment;
