import {
  IsStringChecker,
  HandleErrorTrackAndToast,
  NumConverter,
  IsNumberChecker,
  IsArrayNotEmpty,
} from "../../../../utils/helper";

export const ZeroLimit = 0,
  DecimalOneLimit = 0.01,
  OneLimit = 1,
  TwentyLimit = 20,
  HundredLimit = 100,
  OneMillionLimit = 1000000,
  FiveMillionLimit = 5000000,
  NineMillionLimit = 9000000;
export const maxBarrels = 9000000;

export function WholeRateValidation(value, min = 0, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num >= min && num <= max) {
        return `${value}`;
      } else if (num <= max) {
        return `${min}`;
      } else if (num >= min) {
        return `${max}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function MinRateValidation(value, min = 0) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num >= min) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function MaxRateValidation(value, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num <= max) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function IsNumber(
  value,
  oMinCheck = false,
  oMaxCheck = false,
  isEquals = false,
  oMin = 0,
  oMax = 100
) {
  try {
    if (IsNumberChecker(value)) {
      return CalcValidate(value, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
    } else if (IsStringChecker(value)) {
      const num = parseFloat(value);
      if (IsNumberChecker(num)) {
        return CalcValidate(num, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function CommaNumberToNormal(num, float = true) {
  try {
    const new_num = `${num}`.replace(/,/g, "");
    return float ? parseFloat(new_num) : parseInt(new_num);
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
}

export function NumberToNormal(num, float = true, subtract = 0) {
  try {
    const new_num = `${num}`;
    const numb = float ? parseFloat(new_num) : parseInt(new_num);
    if (numb) {
      if (parseInt(subtract)) {
        const result = numb - subtract;
        return result >= 0 ? result : 0;
      }
      return numb;
    }
    return 0;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
}

export function EventMGMT(e, def = true, propagate = true) {
  try {
    if (def && propagate) {
      e.stopPropagation();
      e.preventDefault();
    } else if (def || propagate) {
      if (def) {
        e.preventDefault();
      } else {
        e.stopPropagation();
      }
    }
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
  }
}

export function CalcValidate(val, equals, minCheck, min, maxCheck, max) {
  try {
    if (minCheck && maxCheck) {
      if (equals) {
        return val >= min && val <= max;
      } else {
        return val > min && val < max;
      }
    } else if (minCheck || maxCheck) {
      if (minCheck) {
        if (equals) {
          return val >= min;
        } else {
          return val > min;
        }
      } else {
        if (equals) {
          return val <= max;
        } else {
          return val < max;
        }
      }
    } else {
      return true;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function NumberFormaterWithValidation(
  value,
  Validator = () => {},
  min = 0,
  max = 100
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const num = `${inputNum}.${decimals}`;
          if (num === "0.0") {
            return num;
          } else {
            return Validator(num, min, max);
          }
        } else {
          const num = Validator(inputNum, min, max);
          return IsStringChecker(num) ? `${num}.` : "";
        }
      } else {
        return Validator(inputNum, min, max);
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function Digit4NumberFormaterWithValidation(
  value,
  Validator = () => {},
  min = 0,
  max = 100
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 4)
            : "";
          const num = `${inputNum}.${decimals}`;
          if (num === "0.0" || "0.00" || "0.000") {
            return num;
          } else {
            return Validator(num, min, max);
          }
        } else {
          const num = Validator(inputNum, min, max);
          return IsStringChecker(num) ? `${num}.` : "";
        }
      } else {
        return Validator(inputNum, min, max);
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function RemoveNumberFormater(value) {
  try {
    if (IsStringChecker(value)) {
      const withoutComma = value.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    }
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  }
}

export function BarrelsNumberFormater(
  value,
  validate = false,
  decimal = false
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      const options = {};
      if (decimal) {
        options.style = "decimal";
        options.maximumFractionDigits = 2;
      }
      const USNum = new Intl.NumberFormat("en-US", { ...options });

      const num = USNum.format(inputNum);
      if (num == "NaN") {
        return "";
      } else {
        if (validate) {
          const { inputNum: toValidate } = RemoveNumberFormater(num);
          const inputValidate = NumConverter(toValidate);
          if (inputValidate < maxBarrels) {
            if (isDecimal) {
              const decimals = IsStringChecker(decimalSeq)
                ? decimalSeq.substring(0, 2)
                : "";
              return `${num}.${decimals}`;
            } else {
              return num;
            }
          } else {
            return `9,000,000`;
          }
        } else {
          if (isDecimal) {
            const decimals = IsStringChecker(decimalSeq)
              ? decimalSeq.substring(0, 2)
              : "";
            return `${num}.${decimals}`;
          } else {
            return num;
          }
        }
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function OnChangeDecimalWrapper(e) {
  try {
    const { name, value } = e?.target || {};
    if (value === ".") {
      return { name, value: "0." };
    } else if (value.startsWith(".")) {
      return { name, value: `0${value}` };
    }
    return { name, value };
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return e?.target || {};
  }
}

export function KeyDownHandler(e) {
  const { key, target = {} } = e;
  const { value } = target;
  // console.log({ key, value });
  if (key === " ") {
    EventMGMT(e);
  }
  if (key.length === 1) {
    if (key === ".") {
      const isDecimal = value.includes(".");
      if (isDecimal) {
        EventMGMT(e);
      }
    } else {
      if (isNaN(key)) {
        EventMGMT(e);
      }
    }
  }
}

export function NumberFormater(value) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const num = `${inputNum}.${decimals}`;
          return num;
        } else {
          return IsStringChecker(inputNum) ? `${inputNum}.` : "";
        }
      } else {
        return IsStringChecker(inputNum) ? inputNum : "";
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function RateValidation(value, min = 0, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num > min && num <= max) {
        return `${value}`;
      } else if (num <= max) {
        return `${min}`;
      } else if (num > min) {
        return `${max}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function EstimateNumberValidation(value) {
  try {
    if (IsNumberChecker(value)) {
      return value > 0;
    } else if (IsStringChecker(value)) {
      const num = parseFloat(value);
      return num > 0;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function ExportNumberConverter(value, fixed = 2) {
  try {
    if (IsNumberChecker(value) || IsStringChecker(value)) {
      const str = parseFloat(value).toFixed(fixed);
      return parseFloat(str);
    } else {
      return 0.0;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0.0;
  }
}

export function EstimateRemoveNumberFormater(value) {
  try {
    if (IsStringChecker(value)) {
      const withoutComma = value.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    } else if (IsNumberChecker(value)) {
      const withoutComma = `${value}`.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    }
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  }
}

export function EstimateNumberFormater(value, decimal = false) {
  const { inputNum, decimalSeq, isDecimal } =
    EstimateRemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      const options = {};
      if (decimal) {
        options.style = "decimal";
        options.maximumFractionDigits = 2;
      }
      const USNum = new Intl.NumberFormat("en-US", { ...options });

      const num = USNum.format(inputNum);
      if (num == "NaN") {
        return "";
      } else {
        if (isDecimal) {
          const decimalsArrange = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const decimals =
            decimalsArrange.length >= 2
              ? decimalsArrange
              : decimalsArrange.length === 1
              ? `${decimalsArrange}0`
              : `00`;
          return `${num}.${decimals}`;
        } else {
          return `${num}.00`;
        }
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function CheckFetchesLoaded() {
  const args = [...arguments];
  return args.some((li) => li);
}

export function ToConcatenatedString() {
  try {
    const args = [...arguments];
    return args.join(",");
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function PaymentsArrayFieldsFormatter(payments_arr = []) {
  try {
    if (IsArrayNotEmpty(payments_arr)) {
      return payments_arr.map((it) => {
        const {
          key,
          NA_Order,
          SA_Order,
          contractOrder,
          month,
          isDeferredPeriod,
          rate,
          incentiveRate,
          incentiveDollars,
          deferrelPeriod,
          deferrelPercent,
          rateDisabled,
          incentiveRateDisabled,
          incentiveDollarsDisabled,
          deferrelPeriodDisabled = false,
          deferrelPercentDisabled = false,
        } = it || {};
        return {
          key,
          na_order: NA_Order,
          sa_order: SA_Order,
          contract_order: contractOrder,
          month,
          is_deferred_period: isDeferredPeriod,
          payment_rate: rate,
          incentive_rate: incentiveRate,
          incentive_dollars: incentiveDollars,
          deferrel_period: deferrelPeriod,
          deferrel_percent: deferrelPercent,
          rate_disabled: rateDisabled,
          incentive_rate_disabled: incentiveRateDisabled,
          incentive_dollars_disabled: incentiveDollarsDisabled,
          deferrel_percent_disabled: deferrelPercentDisabled,
          deferrel_period_disabled: deferrelPeriodDisabled,
        };
      });
    }
    return [];
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
}

export function InpuctComponentFieldsFormatter(li = {}) {
  try {
    const {
      key,
      order,
      payments_pending = false,
      cropyear,
      barrels,
      isDeferred,
      interestRate,
      incentiveRateOrDollars,
      payments,
      are_data,
      stock_retain_data,
      common_data,
    } = li || {};

    return {
      key,
      order,
      payments_pending,
      cropyear,
      barrels: `${barrels}`.replace(/,/g, ""),
      is_deferred: isDeferred,
      deferrel_interest_rate: interestRate,
      incentive_type: incentiveRateOrDollars,
      payments: PaymentsArrayFieldsFormatter(payments),
      are_data: AREFieldsFormatter(are_data),
      stock_retain_data: StockRetainFieldsFormatter(stock_retain_data),
      common_data,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function AREFieldsFormatter(li = {}) {
  try {
    const {
      are_region,
      are_crop_year,
      are_issuance_month,
      are_issuance_year,
      are_redemption_month,
      are_redemption_year,
      are_redemption_crop_year,
      are_issuance,
      are_redemption,
      are_rate,
    } = li || {};
    return {
      are_region,
      are_crop_year,
      are_issuance_month,
      are_issuance_year,
      are_redemption_month,
      are_redemption_year,
      are_redemption_crop_year,
      are_issuance,
      are_redemption,
      are_rate,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function StockRetainFieldsFormatter(li = {}) {
  try {
    const {
      stock_retain_region,
      stock_retain_crop_year,
      stock_retain_month,
      stock_retain_year,
      average_deliveries,
      quota_rate,
      par_value,
      quota_shares,
      common_stock_shares_held,
      percent_to_quota,
      retain_rate,
      retain_dollars,
      potential_retain_shares,
      shares_under_quota,
      shares_under_quota_value,
      retain_shares_to_be_issued,
      retain_shares_to_be_issued_value,
      cash_in_lieu_of_stock,
    } = li || {};

    return {
      stock_retain_region,
      stock_retain_crop_year,
      stock_retain_month,
      stock_retain_year,
      average_deliveries,
      quota_rate,
      par_value,
      quota_shares,
      common_stock_shares_held,
      percent_to_quota,
      retain_rate,
      retain_dollars,
      potential_retain_shares,
      shares_under_quota,
      shares_under_quota_value,
      retain_shares_to_be_issued,
      retain_shares_to_be_issued_value,
      cash_in_lieu_of_stock,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function ReversePaymentsArrayFieldsFormatter(payments_arr = []) {
  try {
    if (IsArrayNotEmpty(payments_arr)) {
      return payments_arr.map((li) => {
        const {
          key,
          na_order,
          sa_order,
          contract_order,
          month,
          payment_rate,
          incentive_rate,
          deferrel_period,
          deferrel_percent,
          incentive_dollars,
          is_deferred_period,
          rate_disabled,
          incentive_rate_disabled,
          incentive_dollars_disabled,
          deferrel_period_disabled,
          // deferrel_percent_disabled,
        } = li || {};
        function getDeferrelDisabled() {
          const num = NumConverter(deferrel_period);
          if (num > 0) {
            return false;
          } else {
            return true;
          }
        }
        return {
          key,
          NA_Order: na_order,
          SA_Order: sa_order,
          contractOrder: contract_order,
          month,
          isDeferredPeriod: is_deferred_period,
          rate: payment_rate,
          incentiveRate: incentive_rate,
          incentiveDollars: incentive_dollars,
          deferrelPeriod: deferrel_period,
          deferrelPercent: deferrel_percent,
          rateError: false,
          incentiveRateError: false,
          incentiveDollarsError: false,
          deferrelPeriodError: false,
          deferrelPercentError: !IsNumber(deferrel_percent, true, true, true),
          rateDisabled: rate_disabled,
          incentiveRateDisabled: incentive_rate_disabled,
          incentiveDollarsDisabled: incentive_dollars_disabled,
          deferrelPeriodDisabled: deferrel_period_disabled,
          deferrelPercentDisabled: getDeferrelDisabled(),
        };
      });
    }
    return [];
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
}

export function ReverseInputComponentFieldsFormatter(li = {}) {
  try {
    const {
      key,
      order,
      payments_pending = false,
      barrels,
      cropyear,
      is_deferred,
      incentive_type,
      deferrel_interest_rate,
      are_data,
      common_data,
      stock_retain_data,
    } = li || {};

    return {
      key,
      order,
      payments_pending,
      cropyearReadonly: true,
      barrelsError: false,
      isDeferred: is_deferred,
      interestRateError: false,
      cropyear,
      barrels: BarrelsNumberFormater(barrels),
      interestRate: deferrel_interest_rate,
      incentiveRateOrDollars: incentive_type,
      are_data: ReverseAREFieldsFormatter(are_data),
      stock_retain_data: ReverseStockRetainFieldsFormatter(stock_retain_data),
      common_data,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function ReverseAREFieldsFormatter(li = {}) {
  try {
    return {
      ...li,
      are_issuance_error: false,
      are_redemption_error: false,
      are_rate_error: false,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function ReverseStockRetainFieldsFormatter(li = {}) {
  try {
    return {
      ...li,
      average_deliveries_error: false,
      quota_rate_error: false,
      par_value_error: false,
      quota_shares_error: false,
      common_stock_shares_held_error: false,
      percent_to_quota_error: false,
      retain_rate_error: false,
      retain_dollars_error: false,
      potential_retain_shares_error: false,
      shares_under_quota_error: false,
      shares_under_quota_value_error: false,
      retain_shares_to_be_issued_error: false,
      retain_shares_to_be_issued_value_error: false,
      cash_in_lieu_of_stock_error: false,
    };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

export function IsArrayElementsTrue() {
  try {
    const arr = [...arguments];
    return arr.every((li) => li);
  } catch (e) {
    console.error(e);
    return false;
  }
}

export function pastYearCompare(year1 = null, year2 = null) {
  try {
    if (year1 && year2) {
      const y1 = parseInt(year1),
        y2 = parseInt(year2);
      if (isNaN(y1) || isNaN(y2)) {
        return false;
      } else {
        return y2 < y1;
      }
    }
    return false;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}
