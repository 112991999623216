import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
// import parse from "html-react-parser";
import Loading from "../../global/loading";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import ContentModifier from "../../global/contentModifier";

const EPURsGuid = () => {
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const [contentBlock, setcontentBlock] = useState(null);
  const { i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    IsTokenChecker(idToken) && getContentBlock();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);
  const getContentBlock = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      "content_blocks?select=id,content_en,content_fr,content_es,order_by,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_es,accordion_name_fr,accordion_name_en)&component_listings.component_id=eq.030de3d9-bf9e-4c40-a74d-f0e9dda5e83d"
    )
      .then((resp) => {
        if (resp?.data?.data[0]) {
          setcontentBlock(resp?.data?.data[0]);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <Accordion id="accordionEpursGuid" className="mt-0 harvest_news">
        <Accordion.Item eventKey="lastPayment">
          <Accordion.Header className="accordion-desktop-font-18 fw-bold ">
            {contentBlock != null
              ? contentBlock.component_listings[
                  "accordion_name_" + i18n.resolvedLanguage
                ]
              : ""}
          </Accordion.Header>
          <Accordion.Body>
            {contentBlock != null ? (
              <>
              {/* <div className="rich-editor-content-block"> */}
              {/* {parse(contentBlock["content_" + i18n.resolvedLanguage])} */}
              <ContentModifier
                key={0}
                str={contentBlock["content_" + i18n.resolvedLanguage]}
              />
            {/* </div> */}
            </>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default EPURsGuid;
