/**
 * @component_Name : DDReceipts -Delivery Data Receipts component
 * @description : Show Delivery Data Receipts as grid and accordion
 * @company : Photon
 * @author : Ashish Ranjan/15-07-2022
 **/

import React, { useEffect, useState } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  getweightedAverage,
  toDecimal,
  getFullDate,
  setOptions,
  setObjToString,
  IsTokenChecker,
  getObjectLength,
  remainFirstCharacter,
  IsStringChecker,
  IsNumberChecker,
  IsArrayNotEmpty,
  IsObjectChecker,
  HandleErrorTrackAndToast,
  IsFunctionChecker,
  NumConverter,
  compareString,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import { ConstantsVar } from "../../../utils/constants";
import ExportFile from "../../exportFile/exportFile";
import WhiteCircleLoader from "../../whiteCircleLoader"; 
import "react-datepicker/dist/react-datepicker.css";
import DDReceiptsMobile from "./dDReceiptsMobile";
import DataGridPagination from "../../global/dataGridPagination";
import moment from "moment";
import {DeliveryDataPdfFile} from "../../exportFile/pdfFile";
import Loading from "../../../components/global/loading";
import _ from "lodash";
import {
  FilterContractByRole,
  MapContractsWithRole,
  ImpersonationAccountsSwap
} from "../../../permissionWrapper";
import { useGbsDataCache } from "../../../services/gbsDataCache";
import { roleCBPayment as PaymentRole, roleCBDecisionMaker as DecisionMaker } from "../../../utils/roles";
import DatePickerComp from "../../global/datepicker";
const filterSummaryData = (param) => {
  //const consolidateArray = [];
  const DeliveryWeight = param.map((x) => {
    return x["DeliveryWeight"] && x["DeliveryWeight"];
  });
  const TotalBarrels = param.map((x) => {
    return x["TotalBarrels"] && x["TotalBarrels"];
  });
  const FirmnessAverage = param.map((x) => {
    return x["FirmnessAverage"] && x["FirmnessAverage"];
  });
  const PercentGreaterThanHalf = param.map((x) => {
    return x["PercentGreaterThanHalf"] && x["PercentGreaterThanHalf"];
  });
  const PercentDryTrash = param.map((x) => {
    return x["PercentDryTrash"] && x["PercentDryTrash"];
  });
  const PercentMoist = param.map((x) => {
    return x["PercentMoist"] && x["PercentMoist"];
  });
  const PercentTotalTrash = param.map((x) => {
    return x["PercentTotalTrash"] && x["PercentTotalTrash"];
  });
  const PercentPoor = param.map((x) => {
    return x["PercentPoor"] && x["PercentPoor"];
  });
  const PercentClassOne = param.map((x) => {
    return x["PercentClassOne"] && x["PercentClassOne"];
  });
  const PercentUsable = param.map((x) => {
    return x["PercentUsable"] && x["PercentUsable"];
  });
  const CleanDryWeight = param.map((x) => {
    return x["CleanDryWeight"] && x["CleanDryWeight"];
  });

  return {
    DeliveryWeight: DeliveryWeight,
    TotalBarrels: TotalBarrels,
    FirmnessAverage: FirmnessAverage,
    PercentGreaterThanHalf: PercentGreaterThanHalf,
    PercentDryTrash: PercentDryTrash,
    PercentMoist: PercentMoist,
    PercentTotalTrash: PercentTotalTrash,
    PercentPoor: PercentPoor,
    PercentClassOne: PercentClassOne,
    PercentUsable: PercentUsable,
    CleanDryWeight: CleanDryWeight,
  };
};

const sumArrayValue = (param) => {
  let newVal = 0;
  param.map((x) => {
    newVal = newVal + NumConverter(x);
  });
  return newVal;
};

const DDReceipts = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const {
    DropDownCBRoles = [],
    noContractsFound = "",
    listCropYear: propListCropYear = [],
    ContractIds: propContractIds = "",
    currentCropYear: propCurrentCropYear = 0,
    ContractOption: propContractOption = [],
    minMaxYear: propMinMaxYear = [],
    RoleCBPrivileges = {},
  } = props;
  const { roleCBPayment = false, roleCBDecisionMaker = false } = RoleCBPrivileges;
  const summaryProcessData = {
    proDeliveryCount: 0,
    proDeliveryWeight: 0,
    proTotalBarrels: `0.00`,
    proAvgfirm: `0.00`,
    proAvgHalf: `0.00`,
    proAvgDryTrash: `0.00`,
    proAvgMoist: `0.00`,
    proAvgTrash: `0.00`,
    proAvgPoor: `0.00`,
    proAvgClassOne: `0.00`,
    proAvgUsable: `0.00`,
  };
  const summaryFreshData = {
    freshDeliveryCount: 0,
    freshDeliveryWeight: 0,
    freshTotalBarrels: `0.00`,
    freshAvgfirm: `0.00`,
    freshAvgHalf: `0.00`,
    freshAvgDryTrash: `0.00`,
    freshAvgMoist: `0.00`,
    freshAvgTrash: `0.00`,
    freshAvgPoor: `0.00`,
    freshAvgClassOne: `0.00`,
    freshAvgUsable: `0.00`,
  };
  const summaryTotalData = {
    totalDeliveryCount: 0,
    totalDeliveryWeight: 0,
    totalTotalBarrels: `0.00`,
    totalAvgfirm: `0.00`,
    totalAvgHalf: `0.00`,
    totalAvgDryTrash: `0.00`,
    totalAvgMoist: `0.00`,
    totalAvgTrash: `0.00`,
    totalAvgPoor: `0.00`,
    totalAvgClassOne: `0.00`,
    totalAvgUsable: `0.00`,
  };

  const tokenId = useSelector((state) => state.reducerToken);
  const [listCropYear, setListCropYear] = useState([]);
  const [currentCropYear, setCurrentCropYear] = useState(0);
  const [multiCrop, setMultiCrop] = useState({});
  const [multiContId, setMultiContId] = useState([]);
  const [multiValue, setMultiValue] = useState({});
  const [inputDelivery, setInputDelivery] = useState("");
  /*filter default disabled**/
  const [disabled, setDisabled] = useState("disabled");
  const [gridItems, setGridItems] = useState([]);
  const [exportItems, setExportItems] = useState([]);
  const [exportParameter, setExportParameter] = useState([]);
  const [resultItems, setResultItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    // searchBtnDisabled
    , setSearchBtnDisabled] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [hideExport, setHideExport] = useState(true);
  const [summaryProcess, setsummaryProcess] = useState(summaryProcessData);
  const [summaryFresh, setsummaryFresh] = useState(summaryFreshData);
  const [summaryTotal, setsummaryTotal] = useState(summaryTotalData);
  const [preSelectedArray, setPreSelectedArray] = useState([]);

  //const [CropYears, setCropYears] = useState([]);
  const [fromMinDate, setfromMinDate] = useState();
  const [endMinDate, setendMinDate] = useState();
  const [endMaxDate, setendMaxDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minMaxYear, setMinMaxYear] = useState([]);

  const [
    // disablePreSelected
    , setDisablePreSelected] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [reloadCropyear, setReloadCropyear] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  const [reloadcontract] = useState(true);
  // const [reloadcontract, setReloadcontract] = useState(true);
  const [contractPreselect, setContractPreselect] = useState(["all"]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [harvestbedOption, setHarvestbedOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ContractIds, setContractIds] = useState("");
  const [formatArray, setFormatArray] = useState([]);
  const [PDFExport, setPDFExport] = useState([]);
  const [disableContractField, setDisableContractField] = useState(true);
  const DisplayDataSafe = (
    propVal = {},
    field = "",
    returnVal = `-`,
    fixed = false,
    formatFunc = false,
    convertFunc = false,
    fixedVal = 2,
    currency = ""
  ) => {
    try {
      const temp = _.get(propVal, `row.${field}`, null);
      const convert = IsFunctionChecker(convertFunc);
      const format = IsFunctionChecker(formatFunc);
      if (temp) {
        if (convert && fixed) {
          const temp2 = convertFunc(temp).toFixed(fixedVal);
          const temp3 = format
            ? formatFunc(temp2, fixedVal, currency, returnVal)
            : temp2;
          return temp3 || returnVal;
        } else {
          const temp2 = convert
            ? convertFunc(temp)
            : fixed
            ? temp.toFixed(fixedVal)
            : temp;
          const temp3 = format
            ? formatFunc(temp2, fixedVal, currency, returnVal)
            : temp2;
          return temp3 || returnVal;
        }
      }
      return returnVal;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return returnVal;
    }
  };

  const columns = [
    {
      key: "LocationCode",
      name: "Facility",
      width: 60,
      headerCellClass: "enablesTextWrappingHeading",
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
    },
    {
      key: "LoadDeliveryTicketNumber",
      name: "Delivery # - Shipping Memo",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
    },
    {
      key: "HarvestBed",
      name: "Bed",
      width: 60,
    },
    {
      key: "Percentage",
      name: "Bed%",
      headerCellClass: "enablesTextWrappingHeading",
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
      formatter(props) {
        let temp = Math.round(NumConverter(_.get(props, "row.Percentage", 0)));
        return <>{temp > 0 ? `${temp}%` : 0}</>;
      },
    },
    {
      key: "IncomingTime",
      name: "Date In",
      width: 95,
      formatter(props) {
        let temp = _.get(props, "row.IncomingTime", null);
        return temp ? moment.utc(temp).format(ConstantsVar.dateFormate) : "-";
      },
    },
    {
      key: "Time_In_IncomingTime",
      name: "Time In",
      width: 80,
      formatter(props) {
        let temp = _.get(props, "row.Time_In_IncomingTime", null);
        return temp ? moment.utc(temp).format(ConstantsVar.timeFormate) : "-";
      },
    },
    {
      key: "ProcessedFreshType",
      name: "F/P",
      width: 30,
      sortable: true,
      headerCellClass: "enablesTextWrappingHeading tooltip-container",
      formatter(props) {
        let temp = _.get(props, "row.ProcessedFreshType", null);
        let xyz = temp ? remainFirstCharacter(temp) : "-";
        return (
          <span title={temp} className="text-capitalize">
            {xyz}
          </span>
        );
      },
      // headerRenderer(data){
      //   console.log(data)
      //   return <span className="tableheaderTooltip rdg-header-sort-cell"><span className="rdg-header-sort-name">F/P</span><span className="tooltiptext">Fresh/Processed</span></span>
      // }
    },
    {
      key: "RelationshipNumber",
      name: "Contract #",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
    },
    {
      key: "LegacyVarietyCode",
      name: "Var",
      width: 60,
      formatter(props) {
        let temp = _.get(props, "row.VarietyDescription", "-");
        let temp2 = _.get(props, "row.LegacyVarietyCode", "-");
        return <span title={temp}>{temp2}</span>;
      },
    },
    {
      key: "DeliveryWeight",
      name: "Deliv Weight",
      width: 90,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.DeliveryWeight", null);
        let xyz = temp
          ? toDecimal(Math.round(NumConverter(temp)), 0, "", 0)
          : null;
        return <>{xyz ? xyz : "0"}</>;
      },
    },
    {
      key: "TotalBarrels",
      name: "Total Barrels",
      width: 90,
      cellClass: "text-lg-end",
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "TotalBarrels",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "FirmnessAverage",
      name: "Firm",
      width: 80,
      cellClass: "text-lg-end",
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      formatter(props) {
        let temp = _.get(props, "row.FirmnessAverage", null);
        let xyz = temp
          ? toDecimal(Math.round(NumConverter(temp)), 0, "", 0)
          : null;
        return <>{xyz ? xyz : "0"}</>;
      },
    },
    {
      key: "PercentGreaterThanHalf",
      name: "% > 1/2",
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      width: 70,
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentGreaterThanHalf",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentDryTrash",
      name: "% Dry Trash",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentDryTrash",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentMoist",
      name: "% Moist",
      width: 65,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentMoist",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentTotalTrash",
      name: "% Total Trash",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentTotalTrash",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentPoor",
      name: "% Poor",
      width: 60,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentPoor",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentClassOne",
      name: "% Class1",
      width: 60,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentClassOne",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentUsable",
      name: "% Usable",
      width: 60,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentUsable",
          "0.00",
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PhotoLink",
      name: "Photo",
      width: 100,
      cellClass: "text-lg-center enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.PhotoLink", null);
        return temp ? (
          <span>
            <a href={temp} target={"_blank"} rel="noreferrer">
              <img
                src="../../assets/images/single-cranberry.png"
                className="width-25"
              />
            </a>
          </span>
        ) : (
          "No Photo Available"
        );
      },
    },
  ];
  const ongoingYear = getCurrentYear();

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  const [responseType, setResponseType] = useState(0);
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  useEffect(() => {
    if (responseType === "withoutSubmit") {
      if (data.data) {
        receiptAllFilterData(data);
      }
      if (status === "error") {
        setLoader(false);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    } else {
      if (data.data) {
        fetchReceiptsData(data);
      }
      if (status === "error") {
        setLoader(false);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End

  useEffect(() => {
    setLoader(true);
    setBogNameOption([]);
    setHarvestbedOption([]);
    setVerityOption([]);
    if (IsTokenChecker(tokenId) && propListCropYear.length > 0) {
      setCurrentCropYear(propCurrentCropYear);
      setMinMaxYear({ ...propMinMaxYear });
      setListCropYear([...propListCropYear]);
      setPreSelectedArray([propCurrentCropYear]);
      setMultiCrop({cropyear:`${propCurrentCropYear}`});
      setDisableContractField(false);
      setContractIds(propContractIds);
      setContractOption([...propContractOption]);

      if (propContractIds != "" && currentCropYear != "") {
        let url = `deliveries/by-receipt?cropyear=${currentCropYear}&contractid=${propContractIds}&status=3402,3403,3404&filtersonly=1`;
        setResponseType("withoutSubmit");
        receiptFilterData(url);
      }
    }
  }, [
    propContractOption,
    propCurrentCropYear,
    propMinMaxYear,
    propListCropYear,
    tokenId,
  ]);

  useEffect(() => {
    let minYear = minMaxYear["minYear"];
    let maxYear = minMaxYear["maxYear"];
    if (minYear) {
      setfromMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + minYear)
      );
      setendMinDate(new Date(ConstantsVar.startingDateOfYear + ", " + minYear));
      setStartDate(new Date(ConstantsVar.startingDateOfYear + ", " + minYear));
    } else if (currentCropYear != "") {
      setfromMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
      setendMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
      setStartDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
    }
    if (maxYear) {
      if (maxYear == ongoingYear) {
        setEndDate(new Date());
        setendMaxDate(new Date());
      } else {
        setendMaxDate(new Date(ConstantsVar.endingDateOfYear + ", " + maxYear));
        setEndDate(new Date(ConstantsVar.endingDateOfYear + ", " + maxYear));
      }
    } else if (currentCropYear != "") {
      setendMaxDate(new Date());
      setEndDate(new Date());
    }
  }, [minMaxYear]);

  const receiptFilterData = async (url) => {
    if (IsTokenChecker(tokenId)) {
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const IsBooleanChecker = (bool = null) => {
    try {
      return typeof bool === "boolean";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const SafeFieldChecker = (value = null) => {
    try {
      if (IsBooleanChecker(value)) {
        return value ? "Y" : "N";
      } else if (IsStringChecker(value)) {
        return value;
      } else if (IsNumberChecker(value)) {
        return value;
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const receiptAllFilterData = (resp) => {
    let resultData = _.get(resp, "data", []);
    let filteredData = FilterContractByRole(resultData, accounts, DropDownCBRoles);
    if (
      IsArrayNotEmpty(filteredData) &&
      resp.data !== "Contract and CropYear must be provided"
    ) {
      setFilterItems(filteredData);
      // if (
      //   multiContId["contract_id"] !== undefined &&
      //   multiContId["contract_id"] !== ""
      // ) {
      //   setContractPreselect([]);
      // }
      handlerContract(propContractIds, "normal", filteredData);
    } else {
      setBogNameOption([]);
      setHarvestbedOption([]);
      setVerityOption([]);
      setDisabled(true);
      setHideExport(true);
      setGridItems([]);
      setFilterItems([]);
    }
    setLoader(false);
  };
  // Contract select change event
  const handlerContract = (val, from = "changeEvent", filteredData) => {
    setMultiValue(null);
    // let filter = filterItems.filter((item) => {
    //   return setObjToString(val)?.toLowerCase()?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1;
    // });
    let filter = [];
    if (from === "changeEvent") {
      filter = filterItems.filter((item) => {
        return (
          setObjToString(val)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setSearchBtnDisabled(false);
    } else {
      filter = filteredData.filter((item) => {
        return (
          val
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
    }

    setIsReset(false);
    setDisabled(false);
    setIsListingHide(true);
    setBogNameOption(setOptions(filter, "BogName", "BogName"));
    setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
    setVerityOption(setOptions(filter, "VarietyId", "LegacyVarietyCode","","VarietyDescription"));
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setMultiValue(null);
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      if(multiCrop?.cropyear){
        setIsReset(false);
      } else {
        setIsReset(true);
      }
      setMultiContId({}); /*set null to contract filter */
      setSearchBtnDisabled(true);
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setIsReset(false);
      setDisabled(false);
      setSearchBtnDisabled(false);
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
      setVerityOption(setOptions(filter, "VarietyId", "LegacyVarietyCode","","","VarietyDescription"));
    }
  };

  const fetchReceiptsDataURL = async (apiUrl) => {
    if (IsTokenChecker(tokenId)) {
      setSearchURL(apiUrl);
      setResponseType("submit");
      apiUrl === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const ParameterDataGetter = (reqData = {}) => {
    const MasterData = {
      currentYear: {
        key: "Crop Year",
        value: "",
        index: 0,
      },
      contract_id: {
        key: "Contract Number",
        value: "",
        index: 1,
      },
      bognames: {
        key: "Bog Name",
        value: "",
        index: 2,
      },
      harvestbeds: {
        key: "Bed Name",
        value: "",
        index: 3,
      },
      varietyids: {
        key: "Variety",
        value: "",
        index: 4,
      },
      deliverynumber: {
        key: "Delivery Number/Shipping Memo",
        value: "",
        index: 5,
      },
      datefrom: {
        key: "Date From",
        value: "",
        index: 6,
      },
      dateto: {
        key: "Date To",
        value: "",
        index: 7,
      },
    };
    try {
      const ReturnValueLogic = (param = null) => {
        if (IsStringChecker(param)) {
          const data = _.get(MasterData, param, {});
          const { key, value } = data;
          const reqValue = _.get(reqData, param, null) || value;
          switch (param) {
            case "currentYear":
              return { A: `Parameters: `, B: `${key}: ${reqValue}` };
            default:
              return { B: `${key}: ${reqValue}` };
          }
        } else {
          return { B: `` };
        }
      };

      if (IsObjectChecker(reqData) && IsObjectChecker(MasterData)) {
        const values = Object.values(reqData);
        if (IsArrayNotEmpty(values)) {
          const returnArr = [];
          for (const key in MasterData) {
            const index = _.get(MasterData, `${key}.index`);
            if (IsNumberChecker(index)) {
              _.set(returnArr, index, ReturnValueLogic(key));
            }
          }
          returnArr.push(ReturnValueLogic());
          return returnArr;
        }
        return [];
      }
      return [];
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return [];
    }
  };

  const getCropYear = (crop = {}) => {
    try {
      let qCropyear = ongoingYear;
      if (!crop["cropyear"]) {
        qCropyear = currentCropYear > 0 ? currentCropYear : ongoingYear;
      } else {
        qCropyear = crop["cropyear"];
      }
      return _.orderBy(qCropyear.toString().split(","), [], ["asc"]).toString();
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const getVarieties = (temp = {}) => {
    try {
      const varities = _.get(temp, "varietyids", "").split(",");
      return varities
        .reduce((acc, li) => {
          try {
            const found = verityOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["name"]];
            } else {
              return acc;
            }
          } catch (error) {
            HandleErrorTrackAndToast(error, true);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const getContracts = (temp = {}) => {
    try {
      const contracts = temp["contract_id"].split(",");
      return contracts
        .reduce((acc, li) => {
          try {
            const found = ContractOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["number"]];
            } else {
              return acc;
            }
          } catch (error) {
            HandleErrorTrackAndToast(error, true);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const DateProvider = (dat = null) => {
    try {
      return dat ? moment(dat).format(ConstantsVar.dateFormate) : "";
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const SummaryData = (summaryArr = []) => {
    try {
      if (IsArrayNotEmpty(summaryArr)) {
        let groupByDelivery = _.groupBy(summaryArr, "LoadDeliveryTicketNumber");
        const filtered = filterSummaryData(summaryArr);
        const DeliveryCount = getObjectLength(groupByDelivery);
        const CleanDryWeight = filtered?.CleanDryWeight;
        const DeliveryWeight = filtered?.DeliveryWeight;
        const TotalBarrels = filtered?.TotalBarrels;
        const FirmnessAverage = filtered?.FirmnessAverage;
        const PercentGreaterThanHalf = filtered?.PercentGreaterThanHalf;
        const PercentDryTrash = filtered?.PercentDryTrash;
        const PercentMoist = filtered?.PercentMoist;
        const PercentTotalTrash = filtered?.PercentTotalTrash;
        const PercentPoor = filtered?.PercentPoor;
        const PercentClassOne = filtered?.PercentClassOne;
        const PercentUsable = filtered?.PercentUsable;

        const Avgfirm = getweightedAverage(FirmnessAverage, TotalBarrels);
        const AvgHalf = getweightedAverage(
          PercentGreaterThanHalf,
          TotalBarrels
        );
        const AvgDryTrash = getweightedAverage(PercentDryTrash, DeliveryWeight);
        const AvgMoist = getweightedAverage(PercentMoist, DeliveryWeight);
        const AvgTrash = getweightedAverage(PercentTotalTrash, DeliveryWeight);
        const AvgPoor = getweightedAverage(PercentPoor, CleanDryWeight);
        const AvgClassOne = getweightedAverage(PercentClassOne, CleanDryWeight);
        const AvgUsable = getweightedAverage(PercentUsable, DeliveryWeight);
        return {
          DeliveryCount: NumConverter(DeliveryCount),
          DeliveryWeight: Math.round(sumArrayValue(DeliveryWeight)),
          TotalBarrels: sumArrayValue(TotalBarrels),
          Avgfirm: NumConverter(Avgfirm),
          AvgHalf: NumConverter(AvgHalf),
          AvgDryTrash: NumConverter(AvgDryTrash),
          AvgMoist: NumConverter(AvgMoist),
          AvgTrash: NumConverter(AvgTrash),
          AvgPoor: NumConverter(AvgPoor),
          AvgClassOne: NumConverter(AvgClassOne),
          AvgUsable: NumConverter(AvgUsable),
        };
      } else {
        return {
          DeliveryCount: 0,
          DeliveryWeight: 0,
          TotalBarrels: 0,
          Avgfirm: 0,
          AvgHalf: 0,
          AvgDryTrash: 0,
          AvgMoist: 0,
          AvgTrash: 0,
          AvgPoor: 0,
          AvgClassOne: 0,
          AvgUsable: 0,
        };
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {
        DeliveryCount: 0,
        DeliveryWeight: 0,
        TotalBarrels: 0,
        Avgfirm: 0,
        AvgHalf: 0,
        AvgDryTrash: 0,
        AvgMoist: 0,
        AvgTrash: 0,
        AvgPoor: 0,
        AvgClassOne: 0,
        AvgUsable: 0,
      };
    }
  };

  const fetchReceiptsData = async (resp) => {
    let testData = _.get(resp, "data", []);
    let filterData = MapContractsWithRole(testData, accounts, [
      ...DropDownCBRoles,
      PaymentRole,
    ]);
    // Filter values start
    if (multiValue?.bognames) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.bognames
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.BogName?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.harvestbeds) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.harvestbeds
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.HarvestBed?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.varietyids) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.varietyids
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.VarietyId?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (inputDelivery !== "") {
      filterData = filterData.filter((item) => {
        return (
          item?.LoadDeliveryTicketNumber?.toString()
            ?.toLowerCase()
            .indexOf(inputDelivery?.toString()?.toLowerCase()) > -1
        );
        // return (
        //   inputDelivery?.toString()?.toLowerCase() ===
        //   item?.LoadDeliveryTicketNumber?.toString()?.toLowerCase()
        // );
      });
    }
    if (startDate || endDate) {
      filterData = filterData.filter((item) => {
        let dateFrom =
          startDate && moment(startDate).format(ConstantsVar.dateFormate);
        let dateto =
          endDate && moment(endDate).format(ConstantsVar.dateFormate);
        let IncomingTime = moment(item.IncomingTime).format(
          ConstantsVar.dateFormate
        );
        return moment(IncomingTime).isBetween(
          dateFrom,
          dateto,
          undefined,
          "[]"
        );
      });
    }
    let resultData = [...filterData];
    // Filter values End
    const completedData = _.filter(
      resultData,
      (obj) =>
        compareString(obj?.ReceiptStatus, "Released") ||
        compareString(obj?.ReceiptStatus, "Inventoried") ||
        compareString(obj?.ReceiptStatus, "Paid")
    );
    let sortedData = _.groupBy(completedData, "ProcessedFreshType");
    const processedArr = sortedData?.Processed;
    const freshArr = sortedData?.Fresh;
    const summaryProcess = SummaryData(processedArr);
    const summaryFresh = SummaryData(freshArr);
    const summaryTotalData = GetSummaryTotal(summaryProcess, summaryFresh);
    setsummaryTotal(summaryTotalData);

    const DisplaySummaryProcess = {
      proDeliveryCount: toDecimal(
        Math.round(NumConverter(summaryProcess?.DeliveryCount)),
        0,
        "",
        0
      ),
      proDeliveryWeight: toDecimal(
        Math.round(NumConverter(summaryProcess?.DeliveryWeight)),
        0,
        "",
        0
      ),
      proTotalBarrels: toDecimal(
        NumConverter(summaryProcess?.TotalBarrels)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgfirm: toDecimal(
        NumConverter(summaryProcess?.Avgfirm, "round"),
        0,
        "",
        `0`
      ),
      proAvgHalf: toDecimal(
        NumConverter(summaryProcess?.AvgHalf)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgDryTrash: toDecimal(
        NumConverter(summaryProcess?.AvgDryTrash)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgMoist: toDecimal(
        NumConverter(summaryProcess?.AvgMoist)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgTrash: toDecimal(
        NumConverter(summaryProcess?.AvgTrash)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgPoor: toDecimal(
        NumConverter(summaryProcess?.AvgPoor)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgClassOne: toDecimal(
        NumConverter(summaryProcess?.AvgClassOne)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      proAvgUsable: toDecimal(
        NumConverter(summaryProcess?.AvgUsable)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
    };
    setsummaryProcess(DisplaySummaryProcess);
    const DisplaySummaryFresh = {
      freshDeliveryCount: toDecimal(
        Math.round(NumConverter(summaryFresh?.DeliveryCount)),
        0,
        "",
        0
      ),
      freshDeliveryWeight: toDecimal(
        Math.round(NumConverter(summaryFresh?.DeliveryWeight)),
        0,
        "",
        0
      ),
      freshTotalBarrels: toDecimal(
        NumConverter(summaryFresh?.TotalBarrels)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgfirm: toDecimal(
        NumConverter(summaryFresh?.Avgfirm)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgHalf: toDecimal(
        NumConverter(summaryFresh?.AvgHalf)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgDryTrash: toDecimal(
        NumConverter(summaryFresh?.AvgDryTrash)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgMoist: toDecimal(
        NumConverter(summaryFresh?.AvgMoist)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgTrash: toDecimal(
        NumConverter(summaryFresh?.AvgTrash)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgPoor: toDecimal(
        NumConverter(summaryFresh?.AvgPoor)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgClassOne: toDecimal(
        NumConverter(summaryFresh?.AvgClassOne)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      freshAvgUsable: toDecimal(
        NumConverter(summaryFresh?.AvgUsable)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
    };
    setsummaryFresh(DisplaySummaryFresh);
    // console.log(summaryFresh, summaryProcess, summaryTotalData);

    let sortByDay = _.orderBy(completedData, ["IncomingTime"], ["desc"]);
    sortByDay = sortByDay.map((element) => {
      element.Time_In_IncomingTime = element.IncomingTime;
      element.DataIdValue = `${element.Id}-${element.DetailId}`;
      return element;
    });
    //(item.DeliveryWeight!=null && )
    const overWirteMobData = Object.values(sortByDay).map((item) => {
      try {
        const perc = NumConverter(item?.Percentage);
        return {
          "Facility_#": item?.LocationCode,
          "Delivery_# - Shipping_Memo": item?.LoadDeliveryTicketNumber,
          Bed: item?.HarvestBed,
          "Bed%": perc > 0 ? `${perc}%` : 0,
          Date_In: item?.IncomingTime
            ? moment.utc(item?.IncomingTime).format(ConstantsVar.dateFormate)
            : "-",
          Time_In: item?.IncomingTime
            ? moment.utc(item?.IncomingTime).format(ConstantsVar.timeFormate)
            : "-",
          "Fresh/Process": remainFirstCharacter(item?.ProcessedFreshType),
          "Contract_#": item?.RelationshipNumber + " & " + item?.Name,
          Variety: item?.LegacyVarietyCode || "-",
          Delivery_Weight: toDecimal(
            Math.round(NumConverter(item?.DeliveryWeight)),
            0,
            "",
            0
          ),
          Total_Barrels: toDecimal(
            NumConverter(item?.TotalBarrels)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          Firmness: toDecimal(
            Math.round(NumConverter(item?.FirmnessAverage)),
            0,
            "",
            0
          ),
          "%_GreaterThanHalf": toDecimal(
            NumConverter(item?.PercentGreaterThanHalf)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Dry_Trash": toDecimal(
            NumConverter(item?.PercentDryTrash)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Moisture": toDecimal(
            NumConverter(item?.PercentMoist)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Total_Trash": toDecimal(
            NumConverter(item?.PercentTotalTrash)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Poor": toDecimal(
            NumConverter(item?.PercentPoor)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Class1": toDecimal(
            NumConverter(item?.PercentClassOne)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          "%_Usable": toDecimal(
            NumConverter(item?.PercentUsable)?.toFixed(2),
            2,
            "",
            "0.00"
          ),
          Photo: item?.PhotoLink || "-",
        };
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return {};
      }
    });
    const exportPDf = [];
    const exportData = Object.values(sortByDay).map((item) => {
      const {
        AssignedRoles = {},
        CropYear,
        LocationCode,
        LoadDeliveryTicketNumberNoMemo,
        HarvestBed,
        Percentage,
        IncomingTime,
        OutgoingTime,
        ReceiptIdentifier,
        ReceiptStatus,
        ProcessedFreshType,
        DowngradedFresh,
        HasIncident,
        RelationshipNumber,
        Name,
        Name2,
        Pool,
        Region,
        StateAbbreviation,
        LegacyVarietyCode,
        ChaffScreenedType,
        WetDry,
        StandardPie,
        GrossWeight,
        TareWeight,
        NetWeight,
        ContainerWeightSum,
        DeliveryWeight,
        UsableBarrels,
        ShrinkBarrels,
        TotalBarrels,
        ExportIncentive,
        WhiteIncentive,
        EarlySequencedIncentive,
        LateSequencedIncentive,
        GlobalGap,
        Organic,
        DomesticAssessment,
        Carrier,
        ShippingMemo,
        DropTrailer,
        SampleWeight,
        DryWeight,
        CleanDryWeight,
        CupWeight,
        PoorWeight,
        Brix,
        Tacy,
        PercentDryTrash,
        PercentMoist,
        PercentTotalTrash,
        PercentPoor,
        PercentUsable,
        ScreeningCharge,
        TrashCharge,
        TruckingAllowance,
        TestDate,
        TestedBy,
        TestBox,
        KeepingQualityAtReceipt,
        KeepingQualityThreeWeek,
        KeepingQualitySixWeek,
        LoadInfoComments,
        LabTestComments,
        PoorCode,
        UncoloredWeight,
        FirmnessAverage,
        HighContaminatedAssessment,
        HighContaminatedHandlingFeeAssessment,
        HighContaminatedCalcBarrelAdjustment,
        ColorTacyIncentive,
        FirmnessAverageIncentive,
        OrganicIncentive,
        GlobalGapFreshIncentive,
        ColorAssessmentNoOffsets,
        PercentGreaterThanHalf,
        SizePremiumIncentive,
        OnHold,
        ClassOne,
        ClassTwo,
        ClassThree,
        ClassFour,
        ClassFive,
        PercentClassOne,
      } = item;
      const PaymentRoleExist = _.get(AssignedRoles, PaymentRole, false);
      const DecissionMakerRoleExist = _.get(AssignedRoles, DecisionMaker, false);
      const returnExport = {
        "Crop Year": SafeFieldChecker(CropYear), //0
        Facility: SafeFieldChecker(LocationCode),
        "Delivery Number": SafeFieldChecker(LoadDeliveryTicketNumberNoMemo),
        Bed: SafeFieldChecker(HarvestBed),
        Percentage: SafeFieldChecker(Percentage),
        "Date In": IncomingTime
          ? moment.utc(IncomingTime).format(ConstantsVar.dateFormate)
          : "",
        "Time In": IncomingTime
          ? moment.utc(IncomingTime).format(ConstantsVar.timeFormate)
          : "",
        "Time Out": OutgoingTime
          ? moment.utc(OutgoingTime).format(ConstantsVar.timeFormate)
          : "",
        "Receipt Identifier": SafeFieldChecker(ReceiptIdentifier),
        Status: SafeFieldChecker(ReceiptStatus),
        "Fresh Process": remainFirstCharacter(
          SafeFieldChecker(ProcessedFreshType)
        ), //10
        Downgraded: SafeFieldChecker(DowngradedFresh),
        Incident: SafeFieldChecker(HasIncident),
        "Contract Number": SafeFieldChecker(RelationshipNumber),
        "Contract Name": SafeFieldChecker(Name),
        "Contract Name2": SafeFieldChecker(Name2),
        Pool: SafeFieldChecker(Pool),
        Region: SafeFieldChecker(Region),
        State: SafeFieldChecker(StateAbbreviation),
        Variety: SafeFieldChecker(LegacyVarietyCode),
        "Chaff Screen": SafeFieldChecker(ChaffScreenedType),//20
        "Wet Dry": SafeFieldChecker(WetDry),
        "Standard Pie": SafeFieldChecker(StandardPie),
        "Gross Weight": Math.round(NumConverter(GrossWeight)),
        "Tare Weight": Math.round(NumConverter(TareWeight)),
        "Net Weight": Math.round(NumConverter(NetWeight)),
        "Container Weight Sum":Math.round(NumConverter(ContainerWeightSum)),
        "Delivery Weight": NumConverter(DeliveryWeight),
        "Usable Barrels": NumConverter(UsableBarrels),
        "Shrink Barrels": NumConverter(ShrinkBarrels),
        "Total Barrels": NumConverter(TotalBarrels), //30
        "Export Incentive": ExportIncentive ? "Y" : "N", 
        "White Incentive": WhiteIncentive ? "Y" : "N",
        "Early Sequence Incentive": EarlySequencedIncentive ? "Y" : "N",
        "Late Sequence Incentive": LateSequencedIncentive ? "Y" : "N",
        "Global Gap": SafeFieldChecker(GlobalGap),
        Organic: SafeFieldChecker(Organic),
        "Domestic Assessment": PaymentRoleExist || DecissionMakerRoleExist ? DomesticAssessment !== null ? DomesticAssessment : null : "", 
        Carrier: SafeFieldChecker(Carrier),
        "Shipping Memo": SafeFieldChecker(ShippingMemo),
        "Drop Trailer": SafeFieldChecker(DropTrailer), //40
        "Delivery Pool": "n/a",
        "Sample Weight": Math.round(NumConverter(SampleWeight)),
        "Dry Weight": Math.round(NumConverter(DryWeight)),
        "Clean Dry Weight": Math.round(NumConverter(CleanDryWeight)),
        "Cup Weight": Math.round(NumConverter(CupWeight)),
        "Poor Weight": NumConverter(PoorWeight),
        Brix: NumConverter(Brix) || null,
        Tacy: NumConverter(Tacy) || null,
        "% Dry Trash": NumConverter(PercentDryTrash),
        "% Moist": NumConverter(PercentMoist),//50
        "% Total Trash": NumConverter(PercentTotalTrash),
        "% Poor": NumConverter(PercentPoor),
        "% Usable": NumConverter(PercentUsable),
        "Screen Charge": PaymentRoleExist || DecissionMakerRoleExist
          ? ScreeningCharge !== null ? NumConverter(ScreeningCharge) : null
          : "",
        "Trash Charge": PaymentRoleExist || DecissionMakerRoleExist
          ? TrashCharge !== null ? NumConverter(TrashCharge) : null
          : "",
        "Trucking Allowance": PaymentRoleExist || DecissionMakerRoleExist
          ? TruckingAllowance !== null ? NumConverter(TruckingAllowance) : null
          : "",
        "Test Date": TestDate
          ? moment.utc(TestDate).format(ConstantsVar.exportdateTimeFormate)
          : "",
        "Test Time": TestDate
          ? moment.utc(TestDate).format(ConstantsVar.timeFormate)
          : "",
        "Test Tech": SafeFieldChecker(TestedBy),
        "Test Box": SafeFieldChecker(TestBox), //60
        "Keeping Quality At Receipt": SafeFieldChecker(KeepingQualityAtReceipt),
        "Keeping Quality At 3 Week": SafeFieldChecker(KeepingQualityThreeWeek),
        "Keeping Quality Late": SafeFieldChecker(KeepingQualitySixWeek),
        "Load Comment": SafeFieldChecker(LoadInfoComments),
        "Lab Test Comment": SafeFieldChecker(LabTestComments),
        "Poor Code": SafeFieldChecker(PoorCode),
        "Firmness Average": Math.round(NumConverter(FirmnessAverage)),
        "Percent Uncolored": SafeFieldChecker(UncoloredWeight),
        "High Contaminated Assessment": SafeFieldChecker(
          HighContaminatedAssessment
        ),
        "High Contaminated Handling Fee Assessment": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(HighContaminatedHandlingFeeAssessment)
          : "", //70
        "High Contaminated Calculated Barrel Adjustment": SafeFieldChecker(
          HighContaminatedCalcBarrelAdjustment
        ),
        "Color Tacy Incentive": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(ColorTacyIncentive)
          : "",
        "Firmness Average Incentive": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(FirmnessAverageIncentive)
          : "",
        "Early Sequence Incentive Amount": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(EarlySequencedIncentive)
          : "",
        "Late Sequence Incentive Amount": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(LateSequencedIncentive)
          : "",
        "Organic Incentive": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(OrganicIncentive)
          : "",
        "Global Gap Fresh Incentive": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(GlobalGapFreshIncentive)
          : "",
        "Domestic Assessment Amount": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(DomesticAssessment)
          : "",
        "Color Assessment No Offsets": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(ColorAssessmentNoOffsets)
          : "",
        "Percent Greater Than Half": NumConverter(PercentGreaterThanHalf), //80
        "Size Premium Incentive": PaymentRoleExist || DecissionMakerRoleExist
          ? SafeFieldChecker(SizePremiumIncentive)
          : "",
        "On Hold": SafeFieldChecker(OnHold),
        ClassOne: NumConverter(ClassOne?.toFixed(2)),
        ClassTwo: NumConverter(ClassTwo?.toFixed(2)),
        ClassThree: NumConverter(ClassThree?.toFixed(2)),
        ClassFour: NumConverter(ClassFour?.toFixed(2)),
        ClassFive: NumConverter(ClassFive?.toFixed(2)),
        "Percent Class One": NumConverter(PercentClassOne?.toFixed(2)),
      };
      const returnPDFExport = {
        Facility: SafeFieldChecker(LocationCode),
        "Delivery #": SafeFieldChecker(LoadDeliveryTicketNumberNoMemo),
        Bed: SafeFieldChecker(HarvestBed),
        "Date In": IncomingTime
          ? moment.utc(IncomingTime).format(ConstantsVar.dateFormate)
          : "",
        "Time In": IncomingTime
          ? moment.utc(IncomingTime).format(ConstantsVar.timeFormate)
          : "",
        "F/P": remainFirstCharacter(
          SafeFieldChecker(ProcessedFreshType)
        ),
        "Contract #": SafeFieldChecker(RelationshipNumber),
        Var: SafeFieldChecker(LegacyVarietyCode),
        "Delivery Weight": toDecimal(
          Math.round(NumConverter(DeliveryWeight)),
          0,
          "",
          0
        ),
        "Total Barrels": toDecimal(
          NumConverter(TotalBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        Tacy: toDecimal(NumConverter(Tacy)?.toFixed(4), 4, "", "-"),
        "Firm": toDecimal(
          Math.round(NumConverter(FirmnessAverage)),
          0,
          "",
          0
        ),
        "% > 1/2": toDecimal(
          NumConverter(PercentGreaterThanHalf)?.toFixed(2),
          2,
          "",
          0
        ),
        "% Class1": toDecimal(
          NumConverter(PercentClassOne)?.toFixed(2),
          2,
          "",
          0
        ),
        "% Dry Trash": toDecimal(
          NumConverter(PercentDryTrash)?.toFixed(4),
          4,
          "",
          0
        ),
        "% Moist": toDecimal(NumConverter(PercentMoist)?.toFixed(4), 4, "", 0),
        "% Total Trash": toDecimal(
          NumConverter(PercentTotalTrash)?.toFixed(4),
          4,
          "",
          0
        ),
        "% Poor": toDecimal(NumConverter(PercentPoor)?.toFixed(4), 4, "", 0),
        "% Usable": toDecimal(
          NumConverter(PercentUsable)?.toFixed(4),
          4,
          "",
          0
        )
      };
      if (!roleCBPayment && !roleCBDecisionMaker) {
        delete returnExport["Domestic Assessment"];
        delete returnExport["Screen Charge"];
        delete returnExport["Trash Charge"];
        delete returnExport["Trucking Allowance"];
        delete returnExport["High Contaminated Handling Fee Assessment"];
        delete returnExport["Color Tacy Incentive"];
        delete returnExport["Firmness Average Incentive"];
        delete returnExport["Early Sequence Incentive Amount"];
        delete returnExport["Late Sequence Incentive Amount"];
        delete returnExport["Size Premium Incentive"];
        delete returnExport["Organic Incentive"];
        delete returnExport["Global Gap Fresh Incentive"];
        delete returnExport["Domestic Assessment Amount"];
        delete returnExport["Color Assessment No Offsets"];
        delete returnPDFExport["Domestic Assessment"];
        delete returnPDFExport["Screen Charge"];
        delete returnPDFExport["Trash Charge"];
        delete returnPDFExport["Trucking Allowance"];
        delete returnPDFExport["High Contaminated Handling Fee Assessment"];
        delete returnPDFExport["Color Tacy Incentive"];
        delete returnPDFExport["Firmness Average Incentive"];
        delete returnPDFExport["Early Sequence Incentive Amount"];
        delete returnPDFExport["Late Sequence Incentive Amount"];
        delete returnPDFExport["Size Premium Incentive"];
        delete returnPDFExport["Organic Incentive"];
        delete returnPDFExport["Global Gap Fresh Incentive"];
        delete returnPDFExport["Domestic Assessment Amount"];
        delete returnPDFExport["Color Assessment No Offsets"];
      }
      exportPDf.push(returnPDFExport);
      return returnExport;      
    });
    setPDFExport(exportPDf);    
    setExportItems(exportData);
    let format = [
      {
        index:23,
        format:'#,##0_);\(#,##0\)'
      },
      {
        index:24,
        format:'#,##0_);\(#,##0\)'
      },
      {
        index:25,
        format:'#,##0_);\(#,##0\)'
      },
      {
        index:26,
        format:'#,##0_);\(#,##0\)'
      },
      {
        index:27,
        format:'#,##0_);\(#,##0\)'
      },
      {
        index:28,
        format:'#,##0.0000_);\(#,##0.0000\)'
      },
      {
        index:29,
        format:'#,##0.0000_);\(#,##0.0000\)'
      },
      {
        index:30,
        format:'#,##0.0000_);\(#,##0.0000\)'
      }
    ]
    if (!roleCBPayment && !roleCBDecisionMaker){
      format.push(...[
        {
          index:41,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:42,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:43,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:44,
          format:'#,##0_);\(#,##0\)'
        },

        {
          index:45,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:46,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:47,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:48,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:49,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:50,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:51,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:52,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:57,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:58,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:59,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:63,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:64,
          format:'#,##0.0000_);\(#,##0.0000_\)'
        },
        {
          index:67,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:69,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:70,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:71,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:72,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:73,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:74,
          format:'#,##0.00_);\(#,##0.00\)'
        }
      ])
    }
    else{
      format.push(...[
        {
          index:37,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:42,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:43,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:44,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:45,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:46,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:47,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:48,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:49,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:50,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:51,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:52,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:53,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:54,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:55,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:56,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:61,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:62,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:63,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:67,
          format:'#,##0_);\(#,##0\)'
        },
        {
          index:68,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:70,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:72,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:73,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:74,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:75,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:76,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:77,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:78,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:79,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:80,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:81,
          format:'#,##0.0000_);\(#,##0.0000\)'
        },
        {
          index:83,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:84,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:85,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:86,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:87,
          format:'#,##0.00_);\(#,##0.00\)'
        },
        {
          index:88,
          format:'#,##0.00_);\(#,##0.00\)'
        }
      ])
    }
    setFormatArray(format)
    const reqData = {
      contract_id: getContracts(multiContId),
      currentYear: getCropYear(multiCrop),
      bognames: multiValue?.bognames,
      harvestbeds: multiValue?.harvestbeds,
      varietyids: getVarieties(multiValue),
      deliverynumber: inputDelivery,
      datefrom: DateProvider(startDate),
      dateto: DateProvider(endDate),
    };
    setExportParameter(ParameterDataGetter(reqData));

    setResultItems(overWirteMobData);
    setGridItems(sortByDay);
    completedData.length > 0 ? setHideExport(false) : setHideExport(true);
    completedData.length > 0 ? setIsListingHide(false) : setIsListingHide(true);
    completedData.length > 0
      ? setNoRecordErrorMsg("")
      : setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
    setLoader(false);
    setLoading(false); /*remove loader from search button*/
    setSearchBtnDisabled(false); /*disabled search button*/
    setisMobFilter(false);
  };

  const handlerCropYear = (val, selectedItem, type) => {
    setMultiValue(null);
    if(val.length === 0){
      return;
    } 
    let cropyears = "";
    const cropIds = val.map((x) => x.id);
    const maxYear = Math.max(...cropIds);
    const minYear = Math.min(...cropIds);
    setMinMaxYear({ maxYear: maxYear, minYear: minYear });
    setIsListingHide(true);

    if (type === "selected") {
      setDisablePreSelected(false);
      setPreSelectedArray([]);
      setDisableContractField(false);
    }
    selectedItem;
    if (val.length > 1) {
      if (val.length == 15 && type === "deSelected") {
        setMinMaxYear({ maxYear: currentCropYear, minYear: currentCropYear });
        cropyears = currentCropYear;
        setReloadCropyear(false);
        setTimeout(() => {
          // setPreSelectedArray([currentCropYear]);
          setDisableContractField(false);
          setDisablePreSelected(true);
          setReloadCropyear(true);
        }, 0);
      } else {
        cropyears = setObjToString(val);
        setDisablePreSelected(false);
        setDisableContractField(false);
      }
    } else if (val.length == 1) {
      cropyears = setObjToString(val);
      setReloadCropyear(false);
      setTimeout(() => {
        setPreSelectedArray([maxYear]);
        setDisableContractField(false);
        setDisablePreSelected(true);
        setReloadCropyear(true);
      }, 0);
    }
    if(multiContId?.contract_id){
      setSearchBtnDisabled(false);
    } else {
      setSearchBtnDisabled(true);
    }
    if (cropyears !== "") {
      const url = `deliveries/by-receipt?cropyear=${_.orderBy(
        cropyears.split(","),
        [],
        ["asc"]
      ).toString()}&contractid=${_.orderBy(
        ContractIds.split(","),
        [],
        ["asc"]
      ).toString()}&filtersonly=1&status=3402,3403,3404`;
      setResponseType("withoutSubmit");
      receiptFilterData(url);
    }
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    if (action == "selected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
      setNoRecordErrorMsg("");
    } else if (action == "deselected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
    }
    const searchParam = {
      Contract_id: ["RelationshipId"],
      Bog_name: ["BogName", "BogName"],
      Harvest_bed: ["HarvestBed", "HarvestBed"],
      Varity_id: ["VarietyId", "VarietyDescription"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setHarvestbedOption,
      setVerityOption,
    });
  };

  const handlerSubmit = () => {
    setIsListingHide(true);
    setGridItems([]); /*set empty to grid Item */
    // const arrayQueryString = [];
    // multiValue &&
    //   Object.keys(multiValue).map((i, index) => {
    //     multiValue[i] != ""
    //       ? (arrayQueryString[index] = `${i}=${multiValue[i]}`)
    //       : "";
    //   });

    // let QueryString =
    //   arrayQueryString.length > 0 ? "&" + arrayQueryString.join("&") : "";
    // QueryString = `${QueryString}&contractid=${multiContId["contract_id"]}`;//894

    let qCropyear = ongoingYear;
    if (!multiCrop["cropyear"]) {
      qCropyear = currentCropYear > 0 ? currentCropYear : ongoingYear;
    } else {
      qCropyear = multiCrop["cropyear"];
    }
    const apiUrl = `deliveries/by-receipt?cropyear=${_.orderBy(
      qCropyear.toString().split(","),
      [],
      ["asc"]
    ).toString()}&contractid=${_.orderBy(
      multiContId["contract_id"].split(","),
      [],
      ["asc"]
    ).toString()}&status=3402,3403,3404`;
    fetchReceiptsDataURL(apiUrl);
  };

  const handlerInput = (e) => {
    setMultiValue((multiValue) => ({
      ...multiValue,
      ["deliverynumber"]: e.target.value,
    }));
    setInputDelivery(e.target.value);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setMultiValue(null); /*set null to all filter */
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    setHideExport(true);
    setStartDate();
    setEndDate();
    setInputDelivery("");
    setsummaryProcess({});
    setsummaryFresh({});
    setsummaryTotal({});
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    /*set default year, after reset*/
    setPreSelectedArray([]);
    setDisableContractField(true);
    setContractPreselect([]);
    setDisablePreSelected(true);
    setMultiCrop(null);
    setMultiContId(null); /*set null to contract filter */
    setMinMaxYear({ maxYear: "", minYear: "" });
  };

  const handlerDate = (param, type) => {
    let startFulldate = "";
    let endFulldate = "";
    if (type == "start") {
      setendMinDate(param);
      setStartDate(param);
      startFulldate = getFullDate(param, "yyyy-mm-dd");
      endFulldate = getFullDate(endDate, "yyyy-mm-dd");
    } else if (type == "end") {
      setEndDate(param);
      startFulldate = getFullDate(startDate, "yyyy-mm-dd");
      endFulldate = getFullDate(param, "yyyy-mm-dd");
    }
    setMultiValue((multiValue) => ({
      ...multiValue,
      ["datefrom"]: startFulldate,
      ["dateto"]: endFulldate,
    }));
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const GetSummaryTotal = (aSummary = {}, bSummary = {}) => {
    const {
      DeliveryCount: aDeliveryCount = 0,
      DeliveryWeight: aDeliveryWeight = 0,
      TotalBarrels: aTotalBarrels = 0,
      Avgfirm: aAvgfirm = 0,
      AvgHalf: aAvgHalf = 0,
      AvgDryTrash: aAvgDryTrash = 0,
      AvgMoist: aAvgMoist = 0,
      AvgTrash: aAvgTrash = 0,
      AvgPoor: aAvgPoor = 0,
      AvgClassOne: aAvgClassOne = 0,
      AvgUsable: aAvgUsable = 0,
    } = aSummary;
    const {
      DeliveryCount: bDeliveryCount = 0,
      DeliveryWeight: bDeliveryWeight = 0,
      TotalBarrels: bTotalBarrels = 0,
      Avgfirm: bAvgfirm = 0,
      AvgHalf: bAvgHalf = 0,
      AvgDryTrash: bAvgDryTrash = 0,
      AvgMoist: bAvgMoist = 0,
      AvgTrash: bAvgTrash = 0,
      AvgPoor: bAvgPoor = 0,
      AvgClassOne: bAvgClassOne = 0,
      AvgUsable: bAvgUsable = 0,
    } = bSummary;
    const sumTwoOperands = (num1, num2) => {
      return NumConverter(num1) + NumConverter(num2);
    };
    return {
      totalDeliveryCount: toDecimal(
        Math.round(sumTwoOperands(aDeliveryCount, bDeliveryCount)),
        0,
        "",
        0
      ),
      totalDeliveryWeight: toDecimal(
        Math.round(sumTwoOperands(aDeliveryWeight, bDeliveryWeight)),
        0,
        "",
        0
      ),
      totalTotalBarrels: toDecimal(
        sumTwoOperands(aTotalBarrels, bTotalBarrels)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      totalAvgfirm: toDecimal(
        sumTwoOperands(aAvgfirm, bAvgfirm)?.toFixed(2),
        2,
        "",
        `0.00`
      ),
      totalAvgHalf: toDecimal(
        sumTwoOperands(aAvgHalf, bAvgHalf)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgDryTrash: toDecimal(
        sumTwoOperands(aAvgDryTrash, bAvgDryTrash)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgMoist: toDecimal(
        sumTwoOperands(aAvgMoist, bAvgMoist)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgTrash: toDecimal(
        sumTwoOperands(aAvgTrash, bAvgTrash)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgPoor: toDecimal(
        sumTwoOperands(aAvgPoor, bAvgPoor)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgClassOne: toDecimal(
        sumTwoOperands(aAvgClassOne, bAvgClassOne)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
      totalAvgUsable: toDecimal(
        sumTwoOperands(aAvgUsable, bAvgUsable)?.toFixed(2),
        2,
        "",
        "0.00"
      ),
    };
  };

  const {
    proDeliveryCount = 0,
    proDeliveryWeight = 0,
    proTotalBarrels = `0.00`,
    proAvgfirm = `0.00`,
    proAvgHalf = `0.00`,
    proAvgDryTrash = `0.00`,
    proAvgMoist = `0.00`,
    proAvgTrash = `0.00`,
    proAvgPoor = `0.00`,
    proAvgClassOne = `0.00`,
    proAvgUsable = `0.00`,
  } = summaryProcess;

  const {
    freshDeliveryCount = 0,
    freshDeliveryWeight = 0,
    freshTotalBarrels = `0.00`,
    // freshAvgfirm = `0.00`,
    // freshAvgHalf = `0.00`,
    // freshAvgDryTrash = `0.00`,
    // freshAvgMoist = `0.00`,
    // freshAvgTrash = `0.00`,
    // freshAvgPoor = `0.00`,
    // freshAvgClassOne = `0.00`,
    // freshAvgUsable = `0.00`,
  } = summaryFresh;

  const {
    totalDeliveryCount = 0,
    totalDeliveryWeight = 0,
    totalTotalBarrels = `0.00`,
    // totalAvgfirm = `0.00`,
    // totalAvgHalf = `0.00`,
    // totalAvgDryTrash = `0.00`,
    // totalAvgMoist = `0.00`,
    // totalAvgTrash = `0.00`,
    // totalAvgPoor = `0.00`,
    // totalAvgClassOne = `0.00`,
    // totalAvgUsable = `0.00`,
  } = summaryTotal;

  const showSummary =
    IsObjectChecker(summaryFresh) &&
    IsObjectChecker(summaryProcess) &&
    !_.isEmpty(summaryFresh) &&
    !_.isEmpty(summaryProcess);

  const summaryHeaderPDF = showSummary ? [`Type`,
  `Completed Delivery Count`,
  `Sum of Delivery Weight`,
  `Sum of Total Barrels`,
  `Wtd. Avg Firmness`,
  `Wtd. Avg % > 1/2`,
  `Wtd. Avg % Dry Trash`,
  `Wtd. Avg % Moist`,
  `Wtd. Avg % Total Trash`,
  `Wtd. Avg % Poor`,
  `Wtd. Avg % Class1`,
  `Wtd. Avg % Usable`] : [];
  const summaryContentPDF = showSummary ? [
    [
      "Processed",
      proDeliveryCount || 0,
      proDeliveryWeight || 0,
      proTotalBarrels || `0.00`,
      proAvgfirm || `0.00`,
      proAvgHalf || `0.00`,
      proAvgDryTrash || `0.00`,
      proAvgMoist || `0.00`,
      proAvgTrash || `0.00`,
      proAvgPoor || `0.00`,
      proAvgClassOne || `0.00`,
      proAvgUsable || `0.00`,
    ],
    [
      "Fresh",
      freshDeliveryCount || 0,
      freshDeliveryWeight || 0,
      freshTotalBarrels || `0.00`,
      "-" || freshAvgfirm || `0.00`,
      "-" || freshAvgHalf || `0.00`,
      "-" || freshAvgDryTrash || `0.00`,
      "-" || freshAvgMoist || `0.00`,
      "-" || freshAvgTrash || `0.00`,
      "-" || freshAvgPoor || `0.00`,
      "-" || freshAvgClassOne || `0.00`,
      "-" || freshAvgUsable || `0.00`,
    ],
    [
      "Total",
      totalDeliveryCount || 0,
      totalDeliveryWeight || 0,
      totalTotalBarrels || `0.00`,
      "-" || totalAvgfirm || `0.00`,
      "-" || totalAvgHalf || `0.00`,
      "-" || totalAvgDryTrash || `0.00`,
      "-" || totalAvgMoist || `0.00`,
      "-" || totalAvgTrash || `0.00`,
      "-" || totalAvgPoor || `0.00`,
      "-" || totalAvgClassOne || `0.00`,
      "-" || totalAvgUsable || `0.00`,
    ]
  ] : [];
  return (
    <>
      {/*<!-- Receipts tab starts -->*/}
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show " : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? " active " : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap2"
          aria-controls="selectWrap2"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"></span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? " show " : ""
          }`}
          id="selectWrap2"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown mandatoryField mandatoryField-pad-10">
                {/* <span className="mandatory">*</span> */}
                {reloadCropyear && (
                  <MultiDropDown
                    optionList={listCropYear}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val, selectedItem) =>
                      handlerCropYear(val, selectedItem, "selected")
                    }
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, removedItem) =>
                      handlerCropYear(val, removedItem, "deSelected")
                    }
                    preSelectedOpt={preSelectedArray}
                    // disablePreSelected={disablePreSelected}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {reloadcontract && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled={disableContractField}
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    optionNotFound={{ contract_id: noContractsFound }}
                    preSelectedOpt={contractPreselect}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- <span>Bog Name</span> -->*/}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="harvest_bed custom_dropdown">
                {/*<!-- <span>harvestbed</span> -->*/}
                <MultiDropDown
                  optionList={harvestbedOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "harvestbed"
                    )
                  }
                  label="harvestbeds"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "harvestbed"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- <span>variety</span> -->*/}
                <MultiDropDown
                  showTitle = {true}
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  label="varietyids"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="delivery_shipping custom_dropdown">
                <span className="d-none d-lg-block">
                  Delivery # or Shipping Memo
                </span>
                <span className="d-block d-lg-none p-2 p-lg-0 fw-normal text-16">
                  {" "}
                  Delivery # or Shipping Memo:{" "}
                  <a className="fw-bold radio_box grey_text_color"></a>{" "}
                  <i className="fa fa-chevron-down p-2 px-1 blue_color position-relative float-end text-14 l_2"></i>
                </span>

                <div className="ps-4 ms-2 ps-lg-0 ms-lg-0 p-3 pt-0 p-lg-0 d-lg-block my_delivery_data_radio_button">
                  <input
                    type="text"
                    value={inputDelivery}
                    name="delivery_num"
                    placeholder=""
                    id="delivery_shipping_number"
                    disabled={disabled}
                    className="delivery_mob_no"
                    onChange={handlerInput}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_from">
                {/* <input type="text" className="datepicker_input form-control border-1" id="datepickerFrom" required placeholder="DD/MM/YYYY" /> */}
                <DatePickerComp
                  id="datepickerFrom"
                  selected={startDate}
                  onChange={(date) => handlerDate(date, "start")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  minDate={fromMinDate} //{new Date(),10}//{new Date(),10}
                  maxDate={endDate ? endDate : new Date()} //{new Date()}
                />
                <label htmlFor="datepickerFrom">
                  Date From{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_to">
                {/* <input type="text" className="datepicker_input form-control border-1" id="datepickerTo" required placeholder="DD/MM/YYYY" /> */}
                <DatePickerComp
                  id="datepickerTo"
                  selected={endDate}
                  onChange={(date) => handlerDate(date, "end")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  startDate={endMinDate}
                  minDate={endMinDate} //{new Date(),10}
                  maxDate={endMaxDate ? endMaxDate : new Date()}
                  showDisabledMonthNavigation
                />
                <label htmlFor="datepickerTo">
                  Date To{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                // disabled={searchBtnDisabled}
                disabled={(multiCrop?.cropyear === undefined || multiCrop?.cropyear === "" || multiContId?.contract_id  === undefined || multiContId?.contract_id  === "")}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            // disabled ={searchBtnDisabled}
            disabled={(multiCrop?.cropyear === undefined || multiCrop?.cropyear === "" || multiContId?.contract_id  === undefined || multiContId?.contract_id  === "")}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}

      {isListingHide == false ? (
        <>
          {showSummary && (
            <>
              {/*<!-- Summary block starts -->*/}
              <h3>Summary</h3>
              <h5>
                The grid below is a summary from your search criteria. The
                detail making up this summary shown on the bottom of the page.
                Using the export function will provide additional details.{" "}
              </h5>

              {/*<!-- Delivery data grid starts -->*/}
              {/*<!-- Summary & Delivery Data table starts -->*/}
              <div className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 max_content_table delivery-recipts-head-table">
                <div className="table-responsive">
                  <table className="table table-striped grid_block mb-0">
                    <thead>
                      <tr className="blue_bg">
                        <th>Type</th>
                        <th>Completed Delivery Count</th>
                        <th>Sum of Delivery Weight</th>
                        <th>Sum of Total Barrels</th>
                        <th>Wtd. Avg Firmness</th>
                        <th>Wtd. Avg % &gt; 1/2</th>
                        <th>Wtd. Avg % Dry Trash</th>
                        <th>Wtd. Avg % Moist</th>
                        <th>Wtd. Avg % Total Trash</th>
                        <th>Wtd. Avg % Poor</th>
                        <th>Wtd. Avg % Class1</th>
                        <th>Wtd. Avg % Usable</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Processed</td>
                        <td className="text-end">{proDeliveryCount}</td>
                        <td className="text-end">{proDeliveryWeight}</td>
                        <td className="text-end">{proTotalBarrels}</td>
                        <td className="text-end">{proAvgfirm}</td>
                        <td className="text-end">{proAvgHalf}</td>
                        <td className="text-end">{proAvgDryTrash}</td>
                        <td className="text-end">{proAvgMoist}</td>
                        <td className="text-end">{proAvgTrash}</td>
                        <td className="text-end">{proAvgPoor}</td>
                        <td className="text-end">{proAvgClassOne}</td>
                        <td className="text-end">{proAvgUsable}</td>
                      </tr>
                      <tr>
                        <td>Fresh</td>
                        <td className="text-end">{freshDeliveryCount}</td>
                        <td className="text-end">{freshDeliveryWeight}</td>
                        <td className="text-end">{freshTotalBarrels}</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="text-end">{totalDeliveryCount}</td>
                        <td className="text-end">{totalDeliveryWeight}</td>
                        <td className="text-end">{totalTotalBarrels}</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                        <td className="text-end">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/*<!-- Summary & Delivery Data table ends -->*/}

          {/*<!-- Delivery Data by receipt starts -->*/}
          <div className="row mt-2">
            <div className="col-12 col-lg-6">
              <h3>Delivery Data by Receipt</h3>
            </div>
            <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <DeliveryDataPdfFile 
                    summaryHeader={summaryHeaderPDF}
                    summaryContent={summaryContentPDF}
                    parameterData={exportParameter}
                    pdfData={PDFExport} 
                    fileName="ReceiptData"
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="csv"
                    exportData={exportItems}
                    fileName="ReceiptData"
                    formatArray = {formatArray}
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={exportItems}
                    fileName="ReceiptData"
                    showParameter={true}
                    parameterData={exportParameter}
                    formatArray = {formatArray}
                  />
                </span>
              </div>
            </div>
          </div>
          {/*<!-- Responsive table starts -->*/}
          {!displayAccordian && 
          <div
            className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space ${
              isListingHide == true ? "d-none" : "d-none d-lg-block"
            }`}
          >
            <DataGridPagination
              uniqueIdentifier="DataIdValue"
              gridData={gridItems}
              columns={columns}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6 table-column-z-index-0"
              dynamicHeight={80}
              dynamicHeaderHeight={70}
            />
          </div>
          }
          {/*<!-- Responsive table ends -->*/}

          <div
            className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <DDReceiptsMobile listingData={resultItems} />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
      {/*<!-- Delivery Data by receipt ends -->*/}
    </>
  );
};
export default DDReceipts;
