
import React, {
    useMemo, useState
} from 'react';
import DataGrid from 'react-data-grid';
import { toDecimal, toDecimalWithNegative } from '../../../../utils/helper';

const AREColumns = [
    // { key: 'id', name: 'ID', width: '15%' },
    { key: 'Date', name: 'Date', width: '10%',cellClass: "enablesTextWrapping ", },
    { key: 'Crop Year', name: 'Crop Year', width: '15%',cellClass: "enablesTextWrapping text-lg-center", },
    { key: 'Equity Type', name: 'Equity Type', width: '15%',cellClass: "enablesTextWrapping text-lg-center", },
    { key: 'Description', name: 'Description', width: '45%',cellClass: "enablesTextWrapping", },
    {
        key: 'ARE', name: 'ARE', width: '15%',
        cellClass: "enablesTextWrapping text-lg-end",
        formatter(props) {
            return <>{toDecimalWithNegative(props.row?.ARE?.toFixed(2), 3, "$")}</>;
        },
    }
];

function CellExpanderFormatter({
    tabIndex,
    expanded,
    onCellExpand
}) {
    // console.log("Expand", tabIndex, expanded,)
    function handleKeyDown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return <>
        <div style={{
            blockSize: '100%',
            alignContent: 'center',
            textAlign: 'center',
            cursor: 'pointer'
        }} onClick={onCellExpand} onKeyDown={handleKeyDown}>
            <span tabIndex={tabIndex}>{expanded ? <i className="fa fa-minus-circle cursor_pointer table_accordion"></i> : <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>}</span>
        </div>
    </>

        ;
}

export function MasterDetail({ quotas, childData }) {
    const data = quotas.map((i, k) => {
        return {
            ...i,
            "expanded": false,
            "type": "MASTER",
            "id": k
        }
    });
    const direction = "ltr";
    // console.log("quotas", quotas)
    const columns = useMemo(() => {
        return [
            {
                key: 'expanded',
                name: '',
                width: '30',
                colSpan(args) {
                    return args.type === 'ROW' && args.row.type === 'DETAIL' ? 7 : undefined;
                },
                cellClass(row) {
                    // console.log("cellClass", row.type)
                    return row.type === 'DETAIL'
                        ? `padding: 24px;`
                        : undefined;
                },
                formatter({ row, tabIndex, onRowChange }) {
                    // console.log("renderCell", row, tabIndex, onRowChange, direction)
                    if (row.type === 'DETAIL') {
                        return <AREDetailGrid parentId={row.RelationshipNumber} data={childData} row={row} direction={direction} />;
                    }
                    if (row.EquityType !== "ARE")
                        return ""

                    return (
                        <CellExpanderFormatter
                            expanded={row.expanded}
                            tabIndex={tabIndex}
                            onCellExpand={() => {
                                onRowChange({ ...row, expanded: !row.expanded });
                            }}
                        />
                    );
                }
            },
            {
                key: "RelationshipNumber",
                name: "Contract Number",
                width: 165,
                sortable: true,
            },
            {
                key: "Name",
                name: "Contract Name",
                cellClass: "enablesTextWrapping",
                width: 180,
                sortable: true,
            },
            {
                key: "EquityType",
                name: "Equity Type",
                cellClass: "enablesTextWrapping",
                width: 160,
                sortable: true,
            },
            {
                key: "FormType",
                name: "Form Type",
                width: 160,
                sortable: true,
            },
            {
                key: "Shares",
                name: "Shares",
                width: 130,
                cellClass: "text-lg-end",
                sortable: true,
                formatter(props) {
                    return (
                        <>{props.row.Shares == 0 ? "" : toDecimal(props.row.Shares, 3)}</>
                    );
                },
            },
            {
                key: "Value",
                name: "Value",
                width: 160,
                sortable: true,
                formatter(props) {
                    return <>{toDecimal(props.row?.Value?.toFixed(2), 3, "$")}</>;
                },
            },
        ];
    }, [direction]);

    const [rows, setRows] = useState([...data]);
    const [sotDirection, setSortDirection] = useState('ASC');
    const [sortColumn, setSortColumn] = useState('RelationshipNumber');

    function onRowsChange(rows, indexes) {
        // console.log("rows, indexes", rows, indexes)
        const row = rows[indexes.indexes[0]];
        if (row.type === 'MASTER') {
            if (row.expanded) {
                rows.splice(indexes.indexes[0] + 1, 0, {
                    ...row,
                    type: 'DETAIL',
                    id: row.id,
                    parentId: row.id
                });
            } else {
                rows.splice(indexes.indexes[0] + 1, 1);
            }
            setRows(rows);
        }
    }

    const handleSort = (columnKey) => {
        let sortedRows;
        let direction;
        let previouscolumnkey;
        let previousSortOrder;
        // console.log("sortColumn, sotDirection", sortColumn, sotDirection)
        setSortColumn((prev) => {
            previouscolumnkey = prev;
            // console.log("prev-1",prev);
            return columnKey
        });

        setSortDirection((prev) => {
            previousSortOrder = prev;

            if (previouscolumnkey === columnKey) {
                direction = previousSortOrder === 'ASC' ? 'DESC' : 'ASC';
            } else {
                direction = 'ASC';
            }
            // console.log("prev-2",prev);
            return direction
        });

        sortedRows = [...rows].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'ASC' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'ASC' ? 1 : -1;
            return 0;
        });
        // console.log("sortedRows", sortedRows);
        setRows([...sortedRows]);

    }

    const renderHeader = (col, sortColumn, sotDirection) => {
        setSortColumn((prev) => {
            return prev
        });

        setSortDirection((prev) => {
            return prev
        })
        return <div onClick={() => handleSort(col.key)}>
            {col.name} {sortColumn === col.key ? (sotDirection === 'ASC' ? <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1mygwml7-0-0-beta-20" aria-hidden="true"><path d="M0 8 6 0 12 8"></path></svg> : <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1mygwml7-0-0-beta-20" aria-hidden="true"><path d="M0 0 6 8 12 0"></path></svg>) : ''}
        </div>
    };

    return (
        <div className='menu'>
            {rows.length > 0 && <>
                <DataGrid
                    rowKeyGetter={rowKeyGetter}
                    // columns={columns}
                    columns={columns.map(col => ({
                        ...col,
                        headerRenderer: () => renderHeader(col, sortColumn, sotDirection) // Use custom header for sorting
                    }))}
                    rows={rows}
                    onRowsChange={onRowsChange}
                    // rowHeight={(row) => (row.type === 'DETAIL' ? 350 : 45)}
                    className="contain-block-size-reset bot-mspace-20 fill-grid padding-with-10 border-radius-6"
                    // enableVirtualization={false}
                    direction={direction}
                // onCellKeyDown={(_, event) => {
                //     if (event.isDefaultPrevented()) {
                //         // skip parent grid keyboard navigation if nested grid handled it
                //         event.preventGridDefault();
                //     }
                // }}
                />
            </>}
        </div>

    );
}

function AREDetailGrid({ parentId, direction, data }) {
    data = data?.filter(i => i['Contract Number'] === parentId)
    let result_Arr = data.sort(function compare(a, b) {
        // var dateA = new Date(a['Crop Year']);
        // var dateB = new Date(b.Date);
        return a['Crop Year'] - b['Crop Year'];
    });
    const [detiilRow, setDetiilRow] = useState([...result_Arr]);


    const [sotDirectionDetail, setSortDirectionDetail] = useState('ASC');
    const [sortColumnDetail, setSortColumnDetail] = useState('Crop Year');

    const handleSort = (columnKey) => {
        let sortedRows;
        let direction;
        let previouscolumnkey;
        let previousSortOrder;
        setSortColumnDetail((prev) => {
            previouscolumnkey = prev;
            return columnKey
        });

        setSortDirectionDetail((prev) => {
            previousSortOrder = prev;

            if (previouscolumnkey === columnKey) {
                direction = previousSortOrder === 'ASC' ? 'DESC' : 'ASC';
            } else {
                direction = 'ASC';
            }
            return direction
        });
        if (columnKey === "Date") {
            sortedRows = detiilRow.sort(function compare(a, b) {
                var dateA = new Date(a.Date);
                var dateB = new Date(b.Date);
                return direction === 'DESC' ? dateB - dateA : dateA - dateB;
            })
        }
        else {
            sortedRows = [...detiilRow].sort((a, b) => {
                if (a[columnKey] < b[columnKey]) return direction === 'ASC' ? -1 : 1;
                if (a[columnKey] > b[columnKey]) return direction === 'ASC' ? 1 : -1;
                return 0;
            });
        }

        setDetiilRow([...sortedRows]);

    }
    const renderDetailHeader = (col, sortColumn, sotDirection) => {
        setSortColumnDetail((prev) => {
            return prev
        });

        setSortDirectionDetail((prev) => {
            return prev
        })
        return <div onClick={() => handleSort(col.key)}>
            {col.name} {sortColumn === col.key ? (sotDirection === 'ASC' ? <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1mygwml7-0-0-beta-20" aria-hidden="true"><path d="M0 8 6 0 12 8"></path></svg> : <svg viewBox="0 0 12 8" width="12" height="8" className="rdg-sort-arrow a1mygwml7-0-0-beta-20" aria-hidden="true"><path d="M0 0 6 8 12 0"></path></svg>) : ''}
        </div>
    };

    return (
        <div>
            <p className='text-end m-2'>
            </p>
            <DataGrid
                className="are-details border-radius-6 "
                // ref={gridRef}
                rows={detiilRow}
                // columns={AREColumns}
                columns={AREColumns.map(col => ({
                    ...col,
                    headerRenderer: () => renderDetailHeader(col, sortColumnDetail, sotDirectionDetail) // Use custom header for sorting
                }))}
                rowKeyGetter={rowKeyGetter}
                style={{ blockSize: 250 }}
                direction={direction}
            />
            <p className='m-2'></p>
        </div>

    );
}

function rowKeyGetter(row) {
    return row.id;
}
