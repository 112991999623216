import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import CurrentLocation from "./currentLocation";
import { useTranslation } from "react-i18next";
import { ConstantsVar } from "../../utils/constants";
import { IsFunctionChecker, HandleErrorTrackAndToast } from "../../utils/helper";

const ImageView = ({
  url,
  setAutoPlay,
  className = "cursor-pointer",
  component,
  height=180,
  width="auto",
}) => {
  const {
    defaultEventImageURL,
    defaultEventHomeImageURL,
    // croppedHarvestImageURL,
    // croppedHarvestHomeImageURL,
    croppedHarvestZoomImageURL,
  } = ConstantsVar;

  const [openModal, setOpenModal] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 3, z: 3, scale: 1 });
  const [disablePointerIn, setDisablePointerIn] = useState(false);
  const [disablePointerOut, setDisablePointerOut] = useState(true);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const ZoomIn = () => {
    if (points.y <= 3 && points.z >= 3 && points.z < 6) {
      setPoints({
        x: points.x + 1,
        y: points.y - 1,
        z: points.z + 1,
        scale: points.scale + 0.25,
      });
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(true);
      setDisablePointerOut(false);
    }
  };
  const ZoomOut = () => {
    if (points.z > 3 && points.z <= 6) {
      setPoints({
        x: points.x - 1,
        y: points.y + 1,
        z: points.z - 1,
        scale: points.scale - 0.25,
      });
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(false);
      setDisablePointerOut(true);
    }
  };

  const GetObjectURL = (url = null) => {
    try {
      if (url) {
        return URL.createObjectURL(url);
      }
      return defaultEventImageURL;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultEventImageURL;
    }
  };

  const DisplayImage = (component === 'plain-img' || component ===  'upcoming-event') ? url : GetObjectURL(url);
  return (
    <>
      {
        component === 'upcoming-event' ? <img
          src={DisplayImage}
          className={`${className} cursor-pointer`}
          alt=""
          onClick={() => {
            setOpenModal(!openModal);
            IsFunctionChecker(setAutoPlay) && setAutoPlay(false);
          }}
        /> :
          <img
            height={height}
            width={width}
            src={DisplayImage}
            className={className}
            alt=""
            onError={(e) => {
              const { currentTarget } = e;
              currentTarget.onerror = null;
              currentTarget.src =
                component === "home"
                  ? defaultEventHomeImageURL
                  : defaultEventImageURL;
              currentTarget.className = `cursor-pointer width-100 height-90 test ${component !== "home" && "height-80-mobile"
                }`;
              currentTarget.height = {height};
            }}
            onClick={() => {
              setOpenModal(!openModal);
              IsFunctionChecker(setAutoPlay) && setAutoPlay(false);
            }}
          />
      }

      <div
        className={openModal ? "modal fade show" : "modal fade"}
        id="RecentDeliveryModal1"
        tabIndex="-1"
        aria-labelledby="RecentDeliveryModal1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header border-0 p-3 pb-0 d-inline-block text-center vlight_bg">
              <button
                type="button"
                className="btn btn-primary btn-sm text-14"
                onClick={() => {
                  setOpenModal(!openModal);
                  IsFunctionChecker(setAutoPlay) && setAutoPlay(true);
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close pe-1"></i>
                {moreLangs[i18n.language]?.home_page?.close}
              </button>
              <div className="btnDiv mt-4 my-1">
                <span
                  className={
                    disablePointerIn
                      ? "zoom-in cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1 disablePointer"
                      : "zoom-in cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1"
                  }
                  onClick={ZoomIn}
                  data-tip={moreLangs[i18n.language]?.home_page?.zoom_in}
                  data-for="zoomIn"
                  data-iscapture="true"
                >
                  <i
                    className="fa fa-search-plus text-20 blue_color"
                    aria-hidden="true"
                  ></i>
                </span>
                <ReactTooltip
                  id="zoomIn"
                  place="bottom"
                  effect="solid"
                  type="dark"
                  multiline={true}
                />
                <span
                  className={
                    disablePointerOut
                      ? "zoom-in cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1 disablePointer"
                      : "zoom-in cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1"
                  }
                  onClick={ZoomOut}
                  data-tip={moreLangs[i18n.language]?.home_page?.zoom_out}
                  data-for="zoomOut"
                  data-iscapture="true"
                >
                  <i
                    className="fa fa-search-minus text-20 blue_color disablePointer"
                    aria-hidden="true"
                  ></i>
                </span>
                <ReactTooltip
                  id="zoomOut"
                  place="bottom"
                  effect="solid"
                  type="dark"
                  multiline={true}
                />
              </div>
            </div>
            <div className="modal-body vlight_bg modalBodyImage">
              <img
                src={DisplayImage}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = croppedHarvestZoomImageURL;
                }}
                className="img-fluid mx-auto d-block ModalImage"
                id="image_delivery_large"
                alt="ModalImage"
                style={{ transform: `scale(${points.scale})` }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageView;
